import { graphql, PageProps } from "gatsby"
import React from "react"
import { FounderProvider } from "../context/founder"
import { useAboutUsModalSelector, useFounderFilter } from "../hooks"
import {
  AboutUsModal,
  AdvisorsSection,
  AuditorsSection,
  DirectorSection,
  FoundersSection,
  Investors,
} from "../components"
import {
  BreadCrumb,
  LandingLayout,
} from "../../../feature/navigation/components"
import { Container, ResponsiveText } from "../../../components/layout"
import { Box, VStack } from "@youngagency/young-ui"
import { LeadershipSection } from "../components/LeadershipSection"
import { useI18next } from "gatsby-plugin-react-i18next"
import {
  AlternateHandler,
  MetaDecorator,
  WebPageSchema,
} from "../../../feature/seo"
import { TranslationProvider } from "../../../feature/translations/context"

const Founder = ({ data }: PageProps<Queries.AllFoundersQueryQuery>) => {
  const ctx = useFounderFilter(data?.allWpAboutUs?.nodes)
  const modalCtx = useAboutUsModalSelector(
    ctx?.people,
    data?.allWpAboutUs?.nodes
  )

  const finalCtx = React.useMemo(() => ({ ...ctx, ...modalCtx }), [
    ctx,
    modalCtx,
  ])
  const { t } = useI18next()
  const breadcrumb = React.useMemo(
    () => [
      {
        text: t("breadcumbs.home_breadcrumb"),
        link: "/",
      },
      {
        text: "CDA & Founders",
        link: "/cda-and-founders",
      },
    ],
    [t]
  )

  return (
    <TranslationProvider>
      <LandingLayout
        dinamycLink="https://youngexchange.page.link/aboutusmenu"
        appleLink="https://youngexchange.page.link/aboutusios"
        playStoreLink="https://youngexchange.page.link/aboutusand"
      >
        <AlternateHandler />
        <MetaDecorator
          metaTitle={t("about_us.founders.seo.tilte")}
          metaDescription={t("about_us.founders.seo.description")}
        />
        <WebPageSchema
          title={t("about_us.founders.seo.tilte")}
          description={t("about_us.founders.seo.description")}
          breadcrumbs={breadcrumb}
        />
        <Container size={["md", "lg"]}>
          <BreadCrumb breadcrumbs={breadcrumb} />
          <Box mt={["24px", "48px"]}>
            <VStack align="start">
              <ResponsiveText
                line={["39px", "60px"]}
                dimension={["34px", "56px"]}
                as="h1"
                fontWeight={600}
              >
                {t("about_us.founders.title")}
              </ResponsiveText>
              <Box mt={["16px", "24px"]}>
                <ResponsiveText
                  line={["24px", "30px"]}
                  dimension={["16px", "20px"]}
                >
                  {t("about_us.founders.description")}
                </ResponsiveText>
              </Box>
            </VStack>
          </Box>
        </Container>
        <FounderProvider
          value={{
            ...finalCtx,
          }}
        >
          <Container size={["md", "lg"]}>
            <Box mt={["24px", "48px"]}>
              <DirectorSection />
            </Box>
            <Box mt={["48px", "96px"]}>
              <LeadershipSection />
            </Box>
            <Box mt={["48px", "96px"]}>
              <FoundersSection />
            </Box>
            <Box mt={["48px", "96px"]}>
              <AdvisorsSection />
            </Box>
            <Box mt={["48px", "96px"]}>
              <AuditorsSection />
            </Box>
            <Box mt={["48px", "96px"]}>
              <Investors />
            </Box>
          </Container>
          <AboutUsModal />
        </FounderProvider>
      </LandingLayout>
    </TranslationProvider>
  )
}

export const query = graphql`query AllFoundersQuery($language: String!, $pageLanguage: WpLanguageCodeEnum!) {
  allWpAboutUs(
    filter: {language: {code: {eq: $pageLanguage}}}
    sort: {title: ASC}
  ) {
    nodes {
      databaseId
      title
      content
      about_us {
        linkedin
        role
      }
      featuredImage {
        node {
          gatsbyImage(width: 366, placeholder: TRACED_SVG)
        }
      }
      allWpAboutGroups {
        nodes {
          name
          slug
        }
      }
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}`

export default Founder
