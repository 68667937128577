import React from "react"
import { Helmet } from "react-helmet"
import { breadCrumbProps } from "../types"
import { useWebPageSchema, useBreadcrumbSchema } from "../hooks"

type Props = {
  breadcrumbs: breadCrumbProps[]
  title?: string | null
  description?: string | null
}

export const WebPageSchema = ({ breadcrumbs, title, description }: Props) => {
  const schema = useWebPageSchema({
    title,
    description,
  })
  const breadcrumbSchema = useBreadcrumbSchema(breadcrumbs)

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          ...schema,
          ["@graph"]: [...schema["@graph"], breadcrumbSchema],
        })}
      </script>{" "}
    </Helmet>
  )
}
