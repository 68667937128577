const getAuthorLogo = (string: string) => {
  const [first, last] = string.split(" ")
  return `${first[0]}${last[0]}`.toUpperCase()
}

const isBrowser = typeof window !== "undefined"

const getLocalePostFormattedDate = (
  date: string,
  lng: string,
  dateOptions = {
    month: "long",
    year: "numeric",
    day: "numeric",
  }
) => {
  let postRowDate: string | Date | string[] = new Date(Date.parse(date))

  postRowDate = new Intl.DateTimeFormat(
    lng.toLocaleLowerCase(),
    dateOptions as any
  ).format(postRowDate)

  postRowDate = postRowDate.split(" ")

  let rawFormattedData = `${postRowDate[0]} ${postRowDate[1]}, ${postRowDate[2]}`
  if (lng === "en") {
    return rawFormattedData.replace(",", "")
  }

  return rawFormattedData
}

const handleWpBannerSpaceInjection = (
  selector: string,
  langTexts: string[]
) => {
  if (typeof window != "undefined") {
    let bannerText = document.querySelectorAll(selector)
    if (bannerText && bannerText.length > 0) {
      bannerText.forEach(text => {
        langTexts.forEach(langText => {
          let indexPosition =
            text?.textContent &&
            text?.textContent
              .toLowerCase()
              .indexOf(langText.toLowerCase() as string)

          if (!text.textContent || !indexPosition || indexPosition < 0) {
            return
          }
          text.innerHTML =
            text.textContent.slice(0, indexPosition) +
            "<br/>" +
            text.textContent.slice(indexPosition)
        })
      })
    }
    return
  }
}

export {
  getAuthorLogo,
  getLocalePostFormattedDate,
  handleWpBannerSpaceInjection,
  isBrowser,
}
