import { GlossaryTermProps } from "../../../types/glossary"
import { GlossaryStorageProps } from "../anchorlist"

const handleActualLetter = (list: GlossaryStorageProps[]) => {
  let first = true

  list.forEach(word => {
    if (word.words_list.length > 0) {
      let element = document.querySelector("#" + word.link)

      if (!element) {
        return
      }

      let rect =
        typeof window !== "undefined" && element.getBoundingClientRect()

      if (!rect) {
        return
      }

      if (rect.top > 70 && first) {
        first = false

        let anchor_element = document.querySelector(
          "#anchor_" + word.link + " p"
        )

        let old_anchor_element = document.querySelector(".actual-anchor")

        if (old_anchor_element) {
          old_anchor_element.classList.remove("actual-anchor")
        }

        if (!anchor_element) {
          return
        }

        anchor_element.classList.add("actual-anchor")
      }
    }
  })
}

const formatGlossaryList = (
  words_graphql: GlossaryTermProps[],
  list: GlossaryStorageProps[],
  search: string
) => {
  if (words_graphql) {
    words_graphql.sort(function (a, b) {
      if (a.title < b.title) {
        return -1
      }
      if (a.title > b.title) {
        return 1
      }

      return 0
    })

    list.forEach(menu_item => {
      menu_item.words_list = []
      menu_item.disabled = false
    })

    words_graphql.forEach(word => {
      list.forEach(menu_item => {
        if (menu_item.text == word.title.substring(0, 1)) {
          if (itemIsSearched(word, search)) {
            menu_item.words_list.push(word)

            menu_item.disabled = false
          } else if (menu_item.words_list.length == 0) {
            menu_item.disabled = true
          }
        }
      })
    })
  }
}

function itemIsSearched(word: GlossaryTermProps, search: string) {
  return (
    word.title.toLowerCase().lastIndexOf(search.toLowerCase()) >= 0 ||
    (word?.seo?.metaDesc &&
      word?.seo?.metaDesc.toLowerCase().lastIndexOf(search.toLowerCase()) >=
        0) ||
    search == ""
  )
}

//Evidenzia le parole che vengono cercate
const highlightsSearchedWord = (text: string, search: string) => {
  if (!search || search === "") {
    return text
  }
  return text.replace(
    new RegExp(search, "gmi"),
    "<span style='background: yellow'>$&</span>"
  )
}

export { handleActualLetter, formatGlossaryList, highlightsSearchedWord }
