import React from "react"
import {
  layout,
  typography,
  space,
  color,
  LayoutProps,
  TypographyProps,
  SpaceProps,
  ColorProps,
  variant,
  compose,
  styled,
  Box,
} from "@youngagency/young-ui"
import { ComponentWithAs } from "../../types/system"
import { css } from "@styled-system/css"

type FontVariant = "regular" | "semibold" | "medium" | "bold"

export type BaseTypographyProps = LayoutProps &
  ColorProps &
  TypographyProps &
  SpaceProps & {
    weight?: FontVariant
  }

export const BaseTypography: ComponentWithAs<
  "div",
  BaseTypographyProps
> = styled(Box)(
  compose(layout, typography, space, color),
  css({
    fontFamily: "Matter",
    mt: 0,
    mb: 0,
    padding: 0,
    border: 0,
    verticalAlign: "baseline",
  }),
  variant({
    prop: "weight",
    variants: {
      regular: {
        fontWeight: 400,
      },
      medium: {
        fontWeight: 500,
      },
      semibold: {
        fontWeight: 600,
      },
      bold: {
        fontWeight: 700,
      },
    },
  })
)

type Props = BaseTypographyProps & {
  children?: React.ReactNode
  disableResponsive?: true
}

export const Display: ComponentWithAs<"div", Props> = ({
  children,
  color,
  weight = "semibold",
  disableResponsive,
  ...rest
}) => {
  return (
    <BaseTypography
      fontSize={disableResponsive ? "64px" : ["34px", "64px"]}
      lineHeight={disableResponsive ? "70px" : ["39px", "70px"]}
      {...rest}
      color={color as any}
      weight={weight}
    >
      {children}
    </BaseTypography>
  )
}

export const HeadingXL: ComponentWithAs<"div", Props> = ({
  children,
  color,
  weight = "semibold",
  disableResponsive,
  ...rest
}) => {
  return (
    <BaseTypography
      fontSize={disableResponsive ? "49px" : ["34px", "48px"]}
      lineHeight={disableResponsive ? "50px" : ["39px", "50px"]}
      {...rest}
      color={color as any}
      weight={weight}
    >
      {children}
    </BaseTypography>
  )
}
export const Heading: ComponentWithAs<"div", Props> = ({
  children,
  color,
  weight = "semibold",
  disableResponsive,
  ...rest
}) => {
  return (
    <BaseTypography
      fontSize={disableResponsive ? "34px" : ["24px", "34px"]}
      lineHeight={disableResponsive ? "39px" : ["30px", "39px"]}
      {...rest}
      color={color as any}
      weight={weight}
    >
      {children}
    </BaseTypography>
  )
}

export const SubTitle: ComponentWithAs<"div", Props> = ({
  children,
  color,
  weight = "semibold",
  disableResponsive,
  ...rest
}: Props) => {
  return (
    <BaseTypography
      fontSize={disableResponsive ? "24px" : ["16px", "24px"]}
      lineHeight={disableResponsive ? "30px" : ["20px", "30px"]}
      {...rest}
      color={color as any}
      weight={weight}
    >
      {children}
    </BaseTypography>
  )
}

export const Description: ComponentWithAs<"div", Props> = ({
  children,
  color,
  weight = "regular",
  disableResponsive,
  ...rest
}: Props) => {
  return (
    <BaseTypography
      fontSize={disableResponsive ? "20px" : ["16px", "20px"]}
      lineHeight={disableResponsive ? "25px" : ["20px", "25px"]}
      {...rest}
      color={color as any}
      weight={weight}
    >
      {children}
    </BaseTypography>
  )
}

export const Paragraph: ComponentWithAs<"div", Props> = ({
  children,
  color,
  weight = "regular",
  disableResponsive,
  ...rest
}: Props) => {
  return (
    <BaseTypography
      fontSize={disableResponsive ? "16px" : ["16px", "16px"]}
      lineHeight={disableResponsive ? "20px" : ["20px", "20px"]}
      {...rest}
      color={color as any}
      weight={weight}
    >
      {children}
    </BaseTypography>
  )
}

export const Label: ComponentWithAs<"div", Props> = ({
  children,
  color,
  weight = "regular",
  disableResponsive = true,
  ...rest
}: Props) => {
  return (
    <BaseTypography
      fontSize={disableResponsive ? "12px" : ["10px", "12px"]}
      lineHeight={disableResponsive ? "15px" : ["12px", "15px"]}
      {...rest}
      color={color as any}
      weight={weight}
    >
      {children}
    </BaseTypography>
  )
}
