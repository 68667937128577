import React from "react"
import BlogNavLinks from "../../../components/blog/BlogNavLink"
import { HeaderTrasnaltion } from "../types"
import { Header } from "./Header"
import { Box, BoxProps, HStack, Icon, Label } from "@youngagency/young-ui"
import styled from "styled-components"
import { Container } from "../../../components/layout"
import { SeoLink } from "../../seo"
import { useI18next } from "gatsby-plugin-react-i18next"
import { BlogSidebar } from "./BlogSidebar"
import { useNavigationContext } from "../context"
// import { PromoBanner } from "../../promo/components"
import { CryptoHubBanner } from "../../b2b/components/crypto-hub"
import { PromoBanner } from "../../promo/components"

type Props = {
  isBlogHome?: true
  slug?: string | null
} & HeaderTrasnaltion

const BlogBannerContainer = styled(Box)`
  display: none;

  @media screen and (min-width: 1024px) {
    background: #f2f2f6;
    display: flex;
    height: 36px;
    transition: all 125ms ease;

    & a:hover .link-back > * {
      color: #101010 !important;
    }
  }
`

const TempBlogBanner = () => {
return <PromoBanner showedPlatform="blog"/>  
}

export const BlogHeader = ({ isBlogHome, slug }: Props) => {
  const context = useNavigationContext()
  return (
    <>
      <Header banner={<TempBlogBanner />}>
        <BlogNavLinks isBlogHome={isBlogHome} slug={slug} />
      </Header>
      {context.isSidebarOpen && <BlogSidebar />}
    </>
  )
}

export const BlogBanner = ({ ...rest }: BoxProps) => {
  const { t } = useI18next()

  return (
    <BlogBannerContainer {...rest}>
      <Container>
        <HStack align="center" justify="start">
          <SeoLink isExternal={false} link="/">
            <HStack
              spacing="6px"
              align="center"
              justify="start"
              className="link-back"
            >
              <Icon icon="arrow_left" color="grey.1" />
              <Label
                size="md"
                color="grey.1"
                fontWeight={400}
                fontFamily="Matter"
              >
                {t("blog.banner.backLink")}
              </Label>
            </HStack>
          </SeoLink>
        </HStack>
      </Container>
    </BlogBannerContainer>
  )
}
