import { Box, css, Icon, IconType, styled } from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { Avatar, ResponsiveText } from "../../../../components/layout"
import { CenterTextBox } from "../../../../components/shared"
import { createArrayFromJson } from "../../../../utils/general"

const StyledStack = styled(Box)(
  css({
    display: "flex",
    flexDirection: ["column", "row"],
    marginTop: ["24px", "48px"],
    "& > *:not(:first-of-type)": {
      marginTop: ["16px", "0px"],
      marginLeft: ["0px", "20px"],
    },
    ".card-container": {
      "& > *:not(:first-of-type)": {
        marginTop: ["8px", "16px"],
      },
    },
  })
)

export const CardSection = () => {
  const { t } = useI18next()
  const cardsArray = React.useMemo(() => {
    return createArrayFromJson(
      t,
      "token_yng.hodl.cards",
      3,
      [],
      "icon",
      "title",
      "description"
    )
  }, [t])
  return (
    <Box>
      <CenterTextBox
        title={t("token_yng.hodl.title")}
        description={t("token_yng.hodl.description")}
      />
      <StyledStack>
        {cardsArray.map(({ title, description, icon }) => {
          return (
            <Box
              p={["16px", "24px"]}
              border="1px solid"
              borderColor="grey.5"
              borderRadius="16px"
            >
              <Box className="card-container">
                <Avatar
                  shape="circle"
                  backgroundColor="green.300"
                  width="56px"
                  height="56px"
                  mb={["8px", "16px"]}
                >
                  <Icon icon={icon as IconType} fontSize="24px" color="white" />
                </Avatar>
                <ResponsiveText
                  line={["22px", "22px"]}
                  dimension={["18px", "18px"]}
                  as="p"
                  fontWeight={600}
                >
                  {title}
                </ResponsiveText>
                <ResponsiveText
                  line={["20px", "20px"]}
                  dimension={["16px", "16px"]}
                  as="p"
                >
                  {description}
                </ResponsiveText>
              </Box>
            </Box>
          )
        })}
      </StyledStack>
    </Box>
  )
}
