import { Box, useMediaQuery } from "@youngagency/young-ui"
import {
  GatsbyImage,
  getImage,
  ImageDataLike,
  StaticImage,
} from "gatsby-plugin-image"
import truncate from "lodash/truncate"
import React from "react"
import { ResponsiveText } from "../../../../components/layout"
import { RelatedArticleWrapper } from "../../../posts/components"
import { SeoLink } from "../../../seo"
type Props = {
  title: string | null
  slug: string
  seo?: {
    metaDesc?: string | null
  }
  featuredImage?: {
    node: ImageDataLike
  }
  categories: {
    nodes: {
      slug: string
    }[]
  }
}

const BlogRelatedArticle = ({
  title,
  slug,
  seo,
  featuredImage,
  categories,
}: Props) => {
  const image = React.useMemo(() => {
    if (featuredImage) {
      return getImage(featuredImage.node)
    }
  }, [featuredImage])

  const { isMobile } = useMediaQuery()

  const blogLink = React.useMemo(() => {
    return `/blog/${categories?.nodes[0]?.slug}/${slug}/`
  }, [categories?.nodes, slug])
  return (
    <SeoLink isExternal={false} link={blogLink}>
      <RelatedArticleWrapper>
        <Box maxWidth="387px" flex={1}>
          <Box mb={["8px", "16px"]} height={"20px"}>
            <StaticImage
              height={20}
              src="../../../posts/images/logo_blog.png"
              alt="Young Platform Academy"
            />
          </Box>
          {title ? (
            <ResponsiveText
              dimension={["16px", "20px"]}
              line={["20px", "25px"]}
              fontWeight={600}
            >
              {isMobile ? truncate(title) : title}
            </ResponsiveText>
          ) : null}
          <Box mt="8px">
            {seo?.metaDesc ? (
              <ResponsiveText
                dimension={["14px", "16px"]}
                line={["18px", "20px"]}
                fontWeight={400}
              >
                {isMobile
                  ? truncate(seo.metaDesc, { length: 75 })
                  : seo.metaDesc}
              </ResponsiveText>
            ) : null}
          </Box>
        </Box>
        {image ? (
          <Box
            width="100%"
            maxWidth={["92px", "296px"]}
            display="flex"
            maxHeight={["92px", "192px"]}
            borderRadius="16px"
            overflow="hidden"
            flex={1}
          >
            <GatsbyImage image={image} alt={title || "Article Image"} />
          </Box>
        ) : null}
      </RelatedArticleWrapper>
    </SeoLink>
  )
}

export default BlogRelatedArticle
