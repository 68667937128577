import { Box, BoxProps, flex, HStack } from "@youngagency/young-ui"
import React from "react"
import { Container, ResponsiveText } from "../../../components/layout"
import { css } from "@styled-system/css"
import styled from "styled-components"
import { SeoLink } from "../../seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { PostType } from "../types"
import { PostWrapper, VideoPost, Post } from "./Posts"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { blogArticleEvents, blogHomeEvents } from "../../../service/tracking"

type Props = {
  link?: string | null
  title?: string | null
  disableTitle?: boolean
  trackingFn?: () => void
  children: React.ReactNode
}

export const PostSectionsWrapper = styled(Box)<{ wall?: true }>(({ wall }) =>
  css({
    display: "flex",
    flexDirection: "column",
    "& > *:not(:last-of-type)": {
      marginBottom: wall ? ["48px", "56px"] : ["48px", "96px"],
    },
  })
)

const CustomBox = styled(Box)(
  css({
    display: "flex",
    flexDirection: ["column", "row"],
    "& > *:not(:last-of-type)": {
      marginBottom: ["24px", "0px"],
      marginRight: ["0px", "20px"],
    },
  })
)

export const Section = ({
  link,
  title,
  disableTitle,
  children,
  trackingFn,
  ...rest
}: Props & Omit<BoxProps, "title">) => {
  const { t } = useTranslation()
  const flexStyle = React.useMemo(() => {
    if (title && link) return "space-between"
    if (title) return "flex-start"
    if (link) return "flex-end"
  }, [])

  return (
    <Box as="section" {...rest}>
      <Container size={["md", "lg"]}>
        {!disableTitle && flexStyle ? (
          <HStack align="center" justify={flexStyle}>
            <ResponsiveText
              line={["22px", "29px"]}
              dimension={["18px", "24px"]}
              as="h2"
              fontWeight={600}
              color="inherit"
              style={{
                textTransform: "capitalize",
              }}
            >
              {title}
            </ResponsiveText>

            <SeoLink link={link as string} isExternal={false} onClick={trackingFn}>
              <ResponsiveText
                line={["22px", "29px"]}
                dimension={["18px", "24px"]}
                color="green.300"
              >
                {t(`blog.cta.sections`)}
              </ResponsiveText>
            </SeoLink>
          </HStack>
        ) : null}
        {children}
      </Container>
    </Box>
  )
}

export const PostSection = ({
  posts,
  disableTitle,
  trackingValue
}: {
  posts: readonly PostType[]
  disableTitle?: true
  trackingValue?: string | null
}) => {
  const { name, slug: link } =
    React.useMemo(() => {
      return posts[0]?.categories?.nodes?.[0]
    }, []) || {}
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)
  const handleTracking = React.useCallback(() => {
    if(gaTracker){
      gaTracker.trackEvent({
        eventName: blogHomeEvents.category_click,
        payload: {
          content: trackingValue?.toLowerCase() ?? null
        }
      })
    }
  }, [gaTracker])
   
  return (
    <Section link={`/blog/${link}/`} title={name} disableTitle={disableTitle} trackingFn={handleTracking}>
      <CustomBox mt={disableTitle ? "0px" : ["24px", "34px"]}>
        {posts.map(post => {
          return (
            <PostWrapper key={post.slug} {...post}>
              <Post />
            </PostWrapper>
          )
        })}
      </CustomBox>
    </Section>
  )
}

export const RelatedPostSection = ({
  posts,
  disableTitle,
}: {
  posts: readonly PostType[]
  disableTitle?: true
}) => {
  const { name, slug: link } =
    React.useMemo(() => {
      return posts[0]?.categories?.nodes?.[0]
    }, []) || {}

  const {gaTracker} = useSelector((state:RootState)=> state.tracking)
  const triggerRelatedClick = React.useCallback(() => {
    if(gaTracker){
      gaTracker.trackEvent({
        eventName: blogArticleEvents.body_related
      })
    }
  }, [gaTracker])
    
  return (
    <Section link={`/blog/${link}/`} title={name} disableTitle={disableTitle} onClickCapture={
      triggerRelatedClick
    }>
      <CustomBox mt={disableTitle ? "0px" : ["24px", "34px"]}>
        {posts.map(post => {
          return (
            <PostWrapper key={post.slug} {...post}>
              <Post isRelated />
            </PostWrapper>
          )
        })}
      </CustomBox>
    </Section>
  )
}

export const VideoSection = ({
  posts,
  disableTitle,
}: {
  posts: readonly PostType[]
  disableTitle?: true
}) => {
  const { name, slug: link } =
    React.useMemo(() => {
      return posts?.[0]?.tags?.nodes?.[0]
    }, []) || {}

    const {gaTracker} = useSelector((state:RootState)=> state.tracking)
  const handleTracking = React.useCallback(() => {
    if(gaTracker){
      gaTracker.trackEvent({
        eventName: blogHomeEvents.category_click,
        payload: {
          content: "video"
        }
      })
    }
  }, [gaTracker])
  return (
    <Section
      backgroundColor="#000"
      py={["48px", "96px"]}
      link={`/blog/tag/${link}/`}
      title={name}
      color="white"
      trackingFn={handleTracking}
    >
      <CustomBox mt={disableTitle ? "0px" : ["24px", "34px"]}>
        {posts.map((post, index) => {
          return (
            <PostWrapper key={post.slug} {...post}>
              <VideoPost first={index === 0} />
            </PostWrapper>
          )
        })}
      </CustomBox>
    </Section>
  )
}
