import React from "react"
import { CardProvider } from "../context/CardContext"
import { ShapedCardProps } from "../types"

export const HorizontalCard = ({ children, ...rest }: ShapedCardProps) => {
  return (
    <CardProvider orientation="row" shape="horizontal" {...rest}>
      {children}
    </CardProvider>
  )
}
