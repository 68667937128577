import React from "react"
import { graphql, PageProps } from "gatsby"
import { Container, ResponsiveText } from "../../../components/layout"
import { Box, Display } from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import { capitalizeFirstLetter } from "../../../utils/helpers"
import { PaginatedPageContextProps } from "../../../types/blog"
import {
  WebPageSchema,
  MetaDecorator,
  AlternateHandler,
} from "../../../feature/seo"
import { BreadCrumb, BlogLayout } from "../../../feature/navigation/components"
import { I18NextCustomContext } from "../../../types/shared"
import { Paginator } from "../../../components/shared/Paginator"
import {
  PostSectionsWrapper,
  ArticleWall,
} from "../../../feature/blog/components"
import { PostType } from "../../../feature/blog/types"
import { TranslationProvider } from "../../../feature/translations/context"
import { CategoryTranslator } from "../../../feature/translations/components/Translator"

type ContextProps = I18NextCustomContext &
  PaginatedPageContextProps & {
    categoryName: string
    slug: string
  }

const CategoryPagintatedPage: React.FC<
  PageProps<Queries.PaginatedBlogCategoryQuery, ContextProps>
> = ({
  data,
  pageContext: { canonicalPath, numPages, slug, currentPage, categoryName },
}) => {
  const { allWpPost } = data
  const slicedCanonicalPath = React.useMemo(() => canonicalPath.slice(1), [])

  const breadcrumb = React.useMemo(
    () => [
      {
        text: "Home",
        link: "/",
      },
      {
        text: "Blog",
        link: "/blog/",
      },
      {
        text: capitalizeFirstLetter(categoryName),
        link: `${canonicalPath}/${currentPage}/`,
      },
    ],
    []
  )

  const { language } = useI18next()

  const ctx = React.useMemo(() => {
    const categoryTrx = new CategoryTranslator(
      allWpPost?.nodes?.[0]?.categories?.nodes?.[0]?.translations
    )

    const trx = categoryTrx.create(language, slug)

    return {
      translations: trx,
      disableTranslations: !categoryTrx.hasTranslation(
        allWpPost?.nodes?.[0]?.categories?.nodes?.[0]?.translations
      ),
    }
  }, [
    language,
    slug,
    allWpPost?.nodes?.[0]?.categories?.nodes?.[0]?.translations,
  ])

  return (
    <TranslationProvider value={ctx}>
      <BlogLayout
        slug={slug}
        dynamicLink={"https://youngexchange.page.link/blog3"}
      >
        <MetaDecorator
          metaTitle={categoryName}
          metaDescription={
            allWpPost?.nodes?.[0]?.categories?.nodes?.[0]?.description &&
            allWpPost.nodes[0].categories.nodes[0].description
          }
        />
        <WebPageSchema breadcrumbs={breadcrumb} />
        <AlternateHandler />
        <Container size={["md", "lg"]}>
          <BreadCrumb
            breadcrumbs={breadcrumb}
            // mt={["0px", "40px"]}
          />
        </Container>{" "}
        <main>
          <Container size={["md", "lg"]} mt={["34px", "57px"]}>
            <Display
              size="sm"
              as="h1"
              fontFamily="Matter"
              mb="12px"
              weight="heavy"
            >
              {categoryName}
            </Display>
            <ResponsiveText
              dimension={["14px", "18px"]}
              maxWidth="55ch"
              color="grey.1"
              line={["18px", "24px"]}
            >
              {allWpPost?.nodes?.[0]?.categories?.nodes?.[0]?.description &&
                allWpPost.nodes[0].categories.nodes[0].description
                  .replace(/<[^>]*>/g, " ")
                  .replace(/\s{2,}/g, " ")
                  .trim()}
            </ResponsiveText>
          </Container>{" "}
          {allWpPost?.nodes ? (
            <PostSectionsWrapper mt={["48px", "96px"]}>
              <ArticleWall posts={allWpPost?.nodes as readonly PostType[]} />
            </PostSectionsWrapper>
          ) : null}
          <Box mt={["34px", "56px"]}>
            <Paginator
              link={slicedCanonicalPath}
              page={currentPage}
              numOfPages={numPages}
            />
          </Box>
        </main>
      </BlogLayout>
    </TranslationProvider>
  );
}

export const articleWallQuery = graphql`query PaginatedBlogCategory($slug: String!, $language: String!, $skip: Int!, $limit: Int!, $pageLanguage: WpLanguageCodeEnum!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  allWpPost(
    sort: {date: DESC}
    skip: $skip
    limit: $limit
    filter: {categories: {nodes: {elemMatch: {slug: {eq: $slug}}}}, language: {code: {eq: $pageLanguage}}}
  ) {
    nodes {
      title
      id
      databaseId
      slug
      date
      modified
      seo {
        readingTime
      }
      tags {
        nodes {
          databaseId
          name
          slug
        }
      }
      categories {
        nodes {
          description
          name
          slug
          translations {
            slug
            language {
              code
              locale
            }
            posts {
              nodes {
                id
              }
            }
          }
        }
      }
      author {
        node {
          name
        }
      }
      featuredImage {
        node {
          mediaItemUrl
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
}`

export default CategoryPagintatedPage
