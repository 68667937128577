import React from "react"
import {
  createPopper,
  VirtualElement,
  Modifier,
  Placement,
  PositioningStrategy,
} from "@popperjs/core"
import { useHasMounted } from "@youngagency/young-ui"

type Props = {
  referenceID: string
  tooltipID: string
  placement?: Placement
  strategy?: PositioningStrategy
  modifiers?: Partial<Modifier<any, any>>[]
  events?: {
    open: string[]
    close: string[]
  }
  callbacks?: {
    onShow?: () => void
    onHide?: () => void
  }
}

const usePopper = ({
  referenceID,
  tooltipID,
  strategy,
  modifiers,
  placement,
  events,
  callbacks,
}: Props) => {
  const hasMounted = useHasMounted()
  const elements = React.useMemo(() => {
    if (hasMounted) {
      return {
        reference: document.getElementById(referenceID) as
          | Element
          | VirtualElement,
        tooltip: document.getElementById(tooltipID) as HTMLElement,
      }
    }
  }, [hasMounted])

  const popperInstance = React.useMemo(() => {
    if (elements) {
      return createPopper(elements.reference, elements.tooltip, {
        modifiers: modifiers,
        placement: placement,
        strategy: strategy || "fixed",
      })
    }
  }, [referenceID, tooltipID, elements])

  const resetTooltip = React.useCallback(() => {
    const allTooltip = document.querySelectorAll(".tooltip")
    if (allTooltip && allTooltip.length) {
      allTooltip.forEach(tooltip => {
        tooltip.removeAttribute("tooltip-data-show")
      })
    }
  }, [])

  const closeAll = React.useCallback(() => {
    const allLinks = Array.from(
      document.getElementsByClassName("toogle-tooltip")
    )
    allLinks.forEach(link => {
      events?.open.forEach(event => {
        link.addEventListener(event, resetTooltip)
        return () => {
          link.removeEventListener(event, resetTooltip)
        }
      })
    })
  }, [resetTooltip, events?.open, events?.close])

  const show = React.useCallback(() => {
    if (!elements?.tooltip || !popperInstance) {
      return
    }
    // resetTooltip()
    elements.tooltip.setAttribute("tooltip-data-show", "")
    popperInstance.update()
    callbacks?.onShow?.()
  }, [elements?.tooltip, popperInstance, closeAll])

  const hide = React.useCallback(() => {
    if (!elements?.tooltip) {
      return
    }
    elements.tooltip.removeAttribute("tooltip-data-show")
  }, [elements?.tooltip])

  React.useEffect(() => {
    if (!elements?.reference) {
      return
    }
    events?.open.forEach(event => {
      if (elements.reference instanceof Element) {
        elements.reference.addEventListener(event, show)
        return () => {
          if (elements.reference instanceof Element) {
            elements.reference.removeEventListener(event, show)
          }
        }
      }
    })
  }, [elements?.reference, show])

  React.useEffect(() => {
    if (!elements?.tooltip) {
      return
    }
    events?.close.forEach(event => {
      elements.tooltip.addEventListener(event, hide)
      return () => elements.tooltip.removeEventListener(event, hide)
    })
  }, [elements?.tooltip])

  const outsideClickHandler = React.useCallback(() => {
    if (!elements?.reference) {
      return
    }
    if (elements.reference instanceof Element) {
      document.addEventListener("click", function (e) {
        if (e.target !== elements.reference && e.target !== elements.tooltip) {
          hide()
        }
      })
    }
  }, [elements?.reference, hide])

  React.useEffect(() => {
    document.addEventListener("click", outsideClickHandler)
    return () => document.removeEventListener("click", outsideClickHandler)
  }, [outsideClickHandler])

  React.useEffect(() => {
    closeAll()
  }, [closeAll])

  return { popperInstance, show, hide, elements, resetTooltip }
}

export default usePopper
