import React from "react"
import { ButtonProps, Button } from "@youngagency/ui"
import { blackButton, whiteButton } from "./style/button.css"

export const BlackButton: React.FC<
  ButtonProps & { children: React.ReactNode }
> = ({ children, ...rest }) => {
  return (
    <Button variant="solid" className={blackButton} {...rest}>
      {children}
    </Button>
  )
}

export const WhiteButton: React.FC<
  ButtonProps & {
    children: React.ReactNode
  } & React.HTMLAttributes<HTMLButtonElement>
> = ({ children, ...rest }) => {
  return (
    <Button variant="solid" className={whiteButton} {...rest}>
      {children}
    </Button>
  )
}
