import { Box } from "@youngagency/young-ui"
import React from "react"
import { ResponsiveText } from "../../../components/layout"
import { useFounderContext } from "../context/founder"
import { getCategoryValue } from "../utils"
import { FounderBox } from "./FounderBox"
import { Grid2To4Box } from "./layout"

export const DirectorSection = () => {
  const ctx = useFounderContext()

  return (
    <Box>
      {ctx && getCategoryValue(ctx, "board-of-directors") ? (
        <ResponsiveText
          line={["25px", "39px"]}
          dimension={["20px", "34px"]}
          as="h2"
          fontWeight={600}
        >
          {getCategoryValue(ctx, "board-of-directors")}
        </ResponsiveText>
      ) : null}

      <Grid2To4Box mt="24px">
        {ctx?.item?.["board-of-directors"]
          ? ctx?.item?.["board-of-directors"].map((tizio, index) => {
              return (
                <FounderBox
                  {...tizio}
                  key={tizio.title + "directors"}
                  maxWidth={["unset", "269px"]}
                  width="100%"
                />
              )
            })
          : null}
      </Grid2To4Box>
    </Box>
  )
}
