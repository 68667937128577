import { createContext, useContext } from "react"

const initialValue = {
  token: {
    name: "",
    symbol: "",
  },
  fiat: {
    name: "",
    symbol: "",
    amountSymbol: "",
  },
}

const TokenFiatContext = createContext(initialValue)

export const TokenFiatProvider = TokenFiatContext.Provider

export const useTokenFiatContext = () => useContext(TokenFiatContext)
