import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import LanguageSelector from "../../../components/footer/LanguageSelector"
import { ArticleSchemaProps } from "../types"
import { useSeoData } from "./useSeoQuery"

export const useArticleSchema = ({
  type,
  title,
  description,
  image,
  publishDate,
  modifiedDate,
  authorName,
}: ArticleSchemaProps) => {
  const {
    site: { siteMetadata },
    languagePath,
  } = useSeoData()

  const { originalPath } = useI18next()

  const url = React.useMemo(
    () => siteMetadata.siteUrl + languagePath + originalPath,
    [siteMetadata.siteUrl, LanguageSelector, originalPath]
  )

  const articleJson = React.useMemo(() => {
    return [
      {
        "@type": type || "Article",
        "@id": url + "#article",
        "isPartOf": {
          "@id": url + "#webpage",
        },
        "author": {
          "@type": "Person",
          "@id": url + "#author",
          "name": "Young Team",
        },
        "publisher": {
          "@id": siteMetadata.siteUrl + "/#organization",
        },
        "headline": title,
        "description": description,
        "datePublished": publishDate,
        "dateModified": modifiedDate,
        "mainEntityOfPage": url + "#webpage",
        "image": {
          "@id": image + "/#primaryImage",
        },
      },
      {
        "@type": "Person",
        "@id": image + "/#author",
        "name": authorName,
        "image": {
          "@type": "ImageObject",
          "@id": image + "#author",
          "url": image,
          "caption": "Young Team",
        },
        "description": "Young Team",
      },
    ]
  }, [
    siteMetadata.siteUrl,
    url,
    modifiedDate,
    publishDate,
    image,
    authorName,
    type,
  ])

  return articleJson
}
