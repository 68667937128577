import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Body, Box, Container, Heading, VStack } from "@youngagency/ui"
import { Container as CustomContainer } from "../../../../components/layout"
import {
  CardButton,
  CardContent,
  CardGrid,
  CardImage,
  CardStack,
  CardTexts,
} from "../../../../components/cards"
import { Card } from "../../../../components/cards"
import { StaticImage } from "gatsby-plugin-image"
import { gridLeft } from "./b2b.css"
import { navigate } from "@reach/router"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { b2bEvents, globalEvents } from "../../../../service/tracking"
import { googleScheduleMeet } from "../../constants"
export const EducationSection = () => {
  const { t } = useTranslation()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  const handleClick = React.useCallback(
    (item: 1 | 2) => {
      gaTracker?.trackMultipleEvents([
        {
          eventName: b2bEvents.b2b_home_body_edu_service,
          payload: {
            action: item,
          },
        },
        {
          eventName: globalEvents.contact_sales,
        },
      ])
      navigate(googleScheduleMeet)
    },
    [gaTracker]
  )
  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              fontWeight={400}
              color="white"
              dangerouslySetInnerHTML={{
                __html: t("clubs_page.b2b.educational_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              color="grey200"
              style={{
                maxWidth: "700px",
              }}
              fontWeight={600}
            >
              {t("clubs_page.b2b.educational_section.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>
      <Box
        marginTop={{
          mobile: 32,
          md: 40,
        }}
      >
        <VStack
          spacing={"unset"}
          align="center"
          justify="center"
          atoms={{
            __columnGap: "1rem",
            width: "full",
            mx: "auto",
          }}
        >
          <div className={gridLeft}>
            <Card
              isAnimated
              hasShadow
              background="var(--blue-600-v2)"
              orientation="column"
              invertBtn
              onClick={() => {
                handleClick(1)
              }}
            >
              <CardContent>
                <CardTexts>
                  <Heading as="h3" variant="6" color="white" fontWeight={600}>
                    {t("clubs_page.b2b.educational_section.card_1.title")}
                  </Heading>
                  <Body
                    variant="1"
                    atoms={{
                      __color: "var(--text-light)",
                    }}
                    fontWeight={600}
                  >
                    {t("clubs_page.b2b.educational_section.card_1.description")}
                  </Body>
                </CardTexts>
                <CardButton textColor="light" size="md" mt={["16px", "24px"]}>
                  {t("clubs_page.b2b.educational_section.card_1.button")}
                </CardButton>
              </CardContent>
              <CardImage>
                <StaticImage
                  src="../../images/educational-card-1.png"
                  alt={t(
                    "clubs_page.b2b.educational_section.card_1.img_alt_text"
                  )}
                />
              </CardImage>
            </Card>
            <Card
              isAnimated
              hasShadow
              background="#131312"
              orientation="column"
              invertBtn
              onClick={() => {
                handleClick(2)
              }}
            >
              <CardContent>
                <CardTexts>
                  <Heading as="h3" variant="6" color="white" fontWeight={600}>
                    {t("clubs_page.b2b.educational_section.card_2.title")}
                  </Heading>
                  <Body
                    variant="1"
                    atoms={{
                      __color: "var(--text-light)",
                    }}
                    fontWeight={600}
                  >
                    {t("clubs_page.b2b.educational_section.card_2.description")}
                  </Body>
                </CardTexts>
                <CardButton textColor="light" size="md" mt={["16px", "24px"]}>
                  {t("clubs_page.b2b.educational_section.card_2.button")}
                </CardButton>
              </CardContent>
              <CardImage height="100%">
                <StaticImage
                  style={{
                    height: "100%",
                  }}
                  src="../../images/educational-card-2.png"
                  alt={t(
                    "clubs_page.b2b.educational_section.card_2.img_alt_text"
                  )}
                />
              </CardImage>
            </Card>
          </div>
          <CardGrid mt="1rem">
            <CardStack>
              <Card
                isAnimated
                hasShadow
                background="#131312"
                orientation="column"
                disableHover
                shape="square"
              >
                <CardContent shape="square">
                  <CardTexts>
                    <Heading as="h3" variant="6" color="white" fontWeight={600}>
                      {t("clubs_page.b2b.educational_section.card_3.title")}
                    </Heading>
                  </CardTexts>
                </CardContent>
                <CardImage>
                  <StaticImage
                    src="../../images/educational-card-3.png"
                    alt={t(
                      "clubs_page.b2b.educational_section.card_3.img_alt_text"
                    )}
                  />
                </CardImage>
              </Card>
              <Card
                isAnimated
                hasShadow
                background="#131312"
                orientation="column"
                disableHover
                shape="square"
              >
                <CardContent shape="square">
                  <CardTexts>
                    <Heading as="h3" variant="6" color="white" fontWeight={600}>
                      {t("clubs_page.b2b.educational_section.card_4.title")}
                    </Heading>
                    <Body variant="1" color="grey200" fontWeight={600}>
                      {t(
                        "clubs_page.b2b.educational_section.card_4.description"
                      )}
                    </Body>
                  </CardTexts>
                </CardContent>
                <CardImage>
                  <StaticImage
                    src="../../images/educational-card-4.png"
                    alt={t(
                      "clubs_page.b2b.educational_section.card_4.img_alt_text"
                    )}
                  />
                </CardImage>
              </Card>
            </CardStack>
          </CardGrid>
        </VStack>
      </Box>
    </>
  )
}
