import {
  Box,
  Heading,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  Paragraph,
  VStack,
} from "@youngagency/young-ui"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { SeoLink } from "../../../feature/seo"
import { cleanStringFromHtlmTags } from "../../../utils/mucciaccia"
import { useFounderContext } from "../context/founder"

export const AboutUsModal = () => {
  const ctx = useFounderContext()
  const { closeModal, modalContent } = ctx || {}
  const handleLinkedinClick = React.useCallback(
    (e: React.BaseSyntheticEvent) => {
      if (!modalContent?.about_us?.linkedin) return
      e.stopPropagation()
    },
    [modalContent?.about_us?.linkedin]
  )

  const image = React.useMemo(
    () =>
      modalContent?.featuredImage
        ? getImage(modalContent?.featuredImage.node)
        : null,
    [modalContent?.featuredImage?.node, modalContent?.featuredImage]
  )

  if (modalContent)
    return (
      <Modal onClose={closeModal}>
        <ModalBody
          backgroundColor="#fff"
          zIndex={999999}
          maxWidth={["calc(100% - 32px)", "562px"]}
          width="100%"
          minHeight={["280px"]}
          p="0px !important"
          borderRadius="16px"
          border="1px solid"
          borderColor="grey.5"
        >
          <Box
            position="relative"
            display="flex"
            justifyContent="center"
            overflow="hidden"
            borderRadius="16px 16px 0px 0px"
            style={{
              transform: "trsnalteZ(0)",
            }}
          >
            {image ? (
              <Box maxWidth={["161px", "265px"]}>
                <GatsbyImage
                  image={image}
                  alt={modalContent?.title || "Image of Young Member"}
                />
              </Box>
            ) : null}
            <Box
              position="absolute"
              top={["8px", "12px"]}
              right={["8px", "12px"]}
            >
              <ModalCloseButton />
            </Box>
          </Box>

          <Box p={["16px", "24px"]}>
            <Box>
              <HStack justify="space-between" align="flex-start" width="100%">
                <VStack spacing="8px" align="start">
                  <Heading size="lg" fontFamily="Matter">
                    {modalContent.title}
                  </Heading>
                  {modalContent?.about_us?.role ? (
                    <Paragraph size="sm" fontFamily="Matter">
                      {modalContent.about_us.role}
                    </Paragraph>
                  ) : null}
                </VStack>
                {modalContent?.about_us?.linkedin ? (
                  <Box role="button" onClick={handleLinkedinClick}>
                    <SeoLink
                      isExternal={true}
                      target="_blank"
                      rel="nofollow"
                      link={modalContent?.about_us?.linkedin}
                    >
                      <Icon icon="linkedin" fontSize="24px" />
                    </SeoLink>
                  </Box>
                ) : null}
              </HStack>
            </Box>
            <Box mt="20px" />
            <Box overflowY="scroll" maxHeight="200px" mt="-10px !important">
              {modalContent?.content ? (
                <Paragraph
                  size="sm"
                  style={{
                    whiteSpace: "inherit",
                  }}
                  fontFamily="Matter"
                  dangerouslySetInnerHTML={{
                    __html: cleanStringFromHtlmTags(modalContent?.content),
                  }}
                />
              ) : null}
            </Box>
          </Box>
        </ModalBody>
      </Modal>
    )
  return null
}
