import styled from "styled-components"
import { BorderBox } from "../../../../components/layout"
import { css } from "@styled-system/css"
import { Box } from "@youngagency/young-ui"

export const Card = styled(BorderBox)<{ variant?: "light" | "dark" }>(
  ({ variant }) =>
    css({
      backgroundColor: variant === "dark" ? "#000" : "white",
      borderRadius: ["16px", "34px"],
      maxWidth: ["unset", "562px"],
      width: "100%",
      p: ["24px", "56px"],
      "& p, h3": {
        color: variant === "dark" ? "white !important" : "inherit",
      },
    })
)

export const CardImage = styled(Box)(
  css({
    width: "100%",
    borderRadius: "16px",
    overflow: "hidden",
    transform: "translateZ(0)",
  })
)
