import { Placement } from "@popperjs/core"
import { Box, BoxProps } from "@youngagency/young-ui"
import React from "react"
import { Text } from "../../../components/texts/temporary"
import usePopper from "../../navigation/hooks/usePopper"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { globalEvents } from "../../../service/tracking"

type Props = {
  children: React.ReactNode
  text: string
  tooltipPosition?: Placement
} & BoxProps

export const ClubTooltips: React.FC<Props> = ({
  children,
  text,
  tooltipPosition = "right",
  id,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { gaTracker } = useSelector((state: RootState) => state.tracking)
  const chilrenID = React.isValidElement(children) ? children.props.id : null
  const tooltipOffset = React.useMemo(() => {
    let offset: [number, number] = [0, 30]
    switch (tooltipPosition) {
      case "top":
        offset = [-10, 0]
        break
      case "bottom":
        offset = [10, 0]
        break
      case "left":
        offset = [0, -30]
        break
      case "right":
        offset = [0, 30]
        break
      default:
        break
    }

    return offset
  }, [tooltipPosition])

  const clonedChildren = React.isValidElement(children)
    ? React.cloneElement(children, {
        onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
          e.preventDefault()
          e.stopPropagation()
        },
      } as any)
    : null

  usePopper({
    referenceID: chilrenID,
    tooltipID: id || "club-tooltip",
    placement: tooltipPosition,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: tooltipOffset,
        },
      },
    ],
    events: {
      open: ["mouseenter", "focus", "tap"],
      close: ["mouseleave", "blur"],
    },
    callbacks: {
      onShow() {
        setIsOpen(true)
      },
    },
  })

  const closeTooltip = React.useCallback(() => {
    const tooltip = document.getElementById(id || "club-tooltip")
    if (isOpen && tooltip && chilrenID) {
      const ref = document.getElementById(chilrenID)
      if (ref) {
        const closeTooltip = () => {
          tooltip?.removeAttribute("tooltip-data-show")
          setIsOpen(false)
        }
        ref.addEventListener("mouseleave", closeTooltip)
        return () => ref.removeEventListener("mouseleave", closeTooltip)
      }
    }
    setIsOpen(false)
  }, [chilrenID, id, isOpen])

  React.useEffect(() => {
    if (isOpen) {
      gaTracker?.trackEvent({
        eventName: globalEvents.info_tooltip_open,
      })
    }
  }, [isOpen, gaTracker])

  React.useEffect(() => {
    closeTooltip()
  }, [closeTooltip])

  return (
    <>
      {clonedChildren}
      <Box
        p="16px"
        background={"var(--white)"}
        maxWidth="260px"
        width="100%"
        borderRadius="16px"
        boxShadow="0px -6px 12px rgba(0, 0, 0, 0.02), 0px 4px 10px rgba(0, 0, 0, 0.06)"
        id={id}
        position="absolute"
        left="75%"
        className="tooltip"
        zIndex={1}
        {...rest}
      >
        <Text.Body2>{text}</Text.Body2>
      </Box>
    </>
  )
}
