import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"

export type Props = GatsbyLinkProps<any> & {
  language?: string
}

export const I18nLink = React.forwardRef<HTMLAnchorElement, Props>(
  ({ language, to, onClick, ...rest }, ref) => {
    const context = useI18next()
    const urlLanguage = language || context.language
    const getLanguagePath = (language: string) => {
      return context.generateDefaultLanguagePage ||
        language !== context.defaultLanguage
        ? `/${language}`
        : ""
    }
    const link = `${getLanguagePath(urlLanguage)}${to}`

    return (
      // @ts-ignore
      <GatsbyLink
        {...rest}
        to={link}
        innerRef={ref}
        onClick={e => {
          if (language) {
            localStorage.setItem("gatsby-i18next-language", language)
          }
          if (onClick) {
            onClick(e)
          }
        }}
      />
    )
  }
)
