import isEmpty from "lodash/isEmpty"
import { useGetAllCurrenciesQuery } from "../../../redux/services/currency/api"
import { useTokenExclusion } from "./useTokenExclusion"
import { useGetAllTokenChartV2Query } from "../../../service/charts/api/api"
import React from "react"

export const useMergeMultipleToken = (currencies?: string[]) => {
  const {
    data: allTokenCharts,
    isLoading: isChartsLoading,
  } = useGetAllTokenChartV2Query({})

  const { currencyDataAndChartsParser } = useTokenExclusion()

  const data = useGetAllCurrenciesQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => {
      let tokens
      if (!isLoading && !isChartsLoading && allTokenCharts) {
        tokens = currencyDataAndChartsParser({
          currencies: data?.currencies,
          chartData: allTokenCharts,
        })
        if (currencies && !isEmpty(currencies)) {
          tokens = tokens.filter(
            el =>
              currencies.includes(el.symbol.toLowerCase()) ||
              currencies.includes(el.symbol.toUpperCase())
          )
        }
      }
      return {
        tokens,
        isLoading: isLoading && isChartsLoading,
      }
    },
  })

  return data
}
