import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { Container } from "../../../../components/layout"
import { CenterTextBox } from "../../../../components/shared"
import {
  DesktopComparativeTable,
  MobileComparativeTable,
} from "../../../clubs/components/ComparativeTabs"
import { Box } from "@youngagency/ui"

export const ClubBox = () => {
  const { t } = useI18next()

  return (
    <Box>
      <Container size={["md", "lg"]}>
        <CenterTextBox
          title={t("clubs.clubOverview.title")}
          description={t("clubs.clubOverview.description")}
        />
      </Container>
      <MobileComparativeTable />
      <DesktopComparativeTable />
      {/* <ClubSections /> */}
    </Box>
  )
}
