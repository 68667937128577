import styled from "styled-components"
import { position, variant, compose, space } from "styled-system"
import { css } from "@styled-system/css"

/**
 * @todo Migliorare il right left delle animazioni nelle varianti
 */
const AbsoluteCard = styled("div")(
  {
    boxSizing: "border-box",
    boxShadow: "0px 6px 15px 0px rgba(0, 0, 0, 0.05)",
    borderRadius: "24px",
    zIndex: 1,
    position: "absolute",
    cursor: "pointer",
    width: " 270px",
    height: "180px",
    background: "white",
  },
  compose(
    position,
    space,
    variant({
      prop: "animation",
      variants: {
        topR: css({
          animation: "fade-in-card-bottom 0.3s forwards",
          top: "-220px",
        }),
        topL: css({
          animation: "fade-in-card-bottom 0.3s forwards",
          top: "-220px",
        }),
        bottomR: css({
          animation: "fade-in-card-top 0.3s forwards",
          bottom: "-220px",
        }),
        bottomL: css({
          animation: "fade-in-card-top 0.3s forwards",
          bottom: "-220px",
        }),
      },
    })
  )
)

AbsoluteCard.defaultProps = {
  boxSizing: "border-box",
  boxShadow: "0px 6px 15px 0px rgba(0, 0, 0, 0.05)",
  borderRadius: "24px",
  zIndex: 99,
  position: "absolute",
  cursor: "pointer",
}

export default AbsoluteCard
