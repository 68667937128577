import { Box } from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import usePopper from "../../hooks/usePopper"
import StepImg from "../../../../images/YP_step_pittogramma.svg"
import BaseImg from "../../../../images/young_icon.svg"
import ProImg from "../../../../images/YP_pro_pittogramma.svg"
import AcademyImg from "../../images/YP_academy_pittogramma.svg"
import { SeoLink } from "../../../seo"
import styled from "styled-components"
import { Avatar } from "../../../../components/layout"
import { ProductMenuProps, useProductMenu } from "../../hooks/useProductMenu"
import { useNavigationContext } from "../../context"
import { Body, HStack, Subtitle } from "@youngagency/ui"

const StyledBox = styled(Box)`
  .header-product-card > *:first-of-type {
    border-radius: 16px;
    &:hover {
      background: var(--r-grey-8);
    }
  }
`

export const ProductTooltip = () => {
  const { t } = useI18next()
  const { offset } = useNavigationContext()

  usePopper({
    referenceID: t("menu.product.text"),
    tooltipID: "product-tooltip",
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset,
        },
      },
    ],
    events: {
      open: ["mouseenter", "focus"],
      close: ["mouseleave", "blur"],
    },
  })

  const enthImages = React.useMemo(
    () => [
      <img
        src={BaseImg}
        alt="Logo Young Platform Base"
        height={36}
        width={36}
      />,
      <img src={ProImg} alt="Logo Young Platform Pro" height={36} width={36} />,
    ],
    []
  )
  const newbyImages = React.useMemo(
    () => [
      <img
        src={StepImg}
        alt="Logo Young Platform Step"
        height={36}
        width={36}
      />,
      <img
        src={AcademyImg}
        alt="Logo Young Platform Academy"
        height={36}
        width={36}
      />,
    ],
    []
  )

  const { enthusiastArrays, newbyArrays } = useProductMenu({
    enthImages,
    newbyImages,
  })

  return (
    <Box
      border="1px solid"
      className="tooltip"
      id="product-tooltip"
      borderRadius="16px"
      borderColor="grey.5"
      backgroundColor="white"
      boxShadow="var(--shadow-1)"
    >
      <Box p="24px">
        <HStack align="flex-start" justify="flex-start" spacing={36}>
          <Box width="100%">
            <Subtitle
              variant="1"
              fontWeight={600}
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {t("menu.product.hover.sections.enthusiast.title")}
            </Subtitle>
            <StyledBox mt="16px">
              {enthusiastArrays.map(element => {
                return (
                  <ProductCard key={element.title as string} {...element} />
                )
              })}
            </StyledBox>
          </Box>
          <Box width="100%">
            <Subtitle variant="1" fontWeight={600}>
              {t("menu.product.hover.sections.newby.title")}
            </Subtitle>
            <StyledBox mt="16px">
              {newbyArrays.map(element => {
                return (
                  <ProductCard key={element.title as string} {...element} />
                )
              })}
            </StyledBox>
          </Box>
        </HStack>
      </Box>
    </Box>
  )
}

const ProductCard = ({
  title,
  link,
  img,
  description,
}: ProductMenuProps["enthusiastArrays" | "newbyArrays"][number]) => {
  return (
    <SeoLink
      isExternal={link !== "/"}
      link={link as string}
      className="header-product-card"
    >
      <Box
        p="16px"
        maxWidth="282px"
        style={{
          boxSizing: "content-box",
        }}
      >
        <HStack align="flex-start" justify="flex-start" spacing={16}>
          <Avatar
            shape="square"
            backgroundColor="white"
            minWidth="56px"
            height="56px"
            borderRadius="16px"
            border="1px solid"
            borderColor="grey.5"
          >
            {img && img}
          </Avatar>
          <Box>
            {typeof title === "string" ? (
              <Subtitle variant="2" color="black" fontWeight={600}>
                {title}
              </Subtitle>
            ) : null}
            {typeof description === "string" ? (
              <Box mt="4px" maxWidth="calc(100% - 16px)">
                <Body variant="2" color="grey600">
                  {description}
                </Body>
              </Box>
            ) : null}
          </Box>
        </HStack>
      </Box>
    </SeoLink>
  )
}
