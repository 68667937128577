import React from "react"
import { Box, HStack, Label } from "@youngagency/young-ui"
import useBlogLayout from "./utils/useBlogLayout"
import styled from "styled-components"
import { SeoLink } from "../../feature/seo"

const StyledBox = styled(Box)`
  .active-blog-link {
    background: var(--r-grey-8);
  }
`

const BlogNavLinks = ({
  isBlogHome,
  slug,
}: {
  isBlogHome: boolean | undefined
  slug?: string | null
}) => {
  const { blogLinks: navLinks, handleCategoryTracking } = useBlogLayout()
  
  let activeLink: "all" | undefined | false | number = isBlogHome && "all"
  if (navLinks && navLinks.length > 0 && !activeLink) {
    activeLink = navLinks.find(el => el.slug == slug)?.databaseId || "all"
  }

  const createFlexOrderValue = (id: number) => {
    switch (id) {
      case 5154:
      case 5100:
        return 3
      case 5150:
      case 5104:
        return 4
      case 5112:
      case 5166:
        return 6
      case 5095:
      case 5158:
        return 2
      case 5162:
      case 5108:
        return 5
      default:
        return 1
    }
  }

  return (
    <StyledBox>
      <HStack width="100%">
        {navLinks &&
          navLinks.map(el => {
            return (
              <Box
                key={el.databaseId}
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="100px"
                className={
                  el.databaseId === activeLink
                    ? "link-item active-blog-link"
                    : "link-item"
                }
                style={{
                  order: createFlexOrderValue(el.databaseId),
                  whiteSpace: "nowrap",
                }}
              >
                <SeoLink
                  isExternal={false}
                  onClick={() => handleCategoryTracking(el.tracking_value?.trackingValue)}
                  link={`/blog/${el.slug}/`}
                  style={{
                    padding: "0px 16px",
                    height: "36px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Label size="sm" fontFamily="Matter" fontWeight={600}>
                    {el.name}
                  </Label>
                </SeoLink>
              </Box>
            )
          })}
      </HStack>
    </StyledBox>
  )
}

export default BlogNavLinks
