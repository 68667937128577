import React from "react"
import { HStack, Box, Body } from "@youngagency/ui"
import useNavigationMenu from "../../hooks/useNavigationMenu"
import { SeoLink } from "../../../seo"
import clsx from "clsx"
import { navigationItem, navigationItemChild } from "./style.css"

export const BusinessNavigation = () => {
  const { menu } = useNavigationMenu()

  return (
    <HStack
      spacing={0}
      atoms={{
        __width: "full",
      }}
    >
      {menu.map(link => {
        return (
          <Box
            key={link.title}
            id={`${link.title}`}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
            className={clsx("link-item", "toogle-tooltip", navigationItem)}
          >
            {link.link ? (
              <SeoLink
                isExternal={link.isExternal}
                link={link.link}
                rel={link.isExternal ? "noopener" : "internal"}
                target={link.isExternal ? "_blank" : "self"}
              >
                <div className={navigationItemChild}>
                  <Body variant="2" color="white" fontWeight={600}>
                    {link.title}
                  </Body>
                </div>
              </SeoLink>
            ) : (
              <Box>
                <div className={navigationItemChild}>
                  <Body variant="2" color="white" fontWeight={600}>
                    {link.title}
                  </Body>
                </div>
              </Box>
            )}
          </Box>
        )
      })}
    </HStack>
  )
}
