import { variant, styled } from "@youngagency/young-ui"
import { Box } from "@youngagency/young-ui"

export const Container = styled(Box)(
  variant({
    prop: "size",
    variants: {
      fullwidth: {
        width: "100%",
      },
      xl: {
        maxWidth: "1400px",
        width: "calc(100% - 64px)",
      },
      lg: {
        maxWidth: "1140px",
        width: "calc(100% - 48px)",
      },
      md: {
        maxWidth: "756px",
        width: "calc(100% - 32px)",
      },
      sm: {
        maxWidth: "592px",
        width: "calc(100% - 12px)",
      },
      xs: {
        maxWidth: "490px",
        width: "100%",
      },
    },
  })
)

Container.defaultProps = {
  size: "lg",
  mt: "auto",
  ml: "auto",
  mr: "auto",
  mb: "auto",
}

export default Container
