import React from "react"
import { Box, BoxProps } from "@youngagency/young-ui"
import styled from "styled-components"
import GooglePlayStore from "../../images/button-play-store.svg"
import AppleStore from "../../images/button-app-store.svg"

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;
  img {
    width: auto !important;
    height: auto !important;
  }
  @media screen and (min-width: 767px) {
    flex-direction: row;
  }
`

const StyledLink = styled.a``

type Props = {
  appleLink: string
  playLink: string
  className?: string
  event?: () => void
}

export const FromStoreBtn = ({
  appleLink,
  playLink,
  className,
  event,
}: Props & BoxProps) => {
  return (
    <StyledBox className={className && className}>
      <StyledLink href={playLink} onClick={event}>
        <img
          src={GooglePlayStore}
          height="56"
          width="188"
          alt="Downaload From Google PlayStore"
        />
      </StyledLink>
      <StyledLink href={appleLink} onClick={event}>
        <img
          src={AppleStore}
          alt="Downaload From Apple Store"
          height="56"
          width="168"
          style={{
            width: "inherit",
          }}
        />
      </StyledLink>
    </StyledBox>
  )
}
