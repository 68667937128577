import { useDispatch } from "react-redux"
import { useHasMounted } from "@youngagency/young-ui"
import {
  closePromoPopUp,
  openPromoPopUp,
  PromoElementStatus,
  setPromoPopUpSeen,
} from "../../../redux/reducers/promoSlice"
import { useCallback } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"

export const usePopUpDispatcher = () => {
  const dispatch = useDispatch()
  const hasMounted = useHasMounted()
  const {
    pop_up: { key },
  } = useSelector((state: RootState) => state.promo)

  const catchPopUserPreference = useCallback(() => {
    if (!hasMounted) return
    const popUpUserPreference = (localStorage.getItem(
      `${key}-popUpUserPreference`
    ) || "hidden") as PromoElementStatus
    return popUpUserPreference
  }, [hasMounted, key])

  const setPopUpUserBehavior = useCallback(
    (status: PromoElementStatus) => {
      if (!hasMounted) return
      localStorage.setItem(`${key}-popUpUserPreference`, status)
    },
    [hasMounted, key]
  )

  const openPopUp = useCallback(() => {
    dispatch(openPromoPopUp())
    setPopUpUserBehavior("open")
  }, [dispatch, setPopUpUserBehavior])

  const closePopUp = useCallback(() => {
    dispatch(closePromoPopUp())
    setPopUpUserBehavior("closed")
    dispatch(setPromoPopUpSeen())
  }, [dispatch, setPopUpUserBehavior])

  return {
    closePopUp,
    catchPopUserPreference,
    openPopUp,
  }
}
