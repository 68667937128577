import React from "react"
import { FounderContexType, ModalFounderProps } from "../hooks"

const FounderContext = React.createContext<
  (FounderContexType & ModalFounderProps) | null
>(null)

export const FounderProvider = FounderContext.Provider

export const useFounderContext = () => React.useContext(FounderContext)
