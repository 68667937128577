import React from "react"
import { CardProvider } from "../context/CardContext"
import { ShapedCardProps } from "../types"

export const VerticalCard = ({ children, ...rest }: ShapedCardProps) => {
  return (
    <CardProvider orientation="column" shape="vertical" {...rest}>
      {children}
    </CardProvider>
  )
}
