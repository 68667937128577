import { createContext, useContext } from "react"

const FooterContext = createContext({
  appleLink: "https://youngexchange.page.link/sBwZ",
  playStoreLink: "https://youngexchange.page.link/dr8x",
})

export const FooterProvider = FooterContext.Provider

export const useFooterContext = () => useContext(FooterContext)

export type FooterContextType = ReturnType<typeof useFooterContext>
