export const globalEvents = {
  click_ecosistema_header: "click_ecosistema_header" as const,
  click_to_exchange: "click_to_exchange" as const,
  click_to_academy: "click_to_academy" as const,
  click_cta: "click_cta" as const,
  action_exchange_app_download: "action_exchange_app_download" as const,
  contact_sales: "contact_sales" as const,
  info_tooltip_open: "info_tooltip_open" as const,
}

export type GlobalEvents = typeof globalEvents
