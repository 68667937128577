import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import TalkAboutUs from "../components/about-us/TalkAboutUs"
import { Container } from "../components/layout"
import { AlternateHandler, MetaDecorator, WebPageSchema } from "../feature/seo"
import { BreadCrumb, LandingLayout } from "../feature/navigation/components"
import { TranslationProvider } from "../feature/translations/context"

const YoungWorld = () => {
  const { t } = useI18next()
  const breadcrumb = React.useMemo(
    () => [
      {
        text: t("breadcumbs.home_breadcrumb"),
        link: "/",
      },
      {
        text: t("breadcumbs.talking_about_us_breadcrumb"),
        link: "/talking-about-us/",
      },
    ],
    [t]
  )

  return (
    <TranslationProvider>
      <LandingLayout
        disableFooterMargin
        dinamycLink="https://youngexchange.page.link/aboutusmenu"
        appleLink="https://youngexchange.page.link/aboutusios"
        playStoreLink="https://youngexchange.page.link/aboutusand"
      >
        <MetaDecorator />
        <WebPageSchema breadcrumbs={breadcrumb} />
        <AlternateHandler />
        <Container>
          <BreadCrumb breadcrumbs={breadcrumb} />
        </Container>
        <TalkAboutUs />
      </LandingLayout>
    </TranslationProvider>
  )
}

export default YoungWorld
export const query = graphql`
  query TalkingAboutMainPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
