import styled from "styled-components"
import { Box } from "@youngagency/young-ui"

export const ChartWrapper = styled(Box)`
  width: 100% !important;

  .tv-lightweight-charts {
    width: 100% !important;
    height: 100% !important;
    & > table {
      width: 100% !important;
      & > tr:first-child > td:last-of-type > div {
        margin-left: 5px;
      }
      & td:not(:first-of-type) {
        width: 100% !important;
        & div {
          width: 100%;
          canvas {
            width: 100% !important;
          }
        }
      }
    }
  }
`
