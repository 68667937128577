import { resetPersistUtm, setPersistedUtm } from "../reducers/promoSlice"

export const persistUtm = () => {
  if (typeof window === "undefined") return resetPersistUtm()
  const { search } = window.location
  const params = new URLSearchParams(search)
  const isUtmPersisted = params.has("utm_persist")
  if (isUtmPersisted) {
    const pathname_utm = params.toString()
    if (pathname_utm) {
      const pureUtmUrl = pathname_utm.replace("&utm_persist=", "")
      return setPersistedUtm(pureUtmUrl)
    }
  }
  return resetPersistUtm()
}
