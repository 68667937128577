import React from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { useWebPageSchema, useOrganizationSchema } from "../hooks"

export const OrganizationSchema = () => {
  const { t } = useTranslation()
  const schema = useWebPageSchema({
    title: t("homepage.seo.title"),
    description: t("homepage.seo.description"),
  })
  delete schema["@graph"][1].breadcrumb

  const organizationSchema = useOrganizationSchema()

  return (
    <Helmet >
      <script type="application/ld+json">
        {JSON.stringify({
          ...schema,
          ["@graph"]: [...schema["@graph"], organizationSchema],
        })}
      </script>
    </Helmet>
  )
}

export default OrganizationSchema
