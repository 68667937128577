import { Box, Icon, Button } from "@youngagency/young-ui"
import React from "react"
import { useConversorContext, useTokenFiatContext } from "../context"
import { AbrateSelector } from "./AbrateSelector"
import styled from "styled-components"
import { css } from "@styled-system/css"
import { navigate } from "@reach/router"
import { useI18next } from "gatsby-plugin-react-i18next"
import { triggerClickToExchangeEvents } from "../../../../utils/general"

const CustomStack = styled(Box)(
  css({
    display: "flex",
    flexDirection: ["column", "row"],
    alignItems: "center",
    "& > *:not(:last-child)": {
      mb: ["12px", "0px"],
      mr: ["0px", "8px"],
    },
  }),
  css({
    ".conversor-icon": {
      transform: ["rotateZ(0deg)", "rotateZ(90deg)"],
    },
  })
)

export const ConversorContent = () => {
  const { t, language } = useI18next()
  const { token } = useTokenFiatContext()
  const {
    conversorList,
    conversorValue,
    swapConversion,
    handleSubmit,
    conversorCta,
  } = useConversorContext() || {}
  const handleNavigate = React.useCallback(() => {
    let link = "https://exchange.youngplatform.com/"
    if (language !== "it") link += language + "/"
    link += `/markets/${token.symbol.toLowerCase()}`
    navigate(link)
    triggerClickToExchangeEvents()
  }, [language, token.symbol])
  if (conversorList && conversorValue)
    return (
      <CustomStack>
        <AbrateSelector {...conversorValue.from} options={conversorList.from} />
        <Icon
          icon="swap"
          fontSize={["24px"]}
          className="conversor-icon"
          onClick={swapConversion}
          alt={"exchange-icon"}
        />
        <AbrateSelector
          {...conversorValue.to}
          options={conversorList.to}
          readonly
        />
        {conversorCta === "buy" ? (
          <Button
            mt={["12px", "0px"]}
            width={["100%", "auto"]}
            onClick={handleNavigate}
          >
            {t("markets.token_fiat.conversor.main_cta")}
          </Button>
        ) : (
          <Button
            mt={["12px", "0px"]}
            width={["100%", "auto"]}
            onClick={handleSubmit}
          >
            {t("markets.token_fiat.conversor.convert_cta")}
          </Button>
        )}
      </CustomStack>
    )
  return null
}
