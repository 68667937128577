import React from "react"
import { useGetSingleAcademyArticleMutation } from "../../../redux/services/academy"
import { ResponsiveText } from "../../../components/layout"
import { Box, Skeleton, useMediaQuery } from "@youngagency/young-ui"
import { RelatedArticleWrapper } from "./RelatedArticleWrapper"
import { useI18next } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import truncate from "lodash/truncate"
import { navigate } from "@reach/router"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { globalEvents } from "../../../service/tracking"

export const AcademyRelatedArticle = ({ id }: { id: string | number }) => {
  const [
    getSingleAcademy,
    { data, isLoading, isError },
  ] = useGetSingleAcademyArticleMutation()
  const { language } = useI18next()
  const { isMobile } = useMediaQuery()
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)

  const [loaded, setLoaded] = React.useState<boolean>(false)
  const articleLink = React.useMemo(() => {
    if (isLoading) return
    let baseUrl = `https://academy.youngplatform.com`
    if (language !== "it") {
      baseUrl + `/${language}`
    }
    return `${baseUrl}/${data?.category.slug}/${data?.slug}/`
  }, [isLoading, language])

  const handleAcademyArticleClick = React.useCallback(() => {
    if (!articleLink) return
    if(gaTracker){
      gaTracker.trackEvent({
        eventName: globalEvents.click_to_academy
      })
    }
    navigate(articleLink)
    
  }, [articleLink, gaTracker])

  React.useEffect(() => {
    getSingleAcademy(id)
      .unwrap()
      .catch(err => console.log(err))
  }, [])

  if (isLoading) {
    return (
      <RelatedArticleWrapper>
        <Box maxWidth="387px" flex={1}>
          <Skeleton height="20px" width="100px" />
          <Skeleton
            height="24px"
            width={["100%", "60%"]}
            mt={["8px", "16px"]}
          />
          <Skeleton height="48px" width="100%" mt="8px" />
        </Box>
        <Box
          width="100%"
          maxWidth={["92px", "296px"]}
          display="flex"
          maxHeight={["92px", "192px"]}
          borderRadius="16px"
          overflow="hidden"
          flex={1}
        >
          <Skeleton
            height="100%"
            pb={["33.3%", "56.25%"]}
            width="100%"
            borderRadius="16px"
          />
        </Box>
      </RelatedArticleWrapper>
    )
  }
  if (isError) {
    return null
  }
  return (
    <RelatedArticleWrapper onClick={handleAcademyArticleClick}>
      <Box maxWidth="387px" flex={1}>
        <Box mb={["16px", "8px"]} height={"20px"}>
          <StaticImage
            height={20}
            src="../images/academy.png"
            alt="Young Platform Academy"
            placeholder="dominantColor"
          />
        </Box>
        {data?.title ? (
          <ResponsiveText
            dimension={["16px", "20px"]}
            line={["20px", "25px"]}
            fontWeight={600}
          >
            {isMobile ? truncate(data.title, { length: 30 }) : data.title}
          </ResponsiveText>
        ) : null}
        <Box mt="8px">
          {data?.description ? (
            <ResponsiveText
              dimension={["14px", "16px"]}
              line={["18px", "20px"]}
              fontWeight={400}
            >
              {isMobile
                ? truncate(data.description, {
                    length: 75,
                  })
                : data.description}
            </ResponsiveText>
          ) : null}
        </Box>
      </Box>
      <Box
        width="100%"
        maxWidth={["92px", "296px"]}
        height={["92px", "auto"]}
        display="flex"
        maxHeight={["92px", "192px"]}
        borderRadius="16px"
        overflow="hidden"
        flex={1}
      >
        <img
          src={data?.image?.src}
          style={{ display: "none" }}
          alt={data?.title}
          onLoad={() => setLoaded(true)}
        />
        {data?.image && loaded ? (
          <img
            style={{
              width: "inherit",
              height: "inherit",
            }}
            src={data.image.src}
            alt={data?.title}
            onLoad={() => setLoaded(true)}
          />
        ) : (
          <Skeleton
            height="0"
            pb={["33.3%", "56.25%"]}
            width="100%"
            borderRadius="16px"
          />
        )}
      </Box>
    </RelatedArticleWrapper>
  )
}
