import styled, { keyframes } from "styled-components"

const fadeInFromBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`

export const FadeInFromBottomContainer = styled.div`
  animation: 450 ${fadeInFromBottom} linear;
`
