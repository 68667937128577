import { graphql, useStaticQuery } from "gatsby"
import isEmpty from "lodash/isEmpty"
import React from "react"
import { useGetAllCurrenciesQuery } from "../../../redux/services/currency/api"
import { GetCurrenciesResponse } from "../../../redux/services/currency/types"
import { isRetardedCoins } from "../utils"
import { useTokenSort } from "./useTokenSort"
import { useGetPillsV2Query } from "../../../redux/services/pills/api"
import { useTokenExclusion } from "./useTokenExclusion"
import { useGetAllTokenChartV2Query } from "../../../service/charts/api/api"

type SearchTokenProps = {
  allWpToken: {
    distinct: string[]
  }
}

const ITEM_PER_PAGE = 10
export const useAllTokenList = (
  page: number,
  sortedBy?: string,
  sortOrder: "asc" | "desc" = "desc"
) => {
  const data: SearchTokenProps = useStaticQuery(query)
  const { sortByType, tokenListOrder } = useTokenSort(sortOrder, sortedBy)
  const { currencyDataAndChartsParser } = useTokenExclusion()
  const start = (page - 1) * ITEM_PER_PAGE
  const end = start + ITEM_PER_PAGE
  const {
    data: allTokenCharts,
    isLoading: isChartsLoading,
  } = useGetAllTokenChartV2Query({})

  const { tokens: baseToken, isLoading } = useGetAllCurrenciesQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => {
      let tokens: GetCurrenciesResponse["currencies"][number][] = []
      if (!isLoading && !isChartsLoading && data && allTokenCharts) {
        tokens = currencyDataAndChartsParser({
          currencies: data.currencies,
          chartData: allTokenCharts,
        })
      }

      return {
        tokens: tokens && tokens,
        isLoading: isLoading && isChartsLoading && data && allTokenCharts,
      }
    },
  })

  const {
    data: pillsTokenV2,
    isLoading: isPillsLoadingV2,
  } = useGetPillsV2Query()

  const isLoaded = React.useMemo(
    () =>
      !isPillsLoadingV2 &&
      !isLoading &&
      !isEmpty(pillsTokenV2) &&
      !isEmpty(baseToken),
    [isPillsLoadingV2 && isLoading, pillsTokenV2, baseToken]
  )

  const sortedToken = React.useMemo(() => {
    let mergedArray
    if (isLoaded && pillsTokenV2) {
      const tempArray = [...baseToken, ...pillsTokenV2]
      mergedArray = tempArray.filter(
        token =>
          data.allWpToken.distinct.includes(token.symbol.toUpperCase()) ||
          !!isRetardedCoins(token.symbol)
      )

      mergedArray = sortByType(mergedArray)
      mergedArray = tokenListOrder(mergedArray)
      return mergedArray.slice(start, end)
    }
  }, [
    isLoaded,
    sortByType,
    tokenListOrder,
    pillsTokenV2,
    baseToken,
    start,
    end,
  ])

  return {
    token: sortedToken,
    isLoading: !isLoaded,
  }
}

const query = graphql`query AllWpAvailableToken {
  allWpToken {
    distinct(field: {customFields: {key: SELECT}})
  }
}`
