import React, { useEffect } from "react"
import { useHasMounted, CookieConsent } from "@youngagency/young-ui"
import "../../../../components/layout.css"
import "../../../../components/landing.css"
import injectAccessiway from "../../../accessiway"
import { useI18next } from "gatsby-plugin-react-i18next"
import { NavigationProvider } from "../../context"
import { useCatchReferallId } from "../../hooks/useCatchReferallId"
import {
  FooterContextType,
  FooterProvider,
} from "../../../../components/footer/context"
import { BusinessHeader } from "./BusinessHeader"
import { BusinessFooter } from "./BusinessFooter"
import { useHandlePersistUtm } from "../../hooks/useHandlePersistUtm"
import { SmartDCAProvider } from "../../../promo/components/context/SmartDCAContext"
import { SmartDCAPopUp } from "../../../promo/components/newsletter/SmartDCAPopUp"
type Props = {
  disableFooterMargin?: true
  children: React.ReactNode[] | React.ReactNode
  dinamycLink?: string
} & Partial<FooterContextType>

export const BusinessLayout = ({
  children,
  dinamycLink,
  appleLink,
  playStoreLink,
}: Props) => {
  const { language } = useI18next()
  const hasMounted = useHasMounted()
  const catchReferralId = useCatchReferallId()
  const ctx = React.useMemo(() => {
    return {
      playStoreLink: playStoreLink || "https://youngexchange.page.link/dr8x",
      appleLink: appleLink || "https://youngexchange.page.link/sBwZ",
    }
  }, [appleLink, playStoreLink])

  useEffect(() => {
    if (hasMounted) {
      injectAccessiway()
    }
  }, [hasMounted])

  useEffect(() => {
    if (hasMounted) catchReferralId()
  }, [hasMounted, catchReferralId])

  useEffect(() => {
    if (hasMounted) {
      const hasBusinessLayout = document.body.classList.contains(
        "business-layout"
      )
      if (hasBusinessLayout) return
      document.body.classList.add("business-layout")
    }
  }, [hasMounted])

  const { handlePersistUtmLink } = useHandlePersistUtm()
  const dynamicLink = React.useMemo(() => {
    if (dinamycLink) {
      return handlePersistUtmLink(dinamycLink)
    }
    return undefined
  }, [dinamycLink, handlePersistUtmLink])

  return (
    <NavigationProvider dinamycLink={dynamicLink} platform="business">
      <SmartDCAProvider>

      <BusinessHeader />
      <SmartDCAPopUp/>
      </SmartDCAProvider>
      <div>
        <main className="main-container">{children}</main>
        <CookieConsent
          lang={language}
          apiKey={process.env.COOKIE_API_KEY as string}
          siteId={Number(process.env.COOKIE_SITE_ID)}
          cookiePolicyId={Number(process.env.COOKIE_POLICY_ID)}
        />
        <FooterProvider value={ctx}>
          <BusinessFooter />
        </FooterProvider>
      </div>
    </NavigationProvider>
  )
}
