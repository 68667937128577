import React from "react"
import { rowalizer } from "../../../utils/general"
import { PostType } from "../types"
import { POSTS_PER_ROW } from "../utils/constants"
import { PostSection, PostSectionsWrapper } from "./PostSection"
import { generateUID } from "../../../utils/general"
import { Mutable } from "../../../types/shared"

export const ArticleWall = ({ posts }: { posts: readonly PostType[] }) => {
  const rowalizedPosts = React.useMemo(() => {
    return rowalizer(posts as Mutable<PostType[]>, POSTS_PER_ROW)
  }, [])

  const customIds = React.useMemo(() => {
    return Array.from({ length: rowalizedPosts.length }, () => generateUID())
  }, [rowalizedPosts.length])
  return (
    <PostSectionsWrapper wall>
      {rowalizedPosts.map((section, index) => (
        <PostSection key={customIds[index]} posts={section} disableTitle />
      ))}
    </PostSectionsWrapper>
  )
}
