import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import isEmpty from "lodash/isEmpty"
import React from "react"
import { useMergeMultipleToken } from "./useMergeMultipleToken"
import { MergedTokenProps } from "../types"
import { useTokenSort } from "./useTokenSort"

type Props = {
  [x: string]: {
    nodes: {
      symbol: string
      name: string
      details: {
        image: string
      }
    }[]
  }
}

type RelatedToken = {
  type: "popular" | "top_gainers"
  exclude: string
}

export const useRelatedToken = ({ type, exclude }: RelatedToken) => {
  const { t } = useTranslation()

  const { sortByType, tokenListOrder } = useTokenSort(
    "desc",
    type === "popular"
      ? t("markets.header_volume_24")
      : t("markets.header_variation")
  )
  const data: Queries.RelatedTokensQuery = useStaticQuery(query)
  const { tokens: allToken, isLoading } = useMergeMultipleToken()
  const staticRelatedToken = React.useMemo(() => {
    let relatedToken

    switch (type) {
      case "popular":
        relatedToken = data.mostPopularV2.nodes
        break
      case "top_gainers":
        relatedToken = data.topGainersV2.nodes
        break // added break statement to fix fallthrough case
      default:
        relatedToken = data.topGainersV2.nodes
        break
    }

    return relatedToken
  }, [data.mostPopularV2.nodes, data.topGainersV2.nodes])

  const related = React.useMemo(() => {
    let temp: Array<MergedTokenProps | Props["x"]["nodes"][number]> = []
    if (!isLoading && allToken && !isEmpty(allToken)) {
      temp = tokenListOrder(sortByType(allToken))
    } else {
      temp = staticRelatedToken as any
    }

    const exludedToken = temp.find(
      token => token.symbol.toLowerCase() === exclude.toLowerCase()
    )?.symbol

    if (!!exludedToken) {
      temp = temp.filter(
        token => token.symbol.toLowerCase() !== exludedToken.toLowerCase()
      )
    }

    temp = temp.filter(token =>
      data.availableTokenOnWp.distinct.includes(token.symbol)
    )

    if (temp.length < 7) {
      return temp
    }
    return temp.slice(0, 7)
  }, [
    isLoading,
    allToken,
    staticRelatedToken,
    data.availableTokenOnWp,
    sortByType,
    tokenListOrder,
  ])

  return { related, allToken, isLoading }
}

const query = graphql`query RelatedTokens {
  mostPopularV2: allToken(
    limit: 7
    sort: {details: {volume24h: DESC}}
    filter: {isBase: {eq: true}, id: {nin: ["EUR", "USD", "GBP"]}}
  ) {
    nodes {
      symbol
      name
      details {
        image
      }
    }
  }
  topGainersV2: allToken(
    limit: 7
    sort: {details: {priceChange24h: DESC}}
    filter: {isBase: {eq: true}, id: {nin: ["EUR", "USD", "GBP"]}}
  ) {
    nodes {
      symbol
      name
      details {
        image
      }
    }
  }
  availableTokenOnWp: allWpToken(
    filter: {id: {nin: ["EUR", "USD", "GBP"]}, customFields: {isunlisted: {eq: null}}}
  ) {
    distinct(field: {customFields: {key: SELECT}})
  }
}`
