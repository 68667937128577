import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react"
import {
  GetUserSubscriptionApiResponse,
  GetUserSubscriptionRequestBody,
  SubscribeNewsletterRequest,
  SubscribeSuccesNewsletterResponse,
} from "../../../../../server/api/types"
import { getHMACDigestWeb } from "../../utils"

const fetchBaseQueryBraze = fetchBaseQuery({
  baseUrl: process.env.GATSBY_BRAZE_GCF_URL ?? "http://localhost:8001",
})

// see https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#examples---basequery
const baseQueryWithHMAC: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const secret = process.env.GATSBY_BRAZE_GCF_SHARED_SECRET ?? ""
  const a = (args as unknown) as {
    url: string
    method: "GET" | "POST"
    body: any
  }
  const url = a.url
  const method = a.method
  const body = a.method == "GET" ? {} : a.body
  const { time, digest } = await getHMACDigestWeb(
    secret,
    method,
    JSON.stringify(body),
    url === "" ? "/" : url
  )
  const result = await fetchBaseQueryBraze(
    {
      url,
      method,
      headers: {
        "X-YNG-AUTH": `BrazeApi ${time}:${digest}`,
      },
      body: a.method == "GET" ? undefined : a.body,
    },
    api,
    extraOptions
  )
  if (result.error) {
    return { error: result.error }
  }

  return {
    data: result.data,
  }
}

export const brazeApiGCF = createApi({
  reducerPath: "brazeApiGCF",
  baseQuery: baseQueryWithHMAC,

  endpoints: builder => ({
    getUserSubscription: builder.query<
      GetUserSubscriptionApiResponse,
      GetUserSubscriptionRequestBody
    >({
      query: body => ({
        url: `/?email=${body.email}`,
        method: "GET",
      }),
    }),

    subscribeUser: builder.mutation<
      SubscribeSuccesNewsletterResponse,
      SubscribeNewsletterRequest["body"]
    >({
      query: body => ({
        url: "",
        method: "POST",
        body,
      }),
    }),
  }),
})

export const {
  useLazyGetUserSubscriptionQuery,
  useSubscribeUserMutation,
} = brazeApiGCF
