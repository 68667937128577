import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { Box, Icon } from "@youngagency/young-ui"
import {
  TwitterShareButton,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share"
import { graphql, useStaticQuery } from "gatsby"
import { SocialShareColumnProps, SocialShareProps } from "../../types/shared"
import { isBrowser } from "../blog/utils/helpers"
import { ColumnStack } from "../layout"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { blogArticleEvents } from "../../service/tracking"

const PreviewCardDiv = styled.div`
  transition: 0.3s;
  padding: 16px;
  border-radius: 16px;
  background: var(--green-300);
  margin-bottom: 8px;
  background-color: var(--light-gray-8);

  &:hover {
    background-color: var(--light-gray-6);
  }
`

const CustomColumn = styled(ColumnStack)`
  @media screen and (max-width: 792px) {
    display: none;
  }
`

//Singola Cards per i social
const CardSocial = ({
  icon,
  url,
  title,
  hashtag,
  hashtags,
  quote,
  summary,
  via,
}: SocialShareProps) => {
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)

  const handleClick = React.useCallback(() => {
    if(gaTracker){
      gaTracker.trackEvent({
        eventName: blogArticleEvents.hero_share,
        payload: {
          action: icon
        }
      })
    }
  }, [gaTracker, icon])
  
  switch (icon) {
    case "facebook":
      return (
        <FacebookShareButton url={url} quote={quote as string} hashtag={hashtag} onClick={handleClick}>
          <PreviewCardDiv>
            <Icon icon={icon} color={"black"} fontSize={"24px"} />
          </PreviewCardDiv>
        </FacebookShareButton>
      )
    case "twitter":
      return (
        <TwitterShareButton
          url={url}
          via={via as string}
          title={title as string}
          hashtags={hashtags}
        >
          <PreviewCardDiv>
            <Icon icon={icon} color={"black"} fontSize={"24px"} />
          </PreviewCardDiv>
        </TwitterShareButton>
      )
    case "linkedin":
      return (
        <LinkedinShareButton url={url} title={title as string} summary={summary as string}>
          <PreviewCardDiv>
            <Icon icon={icon} color={"black"} fontSize={"24px"} />
          </PreviewCardDiv>
        </LinkedinShareButton>
      )
    default:
      return <></>
  }
}

//Componente che crea le row cards di condivisione
export const SocialShareCards = ({
  path,
  socialArray,
  isBlogPost,
  setBoxHeight,
}: SocialShareColumnProps & { isBlogPost: boolean }) => {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(query)
  const createSocialSharableUrl = React.useCallback(() => {
    return socialArray?.map(el => {
      return { ...el, url: `${siteUrl}${path}` }
    })
  }, [siteUrl, path, socialArray])
  const social = createSocialSharableUrl()

  const shareBoxRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isBlogPost && typeof window !== "undefined" && shareBoxRef?.current) {
      if (isBrowser && shareBoxRef?.current) {
        const { height } = shareBoxRef.current.getBoundingClientRect()
        setBoxHeight(height)
      }
    }
  }, [isBlogPost, shareBoxRef.current])
  if (social)
    return (
      <CustomColumn ml="auto" vGap={"10px"} fromTop>
        <ColumnStack
          ref={shareBoxRef}
          ml="auto"
          style={{
            position: isBlogPost ? "sticky" : "static",
            top: isBlogPost ? "150px" : "unset",
          }}
        >
          {social.map(el => (
            <CardSocial {...el} key={el.icon} />
          ))}
        </ColumnStack>
      </CustomColumn>
    )
  return null
}

const query = graphql`
  {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
