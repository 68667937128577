import { Box, Label, VStack } from "@youngagency/young-ui"
import React from "react"
import { Container } from "../../../components/layout"
import { SeoLink } from "../../seo"
// import { BlogBanner } from "./BlogHeader"
import { createFlexOrderValue } from "../utils"
import useBlogLayout from "../../../components/blog/utils/useBlogLayout"
import { useNavigationContext } from "../context"

export const BlogSidebar = () => {
  const { blogLinks: navLinks, handleCategoryTracking } = useBlogLayout()
  const { toggleSidebar } = useNavigationContext()

  const handleCategoryClick = React.useCallback((content?: string | null) => {
    handleCategoryTracking(content)
    toggleSidebar()
  }, [
    handleCategoryTracking,
    toggleSidebar,
  ])

  return (
    <Box
      mt={["84px", "56px"]}
      pt={["16px", "56px"]}
      width="100%"
      height="100vh"
      overflowY="scroll"
      position="fixed"
      top={"0px"}
      left="0px"
      right="0px"
      zIndex={9}
      background="white"
    >
      <Container size="md">
        <VStack width="100%" align="start" mt="10px">
          {navLinks &&
            navLinks.map(el => {
              return (
                <Box
                  key={el.databaseId}
                  style={{
                    order: createFlexOrderValue(el.databaseId),
                  }}
                  width="100%"
                  onClick={() => handleCategoryClick(el.tracking_value?.trackingValue)}
                >
                  <Box
                    py="16px"
                    width="100%"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    <SeoLink
                      isExternal={false}
                      link={`/blog/${el.slug}/`}
                      style={{
                        textDecoration: "none",
                        color: "#000000",
                      }}
                    >
                      <Label size="md" fontFamily="Matter">
                        {el.name}
                      </Label>
                    </SeoLink>
                  </Box>

                  <hr
                    style={{
                      width: "100%",
                      border: "1px solid #F5F6FA",
                    }}
                  />
                </Box>
              )
            })}
        </VStack>
      </Container>
      {/* <BlogBanner
        onClick={toggleSidebar}
        style={{
          position: "fixed",
          display: "flex",
          bottom: "0px",
          left: "0px",
          right: "0px",
          height: "56px",
          background: "#f2f2f6",
          zIndex: 9,
        }}
      /> */}
    </Box>
  )
}
