import React from "react"
import { HStack, Box, Body } from "@youngagency/ui"
import useNavigationMenu from "../../hooks/useNavigationMenu"
import { SeoLink } from "../../../seo"
import {
  NavigationChipContainer,
  NavigationChipContent,
} from "./NavigationChip"

export const EcosistemaNavigation = () => {
  const { menu } = useNavigationMenu()

  return (
    <HStack
      spacing={0}
      atoms={{
        __width: "full",
      }}
    >
      {menu.map(link => {
        return (
          <NavigationChipContainer key={link.title} id={`${link.title}`}>
            {link.link ? (
              <SeoLink
                isExternal={link.isExternal}
                link={link.link}
                rel={link.isExternal ? "noopener" : "internal"}
                target={link.isExternal ? "_blank" : "self"}
              >
                <NavigationChipContent>
                  <Body variant="2" fontWeight={600}>
                    {link.title}
                  </Body>
                </NavigationChipContent>
              </SeoLink>
            ) : (
              <Box>
                <NavigationChipContent>
                  <Body variant="2" fontWeight={600}>
                    {link.title}
                  </Body>
                </NavigationChipContent>
              </Box>
            )}
          </NavigationChipContainer>
        )
      })}
    </HStack>
  )
}
