import { Box } from "@youngagency/young-ui"
import { graphql, PageProps } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { Container, ResponsiveText } from "../components/layout"
import { FaqArgomentoProps, SingleFaqProps } from "../types/faq"
import FaqContainer from "../components/faqs/FaqContainer"
import FaqTitle from "../components/faqs/FaqTitle"
import FaqContent from "../components/faqs/FaqContent"
import SingleFaq from "../components/faqs/SingleFaq"
import { cleanStringFromHtlmTags } from "../utils/mucciaccia"
import FaqFooter from "../components/faqs/FaqFooter"
import { FaqSchema, MetaDecorator, AlternateHandler } from "../feature/seo"
import { BreadCrumb, LandingLayout } from "../feature/navigation/components"
import isEmpty from "lodash/isEmpty"
import { TranslationProvider } from "../feature/translations/context"

type SeoFaqProps = {
  question: string
  answer: string
}

const Faq = ({
  data: { allWpfaqTag, wpArgomento },
  pageContext: { slug },
}: PageProps<Queries.SingleFaqPageQuery, { slug: string }>) => {
  const { t } = useI18next()
  const breadcrumb = React.useMemo(
    () => [
      {
        text: t("breadcumbs.home_breadcrumb"),
        link: "/",
      },
      {
        text: t("breadcumbs.faq_breadcrumb"),
        link: "/faq/",
      },
      {
        text: wpArgomento?.meta_data?.pageTitle as string,
        link: `/faq/${slug}/`,
      },
    ],
    []
  )

  const seoFaqsArray = React.useMemo(() => {
    let tempArray: SeoFaqProps[] = []
    allWpfaqTag.nodes.forEach(node => {
      node?.allWpFaqs?.nodes?.forEach(faq => {
        if (faq?.title && faq.content)
          tempArray.push({
            question: faq.title,
            answer: cleanStringFromHtlmTags(faq.content),
          })
      })
    })

    return tempArray
  }, [])

  return (
    <TranslationProvider>
      <LandingLayout dinamycLink="https://youngexchange.page.link/faqM">
        <MetaDecorator
          metaTitle={wpArgomento?.seo?.title as string}
          metaDescription={wpArgomento?.meta_data?.pageDescription as string}
          disableSlogan
        />
        <FaqSchema
          title={wpArgomento?.meta_data?.pageTitle as string}
          description={wpArgomento?.meta_data?.pageDescription as string}
          breadcrumbs={breadcrumb}
          faqsArray={seoFaqsArray}
        />
        <AlternateHandler />
        <Box background="linear-gradient(180deg, rgba(248, 248, 250, 0) 0%, #f8f8fa 100%)">
          <Container>
            <BreadCrumb breadcrumbs={breadcrumb} removeLast />
            <Box pb={["48px", "96px"]} pt={["24px", "48px"]}>
              <Box maxWidth="948px">
                <ResponsiveText
                  line={["39px", "96px"]}
                  fontWeight={700}
                  dimension={["34px", "96px"]}
                  as="h1"
                >
                  {wpArgomento?.meta_data?.pageTitle}
                </ResponsiveText>
              </Box>
              {wpArgomento?.meta_data?.pageDescription ? (
                <Box maxWidth="563px" mt={"24px"}>
                  <ResponsiveText
                    fontWeight={500}
                    line={["19px", "25px"]}
                    dimension={["16px", "20px"]}
                    color="grey.1"
                  >
                    {wpArgomento.meta_data.pageDescription}
                  </ResponsiveText>
                </Box>
              ) : null}
            </Box>
          </Container>
        </Box>
        {wpArgomento?.meta_data?.extraTitle &&
        wpArgomento.meta_data.extraDescription ? (
          <Box mt={["48px", "96px"]}>
            <Container size="md">
              <Box>
                <ResponsiveText
                  line={["30px", "50px"]}
                  dimension={["24px", "48px"]}
                  as="p"
                  fontWeight={600}
                >
                  {wpArgomento.meta_data.extraTitle}
                </ResponsiveText>
              </Box>
              <Box mt="24px">
                <ResponsiveText
                  line={["19px", "25px"]}
                  fontWeight={400}
                  dimension={["16px", "20px"]}
                  as="p"
                  color="grey.1"
                >
                  {wpArgomento.meta_data.extraDescription}
                </ResponsiveText>
              </Box>
            </Container>
          </Box>
        ) : null}

        <Box mt={["48px", "96px"]}>
          <Container size="md">
            {allWpfaqTag.nodes.map((tag, index) => {
              return (
                <FaqContainer mt={["48px", "96px"]}>
                  {tag?.name ? (
                    <FaqTitle text={tag.name} mb={["34px", "68px"]} />
                  ) : null}
                  {tag.allWpFaqs?.nodes && !isEmpty(tag.allWpFaqs?.nodes) ? (
                    <FaqContent>
                      {tag.allWpFaqs.nodes
                        .filter(x =>
                          x?.allWpArgomenti?.nodes?.some(y => y?.slug === slug)
                        )
                        .map(faq => {
                          return (
                            <SingleFaq
                              title={faq?.title as string}
                              description={faq?.content as string}
                            />
                          )
                        })}
                    </FaqContent>
                  ) : null}

                  {index === allWpfaqTag.nodes.length - 1 && (
                    <FaqFooter mt={["24px"]} />
                  )}
                </FaqContainer>
              )
            })}
          </Container>
        </Box>
      </LandingLayout>
    </TranslationProvider>
  )
}

export const query = graphql`query SingleFaqPage($slug: String!, $language: String!, $pageLanguage: WpLanguageCodeEnum!) {
  allWpfaqTag(
    filter: {allWpFaqs: {nodes: {elemMatch: {allWpArgomenti: {nodes: {elemMatch: {slug: {eq: $slug}}}}, language: {code: {eq: $pageLanguage}}}}}}
    sort: {faqOrder: {order: ASC}}
  ) {
    nodes {
      name
      allWpFaqs {
        nodes {
          title
          content
          allWpArgomenti {
            nodes {
              slug
            }
          }
        }
      }
    }
  }
  wpArgomento(slug: {eq: $slug}, language: {code: {eq: $pageLanguage}}) {
    meta_data {
      pageTitle
      pageDescription
      extraDescription
      extraTitle
    }
    seo {
      title
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}`

export default Faq
