import { Box, Icon } from "@youngagency/young-ui"
import { StaticImage } from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import {
  CardTexts,
  Card,
  CardButton,
  CardContent,
  CardImage,
  CardStack,
} from "../../components/cards"
import { Text } from "../../components/texts/temporary"
import { navigate } from "@reach/router"
import { EduImage } from "./LocalizedImaged"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { globalEvents, homePageEvents } from "../../service/tracking"


export const EduSection = () => {
  const { t, language } = useI18next()
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)
  
  const handleAcademyClick = React.useCallback(() => {
    if(gaTracker)
    gaTracker.trackMultipleEvents([
      {
        eventName: homePageEvents.home_body_edu,
        payload: {
          card_clicked: 1,
        },
      },
      {
        eventName: globalEvents.click_to_academy,
      },
    ])
    const link =
      language === "it"
        ? "https://academy.youngplatform.com/"
        : `https://academy.youngplatform.com/${language}/`
    navigate(link)
  }, [language, gaTracker])
  return (
    <CardStack>
      <Card
        background="#131312"
        orientation="column"
        onClick={handleAcademyClick}
        isAnimated
        invertBtn
      >
        <CardContent>
          <CardTexts>
            <Box maxWidth="145px">
              <StaticImage
                alt="Young Platform"
                className="image-logo"
                src="./images/academy-pittogramma.png"
              />
            </Box>
            <Text.H6 fontWeight={600} as="h3" color="white">
              {t("homepage.section_6.card_1.title")}
            </Text.H6>
          </CardTexts>

          <CardButton
            textColor="light"
            mt={["16px", "24px"]}
            rightIcon={<Icon icon="arrow_right" />}
          >
            {t("homepage.section_6.card_1.button")}
          </CardButton>
        </CardContent>
        <CardImage>
          <Box maxWidth="100%">
            <EduImage.card1 />
          </Box>
        </CardImage>
      </Card>

      <Card
        isAnimated
        invertBtn
        background="#131312"
        orientation="column"
        onClick={() => {
          if(gaTracker)
          gaTracker.trackEvent({
            eventName: homePageEvents.home_body_edu,
            payload: {
              card_clicked: 2,
            },
          })
          navigate("https://youngplatform.page.link/hpstep")
        }}
      >
        <CardContent>
          <CardTexts>
            <Box maxWidth="92px">
              <StaticImage
                placeholder="blurred"
                alt="Young Platform"
                className="image-logo"
                src="./images/step-pittogramma.png"
              />
            </Box>
            <Text.H6 fontWeight={600} color="white" as="h3">
              {t("homepage.section_6.card_2.title")}
            </Text.H6>
          </CardTexts>

          <CardButton
            textColor="light"
            mt={["16px", "24px"]}
            rightIcon={<Icon icon="arrow_right" />}
          >
            {t("homepage.section_6.card_2.button")}
          </CardButton>
        </CardContent>
        <CardImage>
          <Box maxWidth="100%">
            <EduImage.card2 />
          </Box>
        </CardImage>
      </Card>
    </CardStack>
  )
}
