import React, { useEffect } from "react"
import { useHasMounted, CookieConsent } from "@youngagency/young-ui"
import Footer from "../../../components/footer/footer"
import "../../../components/layout.css"
import "../../../components/landing.css"
import injectAccessiway from "../../accessiway"
import { useI18next } from "gatsby-plugin-react-i18next"
import { LandingHeader } from "./website/LandingHeader"
import { NavigationProvider } from "../context"
import { useCatchReferallId } from "../hooks/useCatchReferallId"
// import { PromoModal } from "../../promo/components"
import {
  FooterContextType,
  FooterProvider,
} from "../../../components/footer/context"
import { SmartDCAProvider } from "../../promo/components/context/SmartDCAContext"
import { SmartDCAPopUp } from "../../promo/components/newsletter/SmartDCAPopUp"
type Props = {
  disableFooterMargin?: true
  children: React.ReactNode[] | React.ReactNode
  dinamycLink?: string
} & Partial<FooterContextType>

export const LandingLayout = ({
  children,
  disableFooterMargin,
  dinamycLink,
  appleLink,
  playStoreLink,
}: Props) => {
  const { language } = useI18next()
  const hasMounted = useHasMounted()
  const catchReferralId = useCatchReferallId()
  const ctx = React.useMemo(() => {
    return {
      playStoreLink: playStoreLink || "https://youngexchange.page.link/dr8x",
      appleLink: appleLink || "https://youngexchange.page.link/sBwZ",
    }
  }, [appleLink, playStoreLink])

  useEffect(() => {
    if (hasMounted) {
      injectAccessiway()
    }
  }, [hasMounted])

  useEffect(() => {
    if (hasMounted) catchReferralId()
  }, [hasMounted, catchReferralId])

  useEffect(() => {
    if (hasMounted) {
      const hasBusinessLayout = document.body.classList.contains(
        "business-layout"
      )
      if (!hasBusinessLayout) return
      document.body.classList.remove("business-layout")
    }
  }, [hasMounted])

  return (
    <NavigationProvider dinamycLink={dinamycLink}>
      <SmartDCAProvider>
        <LandingHeader />
        <SmartDCAPopUp />
      </SmartDCAProvider>

      <main className="main-container">{children}</main>
      <CookieConsent
        lang={language}
        apiKey={process.env.COOKIE_API_KEY as string}
        siteId={Number(process.env.COOKIE_SITE_ID)}
        cookiePolicyId={Number(process.env.COOKIE_POLICY_ID)}
      />
      <FooterProvider value={ctx}>
        <Footer disableMargin={disableFooterMargin} />
      </FooterProvider>
    </NavigationProvider>
  )
}
