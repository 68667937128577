import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { graphql, PageProps } from "gatsby"
import { AdvertisingLayout } from "../../../feature/navigation/components"
import { TranslationProvider } from "../../../feature/translations/context"
import {
  MetaDecorator,
  WebPageSchema,
  AlternateHandler,
} from "../../../feature/seo"
import { BusinessUserPage } from "../../../feature/b2b/pages/BusinessUser"
import { typeformWhiteGloves } from "../../../feature/b2b/constants"

const BREADCRUMB = [
  {
    text: "home",
    link: "/",
  },
  {
    text: "b2b",
    link: "/business/",
  },
  {
    text: "Business User",
    link: "/business/private-user/",
  },
]
const BusinessUser: React.FC<PageProps<Queries.BusinessPageQuery>> = () => {
  const { t } = useI18next()
  return (
    <TranslationProvider>
      <AdvertisingLayout link={typeformWhiteGloves}>
        <MetaDecorator
          metaTitle={t("business_user.seo.title")}
          metaDescription={t("business_user.seo.description")}
          og={[
            {
              property: "og:title",
              content: t("business_user.seo.opengraph.title"),
            },
            {
              property: "og:description",
              content: t("business_user.seo.opengraph.description"),
            },
          ]}
        />
        <WebPageSchema
          title={t("business_user.seo.title")}
          description={t("business_user.seo.description")}
          breadcrumbs={BREADCRUMB}
        />
        <AlternateHandler />
        <BusinessUserPage />
      </AdvertisingLayout>
    </TranslationProvider>
  )
}

export default BusinessUser
export const query = graphql`
  query BusinessPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
