import React from "react"
import styled from "styled-components"
import { Box, BoxProps } from "@youngagency/young-ui"
import { css } from "@styled-system/css"
import { PostImageContainer } from "./PostImageContainer"
import PostFooter from "./PostFooter"
import { PostTag } from "./PostTag"
import { GatsbyImage } from "gatsby-plugin-image"
import { ResponsiveText } from "../../../components/layout"
import { PostHoverBox } from "./PostHoverBox"
import { SeoLink } from "../../seo"
import {
  PostProvider,
  usePostContext,
  useTransformPostData,
} from "../context/PostContext"
import { PostType } from "../types"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { blogPostEvents } from "../../../service/tracking"

const CustomBox = styled(Box)(
  css({
    maxWidth: ["unset", "368px"],
    width: "100%",
    display: "flex",
    flexDirection: ["row", "column"],
    alignItems: ["flex-start", "flex-start"],
    "& > *:not(:last-of-type)": {
      marginRight: ["16px", "0px"],
    },
  })
)

export const PostWrapper = ({
  children,
  ...rest
}: PostType & { children: React.ReactNode }) => {
  const ctx = useTransformPostData(rest)
  return <PostProvider value={ctx}>{children}</PostProvider>
}

export const Post = ({
  isRelated,
  ...rest
}: Omit<BoxProps, "title"> & { isRelated?: boolean }) => {
  const {
    formattedData,
    categorySlug,
    slug,
    toggleBoxHoverAnimation,
    image,
    imageAltText,
    handleNavigation,
    tagName,
    tagSlug,
    title,
    seo,
    targetRef,
  } = usePostContext() || {}
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)
  const handleTracking = React.useCallback(() => {
    if (gaTracker) {
      gaTracker.trackEvent({
        eventName: blogPostEvents.post_click,
      })
    }
  }, [gaTracker])

  return (
    <PostHoverBox as="article" onClickCapture={handleTracking}>
      <CustomBox {...rest} className="box_hover" ref={targetRef}>
        {image ? (
          <PostImageContainer
            onClick={handleNavigation}
            maxWidth={["94px", "368px"]}
            width="100%"
            minHeight={["94px"]}
            maxHeight={"206px"}
            borderRadius="16px"
            overflow="hidden"
            style={{
              transform: "translateZ(0)",
            }}
          >
            <GatsbyImage
              image={image}
              alt={imageAltText || (title as string)}
              style={{
                height: "100%",
                display: "contents",
              }}
            />
          </PostImageContainer>
        ) : null}

        <Box>
          {tagName ? (
            <Box mt={["0px", "12px"]}>
              <SeoLink
                link={`/blog/tag/${tagSlug}/`}
                isExternal={false}
                onMouseEnter={toggleBoxHoverAnimation}
                onMouseLeave={toggleBoxHoverAnimation}
              >
                <PostTag text={tagName} size="small" />
              </SeoLink>
            </Box>
          ) : null}
          <Box mt="8px">
            <SeoLink link={`/blog/${categorySlug}/${slug}/`} isExternal={false}>
              <ResponsiveText
                dimension={["16px", "24px"]}
                line={["20px", "29px"]}
                color="grey.9"
                fontWeight={600}
                data-role="_text"
                as={isRelated ? "p" : "h3"}
              >
                {title}
              </ResponsiveText>
            </SeoLink>
          </Box>
          {formattedData && seo?.readingTime ? (
            <Box mt="8px">
              <PostFooter
                formattedData={formattedData}
                readingTime={seo.readingTime}
              />
            </Box>
          ) : null}
        </Box>
      </CustomBox>
    </PostHoverBox>
  )
}

const CustomVideoBox = styled(Box)<{ first?: boolean }>(({ first }) =>
  css({
    maxWidth: ["unset", "562px"],
    width: "100%",
    display: "flex",
    flexDirection: first ? "column" : ["row", "column"],
    alignItems: "flex-start",
    "& > *:not(:last-of-type)": {
      marginRight: first ? "0px" : ["16px", "0px"],
    },
    "[data-role = '_text']": {
      color: "white !important",
    },
  })
)

export const VideoPost = (
  props: { first?: boolean } & Omit<BoxProps, "title">
) => {
  const {
    formattedData,
    categorySlug,
    slug,
    toggleBoxHoverAnimation,
    image,
    imageAltText,
    handleNavigation,
    tagName,
    tagSlug,
    title,
    seo,
    targetRef,
  } = usePostContext() || {}

  return (
    <PostHoverBox as="article">
      <CustomVideoBox {...props} className="box_hover" ref={targetRef}>
        {image ? (
          <PostImageContainer
            onClick={handleNavigation}
            maxWidth={props.first ? "562px" : ["94px", "562px"]}
            minHeight={["94px"]}
            borderRadius="16px"
            overflow="hidden"
            style={{
              transform: "translateZ(0)",
            }}
          >
            <GatsbyImage
              image={image}
              alt={imageAltText || (title as string)}
              style={{
                height: "100%",
                display: "contents",
              }}
            />
          </PostImageContainer>
        ) : null}

        <Box>
          {tagName ? (
            <Box mt={props.first ? "12px" : ["0px", "12px"]}>
              <SeoLink
                link={`/blog/tag/${tagSlug}/`}
                isExternal={false}
                onMouseEnter={toggleBoxHoverAnimation}
                onMouseLeave={toggleBoxHoverAnimation}
              >
                <PostTag text={tagName} size="small" />
              </SeoLink>
            </Box>
          ) : null}
          <Box mt="8px">
            <SeoLink link={`/blog/${categorySlug}/${slug}/`} isExternal={false}>
              <ResponsiveText
                dimension={["16px", "24px"]}
                line={["20px", "29px"]}
                fontWeight={600}
                data-role="_text"
                as="h3"
                color="white"
              >
                {title}
              </ResponsiveText>
            </SeoLink>
          </Box>
          {formattedData && seo?.readingTime ? (
            <Box mt="8px">
              <PostFooter
                formattedData={formattedData}
                readingTime={seo.readingTime}
              />
            </Box>
          ) : null}
        </Box>
      </CustomVideoBox>
    </PostHoverBox>
  )
}
