import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { createArrayFromTranslationQuery } from "../../utils/helpers"
import { Box, Icon, IconType, Button } from "@youngagency/young-ui"
import styled from "styled-components"
import { Avatar, ResponsiveText } from "../layout"
import { SeoLink } from "../../feature/seo"

type Props = {
  title: string
  description: string
  key: string
  link: string
  icon: IconType
}

const StyledBox = styled(Box)`
  & > *:not(:first-of-type) {
    margin-top: 16px;
  }
  @media screen and (min-width: 767px) {
    display: flex;
    & > *:not(:first-of-type) {
      margin-left: 20px;
      margin-top: 0px;
    }
  }
`

const Feature = () => {
  const { t } = useI18next()
  const faqsInfo: Props[] = createArrayFromTranslationQuery(
    t,
    "faqs_page.card_",
    4,
    [],
    "title",
    "description",
    "link",
    "key",
    "icon"
  )
  return (
    <StyledBox>
      {faqsInfo.map(faq => {
        return (
          <Box
            width="100%"
            minWidth={["unset", "271px"]}
            key={faq.title}
            border="1px solid"
            borderColor="grey.5"
            borderRadius="16px"
            padding="24px"
          >
            <Avatar width="54px" height="54px" backgroundColor="grey.4">
              <Icon icon={faq.icon} fontSize="24px" />
            </Avatar>
            <Box mt={["16px"]}>
              <ResponsiveText
                line={["30px"]}
                fontWeight={600}
                dimension={["24px"]}
              >
                {faq.title}
              </ResponsiveText>
              <Box mt={["16px"]}>
                <ResponsiveText line={["20px"]} dimension={["16px"]}>
                  {faq.description}
                </ResponsiveText>
              </Box>
              <Box mt="16px">
                <SeoLink
                  isExternal={false}
                  link={`/faq/${faq.key.toLowerCase()}/`}
                >
                  <Button size="md" isRounded>
                    {faq.link}
                  </Button>
                </SeoLink>
              </Box>
            </Box>
          </Box>
        )
      })}
    </StyledBox>
  )
}

export default Feature
