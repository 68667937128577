import { useRef, useCallback } from "react"
import { isBrowser } from "./helpers"

const usePostHoverAnimation = (className: string) => {
  const targetRef = useRef<HTMLDivElement>(null)

  const toggleBoxHoverAnimation = useCallback(() => {
    if (isBrowser && targetRef.current) {
      if (targetRef.current.classList.contains(className)) {
        targetRef.current.classList.remove(className)
      } else {
        targetRef.current.classList.add(className)
      }
    }
  }, [])

  return { targetRef, toggleBoxHoverAnimation }
}

export default usePostHoverAnimation
