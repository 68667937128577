import { Box } from "@youngagency/young-ui"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import {
  CardTexts,
  Card,
  CardContent,
  CardImage,
  CardStack,
} from "../../components/cards"
import { Text } from "../../components/texts/temporary"

export const SecuritySection = () => {
  const { t } = useTranslation()
  return (
    <>
      <CardStack>
        <Card
          isAnimated
          disableHover
          background="#131312"
          orientation="column"
          justifyContent="flex-start !important"
          height="auto !important"
        >
          <CardContent>
            <CardTexts>
              <Text.H6 fontWeight={600} color="white" as="h3">
                {t("homepage.section_5.card_1.title")}
              </Text.H6>
              <Text.Body1 fontWeight={600} color="grey.1" as="p">
                {t("homepage.section_5.card_1.description")}
              </Text.Body1>
            </CardTexts>
          </CardContent>
          <CardImage position="static !important">
            <Box maxWidth="256px">
              <StaticImage
                placeholder="blurred"
                src="./images/security-1.png"
                alt={t("homepage.section_5.card_1.img_alt_text")}
              />
            </Box>
          </CardImage>
        </Card>

        <Card
          isAnimated
          disableHover
          background="#131312"
          orientation="column"
          height="auto !important"
        >
          <CardContent>
            <CardTexts>
              <Text.H6 fontWeight={600} color="white" as="h3">
                {t("homepage.section_5.card_2.title")}
              </Text.H6>
              <Text.Body1 fontWeight={600} color="grey.1" as="p">
                {t("homepage.section_5.card_2.description")}
              </Text.Body1>
            </CardTexts>
          </CardContent>
          <CardImage position="static !important">
            <Box maxWidth="256px">
              <StaticImage
                placeholder="blurred"
                src="./images/security-2.png"
                alt={t("homepage.section_5.card_2.img_alt_text")}
              />
            </Box>
          </CardImage>
        </Card>

        <Card
          isAnimated
          disableHover
          background="#131312"
          orientation="column"
          height="auto !important"
        >
          <CardContent>
            <CardTexts>
              <Text.H6 fontWeight={600} color="white" as="h3">
                {t("homepage.section_5.card_3.title")}
              </Text.H6>
              <Text.Body1 fontWeight={600} color="grey.1" as="p">
                {t("homepage.section_5.card_3.description")}
              </Text.Body1>
            </CardTexts>
          </CardContent>
          <CardImage position="static !important">
            <Box maxWidth="256px">
              <StaticImage
                placeholder="blurred"
                src="./images/security-3.png"
                alt={t("homepage.section_5.card_3.img_alt_text")}
              />
            </Box>
          </CardImage>
        </Card>
      </CardStack>
    </>
  )
}
