import { useTranslation } from "gatsby-plugin-react-i18next"
import { useMemo } from "react"
import { createArrayFromJson } from "../../../utils/general"
export const useProductMenu = ({
  enthImages,
  newbyImages,
}: Record<string, JSX.Element[]>) => {
  const { t } = useTranslation()
  const enthusiastArrays = useMemo(
    () =>
      createArrayFromJson(
        t,
        "menu.product.hover.sections.enthusiast.children",
        2,
        enthImages,
        "title",
        "description",
        "link",
        "img"
      ),
    [t]
  )

  const newbyArrays = useMemo(
    () =>
      createArrayFromJson(
        t,
        "menu.product.hover.sections.newby.children",
        2,
        newbyImages,
        "title",
        "description",
        "link",
        "img"
      ),
    [t]
  )
  return {
    enthusiastArrays,
    newbyArrays,
  }
}

export type ProductMenuProps = ReturnType<typeof useProductMenu>
