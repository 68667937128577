import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"

const baseUrl = "https://min-api.cryptocompare.com"

export const cryptoCompareApi = createApi({
  reducerPath: "crypto_compare",
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  endpoints: builder => ({}),
})
