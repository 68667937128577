import { FounderItem } from "../types"
import React from "react"

export const useFounderFilter = (array: readonly FounderItem[]) => {
  const tags = React.useMemo(
    () =>
      Array.from(
        new Set(
          array.flatMap(el =>
            el?.allWpAboutGroups?.nodes?.map(tag => tag?.slug)
          )
        )
      ),
    []
  )

  let item: {
    [x: string]: (FounderItem & { category: string })[] | undefined
  } = {}

  tags.forEach(tag => {
    if (tag) {
      item = {
        ...item,
        [tag.toLowerCase()]: array
          .filter(item =>
            item?.allWpAboutGroups?.nodes?.some(value => value?.slug === tag)
          )
          .map(element => ({
            ...element,
            category: array
              ?.find(item =>
                item?.allWpAboutGroups?.nodes?.some(
                  value => value?.slug === tag
                )
              )
              ?.allWpAboutGroups?.nodes?.find(x => x?.slug === tag)
              ?.name as string,
          })),
      }
    }
  })

  const people: number[] = React.useMemo(
    () => Array.from(new Set(array.flatMap(person => person.databaseId))),
    []
  )

  return { item, people }
}

export type FounderContexType = ReturnType<typeof useFounderFilter>

export const useAboutUsModalSelector = (
  people: FounderContexType["people"],
  allElement: readonly FounderItem[]
) => {
  const [modalContent, setModalContent] = React.useState<
    FounderItem | undefined | null
  >(null)

  const openModal = React.useCallback((id?: number) => {
    if (!id) return
    if (!people.includes(id)) return
    setModalContent(allElement.find(person => person.databaseId === id))
  }, [])

  const closeModal = React.useCallback(() => {
    if (!modalContent) return
    setModalContent(null)
  }, [modalContent])

  return { modalContent, openModal, closeModal }
}

export type ModalFounderProps = ReturnType<typeof useAboutUsModalSelector>
