import { useCallback } from "react"
import { saveReferralId } from "../../../redux/reducers/authSlice"
import { useDispatch } from "react-redux"
export const useCatchReferallId = () => {
  const dispatch = useDispatch()
  const catchReferralId = useCallback(() => {
    const { search } = window.location
    const params = new URLSearchParams(search)
    const ref = params.get("ref")
    if (ref) dispatch(saveReferralId(ref))
  }, [dispatch])

  return catchReferralId
}
