import styled from "styled-components"

const AuthorBox = styled.div`
  width: 40px;
  height: 40px;
  background-color: var(--light-gray-8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default AuthorBox
