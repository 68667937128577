import React from "react"

type OpacityContextProps = {
  show: boolean
  onChange?: () => void
}

const OpacityContext = React.createContext<OpacityContextProps>({
  show: false,
})

export const OpacityProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [show, setShow] = React.useState(false)
  const onChange = React.useCallback(() => {
    if (show) {
      return setShow(false)
    }

    return setShow(true)
  }, [show])
  return (
    <OpacityContext.Provider value={{ show, onChange }}>
      {children}
    </OpacityContext.Provider>
  )
}

export const useOpacityContext = () => {
  const context = React.useContext(OpacityContext)
  if (context === undefined) {
    throw new Error(`useOpacityContext must be used within a OpacityProvider`)
  }
  return context
}
