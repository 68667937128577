import {
  Box,
  Heading,
  HStack,
  Paragraph,
  Skeleton,
} from "@youngagency/young-ui"
import { useFormikContext } from "formik"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { SeoLink } from "../../seo"
import styled from "styled-components"
import isEmpty from "lodash/isEmpty"
import { useI18next } from "gatsby-plugin-react-i18next"
import { replaceTokenWithCustomTicker } from "../utils"
import { inverseTokenTicker } from "../token_fiat/utils"

type SearchTokenProps = {
  allWpToken: {
    distinct: string[]
  },
  allToken: {
    group: {
      nodes: {
        name: string
        symbol: string,
        details: {
            image: string
          }
      }[]
    }[]
  }
}

const SearchContainer = styled(Box)`
  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledBox = styled(Box)({
  transition: "all 125ms ease",

  ":hover": {
    background: "var(--r-grey-5)",
  },
})

export const SearchBox = () => {
  const { t } = useI18next()
  const data: SearchTokenProps = useStaticQuery(query)
  const ctx = useFormikContext<{ search: string }>()
  const { search } = ctx.values || null

  const arrayFurbo = React.useMemo(
    () =>
      data.allToken.group
        .map(nodes => nodes.nodes[0])
        .filter(item =>
          data.allWpToken.distinct.includes(inverseTokenTicker(item.symbol))
        ),
    [data.allToken.group, data.allWpToken.distinct]
  )

  const filteredData = React.useMemo(() => {
    if (search) {
      return arrayFurbo
        .filter(
          token =>
            token.symbol.toLowerCase().includes(search.toLowerCase()) ||
            token.name.toLowerCase().includes(search.toLowerCase())
        )
        .map(el => ({
          symbol: replaceTokenWithCustomTicker(el.symbol, "symbol"),
          name: el.name,
          details: {
            image: el.details.image,
          }
        }))
    }
    return null
  }, [search, arrayFurbo])

  return (
    <Box
      id="search-box"
      maxHeight="192px"
      maxWidth={["unset", "366px"]}
      className="tooltip"
      mx="auto"
      width={["calc(100% - 32px )", "100%"]}
      overflowY={"hidden"}
    >
      {filteredData && !isEmpty(filteredData) ? (
        <SearchContainer
          borderRadius="16px"
          border="1px solid"
          borderColor="grey.5"
          background="white"
          maxHeight="192px"
          maxWidth={["unset", "366px"]}
          width="100%"
          overflowY={"scroll"}
        >
          {filteredData.map(el => (
            <SearchRow key={el.symbol} {...el} />
          ))}
        </SearchContainer>
      ) : isEmpty(filteredData) && search.length > 1 ? (
        <Box
          borderRadius="16px"
          border="1px solid"
          borderColor="grey.5"
          background="white"
          minHeight={"68px"}
          maxHeight="192px"
          maxWidth={["unset", "366px"]}
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Paragraph
            size="md"
            fontFamily="Matter"
            color="grey.1"
            textAlign="center"
          >
            {t("markets.txt_empty_list", { search: search })}
          </Paragraph>
        </Box>
      ) : null}
    </Box>
  )
}

const SearchRow = ({
  name,
  details: { image },
  symbol,
}: SearchTokenProps["allToken"]["group"][number]["nodes"][number]) => {
  const [isLoaded, setIsLoaded] = React.useState<boolean>(false)
  return (
    <SeoLink
      link={`/exchange/${symbol.toLocaleLowerCase()}/`}
      isExternal={false}
    >
      <StyledBox py="14px" px="16px">
        <HStack justify="space-between" align="center">
          <HStack spacing="16px" align="center">
            <img
              src={image}
              className="skeleton-img"
              alt="hidden loading state"
              aria-hidden="true"
              onLoad={() => setIsLoaded(true)}
            />
            {isLoaded ? (
              <img
                src={image}
                width={36}
                height={36}
                alt={`${symbol} logo`}
                style={{
                  marginLeft: "0px",
                }}
              />
            ) : (
              <Skeleton
                width="36px"
                height="36px"
                variant="circle"
                style={{
                  marginLeft: "0px",
                }}
              />
            )}
            <Heading size="sm" fontWeight={600} fontFamily="Matter">
              {name}
            </Heading>
          </HStack>
          <Paragraph size="md" fontFamily="Matter">
            {symbol}
          </Paragraph>
        </HStack>
      </StyledBox>
    </SeoLink>
  )
}

const query = graphql`query AllAvailableToken {
  allWpToken {
    distinct(field: {customFields: {key: SELECT}})
  }
  allToken {
    group(field: {symbol: SELECT}) {
      nodes {
        symbol
        name
        details {
          image
        }
      }
    }
  }
}`
