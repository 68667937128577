import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { createArrayFromJson } from "../../../../utils/general"
import { StaticImage } from "gatsby-plugin-image"

export const useData = () => {
  const { t } = useTranslation()
  const breadcrumb = React.useMemo(
    () => [
      {
        text: t("breadcumbs.home_breadcrumb"),
        link: "/",
      },
      {
        text: t("about_us.jobs.seo.breadcrumb"),
        link: "/jobs/",
      },
    ],
    [t]
  )

  const section_1_array = React.useMemo(
    () =>
      createArrayFromJson(
        t,
        "about_us.jobs.section_1.cards",
        6,
        [],
        "icon",
        "title"
      ),
    [t]
  )

  const section_2_image = React.useMemo(
    () => [
      <StaticImage
        className="gatsby-image-radius"
        src="../images/image_1.png"
        alt={t("about_us.jobs.section_2.image_section.alt_text_image_1")}
      />,
      <StaticImage
        className="gatsby-image-radius"
        src="../images/image_2.png"
        alt={t("about_us.jobs.section_2.image_section.alt_text_image_2")}
      />,
      <StaticImage
        className="gatsby-image-radius"
        src="../images/image_3.png"
        alt={t("about_us.jobs.section_2.image_section.alt_text_image_3")}
      />,
    ],
    [t]
  )

  const section_2_array = React.useMemo(
    () =>
      createArrayFromJson(
        t,
        "about_us.jobs.section_2.image_section",
        3,
        section_2_image,
        "title",
        "description",
        "img"
      ),
    [t, section_2_image]
  )

  const section_4_array = React.useMemo(
    () =>
      createArrayFromJson(
        t,
        "about_us.jobs.section_4.cards",
        4,
        [],
        "icon",
        "title"
      ),
    [t]
  )

  const section_5_image = React.useMemo(
    () => [
      <StaticImage
        className="gatsby-image-radius"
        src="../images/Turin.png"
        alt={t("about_us.jobs.section_5.cards.alt_text_image_1")}
      />,
      <StaticImage
        className="gatsby-image-radius"
        src="../images/Londra.png"
        alt={t("about_us.jobs.section_5.cards.alt_text_image_2")}
      />,
      <StaticImage
        className="gatsby-image-radius"
        src="../images/Parigi.png"
        alt={t("about_us.jobs.section_5.cards.alt_text_image_3")}
      />,
      <StaticImage
        className="gatsby-image-radius"
        src="../images/Tallinn.png"
        alt={t("about_us.jobs.section_5.cards.alt_text_image_4")}
      />,
    ],
    [t]
  )

  const section_5_array = React.useMemo(
    () =>
      createArrayFromJson(
        t,
        "about_us.jobs.section_5.cards",
        4,
        section_5_image,
        "img",
        "title"
      ),
    [t, section_5_image]
  )

  const section_6_image = React.useMemo(() => {
    return [
      <StaticImage
        className="gatsby-image-radius"
        src="../images/interview_1.png"
        alt={t("about_us.jobs.section_6.reviews.alt_text_image_1")}
      />,
      <StaticImage
        className="gatsby-image-radius"
        src="../images/interview_1.png"
        alt={t("about_us.jobs.section_6.reviews.alt_text_image_1")}
      />,
    ]
  }, [t])

  const section_6_array = React.useMemo(
    () =>
      createArrayFromJson(
        t,
        "about_us.jobs.section_6.reviews",
        1,
        section_6_image,
        "img",
        "title",
        "description",
        "cta"
      ),
    [t, section_6_image]
  )
  return {
    breadcrumb,
    section_1_array,
    section_2_array,
    section_4_array,
    section_5_array,
    section_6_array,
  }
}
