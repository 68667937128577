import { PillResponse, PillsApi, TransformedPillsDetails } from "./types"

const parseDetails = (
  pill: PillResponse
): Omit<TransformedPillsDetails, "priceChange24h"> => {
  const { coin_details: details } = pill
  return {
    color: pill.color,
    image: pill.image,
    whitePaper: pill.whitepaper,
    webSite: pill.website,
    maxSupply: details.max_supply,
    circulatingSupply: details.current_supply,
    volume24h: details.volume_24h,
    marketCap: details.market_cap,
  }
}

export const parseForUnlist = (pill: PillResponse) => {
  return {
    tradable: pill.tradable,
    isUnlisted: !pill.tradable,
  }
}

export const parsePill = (pill: PillResponse): PillsApi => {
  return {
    name: pill.name,
    symbol: pill.symbol,
    price: pill.coin_details.current_price,
    priority: pill.priority,
    descriptions: pill.descriptions,
    percentageChange: pill.coin_details.percent_change_24h
      ? pill.coin_details.percent_change_24h?.toString()
      : undefined,
    details: parseDetails(pill),
    ...parseForUnlist(pill),
  }
}
