import React, { ReactElement } from "react"
import { Stack, Box, Icon } from "@youngagency/young-ui"
import { BlockDescriptionProps } from "../../types/shared"
import { ResponsiveText } from "../layout"
import { cleanStringFromHtlmTags } from "../../utils/mucciaccia"
import styled from "styled-components"

type Props = {
  title: string | number | ReactElement
  description?: string | number | ReactElement | null
  disableInject?: boolean
  isH3?: boolean
}

const StyledBox = styled(Box)`
  a {
    text-decoration: none;
    color: var(--green-hover);
    &:hover {
      color: var(--green-300);
    }
  }

  .state-box:hover {
    cursor: pointer;
  }
`

const SingleFaq = ({
  title,
  description,
  disableInject = false,
  isH3,
}: Props & { disableInject?: boolean; isH3?: boolean }) => {
  const [open, setOpen] = React.useState<boolean>(false)
  return (
    <StyledBox
      as="li"
      p={["16px", "24px"]}
      border="1px solid"
      borderColor="grey.5"
      borderRadius="16px"
    >
      <Box>
        <Stack
          align="flex-start"
          justify="space-between"
          onClick={() => setOpen(!open)}
          className="state-box"
        >
          <ResponsiveText
            dimension={["16px", "18px"]}
            line={["20px", "22px"]}
            fontWeight={600}
            maxWidth="700px"
            as={isH3 ? "h3" : "h2"}
          >
            {title}
          </ResponsiveText>
          <Box
            style={{
              transform: open ? "rotateZ( 180deg )" : "rotate(0)",
            }}
          >
            <Icon icon="chevron_down" fontSize={["24px"]} />
          </Box>
        </Stack>
      </Box>

      {open && description && typeof description === "string" ? (
        <Box mt={["24px", "24px"]}>
          {disableInject ? (
            <ResponsiveText
              dimension={["14px", "16px"]}
              line={["18px", "20px"]}
            >
              {cleanStringFromHtlmTags(description)}
            </ResponsiveText>
          ) : (
            <ResponsiveText
              dimension={["14px", "16px"]}
              line={["18px", "20px"]}
              dangerouslySetInnerHTML={{
                __html: description.replace(
                  /(<p>&nbsp;<\/p>)|(<h2><span>&nbsp;<\/span><\/h2>)/g,
                  ""
                ),
              }}
            />
          )}
        </Box>
      ) : null}
    </StyledBox>
  )
}

export default SingleFaq
