import { Box } from "@youngagency/young-ui"
import { graphql, PageProps } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import {
  BottomBanner,
  Container,
  ResponsiveButton,
} from "../../components/layout"
import { BreadCrumb, LandingLayout } from "../../feature/navigation/components"
import {
  AlternateHandler,
  MetaDecorator,
  SeoLink,
  WebPageSchema,
} from "../../feature/seo"
import { Body, Hero } from "../../feature/token/token_fiat/components"
import { TokenFiatProvider } from "../../feature/token/token_fiat/context"
import { useGetFiatName } from "../../feature/token/token_fiat/hooks"
import { inverseTokenTicker } from "../../feature/token/token_fiat/utils"
import { TranslationProvider } from "../../feature/translations/context"
import {
  BODY_TOKEN_PAGES_DYNAMIC_LINK,
  HEADER_TOKEN_PAGES_DYNAMIC_LINK,
} from "../../feature/token/constants"

type PageContext = {
  slug: string
}

const TokenFiat: React.FC<
  PageProps<Queries.TokenFiatMainPageQuery, PageContext>
> = ({ data, pageContext }) => {
  const { t } = useI18next()

  const breadcrumbs = React.useMemo(
    () => [
      {
        text: "Home",
        link: "/",
      },
      {
        text: "Exchange",
        link: "/exchange/",
      },
      {
        text: pageContext.slug,
        link: `/exchange/${pageContext.slug}`,
      },
    ],
    [pageContext.slug]
  )

  const getFiatName = useGetFiatName()
  const fiatName = React.useMemo(
    () => getFiatName(data?.availableFiat?.symbol as string),
    [getFiatName]
  )

  const ctx = React.useMemo(
    () => ({
      token: {
        name: data?.wpToken?.customFields?.name as string,
        symbol:
          data?.wpToken?.customFields?.ticker?.toUpperCase() ||
          (data?.wpToken?.customFields?.key as string),
      },
      fiat: {
        name: fiatName,
        symbol: data?.availableFiat?.symbol as string,
        amountSymbol: data.availableFiat?.amountSymbol as string,
      },
    }),
    [fiatName]
  )

  return (
    <TranslationProvider>
      <LandingLayout
        disableFooterMargin
        dinamycLink={HEADER_TOKEN_PAGES_DYNAMIC_LINK}
        appleLink={"https://youngexchange.page.link/stpgfios"}
        playStoreLink={"https://youngexchange.page.link/stpgfa"}
      >
        <MetaDecorator
          metaTitle={t("markets.token_fiat.seo.title", {
            currency: inverseTokenTicker(ctx.token.symbol),
            fiat: ctx.fiat.symbol,
          })}
          metaDescription={t("markets.token_fiat.seo.description", {
            currency: ctx.token.name,
            fiat: ctx.fiat.name,
          })}
        />
        <AlternateHandler />
        <WebPageSchema
          title={t("markets.token_fiat.seo.title", {
            currency: inverseTokenTicker(ctx.token.symbol),
            fiat: ctx.fiat.symbol,
          })}
          description={t("markets.token_fiat.seo.description", {
            currency: inverseTokenTicker(ctx.token.symbol),
            fiat: ctx.fiat.name,
          })}
          breadcrumbs={breadcrumbs}
        />
        <Container size={["md", "lg"]}>
          <BreadCrumb breadcrumbs={breadcrumbs} />
        </Container>
        <TokenFiatProvider value={ctx}>
          <Box mt={["24px", "48px"]}>
            <Hero />
          </Box>
          <Box mt={["24px", "48px"]}>
            <Body />
          </Box>
        </TokenFiatProvider>
        <BottomBanner
          mt={["36px", "72px"]}
          text={t("markets.token_fiat.footer.title")}
        >
          <SeoLink
            isExternal={true}
            isConversion
            link={BODY_TOKEN_PAGES_DYNAMIC_LINK}
            rel="noopener"
          >
            <ResponsiveButton size="lg" variant="primary">
              {t("markets.token_fiat.footer.cta")}
            </ResponsiveButton>
          </SeoLink>
        </BottomBanner>
      </LandingLayout>
    </TranslationProvider>
  )
}

export const query = graphql`
  query TokenFiatMainPage($language: String!, $token: Int!, $fiat: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    availableFiat(id: { eq: $fiat }) {
      symbol
      amountSymbol
      price
    }
    wpToken(databaseId: { eq: $token }) {
      customFields {
        key
        ticker
        name
      }
    }
  }
`

export default TokenFiat
