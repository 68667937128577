import {
  Box,
  css,
  Heading,
  HStack,
  Icon,
  IconType,
  Paragraph,
  Stack,
  styled,
} from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { Avatar } from "../../../components/layout"
import { createArrayFromJson } from "../../../utils/general"

type Props = {
  type: "listed" | "unlisted"
}

const StyledBox = styled(Box)(
  css({
    "& > * ": {
      borderTop: "1px solid var( --r-grey-5 )",
      ":last-of-type": {
        borderBottom: "1px solid var( --r-grey-5 )",
      },
    },
  })
)

export const ActionSection = ({ type }: Props) => {
  const { t } = useI18next()
  const tempArray = React.useMemo(
    () =>
      createArrayFromJson(
        t,
        "single_market.actionSection.cards",
        3,
        [],
        "description",
        "icon"
      ),
    [t]
  )

  const actionCards = React.useMemo(
    () =>
      tempArray.map((el, index) => {
        return {
          ...el,
          title: t(
            `single_market.actionSection.cards.title_${index + 1}.${type}`
          ),
        }
      }),
    [t, tempArray]
  )
  return (
    <StyledBox>
      {actionCards.map(el => {
        return (
          <Box key={el.title} py={["16px", "24px"]}>
            <HStack align={["stretch", "center"]} spacing="24px">
              <Avatar
                minWidth={["48px", "72px"]}
                height={["48px", "72px"]}
                backgroundColor="grey.4"
              >
                <Icon icon={el.icon as IconType} fontSize={["20px", "28px"]} />
              </Avatar>
              <Box>
                <Heading fontFamily="Matter" size="lg" fontWeight={600}>
                  {el.title}
                </Heading>
                <Paragraph size="md" fontFamily="Matter" mt="8px">
                  {el.description}
                </Paragraph>
              </Box>
            </HStack>
          </Box>
        )
      })}
    </StyledBox>
  )
}
