import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { LandingLayout } from "../../feature/navigation/components"
import {
  AlternateHandler,
  MetaDecorator,
  WebPageSchema,
} from "../../feature/seo"
import { TranslationProvider } from "../../feature/translations/context"
import {
  ComparativeSection,
  Hero,
  MiddleConversion,
  NarrativeSection,
  RecapSection,
} from "../../feature/clubs/components"
import { Box } from "@youngagency/young-ui"
import { graphql } from "gatsby"
import { dynamicLinks } from "../../feature/clubs/constants"
import { ClubPageProvider } from "../../feature/clubs/context/ClubPageContext"

const ClubHomePage = () => {
  const { t, originalPath } = useI18next()
  const breadCrumbs = React.useMemo(() => {
    return [
      {
        link: "/",
        text: "Home",
      },
      {
        link: originalPath,
        text: "Clubs",
      },
    ]
  }, [originalPath])

  const metaData = React.useMemo(() => {
    return {
      metaTitle: t("clubs_page.seo.title"),
      metaDescription: t("clubs_page.seo.description"),
      extraMetaTags: [
        {
          property: "title",
          content: t("clubs_page.seo.opengraph.title"),
        },
        {
          property: "description",
          content: t("clubs_page.seo.opengraph.description"),
        },
      ],
    }
  }, [t])
  return (
    <TranslationProvider>
      <LandingLayout
        disableFooterMargin
        dinamycLink={dynamicLinks.menu}
        appleLink={dynamicLinks.ios}
        playStoreLink={dynamicLinks.android}
      >
        <MetaDecorator {...metaData} />
        <WebPageSchema
          breadcrumbs={breadCrumbs}
          title={metaData.metaTitle}
          description={metaData.metaDescription}
        />
        <ClubPageProvider>
          <AlternateHandler />
          <Box mx="auto" mt={["8px", "16px"]}>
            <Hero />
          </Box>
          <Box mt={["48px", "96px"]}>
            <NarrativeSection />
          </Box>
          <Box mt={["48px", "96px"]}>
            <ComparativeSection />
          </Box>
        </ClubPageProvider>

        <Box mt={["48px", "96px"]}>
          <MiddleConversion />
        </Box>
        <Box background="#000" width="100%">
          <Box
            mt={["8px", "16px"]}
            borderRadius={"0px 0px 32px 32px"}
            background="#fff"
            width="100%"
            height={["96px", "48px"]}
            style={{
              transform: "translateY(-1px)",
            }}
          />
          <RecapSection />
        </Box>
      </LandingLayout>
    </TranslationProvider>
  )
}

export const query = graphql`
  query ClubsPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ClubHomePage
