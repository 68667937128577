import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import {
  Box,
  HStack,
  Icon,
  IconType,
  Button,
  useHasMounted,
  VStack,
} from "@youngagency/young-ui"
import { graphql, PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Avatar, BorderBox, Container, HeroStack } from "../components/layout"
import { AlternateHandler, MetaDecorator, WebPageSchema } from "../feature/seo"
import { BreadCrumb, LandingLayout } from "../feature/navigation/components"
import { Description, Display, Heading, Paragraph } from "../components"
import {
  CustomStack,
  CustomGrid,
  CustomStepBoxStack,
  StepBox,
  CitiesGrid,
  FlotaingBanner,
} from "../feature/landing/jobs/components"
import { useData } from "../feature/landing/jobs/hooks"
import { StarFillRow } from "../components/layout/Star"
import { TranslationProvider } from "../feature/translations/context"
import { navigate } from "@reach/router"
import { LegacyJobsComponent } from "../feature/landing/jobs/components/LegacyJobsComponent"

const SecondPage: React.FC<PageProps<Queries.JobsMainPageQuery>> = ({
  data,
}) => {
  const { t } = useTranslation()
  const {
    // section_1_array,
    // section_2_array,
    // section_4_array,
    // section_5_array,
    // section_6_array,
    breadcrumb,
  } = useData()

  // const handleNavigation = React.useCallback(
  //   () => navigate("https://careers.youngplatform.com/", { replace: true }),
  //   []
  // )

  // const ref = React.useRef<HTMLDivElement | null>(null)
  // const [animationComplated, setAnimationCompleted] = React.useState<boolean>(
  //   false
  // )
  // const hasMounted = useHasMounted()
  // const handleAnimationOnScroll = React.useCallback(() => {
  //   if (typeof window !== "undefined") {
  //     if (!ref?.current) return
  //     const displayHeight = window.screen.height / 2
  //     const windowY = window.scrollY
  //     if (!animationComplated) {
  //       if (windowY > displayHeight) {
  //         ref.current.classList.remove("opacity-outro-animation")
  //         ref.current.classList.add("opacity-entry-animation")
  //         setTimeout(() => {
  //           setAnimationCompleted(true)
  //         }, 750)
  //       }
  //     } else {
  //       if (windowY < displayHeight) {
  //         ref.current.classList.remove("opacity-entry-animation")
  //         ref.current.classList.add("opacity-outro-animation")
  //         setTimeout(() => {
  //           setAnimationCompleted(false)
  //         }, 750)
  //       }
  //     }
  //   }
  // }, [ref?.current, animationComplated])

  // React.useEffect(() => {
  //   if (hasMounted) {
  //     window.addEventListener("scroll", handleAnimationOnScroll)
  //     return () => window.removeEventListener("scroll", handleAnimationOnScroll)
  //   }
  // }, [hasMounted, handleAnimationOnScroll])

  return (
    <TranslationProvider>
      <LandingLayout
        dinamycLink="https://youngexchange.page.link/aboutusmenu"
        appleLink="https://youngexchange.page.link/aboutusios"
        playStoreLink="https://youngexchange.page.link/aboutusand"
      >
        <WebPageSchema breadcrumbs={breadcrumb} />
        <MetaDecorator />
        <AlternateHandler />
        <Container size={["md", "lg"]}>
          <BreadCrumb breadcrumbs={breadcrumb} />
        </Container>
        <LegacyJobsComponent positions={data.allWpJobPosition.nodes} />
        {/* <Box position="relative">
          <Container size={["md", "lg"]}>
            <BreadCrumb breadcrumbs={breadcrumb} />
            <Box mt={["30px", "48px"]}>
              <VStack align="center">
                <Display as="h1">{t("about_us.jobs.hero.title")}</Display>
                <Box mt={["16px", "24px"]}>
                  <Description maxWidth="756px" textAlign="center">
                    {t("about_us.jobs.hero.description")}
                  </Description>
                </Box>
                <Button
                  mt={["16px", "24px"]}
                  size="lg"
                  rightIcon={<Icon icon="arrow_right" />}
                  onClick={handleNavigation}
                >
                  {t("about_us.jobs.hero.cta")}
                </Button>
              </VStack>
              <Box
                maxWidth="949px"
                width="100%"
                mx="auto"
                mt={["24px", "56px"]}
                style={{
                  transform: "translateZ(0)",
                }}
              >
                <StaticImage
                  className="gatsby-image-radius"
                  alt={t("about_us.jobs.hero.image_alt_text")}
                  src="../feature/landing/jobs/images/Hero.png"
                />
              </Box>
            </Box>
            <Box mt={["48px", "96px"]}>
              <VStack spacing={["16px", "24px"]}>
                <Heading textAlign="center" as="h2">
                  {t("about_us.jobs.section_1.title")}
                </Heading>
                <Box mt={["16px", "24px"]}>
                  <Paragraph maxWidth="756px" textAlign="center">
                    {t("about_us.jobs.section_1.description")}
                  </Paragraph>
                </Box>
              </VStack>
              <Box mt={["24px", "48px"]}>
                <CustomStack>
                  {section_1_array.map(item => {
                    return (
                      <BorderBox
                        as="article"
                        maxWidth={["unset", "365px"]}
                        width="100%"
                        borderRadius="16px"
                        p={["16px", "24px"]}
                        key={item.title as string}
                      >
                        <HStack spacing="16px">
                          <Avatar
                            minWidth="56px"
                            height="56px"
                            backgroundColor="green.300"
                            shape="circle"
                          >
                            <Icon
                              icon={item.icon as IconType}
                              fontSize="24px"
                              color="white"
                            />
                          </Avatar>
                          <Paragraph as="p" weight="semibold">
                            {item.title}
                          </Paragraph>
                        </HStack>
                      </BorderBox>
                    )
                  })}
                </CustomStack>
              </Box>
            </Box>
            <Box mt={["48px", "96px"]}>
              <VStack align="center" spacing={["16px", "24px"]}>
                <Heading as="h2">{t("about_us.jobs.section_2.title")}</Heading>
                <Box mt={["16px", "24px"]}>
                  <Paragraph as="p" textAlign="center" maxWidth="756px">
                    {t("about_us.jobs.section_2.description")}
                  </Paragraph>
                </Box>
              </VStack>
              {section_2_array.map((item, index) => {
                return (
                  <HeroStack
                    mt={["48px", "96px"]}
                    reverse={index % 2 > 0 ? "row" : "inverse"}
                    key={item.title as string}
                  >
                    <Box
                      maxWidth="562px"
                      width="100%"
                      borderRadius="16px"
                      overflow="hidden"
                      style={{
                        transform: "translateZ(0)",
                      }}
                    >
                      {item.img}
                    </Box>
                    <Box>
                      <Heading as="h2" maxWidth="465px">
                        {item.title}
                      </Heading>
                      <Box mt={["16px", "24px"]}>
                        <Paragraph as="p" maxWidth="465px">
                          {item.description}
                        </Paragraph>
                      </Box>
                    </Box>
                  </HeroStack>
                )
              })}
            </Box>
          </Container>
          <Box
            mt={["48px", "96px"]}
            backgroundColor="blue.400"
            py={["48px", "96px"]}
          >
            <Container size={["md", "lg"]}>
              <VStack align="center" spacing={["16px", "24px"]}>
                <Heading color="white" as="h2">
                  {t("about_us.jobs.section_3.title")}
                </Heading>
                <Box mt={["16px", "24px"]}>
                  <Paragraph
                    color="white"
                    as="p"
                    textAlign="center"
                    maxWidth="756px"
                  >
                    {t("about_us.jobs.section_3.description")}
                  </Paragraph>
                </Box>
              </VStack>
              <CustomGrid mt={["24px", "48px"]} mx="auto">
                <Box
                  borderRadius="16px"
                  overflow="hidden"
                  style={{
                    transform: "translateZ(0)",
                  }}
                  className="top-image"
                >
                  <StaticImage
                    className="gatsby-image-radius"
                    alt={t("about_us.jobs.section_3.image_alt_text_1")}
                    src="../feature/landing/jobs/images/grid_top.png"
                  />
                </Box>

                <Box
                  borderRadius="16px"
                  overflow="hidden"
                  style={{
                    transform: "translateZ(0)",
                  }}
                  className="left-image"
                >
                  <StaticImage
                    className="gatsby-image-radius"
                    alt={t("about_us.jobs.section_3.image_alt_text_2")}
                    src="../feature/landing/jobs/images/grid_left.png"
                  />
                </Box>

                <Box
                  borderRadius="16px"
                  overflow="hidden"
                  style={{
                    transform: "translateZ(0)",
                  }}
                  className="right-image"
                >
                  <StaticImage
                    className="gatsby-image-radius"
                    alt={t("about_us.jobs.section_3.image_alt_text_3")}
                    src="../feature/landing/jobs/images/grid_right.png"
                  />
                </Box>

                <Box
                  borderRadius="16px"
                  overflow="hidden"
                  style={{
                    transform: "translateZ(0)",
                  }}
                  className="bottom-image"
                >
                  <StaticImage
                    className="gatsby-image-radius"
                    alt={t("about_us.jobs.section_3.image_alt_text_4")}
                    src="../feature/landing/jobs/images/grid_bottom.png"
                  />
                </Box>
              </CustomGrid>
            </Container>
          </Box>
          <Container size={["md", "lg"]}>
            <Box mt={["48px", "96px"]}>
              <VStack align="center">
                <Heading textAlign="center" as="h2">
                  {t("about_us.jobs.section_4.title")}
                </Heading>
                <Box mt={["16px", "24px"]}>
                  <Paragraph as="p" textAlign="center" maxWidth="756px">
                    {t("about_us.jobs.section_4.description")}
                  </Paragraph>
                </Box>
              </VStack>
              <CustomStepBoxStack mt={["24px", "48px"]}>
                {section_4_array.map(item => (
                  <StepBox key={item.title as string}>
                    <Avatar
                      maxWidth="56px"
                      minWidth="56px"
                      height="56px"
                      backgroundColor="green.300"
                      shape="circle"
                    >
                      <Icon
                        icon={item.icon as IconType}
                        fontSize="24px"
                        color="white"
                      />
                    </Avatar>
                    <Paragraph
                      textAlign={["left", "center"]}
                      as="p"
                      weight="semibold"
                    >
                      {item.title}
                    </Paragraph>
                  </StepBox>
                ))}
              </CustomStepBoxStack>
            </Box>
            <Box mt={["48px", "96px"]}>
              <VStack align="center">
                <Heading as="h2">{t("about_us.jobs.section_5.title")}</Heading>
                <Box mt={["16px", "24px"]}>
                  <Paragraph as="p" textAlign="center" maxWidth="756px">
                    {t("about_us.jobs.section_5.description")}
                  </Paragraph>
                </Box>
              </VStack>
              <CitiesGrid mt={["24px", "48px"]}>
                {section_5_array.map(item => {
                  return (
                    <Box key={item.title as string}>
                      <Box
                        maxWidth="271px"
                        maxHeight="271px"
                        overflow="hidden"
                        style={{
                          transform: "translateZ(0)",
                        }}
                      >
                        {item.img}
                      </Box>
                      <Box mt={["8px", "16px"]}>
                        <Paragraph weight="semibold">{item.title}</Paragraph>
                      </Box>
                    </Box>
                  )
                })}
              </CitiesGrid>
            </Box>
            <Box mt={["48px", "96px"]}>
              <VStack align="center">
                <Heading as="h2">{t("about_us.jobs.section_6.title")}</Heading>
                <Box mt={["16px", "24px"]}>
                  <Paragraph as="p" textAlign="center" maxWidth="756px">
                    {t("about_us.jobs.section_6.description")}
                  </Paragraph>
                </Box>
              </VStack>
              {section_6_array.map((item, index) => (
                <HeroStack
                  mt={["24px", "48px"]}
                  reverse={index % 2 > 0 ? "row" : "inverse"}
                  key={item.title as string}
                >
                  <Box
                    maxWidth="562px"
                    borderRadius="16px"
                    style={{
                      transform: "transleteZ(0%)",
                    }}
                  >
                    {item.img}
                  </Box>
                  <Box maxWidth={["unset", "465px"]}>
                    <StarFillRow
                      number={5}
                      options={{
                        fontSize: "24px",
                        color: "yellow.300",
                      }}
                    />
                    <Box mt={["16px", "24px"]}>
                      <Heading as="h2">{item.title}</Heading>
                    </Box>
                    <Box mt={["16px", "24px"]}>
                      <Paragraph as="p">{item.description}</Paragraph>
                    </Box>
                    <Button
                      size="lg"
                      isRounded
                      mt={["16px", "24px"]}
                      onClick={handleNavigation}
                    >
                      {item.cta}
                    </Button>
                  </Box>
                </HeroStack>
              ))}
            </Box>
          </Container>
          <Box position="sticky" bottom={0} left={0}>
            <Box
              height="88px"
              width="100%"
              background="transarent"
              zIndex={-1}
            />
            <FlotaingBanner
              borderTop="1px solid"
              borderTopColor="grey.5"
              ref={ref}
            >
              <Button size="lg" isRounded onClick={handleNavigation}>
                {t("about_us.jobs.floting_banner.cta")}
              </Button>
            </FlotaingBanner>
          </Box>
        </Box> */}
      </LandingLayout>
    </TranslationProvider>
  )
}

export default SecondPage
export const query = graphql`
  query JobsMainPage($language: String!, $pageLanguage: WpLanguageCodeEnum!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allWpJobPosition(filter: { language: { code: { eq: $pageLanguage } } }) {
      nodes {
        title
        slug
      }
    }
  }
`
