import styled, { keyframes } from "styled-components"
import { Box } from "@youngagency/young-ui"

const fadeIn = keyframes`
from{opacity: 0.1;}
to{opacity: 1;}
`

const ImgContainer = styled(Box)`
  position: relative;
  height: ${props => props.height || "341px"};
  border-radius: 16px;
  overflow: hidden;
  background-color: #f1f2f3;
  opacity: 0.1;
  width: 100%;
  animation: ${fadeIn} 300ms forwards;
  -webkit-animation: ${fadeIn} 300ms forwards;
  -moz-animation: ${fadeIn} 300ms forwards;
  -o-animation: ${fadeIn} 300ms forwards;
  display: flex;
  justify-content: center;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
`

export default ImgContainer
