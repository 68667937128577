import { Box, styled } from "@youngagency/young-ui"
import React from "react"
import { ResponsiveText } from "../../../components/layout"

const CustomBox = styled(Box)({
  cursor: "pointer",
  ":hover": {
    textDecoration: "underline",
    textDecorationColor: "var(--r-green-300)",
  },
})

export const PostTag = ({
  text,
  size = "big",
}: {
  text: string
  size?: "small" | "big"
}) => {
  return (
    <CustomBox>
      <ResponsiveText
        color="green.300"
        as="p"
        dimension={size === "big" ? ["14px", "18px"] : ["14px", "14px"]}
        line={["16px", "22px"]}
        fontWeight={600}
        style={{
          textTransform: "capitalize",
        }}
      >
        {text}
      </ResponsiveText>
    </CustomBox>
  )
}
