import { useMemo } from "react"
import { TimeFrameListKeys } from "../../../constants"
import { useGetAllCurrenciesQuery } from "../../../redux/services/currency/api"
import { useGetTokenTimeFrameByKey } from "../../../redux/services/utils"
import { useGetSingleTokenChartV2Query } from "../../../service/charts/api/api"

export const useMergeTokenChartData = (
  currency: string,
  optionalTimeframe?: TimeFrameListKeys,
  fiat = "EUR"
) => {
  const selectedTimeFrame = useGetTokenTimeFrameByKey(optionalTimeframe)

  const {
    data: tokenChart,
    isError: isChartLoading,
  } = useGetSingleTokenChartV2Query({
    currency: currency.toUpperCase() as Uppercase<string>,
    timeframe: selectedTimeFrame,
    fiat: fiat.toUpperCase() as Uppercase<string>,
  })

  const { tokenV2, isLoading } = useGetAllCurrenciesQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      isLoading,
      tokenV2: data?.currencies?.[currency],
    }),
  })

  const mergedData = useMemo(() => {
    if (!isLoading && !isChartLoading) {
      return {
        ...tokenV2,
        ...tokenChart,
      }
    }
  }, [tokenV2, tokenChart, isLoading])

  return {
    isLoading: isLoading || isChartLoading,
    mergedData,
  }
}
