import React from "react"
import { useCardContext } from "../context/CardContext"
import { Box, BoxProps } from "@youngagency/ui"
import { cardLayout, cardLayoutVariant } from "../style/card-content.css"
import clsx from "clsx"
import { DefaultCardProps } from "../types"

type CardContentProps = DefaultCardProps & { spacing?: 1 | 2 } & Omit<
    BoxProps,
    "color"
  >

export const CardContent = ({
  className,
  children,
  spacing,
  ...rest
}: CardContentProps) => {
  const { orientation } = useCardContext()
  const layoutVariant = cardLayoutVariant({ orientation, spacing })
  return (
    <Box
      as="div"
      className={clsx(cardLayout, layoutVariant, className)}
      {...rest}
    >
      {children}
    </Box>
  )
}
