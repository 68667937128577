import md5 from "blueimp-md5"
import { levelTrackingMap } from "../constants"

export const getLevelTackingValue = (level?: string | null) => {
  if (!level) return
  if (Object.keys(levelTrackingMap).includes(level.toLowerCase())) {
    return levelTrackingMap[
      level.toLowerCase() as keyof typeof levelTrackingMap
    ]
  }
}

/**
 * Sign HMAC using WebCrypto API + external MD5 implementation
 * such as blueimp-md5
 * The digest is compatible with hmac-auth-express
 * @see https://connorjburton.github.io/hmac-auth-express/#generating-your-hmac-digest
 * @param secret
 * @param verb
 * @param body body to be md5 hashed. Leave "{}" for GET requests
 * @param path
 * @param date
 * @returns
 */
export async function getHMACDigestWeb(
  secret: string,
  verb: "POST" | "GET" = "POST",
  body: any = "{}",
  path = "/",
  date: number = Date.now()
) {
  const time = date.toString()
  const encodedTime = new TextEncoder().encode(time)
  const encodedVerb = new TextEncoder().encode(verb)
  const encodedPath = new TextEncoder().encode(path)
  const encodedBody = new TextEncoder().encode(md5(body))

  // Get the total length of all arrays. - order matters
  const allArrays = [encodedTime, encodedVerb, encodedPath, encodedBody]
  const totalLength = allArrays.reduce((total, buf) => total + buf.length, 0)

  // Create a new array with total length and merge all source arrays.
  const data = new Uint8Array(totalLength)
  let offset = 0
  allArrays.forEach(item => {
    data.set(item, offset)
    offset += item.length
  })

  const keyEncoder = new TextEncoder()
  const key = await window.crypto.subtle.importKey(
    "raw", // raw format of the key - should be Uint8Array
    keyEncoder.encode(secret),
    {
      // algorithm details
      name: "HMAC",
      hash: { name: "SHA-512" },
    },
    false, // export = false
    ["sign", "verify"] // what this key can do
  )

  const signature = await window.crypto.subtle.sign("HMAC", key, data)
  const buffer = new Uint8Array(signature)
  const digest = Array.prototype.map
    .call(buffer, x => x.toString(16).padStart(2, "0"))
    .join("")
  return { digest, time }
}
