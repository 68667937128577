import { Box, css } from "@youngagency/young-ui"
import styled from "styled-components"

export const RelatedArticleWrapper = styled(Box)(
  css({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: ["flex-start", "space-between"],
    flexWrap: "nowrap",
    border: "1px solid",
    "& > *:not(:first-of-type)": {
      marginLeft: ["16px", "25px"],
    },
    borderColor: "grey.5",
    borderRadius: ["16px"],
    p: ["16px", "24px"],
    transition: "all 75ms ease",
    ":hover": {
      backgroundColor: "#E3E4E8",
    },
    cursor: "pointer",
  })
)
