export const handleSpaceInjection = (
  id: string,
  text: string,
  isMobile: boolean
) => {
  const tempElement = document.getElementById(id) as HTMLElement
  let bannerContent = tempElement?.textContent as string
  if (isMobile) {
    let index =
      bannerContent && bannerContent.toLowerCase().indexOf(text.toLowerCase())
    if (index) {
      tempElement.innerHTML =
        bannerContent.slice(0, index) + `</br>` + bannerContent.slice(index)
    }
  } else {
    tempElement.innerHTML = bannerContent
  }
}

export function isInViewport(element: HTMLElement) {
  const rect = element.getBoundingClientRect()
  if (typeof window !== "undefined") {
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }
}

export function parseColor(text: string, color = "var(--green-300)"): string {
  var t = text.split("*color*")
  let array: string[] = []
  t.map((p, index) => {
    if (index != 0 && index % 2 != 0) {
      array.push(
        `<span key=${index} style="color: ${color}; fontSize: inherit;">
          ${p}
        </span>`
      )
    } else {
      array.push(p)
    }
  })

  return array.join("")
}

export const createGAEvent = (event: string, content: string) => {
  return () => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: event,
        content: content,
      })
    }
  }
}

export function cleanStringFromHtlmTags(text: string) {
  return text
    .replace(/<\/?[^>]+(>|$)/g, "")
    .replace(/(<p>&nbsp;<\/p>)|(<h2><span>&nbsp;<\/span><\/h2>)/g, "")
}

// var addYoutubeEventListener = (function() {

//     var callbacks: [] | any[] = [];
//     var iframeId = 0;

//     return function (iframe: HTMLIFrameElement, callback: Function) {

//         // init message listener that will receive messages from youtube iframes
//         if(iframeId === 0) {
//             window.addEventListener("message", function (e) {

//                 if(e.origin !== "https://www.youtube.com" || e.data === undefined) return;
//                 try {
//                     var data = JSON.parse(e.data);
//                     if(data.event !== 'onStateChange') return;

//                     var callback = callbacks[data.id];
//                     callback(data);
//                 }
//                 catch(e) {}
//             });
//         }

//         // store callback
//         iframeId++;
//         callbacks[iframeId] = callback;
//         var currentFrameId = iframeId;

//         // sendMessage to frame to start receiving messages
//         iframe.addEventListener("load", function () {
//             var message = JSON.stringify({
//                 event: 'listening',
//                 id: currentFrameId,
//                 channel: 'widget'
//             });
//             if(iframe.contentWindow){
//               iframe.contentWindow.postMessage(message, 'https://www.youtube.com');

//               message = JSON.stringify({
//                 event: "command",
//                 func: "addEventListener",
//                 args: ["onStateChange"],
//                 id: currentFrameId,
//                 channel: "widget"
//               });
//               iframe.contentWindow.postMessage(message, 'https://www.youtube.com');
//             }
//         });
//     }
// })();
