import {
  DEFAULT_TIMEFRAME_LIST,
  DEFAULT_TIMEFRAME_VARIATION,
  TimeFrame,
  TimeFrameListKeys,
} from "../../../constants"

export const getTimeFrame = (key: TimeFrameListKeys) => {
  const x =
    DEFAULT_TIMEFRAME_LIST.find(timeframe => timeframe.key === key) ||
    DEFAULT_TIMEFRAME_VARIATION
  return {
    limit: x.limit,
    interval: x.interval,
  }
}

export const generteUrl = (
  timeframe: Pick<TimeFrame, "limit" | "interval">
) => {
  return `interval=${timeframe.interval}&limit=${
    timeframe.limit
  }&timestamp=${Date.now()}`
}

export const getTimeFrameURL = (
  timeframe: Pick<TimeFrame, "limit" | "interval">
) => {
  return `interval=${timeframe.interval}&limit=${timeframe.limit}`
}
