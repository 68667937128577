import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

type SingleAcademyArticleGraphqlResponse = {
  data: {
    post: {
      slug: string
      title: string
      categories: {
        nodes: { name: string; slug: string }[]
      }
      featuredImage: {
        node: {
          altText: string
          sourceUrl: string
        }
      }
      link: string
      seo: {
        metaDesc: string
        readingTime?: number
      }
    }
  }
}

export type SingleArticleResponse = {
  title: string
  description: string
  readingTime?: number
  slug: string
  link: string
  category: {
    name: string
    slug: string
  }
  image: {
    src: string
    altText: string
  }
}

type PostsArticleGraphqlResponse = {
  data: {
    posts: {
      nodes: SingleAcademyArticleGraphqlResponse["data"]["post"][]
    }
  }
}

export const academyApi = createApi({
  reducerPath: "academy",
  baseQuery: fetchBaseQuery({
    headers: {
      "content-type": "application/json",
      "Authorization": "Bearer",
    },
  }),
  tagTypes: ["Post"],
  endpoints: builder => ({
    getSingleAcademyArticle: builder.mutation<
      SingleArticleResponse,
      number | string
    >({
      query: id => ({
        url: "https://academyapi2.youngplatform.com/graphql",
        method: "POST",
        body: {
          query: `{
            post(id: ${id.toString()}, idType: DATABASE_ID) {
                title
                slug
                link
                seo{
                    metaDesc
                    readingTime
                }
                categories{
                    nodes{
                    name
                    slug
                    }
                }
                featuredImage {
                    node{
                        altText
                        sourceUrl
                    }
                }
            }
            }
          `,
        },
      }),
      transformResponse: (response: SingleAcademyArticleGraphqlResponse) => {
        const {
          data: {
            post: { title, slug, seo, categories, featuredImage, link },
          },
        } = response
        return {
          title,
          slug,
          description: seo.metaDesc,
          link,
          category: { ...categories.nodes[0] },
          image: {
            src: featuredImage.node.sourceUrl,
            altText: featuredImage.node.altText,
          },
        }
      },
      invalidatesTags: ["Post"],
    }),
    getLastAcademyArticle: builder.mutation<
      Omit<SingleArticleResponse, "description">[],
      string
    >({
      query: lng => ({
        url: "https://academyapi2.youngplatform.com/graphql",
        method: "POST",
        body: {
          query: `
            {
              posts(where: {language: ${lng.toUpperCase()}}, first: 3) {
                nodes {
                  title
                  slug
                  link
                  seo{
                    metaDesc
                    readingTime
                  }
                  categories {
                    nodes {
                      name
                      slug
                    }
                  }
                  featuredImage {
                    node {
                      altText
                      sourceUrl(size: MEDIUM)
                    }
                  }
                }
              }
            }`,
        },
      }),
      transformResponse: (response: PostsArticleGraphqlResponse) => {
        const {
          data: {
            posts: { nodes: allPosts },
          },
        } = response
        return allPosts.map(post => ({
          title: post.title,
          slug: post.slug,
          link: post.link,
          image: {
            src: post.featuredImage.node.sourceUrl,
            altText: post.featuredImage.node.altText,
          },
          category: { ...post.categories.nodes[0] },
        }))
      },
    }),
    getArticlesByArray: builder.mutation<SingleArticleResponse[], number[]>({
      query: articleArray => ({
        url: "https://academyapi2.youngplatform.com/graphql",
        method: "POST",
        body: {
          query: `
          {
              posts(where: {in: [${articleArray}]}) {
                nodes {
                  title
                  slug
                  link
                   seo{
                    metaDesc
                    readingTime
                  }
                  categories {
                    nodes {
                      name
                      slug
                    }
                  }
                  featuredImage {
                    node {
                      altText
                      sourceUrl(size: MEDIUM)
                    }
                  }
                }
              }
            }
          `,
        },
      }),
      transformResponse: (response: PostsArticleGraphqlResponse) => {
        const {
          data: {
            posts: { nodes: allPosts },
          },
        } = response
        return allPosts.map(post => ({
          title: post.title,
          slug: post.slug,
          description: post.seo.metaDesc,
          readingTime: post.seo?.readingTime,
          link: post.link,
          image: {
            src: post.featuredImage.node.sourceUrl,
            altText: post.featuredImage.node.altText,
          },
          category: { ...post.categories.nodes[0] },
        }))
      },
    }),
  }),
})

export const {
  useGetSingleAcademyArticleMutation,
  useGetLastAcademyArticleMutation,
  useGetArticlesByArrayMutation,
} = academyApi
