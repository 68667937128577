import React from "react"
import styled from "styled-components"
import { AbsoluteContainer } from "../styledUi"
import { StaticImage } from "gatsby-plugin-image"

const Image = styled.img`
  width: 100%;
  height: auto;
  margin: auto;
  transition: 0.3s;
  @media screen and (max-width: 768px) {
    width: 60%;
    height: 60%;
  }
`

const ReferralComponents = () => {
  return (
    <React.Fragment>
      <AbsoluteContainer
        top="650px"
        right="180px"
        style={{
          zIndex: 333,
        }}
      >
        <StaticImage
          src="./images/startup-italia.png"
          alt="StartupItalia"
          placeholder="dominantColor"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </AbsoluteContainer>
      <AbsoluteContainer
        top="85px"
        left="60%"
        style={{
          zIndex: 333,
          transform: "translateX(-50%)",
        }}
      >
        <StaticImage
          src="./images/Corriere_della_Sera.png"
          alt="Corriere della Sera"
          placeholder="dominantColor"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </AbsoluteContainer>
      <AbsoluteContainer
        top="175px"
        left="65px"
        style={{
          zIndex: 333,
        }}
      >
        <StaticImage
          src="./images/Il_Sole_24_Ore.png"
          alt="Il Sole 24 Ore"
          placeholder="dominantColor"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </AbsoluteContainer>
      <AbsoluteContainer
        top="225px"
        right="85px"
        style={{
          zIndex: 333,
        }}
      >
        <StaticImage
          src="./images/Money.png"
          alt="Money.it"
          placeholder="dominantColor"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </AbsoluteContainer>
      <AbsoluteContainer
        top="645px"
        left="65px"
        style={{
          zIndex: 333,
        }}
      >
        <StaticImage
          src="./images/forbes-logo-black-transparent.png"
          alt="Forbes"
          placeholder="dominantColor"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </AbsoluteContainer>
    </React.Fragment>
  )
}

export default ReferralComponents
