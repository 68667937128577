import { useI18next } from "gatsby-plugin-react-i18next"
import { useCallback } from "react"

export const useGetFiatName = () => {
  const { language } = useI18next()
  const getFiatName = useCallback(
    (symbol: string) => {
      switch (symbol) {
        case "EUR":
          return "Euro"
        case "USD":
          if (language === "it") {
            return "Dollaro"
          }
          return "Dollar"
        default:
          return "Euro"
      }
    },
    [language]
  )

  return getFiatName
}
