import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Body, Box, Container, Heading, VStack } from "@youngagency/ui"
import { Container as CustomContainer } from "../../../../components/layout"
import {
  CardButton,
  CardContent,
  CardGrid,
  CardImage,
  CardStack,
  CardTexts,
} from "../../../../components/cards"
import { Card } from "../../../../components/cards"
import { StaticImage } from "gatsby-plugin-image"
import { gridRight } from "./b2b.css"
import { Text } from "../../../../components/texts/temporary"
import { navigate } from "@reach/router"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { b2bEvents, globalEvents } from "../../../../service/tracking"
import { googleScheduleMeet } from "../../constants"

export const TradingServiceSection = () => {
  const { t } = useTranslation()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  const handleClick = React.useCallback(
    (item: 1 | 2) => {
      gaTracker?.trackMultipleEvents([
        {
          eventName: b2bEvents.b2b_home_body_trading_service,
          payload: {
            action: item,
          },
        },
        {
          eventName: globalEvents.contact_sales,
        },
      ])
      navigate(googleScheduleMeet)
    },
    [gaTracker]
  )
  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              color="white"
              dangerouslySetInnerHTML={{
                __html: t("clubs_page.b2b.trading_service_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              style={{
                maxWidth: "700px",
              }}
              color="grey200"
              fontWeight={600}
            >
              {t("clubs_page.b2b.trading_service_section.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>
      <Box
        marginTop={{
          mobile: 32,
          md: 40,
        }}
      >
        <VStack
          spacing={"unset"}
          align="center"
          justify="center"
          atoms={{
            width: "full",
            mx: "auto",
            __columnGap: "1rem",
          }}
        >
          <CardGrid>
            <div className={gridRight}>
              <Card
                isAnimated
                hasShadow
                background="var(--grey-700-v2)"
                orientation="column"
                invertBtn
                onClick={() => {
                  handleClick(1)
                }}
              >
                <CardContent>
                  <CardTexts>
                    <Heading as="h3" variant="6" color="white" fontWeight={600}>
                      {t("clubs_page.b2b.trading_service_section.card_1.title")}
                    </Heading>
                    <Body
                      variant="1"
                      atoms={{
                        __color: "var(--text-light)",
                      }}
                      fontWeight={600}
                    >
                      {t(
                        "clubs_page.b2b.trading_service_section.card_1.description"
                      )}
                    </Body>
                  </CardTexts>
                  <CardButton textColor="light" size="md" mt={["16px", "24px"]}>
                    {t("clubs_page.b2b.trading_service_section.card_1.button")}
                  </CardButton>
                </CardContent>
                <CardImage>
                  <StaticImage
                    src="../../images/trading-service-1.png"
                    alt={t(
                      "clubs_page.b2b.trading_service_section.card_1.img_alt_text"
                    )}
                  />
                </CardImage>
              </Card>
              <Card
                isAnimated
                hasShadow
                background="#131312"
                orientation="column"
                disableHover
              >
                <CardContent>
                  <CardTexts>
                    <Heading as="h3" variant="6" color="white" fontWeight={600}>
                      {t("clubs_page.b2b.trading_service_section.card_2.title")}
                    </Heading>
                    <Text.H5 color="var(--grey-300-v2)" fontWeight={600}>
                      {t(
                        "clubs_page.b2b.trading_service_section.card_2.description_1"
                      )}
                    </Text.H5>
                    <Text.H5 color="var(--green-300-v2)" fontWeight={600}>
                      {t(
                        "clubs_page.b2b.trading_service_section.card_2.description_2"
                      )}
                    </Text.H5>
                  </CardTexts>
                </CardContent>
              </Card>
            </div>
            <CardStack mt="1rem">
              <Card
                isAnimated
                hasShadow
                background="#131312"
                orientation="column"
                disableHover
                shape="square"
              >
                <CardContent shape="square">
                  <CardTexts>
                    <Heading as="h3" variant="6" color="white" fontWeight={600}>
                      {t("clubs_page.b2b.trading_service_section.card_3.title")}
                    </Heading>
                    <Body variant="1" color="grey200" fontWeight={600}>
                      {t(
                        "clubs_page.b2b.trading_service_section.card_3.description"
                      )}
                    </Body>
                  </CardTexts>
                </CardContent>
                <CardImage>
                  <StaticImage
                    src="../../images/trading-service-3.png"
                    alt={t(
                      "clubs_page.b2b.trading_service_section.card_3.img_alt_text"
                    )}
                  />
                </CardImage>
              </Card>
              <Card
                isAnimated
                hasShadow
                background="#131312"
                orientation="column"
                disableHover
                shape="square"
              >
                <CardContent shape="square">
                  <CardTexts>
                    <Heading as="h3" variant="6" color="white" fontWeight={600}>
                      {t("clubs_page.b2b.trading_service_section.card_4.title")}
                    </Heading>
                    <Body variant="1" color="grey200" fontWeight={600}>
                      {t(
                        "clubs_page.b2b.trading_service_section.card_4.description"
                      )}
                    </Body>
                  </CardTexts>
                </CardContent>
                <CardImage>
                  <StaticImage
                    src="../../images/trading-service-4.png"
                    alt={t(
                      "clubs_page.b2b.trading_service_section.card_4.img_alt_text"
                    )}
                  />
                </CardImage>
              </Card>
            </CardStack>
          </CardGrid>
        </VStack>
      </Box>
    </>
  )
}
