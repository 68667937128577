import styled from "styled-components"
import { css } from "@styled-system/css"

export const Divider = styled("hr")(
  css({
    border: "0.5px solid",
    borderColor: "grey.5",
    width: "100%",
  })
)
