import { useI18next } from "gatsby-plugin-react-i18next"
import { navigate } from "@reach/router"
import { useCallback } from "react"

export const useLocalizedNavigate = () => {
  const { language } = useI18next()
  const navigateToExchange = useCallback(() => {
    let link = `https://exchange.youngplatform.com/${language}/signup/`
    if (language === "it") link = `https://exchange.youngplatform.com/signup/`
    navigate(link)
  }, [language, navigate])
  return navigateToExchange
}
