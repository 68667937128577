import styled from "styled-components"
import { css } from "@styled-system/css"
import { Box, variant } from "@youngagency/young-ui"

export const CardGrid = styled(Box)<{ size?: "hero" | "body" }>(
  css({
    mx: "auto",
  }),
  variant({
    prop: "size",
    variants: {
      hero: {
        maxWidth: ["100%", "calc(100% - 64px)"],
      },
      body: {
        maxWidth: ["100%", "1188px"],
        width: ["100%", "calc(100% - 48px)"],
      },
    },
  })
)

CardGrid.defaultProps = {
  size: "body",
}
