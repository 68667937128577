import React from "react"
import { Helmet } from "react-helmet"
import {
  useWebPageSchema,
  useBreadcrumbSchema,
  useArticleSchema,
} from "../hooks"
import { ArticleSchemaProps, breadCrumbProps } from "../types"

type Props = ArticleSchemaProps & {
  imageAltText: string
  breadcrumbs: breadCrumbProps[]
}

export const ArticleSchema = ({
  type,
  title,
  description,
  image,
  publishDate,
  modifiedDate,
  imageAltText,
  breadcrumbs,
  authorName,
}: Props) => {
  const schema = useWebPageSchema({
    title,
    description,
    image,
    publishDate,
    modifiedDate,
    imageAltText,
  })

  const breadcrumbSchema = useBreadcrumbSchema(breadcrumbs)
  const articleSchema = useArticleSchema({
    type,
    title,
    description,
    image,
    publishDate,
    modifiedDate,
    authorName,
  })

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          ...schema,
          ["@graph"]: [...schema["@graph"], breadcrumbSchema, ...articleSchema],
        })}
      </script>
    </Helmet>
  )
}

export default ArticleSchema
