import { Box, Icon } from "@youngagency/young-ui"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import {
  CardTexts,
  Card,
  CardButton,
  CardContent,
  CardImage,
  CardStack,
  AbsoluteCardImageBox,
} from "../../components/cards"
import { Text } from "../../components/texts/temporary"
import { useLocalizedNavigate } from "../../hook/useLocalizedNavigate"
import { NextImage } from "./LocalizedImaged"
import { useHandlePersistUtm } from "../navigation/hooks/useHandlePersistUtm"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { globalEvents, homePageEvents } from "../../service/tracking"
import {
  customDesktopResponsiveBox,
  customMobileResponsiveBox,
} from "./styles/style.css"

export const NextSection = () => {
  const { t } = useTranslation()
  const navigateToExchange = useLocalizedNavigate()
  const { handlePersistUtmCallback } = useHandlePersistUtm()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  return (
    <>
      <Card
        isAnimated
        mt="40px"
        backgroundColor="blue.400"
        invertBtn
        orientation="row"
        hasShadow
        onClick={() => {
          if (gaTracker)
            gaTracker.trackMultipleEvents([
              {
                eventName: homePageEvents.home_body_next,
                payload: {
                  card_clicked: 1,
                },
              },
              {
                eventName: globalEvents.click_to_exchange,
              },
            ])
          handlePersistUtmCallback({
            callback: () => navigateToExchange(),
          })
        }}
      >
        <CardContent>
          <CardTexts>
            <Text.H6 fontWeight={600} as="h3" color="white">
              {t("homepage.section_2.card_1.title")}
            </Text.H6>
            <Text.Body1 fontWeight={600} color="var(--text-light)" as="p">
              {t("homepage.section_2.card_1.description")}
            </Text.Body1>
          </CardTexts>

          <CardButton
            textColor="light"
            mt={["16px", "24px"]}
            rightIcon={<Icon icon="arrow_right" />}
          >
            {t("homepage.section_2.card_1.button")}
          </CardButton>
        </CardContent>
        <AbsoluteCardImageBox
          className={customMobileResponsiveBox}
          mb="0px !important"
        >
          <NextImage.card1 />
        </AbsoluteCardImageBox>
        <CardImage pb="0px !important" className={customDesktopResponsiveBox}>
          <Box maxWidth="100%" alignSelf="flex-end">
            <NextImage.card1 />
          </Box>
        </CardImage>
      </Card>
      <CardStack mt="1rem">
        <Card
          hasShadow
          isAnimated
          background="#CCF6E3"
          orientation="column"
          onClick={() => {
            if (gaTracker)
              gaTracker.trackMultipleEvents([
                {
                  eventName: homePageEvents.home_body_next,
                  payload: {
                    card_clicked: 3,
                  },
                },
                {
                  eventName: globalEvents.click_to_exchange,
                },
              ])
            handlePersistUtmCallback({
              callback: () => navigateToExchange(),
            })
          }}
        >
          <CardContent>
            <CardTexts>
              <Text.H6 fontWeight={600} as="h3">
                {t("homepage.section_2.card_3.title")}
              </Text.H6>
            </CardTexts>

            <CardButton
              mt={["16px", "24px"]}
              rightIcon={<Icon icon="arrow_right" />}
            >
              {t("homepage.section_2.card_3.button")}
            </CardButton>
          </CardContent>
          <CardImage>
            <Box maxWidth="100%">
              <NextImage.card3 />
            </Box>
          </CardImage>
        </Card>

        <Card
          hasShadow
          isAnimated
          backgroundColor="#ffffff"
          orientation="column"
          onClick={() => {
            if (gaTracker)
              gaTracker.trackMultipleEvents([
                {
                  eventName: homePageEvents.home_body_next,
                  payload: {
                    card_clicked: 2,
                  },
                },
                {
                  eventName: globalEvents.click_to_exchange,
                },
              ])
            handlePersistUtmCallback({
              callback: () => navigateToExchange(),
            })
          }}
        >
          <CardContent>
            <CardTexts>
              <Text.H6 fontWeight={600} as="h3">
                {t("homepage.section_2.card_2.title")}
              </Text.H6>
            </CardTexts>

            <CardButton mt={"16px"} rightIcon={<Icon icon="arrow_right" />}>
              {t("homepage.section_2.card_2.button")}
            </CardButton>
          </CardContent>
          <CardImage size="small">
            <Box maxWidth="100%">
              <NextImage.card2 />
            </Box>
          </CardImage>
        </Card>
      </CardStack>
    </>
  )
}
