import { css } from "@styled-system/css"
import {
  Box,
  BoxProps,
  Button,
  variant,
  styled,
  ComponentWithAs,
} from "@youngagency/young-ui"
import { createContext, useContext } from "react"
import React from "react"
import { useInView } from "framer-motion"

type Orientation = "row" | "column"
type Shape = "regular" | "square" | "text" | "flexible"

type CardProps = BoxProps & {
  orientation?: Orientation
  invertBtn?: true
  disableHover?: true
  isAnimated?: true
  hasShadow?: true
  shape?: Shape
}

const CardContainer: ComponentWithAs<"div", CardProps> = styled(Box)<CardProps>(
  {
    display: "flex",
    overflow: "hidden",
    transform: "translateZ(0)",
    width: "100%",
    justifyContent: "space-between",
    position: "relative",
  },
  ({ invertBtn, disableHover, hasShadow, shape = "regular" }) =>
    css({
      borderRadius: "32px",
      cursor: disableHover ? "auto" : "pointer",
      boxShadow: hasShadow ? "0px 4px 40px rgba(0, 0, 0, 0.06)" : "none",
      ":hover": disableHover
        ? undefined
        : {
          "button": {
            backgroundColor: invertBtn ? "white" : "green.300",
            borderColor: invertBtn ? "white" : "green.300",
            paddingLeft: "16px !important",
            color: invertBtn ? "black" : "white",
          },
          ".card-image": {
            ">*": {
              transform: ["scale(1)", "scale(1.02)"],
            },
          },
        },
    }),
  variant({
    prop: "orientation",
    variants: {
      row: css({
        flexDirection: ["column", "row"],
        height: ["596px", "594px"],
        "& >*:not(:last-of-type)": {
          mb: ["32px", "0px"],
          mr: ["0px", "24px"],
        },
      }),
      column: css({
        minHeight: ["474px", "629px"],
        flexDirection: "column",
        "& >*:not(:last-of-type)": {
          mb: ["32px", "40px"],
        },
      }),
    },
  }),
  variant({
    prop: "shape",
    variants: {
      regular: {},
      square: css({
        flexDirection: ["column", "row"],
        height: ["auto", "324px"],
        minHeight: ["280px", "324px"],
        "& >*:not(:last-of-type)": {
          mb: ["32px", "0px"],
          mr: ["0px", "24px"],
        },
      }),
      flexible: css({
        flexDirection: ["column", "row"],
        height: ["auto", "auto"],
        minHeight: ["220px", "324px"],
        "& >*:not(:last-of-type)": {
          mb: ["32px", "0px"],
          mr: ["0px", "24px"],
        },
      }),
      text: css({
        flexDirection: ["column", "row"],
        height: ["auto", "324px"],
        minHeight: ["280px", "324px"],
        "& >*:not(:last-of-type)": {
          mb: ["32px", "0px"],
          mr: ["0px", "24px"],
        },
      }),
    },
  })
)

export const CardContent = styled(Box)<{
  orientation?: Orientation
  shape?: Shape
}>(({ orientation, shape }) =>
  css({
    px: ["24px", "48px"],
    zIndex: 1,
    py: orientation === "column" ? ["24px", "48px"] : ["24px", "unset"],
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: ["stretch", shape === "square" ? "flex-start" : shape === "flexible" || orientation === "column" ? "space-between" : "center"],
    maxWidth: orientation === "column" ? "unset" : ["unset", "50%"],
    width: "100%",
  })
)

export const CardTexts = styled(Box)(
  css({
    "& >*:not(:last-child)": {
      mb: ["16px", "16px"],
    },
  })
)

export const CardImage = styled(Box)<{ orientation?: Orientation }>(
  ({ orientation }) =>
    css({
      pb: orientation === "column" ? "0px" : ["24px", "unset"],
      px: orientation === "row" ? "0px" : "unset",
      position: orientation === "row" ? "relative" : "absolute",
      right: orientation === "row" ? "unset" : "0px",
      left: orientation === "row" ? "unset" : "0px",
      bottom: orientation === "row" ? "unset" : "0px",
      display: "flex",
      alignContent: "center",
      alignItems: ["flex-end", "center"],
      justifyContent: "center",
      maxWidth: orientation === "column" ? "unset" : ["unset", "50%"],
      width: "100%",
      ">*": {
        transition: "all 0.3s ease",
      },
    })
)

export const AbsoluteCardImageBox = styled(Box)<{ orientation?: Orientation }>(
  () =>
    css({
      position: "absolute",
      right: "0px",
      left: "0px",
      bottom: "0px",
      display: "flex",
      alignContent: "center",
      alignItems: ["flex-end", "center"],
      justifyContent: "center",
      maxWidth: "100%",
      width: "100%",
      ">*": {
        transition: "all 0.3s ease",
      },
    })
)

CardImage.defaultProps = {
  className: "card-image",
}

export const CardButton = styled(Button)<{
  textColor?: "light"
}>(
  () =>
    css({
      backgroundColor: "transparent",
      borderColor: "transparent",
      transition: "all 0.3s ease",
      paddingLeft: "0px !important",
    }),
  variant({
    prop: "textColor",
    variants: {
      light: {
        color: "white",
      },
      dark: {
        color: "black",
      },
    },
  })
)

CardButton.defaultProps = {
  className: "card-button",
  variant: "ghost",
}

export const Card: React.FC<CardProps> = ({
  orientation = "row",
  children,
  isAnimated,
  ...rest
}) => {
  const cloned = React.useMemo(
    () =>
      React.Children.toArray(children as React.ReactNode | React.ReactNode[])
        .filter(child => () =>
          (React.isValidElement(child) && child.type === CardContent) ||
          (React.isValidElement(child) && child.type === CardImage)
        )
        .map(child => {
          return React.cloneElement(
            child as any,
            {
              orientation,
            },
            React.isValidElement(child) ? child.props.children : null
          )
        }),
    [children, orientation]
  )

  const ref = React.useRef<HTMLDivElement>(null)

  const isInView = useInView(ref, { once: true })

  const cardsAnimatedStyle = React.useMemo(() => {
    return isAnimated
      ? {
        transform: isInView ? "none" : "translateY(75px)",
        opacity: isInView ? 1 : 0.1,
        transition: "all 0.375s cubic-bezier( 0.5, -0.28, 0.6, 0.99 ) 0s",
        WebkitTransition:
          "all 0.375s cubic-bezier( 0.5, -0.28, 0.6, 0.99 ) 0s",
      }
      : {}
  }, [isInView, isAnimated])

  return (
    <CardContainer
      as="article"
      ref={ref}
      orientation={orientation}
      {...rest}
      style={cardsAnimatedStyle}
    >
      {cloned}
    </CardContainer>
  )
}

type Context = Record<string, any>

const CardContext = createContext<Context>({})

export const CardProvider = CardContext.Provider

export const useCardContext = () => useContext(CardContext)
