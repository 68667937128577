const injectAccessiway = () => {
  var s = document.createElement("script"),
    e = document.getElementsByTagName("body")[0]
  s.src = "https://acsbapp.com/apps/app/dist/js/app.js"
  s.async = true
  s.onload = function () {
    window.acsbJS.init({
      statementLink: "",
      footerHtml:
        '<a href="https://www.accessiway.com/">Accessiway</a>. The Web Accessibility Solution',
      hideMobile: false,
      hideTrigger: false,
      language: "it",
      position: "right",
      leadColor: "#00D272",
      triggerColor: "#00D272",
      triggerRadius: "50%",
      triggerPositionX: "right",
      triggerPositionY: "bottom",
      triggerIcon: "people",
      triggerSize: "medium",
      triggerOffsetX: 24,
      triggerOffsetY: 16,
      mobile: {
        triggerSize: "small",
        triggerPositionX: "right",
        triggerPositionY: "bottom",
        triggerOffsetX: 10,
        triggerOffsetY: 10,
        triggerRadius: "50%",
      },
    })
  }
  e.appendChild(s)
}

export default injectAccessiway
