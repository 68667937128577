export const isProd = process.env.GATSBY_ACTIVE_ENV === "production"

export const isStaging = process.env.GATSBY_ACTIVE_ENV === "staging"

export const isDev =
  process.env.NODE_ENV === "development" ||
  process.env.GATSBY_ACTIVE_ENV === "development"

export const isLocal =
  process.env.NODE_ENV === "local" || process.env.GATSBY_ACTIVE_ENV === "local"

export const environment = {
  isProd,
  isDev: isDev,
  isStaging,
  isLocal,
  checkEnv: () => process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV,
}
