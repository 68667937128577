import React from "react"
import useNavigationMenu from "../../hooks/useNavigationMenu"
import useDropDown from "../../../../hook/useDropDown"
import {
  Box,
  Heading,
  HStack,
  Icon,
  Label,
  Paragraph,
} from "@youngagency/young-ui"
import { SeoLink } from "../../../seo"
import styled from "styled-components"
import StepImg from "../../../../images/YP_step_pittogramma.svg"
import BaseImg from "../../../../images/young_icon.svg"
import ProImg from "../../../../images/YP_pro_pittogramma.svg"
import AcademyImg from "../../images/YP_academy_pittogramma.svg"
import { createArrayFromJson } from "../../../../utils/general"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Avatar, ResponsiveText } from "../../../../components/layout"
import { SidebarAction } from "../SidebarAction"
import { useNavigationContext } from "../../context"
import { ProductMenuProps, useProductMenu } from "../../hooks/useProductMenu"

const StyledBox = styled(Box)`
  display: block;
  & [id$="menu"] {
    display: none;
  }

  & .show-dropdown {
    display: inherit !important;
    z-index: 9999;
  }
  .sidebar-link {
    &:hover {
      background: var(--r-grey-8);
    }
  }

  @media screen and (min-width: 1024px) {
    display: none;
  }
`

export const EcosistemaSidebar = () => {
  const { toggleMenu } = useDropDown(["product", "company"])
  const { menu } = useNavigationMenu()
  const { t } = useI18next()
  const { toggleSidebar } = useNavigationContext()

  const enthImages = React.useMemo(
    () => [
      <img
        src={BaseImg}
        alt="Logo Young Platform Base"
        height={36}
        width={36}
      />,
      <img src={ProImg} alt="Logo Young Platform Pro" height={36} width={36} />,
    ],
    []
  )
  const newbyImages = React.useMemo(
    () => [
      <img
        src={StepImg}
        alt="Logo Young Platform Step"
        height={36}
        width={36}
      />,
      <img
        src={AcademyImg}
        alt="Logo Young Platform Academy"
        height={36}
        width={36}
      />,
    ],
    []
  )

  const { enthusiastArrays, newbyArrays } = useProductMenu({
    enthImages,
    newbyImages,
  })

  const companyLinkArray = React.useMemo(() => {
    return createArrayFromJson(
      t,
      "menu.company.hover.sections",
      4,
      [],
      "title",
      "link"
    )
  }, [t])

  return (
    <StyledBox
      mt={["48px", "56px"]}
      pt={["16px", "56px"]}
      width="100%"
      height="100vh"
      overflowY="scroll"
      position="fixed"
      top={"0px"}
      left="0px"
      right="0px"
      zIndex={9}
      background="white"
    >
      {menu.map(item => {
        return (
          <Box key={`sidebar-${item.title}`} onClick={toggleSidebar}>
            {item.link ? (
              <SeoLink
                isExternal={item.isExternal}
                link={item.link}
                rel={item.isExternal ? "noopener" : "internal"}
                target={item.isExternal ? "_blank" : "self"}
                style={{
                  padding: "0px 16px",
                  height: "48px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Box width="100%">
                  <HStack align="center" justify="start" spacing="8px">
                    <Label fontFamily="Matter" size="md" fontWeight={600}>
                      {item.title}
                    </Label>
                    {item.hasLabel && (
                      <Box
                        height="24px"
                        px="6px"
                        backgroundColor="blue.400"
                        display="flex"
                        alignItems="center"
                        borderRadius="21px"
                      >
                        <ResponsiveText
                          dimension={["10px"]}
                          line={["10px"]}
                          style={{
                            textTransform: "uppercase",
                          }}
                          fontWeight={600}
                          color="white"
                        >
                          new
                        </ResponsiveText>
                      </Box>
                    )}
                  </HStack>
                </Box>
              </SeoLink>
            ) : (
              <Box
                px="16px"
                py="14px"
                display="flex"
                onClick={e => e.stopPropagation()}
              >
                <Box width="100%">
                  <HStack
                    justify="space-between"
                    align="center"
                    onClick={() =>
                      toggleMenu(
                        item.title === t("menu.product.text")
                          ? "product"
                          : "company"
                      )
                    }
                  >
                    <Label size="md" fontFamily="Matter" fontWeight={600}>
                      {item.title}
                    </Label>
                    <Icon icon="chevron_down" fontSize="24px" />
                  </HStack>
                  {item.title === t("menu.product.text") ? (
                    <Box id="product-menu">
                      {enthusiastArrays.concat(newbyArrays).map(product => {
                        return (
                          <ProductCard
                            key={product.title as string}
                            {...product}
                          />
                        )
                      })}
                    </Box>
                  ) : (
                    <Box id="company-menu" mt="14px" onClick={toggleSidebar}>
                      {companyLinkArray.map(element => {
                        return (
                          <Box
                            key={element.title as string}
                            borderRadius="16px"
                            className="sidebar-link"
                            width="100%"
                          >
                            <SeoLink
                              link={element.link as string}
                              isExternal={false}
                              style={{
                                padding: "0px 16px",
                              }}
                            >
                              <Label
                                fontFamily="Matter"
                                fontWeight={600}
                                size="md"
                                ml="16px"
                              >
                                {element.title as string}
                              </Label>
                            </SeoLink>
                          </Box>
                        )
                      })}
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        )
      })}
      <Box mt="24px">
        <SidebarAction />
      </Box>
    </StyledBox>
  )
}

const ProductCard = ({
  title,
  link,
  img,
  description,
}: ProductMenuProps["enthusiastArrays" | "newbyArrays"][number]) => {
  return (
    <SeoLink
      isExternal={link !== "/"}
      link={link as string}
      className="header-product-card"
    >
      <Box
        p="16px"
        maxWidth="344px"
        style={{
          boxSizing: "content-box",
        }}
      >
        <HStack align="flex-start" justify="start" spacing="16px">
          <Avatar
            shape="square"
            backgroundColor="white"
            minWidth="56px"
            height="56px"
            borderRadius="16px"
            border="1px solid"
            borderColor="grey.5"
          >
            {img && img}
          </Avatar>
          <Box>
            {typeof title === "string" ? (
              <Heading
                size="sm"
                color="black"
                fontFamily="Matter"
                fontWeight={600}
              >
                {title}
              </Heading>
            ) : null}
            {typeof description === "string" ? (
              <Box mt="4px" maxWidth="238px">
                <Paragraph size="sm" color="grey.1" fontFamily={"Matter"}>
                  {description}
                </Paragraph>
              </Box>
            ) : null}
          </Box>
        </HStack>
      </Box>
    </SeoLink>
  )
}
