import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Button, HStack, VStack } from "@youngagency/ui"
import { Box as LegacyBox } from "@youngagency/young-ui"
import {
  AbsoluteCardImageBox,
  Card,
  CardContent,
  CardGrid,
  CardTexts,
} from "../../../../components/cards"
import { hero, heroBlackBtn } from "./b2b.css"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { b2bEvents, globalEvents } from "../../../../service/tracking"
import { navigate } from "@reach/router"
import { Text } from "../../../../components/texts/temporary"
import { useHandlePersistUtm } from "../../../navigation/hooks/useHandlePersistUtm"
import { StaticImage } from "gatsby-plugin-image"
import { heroBtnsStack } from "../business-user/style.css"
import { googleScheduleMeet } from "../../constants"
export const Hero = () => {
  const { t } = useI18next()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  const { handlePersistUtmCallback } = useHandlePersistUtm()

  const handleExchangeClick = React.useCallback(() => {
    gaTracker?.trackMultipleEvents([
      {
        eventName: b2bEvents.b2b_home_hero_start,
        payload: {
          action: 1,
        },
      },
      {
        eventName: globalEvents.click_to_exchange,
      },
    ])
    handlePersistUtmCallback({
      callback: () => navigate("https://youngexchange.page.link/B2Bb"),
    })
  }, [gaTracker, handlePersistUtmCallback])
  return (
    <CardGrid size="hero">
      <Card
        className={hero}
        disableHover
        backgroundColor="var(--grey-700-v2)"
        height={["auto !important", "calc(100vh - 98px) !important"]}
        position="relative"
        borderRadius={["0px !important", "32px !important"]}
      >
        <CardContent
          maxWidth="100% !important"
          alignItems={["center", "center"] as any}
          mr="0px !important"
        >
          <CardTexts mx="auto">
            <Text.H2
              as="h1"
              textAlign="center"
              fontWeight={600}
              color={"white"}
            >
              {t("clubs_page.b2b.hero.title")}
            </Text.H2>
            <LegacyBox maxWidth="616px" mt="24px" mx="auto">
              <Text.Subtitle2
                as="p"
                textAlign="center"
                fontWeight={600}
                color={"var(--grey-200-v2)"}
              >
                {t("clubs_page.b2b.hero.description")}
              </Text.Subtitle2>
            </LegacyBox>
          </CardTexts>
          <div className={heroBtnsStack}>
            <Button
              variant="solid"
              size={48}
              colorScheme="green"
              onClick={handleExchangeClick}
            >
              {t("clubs_page.b2b.hero.cta_join")}
            </Button>
            <Button
              variant="solid"
              size={48}
              atoms={{
                marginLeft: 8,
              }}
              colorScheme="black"
              className={heroBlackBtn}
              onClick={() => {
                gaTracker?.trackMultipleEvents([
                  {
                    eventName: b2bEvents.b2b_home_hero_start,
                    payload: {
                      action: 2,
                    },
                  },
                  {
                    eventName: globalEvents.contact_sales,
                  },
                ])
                navigate(googleScheduleMeet)
              }}
            >
              {t("clubs_page.b2b.hero.cta_contact")}
            </Button>
          </div>
        </CardContent>
        <AbsoluteCardImageBox display={["none !important", "flex !important"]}>
          <StaticImage
            quality={100}
            outputPixelDensities={[1, 2]}
            style={{
              height: "100%",
            }}
            placeholder="none"
            src="../../images/b2b_home_hero.png"
            alt={t("business_user.hero.alt_text_image")}
          />
        </AbsoluteCardImageBox>
      </Card>
    </CardGrid>
  )
}
