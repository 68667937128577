import React, { Component, Fragment } from "react"
import CryptoIcon from "./crypto-icon.component"
import EthereumGrey from "./images/ethereum.svg"
import LinkGrey from "./images/link.svg"
import BatGrey from "./images/bat.svg"
import StellarGrey from "./images/stellar.svg"
import { AbsoluteContainer } from "../styledUi"
import { FadeInFromBottomContainer } from "../why-us/AnimatedToken"

class CryptoIcons extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialState: true,
    }
  }

  setInitialState = () => {
    this.setState({ initialState: false })
  }
  render() {
    // const { t } = this.props
    return (
      <Fragment>
        <AbsoluteContainer
          cursor="pointer"
          zIndex={9}
          mediaScreen={1400}
          top="120px"
          right="120px"
          topMobile="139px"
          rightMobile="0px"
          style={{ zIndex: "333" }}
        >
          {" "}
          <FadeInFromBottomContainer>
            <CryptoIcon symbol={"LINK"} color={"#375BD2"} icon={LinkGrey} />
          </FadeInFromBottomContainer>
        </AbsoluteContainer>
        <AbsoluteContainer
          cursor="pointer"
          zIndex={9}
          mediaScreen={1400}
          top="64px"
          left="72px"
          topMobile="258px"
          rightMobile="78px"
        >
          {" "}
          <FadeInFromBottomContainer>
            <CryptoIcon symbol={"ETH"} color={"#5C6BC0"} icon={EthereumGrey} />
          </FadeInFromBottomContainer>
        </AbsoluteContainer>

        <AbsoluteContainer
          cursor="pointer"
          zIndex={9}
          mediaScreen={1400}
          top="450px"
          left="124px"
        >
          <FadeInFromBottomContainer>
            <CryptoIcon symbol={"BAT"} color={"#FF4724"} icon={BatGrey} />
          </FadeInFromBottomContainer>
        </AbsoluteContainer>

        <AbsoluteContainer
          cursor="pointer"
          zIndex={9}
          mediaScreen={1400}
          top="483px"
          right="164px"
        >
          <FadeInFromBottomContainer>
            <CryptoIcon symbol={"XLM"} color={"#000000"} icon={StellarGrey} />
          </FadeInFromBottomContainer>
        </AbsoluteContainer>
      </Fragment>
    )
  }
}
export default CryptoIcons
