import { Box } from "@youngagency/young-ui"
import { graphql, PageProps } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import { CardGrid } from "../components/cards"
import { Container } from "../components/layout"
import {
  BoostSection,
  EduSection,
  FooterBanner,
  Hero,
  NextSection,
  RumpUpSection,
  SecuritySection,
  TrustSection,
} from "../feature/home"
import { LandingLayout } from "../feature/navigation/components"
import { TranslationProvider } from "../feature/translations/context"
import {
  AlternateHandler,
  MetaDecorator,
  OrganizationSchema,
} from "../feature/seo"
import { Body, Heading } from "@youngagency/ui"

const HomePage: React.FC<PageProps<Queries.HomePageV2Query>> = () => {
  const { t } = useTranslation()
  return (
    <TranslationProvider>
      <LandingLayout
        disableFooterMargin
        dinamycLink="https://youngexchange.page.link/hDm"
      >
        <MetaDecorator
          metaTitle={t("homepage.seo.title")}
          metaDescription={t("homepage.seo.description")}
          og={[
            {
              property: "title",
              content: t("homepage.seo.title"),
            },
            {
              property: "description",
              content: t("homepage.seo.description"),
            },
          ]}
          disableSlogan
        />
        <OrganizationSchema />
        <AlternateHandler />
        <Box mx="auto" mt={["8px", "16px"]}>
          <CardGrid size="hero">
            <Hero />
          </CardGrid>
        </Box>
        <Container>
          <Box maxWidth="756px" mx="auto" mt={["48px", "96px"]}>
            <Heading
              variant="3"
              fontWeight={600}
              as="h2"
              style={{
                textAlign: "center",
              }}
            >
              {t("homepage.section_1.title")}
            </Heading>
            <Box mt={["16px", "24px"]}>
              <Body
                variant="1"
                fontWeight={600}
                color="grey200"
                style={{
                  textAlign: "center",
                }}
              >
                {t("homepage.section_1.description")}
              </Body>
            </Box>
          </Box>
        </Container>
        <CardGrid as="section" mt={["24px", "48px"]}>
          <RumpUpSection />
        </CardGrid>
        <Container>
          <Box mt={["48px", "96px"]} maxWidth="756px" mx="auto">
            <Heading
              variant="3"
              fontWeight={600}
              as="h2"
              style={{
                textAlign: "center",
              }}
            >
              {t("homepage.section_2.title")}
            </Heading>
            <Box mt={["16px", "24px"]}>
              <Body
                variant="1"
                fontWeight={600}
                color="grey200"
                style={{
                  textAlign: "center",
                }}
              >
                {t("homepage.section_2.description")}
              </Body>
            </Box>
          </Box>
        </Container>
        <CardGrid as="section" mt={["24px", "48px"]}>
          <NextSection />
        </CardGrid>
        <Container>
          <Box mt={["48px", "96px"]} maxWidth="756px" mx="auto">
            <Heading
              variant="3"
              fontWeight={600}
              as="h2"
              style={{
                textAlign: "center",
              }}
            >
              {t("homepage.section_4.title")}
            </Heading>
            <Box mt={["16px", "24px"]}>
              <Body
                variant="1"
                fontWeight={600}
                color="grey200"
                style={{
                  textAlign: "center",
                }}
              >
                {t("homepage.section_4.description")}
              </Body>
            </Box>
          </Box>
        </Container>
        <CardGrid as="section" mt={["24px", "48px"]}>
          <BoostSection />
        </CardGrid>
        <Container>
          <Box mt={["48px", "96px"]} maxWidth="756px" mx="auto">
            <Heading
              variant="3"
              as="h2"
              fontWeight={600}
              style={{
                textAlign: "center",
              }}
              dangerouslySetInnerHTML={{
                __html: t("homepage.section_3.title"),
              }}
            />
            <Box mt={["16px", "24px"]}>
              <Body
                variant="1"
                fontWeight={600}
                color="grey200"
                style={{
                  textAlign: "center",
                }}
              >
                {t("homepage.section_3.description")}
              </Body>
            </Box>
          </Box>
        </Container>
        <CardGrid as="section" mt={["24px", "48px"]}>
          <TrustSection />
        </CardGrid>

        <Box background="#000" width="100%">
          <Box
            mt={["8px", "16px"]}
            borderRadius={"0px 0px 32px 32px"}
            background="#fff"
            width="100%"
            height={["96px", "48px"]}
            style={{
              transform: "translateY(-1px)",
            }}
          />
          <Container>
            <Box mt={["48px", "96px"]} maxWidth="756px" mx="auto">
              <Heading
                variant="3"
                as="h2"
                color="white"
                fontWeight={600}
                style={{
                  textAlign: "center",
                }}
              >
                {t("homepage.section_6.title")}
              </Heading>
              <Box mt={["16px", "24px"]}>
                <Body
                  variant="1"
                  fontWeight={600}
                  color="grey200"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {t("homepage.section_6.description")}
                </Body>
              </Box>
            </Box>
          </Container>
          <CardGrid as="section" mt={["24px", "48px"]}>
            <EduSection />
          </CardGrid>
          <Container pb={["48px", "96px"]} mt={["48px", "96px"]}>
            <FooterBanner />
          </Container>
        </Box>
      </LandingLayout>
    </TranslationProvider>
  )
}

export const query = graphql`
  query HomePageV2($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default HomePage
