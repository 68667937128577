import {
  Box,
  HStack,
  Icon,
  Paragraph,
  styled,
  css,
} from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { SeoLink } from "../../seo"
import LightExtendedLogo from "../../../components/footer/images/footer-white-logo.svg"
import DarkExtendedLogo from "../../../components/footer/images/dark_logo_footer.svg"
import { Container } from "../../../components/layout"

const FooterTextBox = styled(Box)`
  display: flex;
  flex-direction: column-reverse;

  & > *:not(:first-of-type) {
    margin-bottom: 16px;
  }
  @media screen and (min-width: 1140px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & > *:not(:first-of-type) {
      margin-left: 20px;
      margin-bottom: 0px;
    }
    .footer-copy {
      min-width: 832px;
    }

    .iubenda-cs-preferences-link {
      cursor: pointer;
    }
  }
`

const StyledBox = styled(Box)(
  css({
    display: "flex",
    flexDirection: ["column", "row"],
    justifyContent: ["flex-start", "space-between"],
  })
)

const FOOTER_VARIANT = {
  black: {
    backgroundColor: "#000",
    color: "#fff",
    img: LightExtendedLogo,
  },
  white: {
    backgroundColor: "#fff",
    color: "#000",
    img: DarkExtendedLogo,
  },
}

export const CompactFooter = ({
  footerColorVariant,
}: {
  footerColorVariant: "black" | "white"
}) => {
  const { t } = useI18next()
  const colorScheme = React.useMemo(() => FOOTER_VARIANT[footerColorVariant], [
    footerColorVariant,
  ])
  return (
    <Box
      height="100%"
      backgroundColor={colorScheme.backgroundColor}
      py={["48px"]}
    >
      <Container size={["md", "lg"]}>
        <StyledBox>
          <img
            src={colorScheme.img}
            alt="footer-logo"
            height={24}
            style={{
              alignSelf: "flex-start",
            }}
          />
          <HStack
            justify="start"
            align="center"
            spacing="16px"
            className="last-grid-element"
            mt={["16px", "0px"]}
          >
            <SeoLink
              isExternal={true}
              rel="noopener"
              target="_blank"
              link={t("footer.social.twitter")}
            >
              <Icon icon="twitter" fontSize="24px" color={colorScheme.color} />
            </SeoLink>
            <SeoLink
              isExternal={true}
              rel="noopener"
              target="_blank"
              link={t("footer.social.facebook")}
            >
              <Icon icon="facebook" fontSize="24px" color={colorScheme.color} />
            </SeoLink>
            <SeoLink
              isExternal={true}
              rel="noopener"
              target="_blank"
              link={t("footer.social.instagram")}
            >
              <Icon
                icon="instagram"
                fontSize="24px"
                color={colorScheme.color}
              />
            </SeoLink>
            <SeoLink
              isExternal={true}
              rel="noopener"
              target="_blank"
              link={t("footer.social.tiktok")}
            >
              <Icon icon="tiktok" fontSize="24px" color={colorScheme.color} />
            </SeoLink>
            <SeoLink
              isExternal={true}
              rel="noopener"
              target="_blank"
              link={t("footer.social.youtube")}
            >
              <Icon icon="youtube" fontSize="24px" color={colorScheme.color} />
            </SeoLink>
          </HStack>
        </StyledBox>
        <FooterTextBox mt={["16px", "24px"]}>
          <Box className="footer-copy">
            <Paragraph size="xs" color={colorScheme.color} fontFamily="Matter">
              {new Date().getFullYear()} {t("footer.top_copyright")}-
              {t("footer.sub_copyright")}
            </Paragraph>
          </Box>
          <Box
            className="iubenda-cs-preferences-link"
            aria-label="Handle Cookie"
            role="button"
          >
            <Paragraph size="xs" color={colorScheme.color} fontFamily="Matter">
              {t("footer.cookie_text")}
            </Paragraph>
          </Box>
        </FooterTextBox>
      </Container>
    </Box>
  )
}
