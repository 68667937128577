import { Box, styled } from "@youngagency/young-ui"
import { css } from "@styled-system/css"

export type ColumnStackProps = {
  vGap?: string | string[]
  fromTop?: true
}

export type RowStackProps = {
  rGap?: string | string[]
  fromRight?: true
}

export const ColumnStack = styled(Box)<ColumnStackProps>(({ vGap, fromTop }) =>
  css({
    display: "flex",
    flexDirection: "column",
    "& >*:not(:last-of-type)": {
      mb: fromTop ? "initial" : vGap,
      mt: fromTop ? vGap : "initial",
    },
  })
)

export const RowStack = styled(Box)<RowStackProps>(({ rGap, fromRight }) =>
  css({
    display: "flex",
    flexDirection: "row",
    "& >*:not(:first-of-type)": {
      ml: fromRight ? "initial" : rGap,
      mr: fromRight ? rGap : "initial",
    },
  })
)
