import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Body, Box, Container, Heading, VStack, Tag } from "@youngagency/ui"
import { Container as CustomContainer } from "../../../../components/layout"
import {
  CardContent,
  CardGrid,
  CardStack,
  CardTexts,
} from "../../../../components/cards"
import { Card } from "../../../../components/cards"
import { navigate } from "@reach/router"
import { SeoLink } from "../../../seo"

export const FeedbackSection = () => {
  const { t } = useTranslation()
  const handleNavigate = React.useCallback((link: string) => {
    navigate(link)
  }, [])
  // https://it.trustpilot.com/reviews/6655ec32f30225a4891ce382
  const link1 = t("business_user.feedback_section.card_1.link");
  // https://it.trustpilot.com/reviews/66563147a93e5105556c34e6
  const link2 = t("business_user.feedback_section.card_2.link");
  // https://it.trustpilot.com/reviews/61b252d60b7cbbcbfe071727
  const link3 = t("business_user.feedback_section.card_3.link");

  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              color="white"
              dangerouslySetInnerHTML={{
                __html: t("business_user.feedback_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              color="grey200"
              style={{
                maxWidth: "700px",
              }}
              fontWeight={600}
            >
              {t("business_user.feedback_section.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>
      <Box
        marginTop={{
          mobile: 32,
          md: 40,
        }}
      >
        <VStack
          spacing={24}
          align="center"
          justify="center"
          atoms={{
            width: "full",
            mx: "auto",
          }}
        >
          <CardGrid>
            <CardStack>
              <Card
                isAnimated
                hasShadow
                disableHover
                background="var(--grey-700-v2)"
                orientation="column"
                invertBtn
                shape="flexible"
                onClick={() => handleNavigate(link1)}
              >
                <SeoLink
                  isExternal={true}
                  target="_blank"
                  rel="nofollow"
                  link={link1}
                >
                  <CardContent shape="flexible" orientation="column">
                    <CardTexts>
                      <Heading as="h3" variant="6" color="white" fontWeight={600}>
                        {t("business_user.feedback_section.card_1.title")}
                      </Heading>
                    </CardTexts>
                    <Body
                      atoms={{
                        marginTop: {
                          mobile: 24,
                          md: 48,
                        },
                        marginBottom: {
                          mobile: 24,
                          md: 48,
                        },
                      }}
                      variant="1"
                      color="white"
                      fontWeight={600}
                    >
                      {t("business_user.feedback_section.card_1.author")}
                    </Body>
                  </CardContent>
                </SeoLink>
              </Card>
              <Card
                isAnimated
                hasShadow
                disableHover
                background="var(--grey-700-v2)"
                orientation="column"
                invertBtn
                shape="flexible"
              >
                <SeoLink
                  isExternal={true}
                  target="_blank"
                  rel="nofollow"
                  link={link2}
                >
                  <CardContent shape="flexible" orientation="column">
                    <CardTexts>
                      <Heading as="h3" variant="6" color="white" fontWeight={600}>
                        {t("business_user.feedback_section.card_2.title")}
                      </Heading>
                    </CardTexts>
                    <Body
                      atoms={{
                        marginTop: {
                          mobile: 24,
                          md: 48,
                        },
                        marginBottom: {
                          mobile: 24,
                          md: 48,
                        },
                      }}
                      variant="1"
                      color="white"
                      fontWeight={600}
                    >
                      {t("business_user.feedback_section.card_2.author")}
                    </Body>
                  </CardContent>
                </SeoLink>
              </Card>
              <Card
                isAnimated
                hasShadow
                disableHover
                background="var(--grey-700-v2)"
                orientation="column"
                invertBtn
                shape="flexible"
              >
                <SeoLink
                  isExternal={true}
                  target="_blank"
                  rel="nofollow"
                  link={link3}
                >
                  <CardContent shape="flexible" orientation="column">
                    <CardTexts>
                      <Heading as="h3" variant="6" color="white" fontWeight={600}>
                        {t("business_user.feedback_section.card_3.title")}
                      </Heading>
                    </CardTexts>
                    <Body
                      atoms={{
                        marginTop: {
                          mobile: 24,
                          md: 48,
                        },
                        marginBottom: {
                          mobile: 24,
                          md: 48,
                        },
                      }}
                      variant="1"
                      color="white"
                      fontWeight={600}
                    >
                      {t("business_user.feedback_section.card_3.author")}
                    </Body>
                  </CardContent>
                </SeoLink>
              </Card>
            </CardStack>
          </CardGrid>
        </VStack>
      </Box>
    </>
  )
}
