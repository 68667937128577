import { useGetFiatName } from "."
import { useMemo } from "react"
import { OptionType } from "@youngagency/young-ui/dist/src/components/select/shared"
import { FIATIMAGES } from "../constants"
import { ConversorEntry, ProviderProps } from "../context"
import { parseAsOptions, inverseTokenTicker } from "../utils"

type Props = Omit<ProviderProps, "children"> & Queries.TokenConversorQuery

export const useConversor = (props: Props) => {
  const { token, fiat, allToken, allAvailableFiat } = props
  const getFiatName = useGetFiatName()

  const formattedToken = useMemo(() => {
    return allToken.nodes.map(el => ({
      name: el.name,
      symbol: el.symbol,
      image: el.details?.image || null,
    }))
  }, [allToken.nodes])

  const initialToken = useMemo(() => {
    return {
      ...token,
      value: 1,
      img:
        formattedToken.find(
          el => el.symbol?.toLowerCase() === token.symbol.toLowerCase()
        )?.image || null,
    }
  }, [token,  formattedToken])

  const initialFiat = useMemo(() => {
    return {
      ...fiat,
      img: FIATIMAGES[fiat.symbol.toUpperCase() as "EUR" | "USD"],
      value: 0,
    }
  }, [fiat, fiat.symbol])

  const initialConverorState = useMemo(
    (): Record<"from" | "to", ConversorEntry> => ({
      from: initialToken,
      to: initialFiat,
    }),
    [initialToken, initialFiat]
  )

  const staticTokenList = useMemo(
    () =>
      formattedToken.reduce<OptionType[]>((all, current) => {
        if (current?.symbol === token.symbol) return all
        return parseAsOptions(all, {
          ...current,
          symbol: inverseTokenTicker(current.symbol as string),
        })
      }, []),
    [formattedToken, token.symbol]
  )

  const fiatWithImages = useMemo(
    () =>
      allAvailableFiat.nodes.map(fiat => ({
        ...fiat,
        name: getFiatName(fiat.symbol as string),
        image_light: FIATIMAGES[fiat.symbol as "EUR" | "USD"],
      })),
    [allAvailableFiat.nodes, FIATIMAGES]
  )

  const staticFiatList = useMemo(
    () =>
      fiatWithImages.reduce<OptionType[]>((all, current) => {
        if (current?.symbol === fiat.symbol) return all
        return parseAsOptions(all, current)
      }, []),
    [fiatWithImages, fiat.symbol]
  )

  const initialLists = useMemo(
    () => ({
      from: staticTokenList,
      to: staticFiatList,
    }),
    [
      staticTokenList,
      staticFiatList,
    ]
  )

  return {
    initialLists,
    initialConverorState,
    staticFiatList,
    staticTokenList,
  }
}
