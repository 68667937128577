import {
  FiatCurrencyLookupTable,
  FiatAPI,
  FiatCurrency,
  Currency,
  CurrencyLookupTable,
  GetCurrenciesAPI,
  GetCurrenciesResponse,
  CurrencyAPI,
  CurrencyDetailsAPI,
  CurrencyDetails,
  CurrencyNetwork,
  CurrencyNetworkAPI,
} from "./types"

const parseFiatCurrencies = (
  fiatCurrencies: FiatCurrencyLookupTable,
  fiatApi: FiatAPI
) => {
  const { character, currency, price } = fiatApi
  const parsedFiatCurrency: FiatCurrency = {
    symbol: currency,
    price,
    amountSymbol: character,
  }
  return Object.assign(fiatCurrencies, { [currency]: parsedFiatCurrency })
}

const parseCurrencyDetails = (details: CurrencyDetailsAPI): CurrencyDetails => {
  const { mineable, ...rest } = details || {}

  return {
    ...rest,
    isMineable: mineable,
  }
}

const parseCurrencyNetwork = ({
  enabled,
  ...rest
}: CurrencyNetworkAPI): CurrencyNetwork => ({
  ...rest,
  isEnabled: enabled,
})

export const parseCurrency = (
  { details, networks, ...currency }: CurrencyAPI,
  fiat?: FiatAPI
): Currency => {
  const isPro = !!currency.tags?.some(tag => tag.label === "platform_pro")
  const isBase = !!currency.tags?.some(tag => tag.label === "platform_basic")
  const isSaving = !!currency.tags?.some(tag => tag.label === "saving")
  const isLocked = !!currency.tags?.some(tag => tag.label === "status_locked")
  const isProOnly = isPro && !isBase

  const optionalNetworksObject = networks
    ? { networks: networks.map(parseCurrencyNetwork) }
    : {}

  return {
    ...currency,
    ...optionalNetworksObject,
    details: parseCurrencyDetails(details),
    isFiat: !!fiat,
    isPro,
    isBase,
    isSaving,
    isProOnly,
    isLocked,
    amountSymbol: fiat?.character || currency.symbol,
  }
}

export const parseCurrencies = (
  data: GetCurrenciesAPI
): GetCurrenciesResponse => {
  const parsedCurrencies = data.currencies
    .filter(x => !!x.details)
    .reduce<CurrencyLookupTable>(
      (acc, currency) =>
        Object.assign(acc, {
          [currency.symbol]: parseCurrency(
            currency,
            data.fiats.find(fiat => fiat.currency === currency.symbol)
          ),
        }),
      {}
    )

  const parsedFiatCurrencies = data.fiats.reduce<FiatCurrencyLookupTable>(
    parseFiatCurrencies,
    {}
  )

  return {
    currencies: parsedCurrencies,
    fiats: parsedFiatCurrencies,
  }
}

export const parseFiatList = (data: GetCurrenciesAPI) => {
  const parsedFiatCurrencies = data.fiats.map(x => {
    const { character, currency, price } = x
    const parsedFiatCurrency: FiatCurrency = {
      symbol: currency,
      price,
      amountSymbol: character,
    }
    return parsedFiatCurrency
  })

  return parsedFiatCurrencies
}

import { GetTokenChartResponse } from ".."
import {
  ChartsV2SingleToken,
  SingleChartApiResponse,
} from "../../../service/charts/types"

export const parseSingleTokenChartResponseV2 = (data: any) => {}

export const parseSingleTokenChartResponse = (data: any) => {
  const points = data
    .sort(
      (a: any, b: any) =>
        new Date(a.time).valueOf() - new Date(b.time).valueOf()
    )
    .map((e: any) => ({
      value: Number(e.close),
      time: e.time,
      volume: e.volume,
      close: e.close,
      open: e.open,
      high: e.high,
      low: e.low,
    })) as GetTokenChartResponse["points"]

  const firstValue = points.find(x => x.value)?.value || 0
  const lastValue = points[points.length - 1].value

  const percentageChange = (
    ((lastValue - firstValue) / firstValue) *
    100
  ).toFixed(2)

  return {
    points,
    percentageChange,
  }
}

const formattedATH_ATV = (values: ParsedChartData) => {
  return {
    percentageChange: values.percentageChange,
    ath: Math.max(...values.points.map(value => value.close)),
    atl: Math.min(...values.points.map(value => value.close)),
  }
}

export const athAtlparser = ({ data }: any) => {
  const parsedChartData = parseSingleTokenChartResponse(data)
  return formattedATH_ATV(parsedChartData)
}
export const athAtlparserV2 = (data: ChartsV2SingleToken) => {
  const parsedChartData = parseSingleTokenChartResponse(data)
  return formattedATH_ATV(parsedChartData)
}

export type ParsedChartData = ReturnType<typeof parseSingleTokenChartResponse>
export type TokenATH_ATL = ReturnType<typeof athAtlparser>
