import { Box } from "@youngagency/young-ui"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import {
  Card,
  CardButton,
  CardContent,
  CardGrid,
  CardImage,
  CardStack,
  CardTexts,
} from "../../../components/cards"
import { Container } from "../../../components/layout"
import { Text } from "../../../components/texts/temporary"
import { NarrativeLocalizedImages } from "./ClubLocalizedImage"
import { navigate } from "@reach/router"
import { dynamicLinks } from "../constants"
import {  clubsEvents, globalEvents } from "../../../service/tracking"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"


export const NarrativeSection = () => {
  const { t } = useTranslation()
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)

  const handleOnClick = React.useCallback((card_clicked: number) => {
    if(gaTracker)
    gaTracker.trackMultipleEvents([
      {
        eventName: clubsEvents.club_body_overview,
        payload: {
          card_clicked: card_clicked,
        },
      },
      {
        eventName: globalEvents.click_to_exchange,
      },
    ])
    navigate(dynamicLinks.main)
  }, [])
  return (
    <CardGrid>
      <Container size="md">
        <Text.H3
          textAlign="center"
          as="h2"
          fontWeight={400}
          dangerouslySetInnerHTML={{
            __html: t("clubs_page.narrative_section.title"),
          }}
        ></Text.H3>
        <Box mt={["16px", "24px"]}>
          <Text.Body1
            fontWeight={600}
            textAlign="center"
            color="var(--grey-200-v2)"
            as="div"
          >
            {t("clubs_page.narrative_section.description")}
          </Text.Body1>
        </Box>
      </Container>

      <Card
        isAnimated
        hasShadow
        background="white"
        mt="40px"
        orientation="row"
        onClick={() => handleOnClick(1)}
      >
        <CardContent>
          <CardTexts>
            <Text.H6 as="h3" fontWeight={600}>
              {t("clubs_page.narrative_section.card_1.title")}
            </Text.H6>
            <Text.Body1 fontWeight={600} as="p" color="grey.1">
              {t("clubs_page.narrative_section.card_1.description")}
            </Text.Body1>
          </CardTexts>
          <CardButton size="md" mt={["16px", "24px"]}>
            {t("clubs_page.narrative_section.card_1.button")}
          </CardButton>
        </CardContent>
        <CardImage>
          <NarrativeLocalizedImages.card_1 />
        </CardImage>
      </Card>
      <Box>
        <CardStack mt="1rem">
          <Card
            hasShadow
            orientation="column"
            background="linear-gradient(189.61deg, #C9F3F2 11.09%, #F2F7F0 100%), #C7F3F4"
            isAnimated
            onClick={() => handleOnClick(2)}
          >
            <CardContent>
              <CardTexts>
                <Text.H6 as="h3" fontWeight={600}>
                  {t("clubs_page.narrative_section.card_2.title")}
                </Text.H6>
              </CardTexts>
              <CardButton size="md" mt={["16px", "24px"]}>
                {t("clubs_page.narrative_section.card_1.button")}
              </CardButton>
            </CardContent>
            <CardImage>
              <NarrativeLocalizedImages.card_2 />
            </CardImage>
          </Card>
          <Card
            hasShadow
            orientation="column"
            background="var(--green-20-v2)"
            isAnimated
            onClick={() => handleOnClick(3)}
          >
            <CardContent>
              <CardTexts>
                <Text.H6 as="h3" fontWeight={600}>
                  {t("clubs_page.narrative_section.card_3.title")}
                </Text.H6>
              </CardTexts>
              <CardButton size="md" mt={["16px", "24px"]}>
                {t("clubs_page.narrative_section.card_3.button")}
              </CardButton>
            </CardContent>
            <CardImage>
              <StaticImage
                placeholder="blurred"
                src="../images/it/narrative-3.png"
                alt={t("clubs_page.narrative_section.card_3.img_alt_text")}
              />
            </CardImage>
          </Card>
        </CardStack>
      </Box>
    </CardGrid>
  )
}
