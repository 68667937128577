import React from "react"
import { useRef } from "react"
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useMotionValue,
  useVelocity,
  useAnimationFrame,
} from "framer-motion"
import { wrap } from "@motionone/utils"
import { parallax, scroller, scrollerItem } from "./b2b.css"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Body, Box, Container, Heading, VStack } from "@youngagency/ui"
import { Container as CustomContainer } from "../../../../components/layout"
import { navigate } from "@reach/router"

interface ParallaxProps {
  children: React.ReactNode
  baseVelocity: number
}

const ImageSection_1 = () => {
  const { t } = useTranslation()
  return (
    <div className={scroller}>
      <div
        className={scrollerItem}
        onClick={() => navigate("https://www.arkes.it/")}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/arkes.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.arkes")}
        />
      </div>
      <div
        className={scrollerItem}
        onClick={() => navigate("https://www.azimut-group.com/")}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/azimut.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.azimut")}
        />
      </div>
      <div
        className={scrollerItem}
        onClick={() => navigate("https://bcode.cloud/")}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/bcode.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.bcode")}
        />
      </div>
      <div
        className={scrollerItem}
        onClick={() => navigate("https://www.chainalysis.com/")}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/chainalysis.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.chainalysis")}
        />
      </div>
      <div
        className={scrollerItem}
        onClick={() => navigate("https://www.checkout.com/")}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/checkout.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.checkout")}
        />
      </div>
      <div
        className={scrollerItem}
        // onClick={() => navigate("https://www.sia.eu/")}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/united.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.united")}
        />
      </div>
    </div>
  )
}

const ImageSection_2 = () => {
  const { t } = useTranslation()
  return (
    <div className={scroller}>
      <div
        className={scrollerItem}
        // onClick={() => navigate("https://www.digitech.it/")}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/digitech.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.digitech")}
        />
      </div>
      <div
        className={scrollerItem}
        onClick={() => navigate("https://www.fireblock.com/")}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/fireblock.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.fireblock")}
        />
      </div>
      <div
        className={scrollerItem}
        // onClick={() => navigate("https://www.ithacaweb.org/")}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/ithaca.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.ithaca")}
        />
      </div>
      <div
        className={scrollerItem}
        onClick={() => navigate("https://www.knobs.it/")}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/knobs.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.knobs")}
        />
      </div>
      <div
        className={scrollerItem}
        onClick={() => navigate("https://onfido.com/")}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/onfido.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.onfido")}
        />
      </div>
      <div
        className={scrollerItem}
        onClick={() => navigate("https://www.sella.it/")}
      >
        <StaticImage
          placeholder="none"
          src="../../images/logos/sella.png"
          alt={t("clubs_page.b2b.investors_section.logos_alt_text.sella")}
        />
      </div>
    </div>
  )
}

type Props = React.HTMLAttributes<HTMLDivElement> & ParallaxProps

function ParallaxBox({ children, baseVelocity = 100, ...rest }: Props) {
  const baseX = useMotionValue(0)
  const { scrollY } = useScroll()
  const scrollVelocity = useVelocity(scrollY)
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400,
  })
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false,
  })

  /**
   * This is a magic wrapping for the length of the text - you
   * have to replace for wrapping that works for you or dynamically
   * calculate
   */
  const x = useTransform(baseX, v => `${wrap(-20, -45, v)}%`)

  const directionFactor = useRef<number>(1)
  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 1000)

    /**
     * This is what changes the direction of the scroll once we
     * switch scrolling directions.
     */
    if (velocityFactor.get() < 0) {
      directionFactor.current = -1
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1
    }

    moveBy += directionFactor.current * (moveBy / 4) * velocityFactor.get()

    baseX.set(baseX.get() + moveBy)
  })

  /**
   * The number of times to repeat the child text should be dynamically calculated
   * based on the size of the text and viewport. Likewise, the x motion value is
   * currently wrapped between -20 and -45% - this 25% is derived from the fact
   * we have four children (100% / 4). This would also want deriving from the
   * dynamically generated number of children.
   */
  return (
    <div className={parallax} {...rest}>
      <motion.div className={scroller} style={{ x }}>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.div>
    </div>
  )
}

type ParallaxElementVelocity = {
  1: number
  2: number
}

export const PartnerSection = () => {
  const { t } = useTranslation()

  const [velocity, setVelocity] = React.useState<ParallaxElementVelocity>({
    1: 2,
    2: 2,
  })

  const stopVelocity = React.useCallback((item: 1 | 2) => {
    return setVelocity({ ...velocity, [item]: 0 })
  }, [])

  const playVelocity = React.useCallback((item: 1 | 2) => {
    return setVelocity({ ...velocity, [item]: 2 })
  }, [])

  return (
    <section>
      <CustomContainer>
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              fontWeight={400}
              dangerouslySetInnerHTML={{
                __html: t("clubs_page.b2b.investors_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              color="grey200"
              style={{
                maxWidth: "700px",
              }}
              fontWeight={600}
            >
              {t("clubs_page.b2b.investors_section.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>

      <Box marginTop={24}>
        <ParallaxBox
          baseVelocity={-velocity[1]}
          onMouseEnter={() => stopVelocity(1)}
          onMouseLeave={() => playVelocity(1)}
        >
          <ImageSection_1 />
        </ParallaxBox>
        <ParallaxBox
          baseVelocity={velocity[2]}
          onMouseEnter={() => stopVelocity(2)}
          onMouseLeave={() => playVelocity(2)}
        >
          <ImageSection_2 />
        </ParallaxBox>
      </Box>
    </section>
  )
}
