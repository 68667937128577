import {
  Box,
  Icon,
  IconType,
  Button,
  useHasMounted,
} from "@youngagency/young-ui"
import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Paragraph } from "../../../components"
import { Avatar, Container } from "../../../components/layout"
import { SeoLink } from "../../../feature/seo"
import styled from "styled-components"
import { useLegalProvider } from "../context"
import { graphql, useStaticQuery } from "gatsby"
import { slugifyString } from "../../../utils/general"

const StyledBox = styled(Box)`
  & .legal-doc:not(:first-of-type) {
    margin-top: 8px;
  }

  .legal-doc {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;

    & > *:not(:first-of-type) {
      margin-left: 16px;
    }
  }

  .legal-doc-header {
    display: flex;
    align-items: center;

    & > *:not(:first-child) {
      margin-left: 16px;
    }
  }

  @media screen and (min-width: 400px) {
    & .legal-doc:not(:first-of-type) {
      margin-top: 16px;
    }
  }
`

export const LegalDocs = () => {
  const { t } = useTranslation()
  const hasMounted = useHasMounted()
  const { docs: legalDocs, category } = useLegalProvider() || {}
  const data: Queries.PriceAndFeesLegalCategoryQuery = useStaticQuery(query)
  const isFeesShowable = React.useMemo(() => {
    if (!category) return true
    return (
      category?.toLowerCase() === data.wpLegalCategory?.name?.toLowerCase() ||
      data.wpLegalCategory?.translations?.some(
        el => el?.name?.toLowerCase() === category?.toLowerCase()
      )
    )
  }, [category, data.wpLegalCategory?.name, data.wpLegalCategory?.translations])

  const setCameFrom = React.useCallback(() => {
    if (!hasMounted) return
    if (!category) return
    localStorage.setItem("comeFrom", category)
  }, [hasMounted, category])

  if (legalDocs)
    return (
      <StyledBox mt="24px">
        <Container size={["md", "lg"]}>
          {legalDocs.map(docs => {
            return (
              <Box
                as="article"
                key={docs.databaseId}
                className="legal-doc"
                p={["16px", "24px"]}
                border="1px solid"
                borderColor="grey.5"
                borderRadius={["16px", "24px"]}
              >
                <Box className="legal-doc-header">
                  <Avatar
                    shape="circle"
                    minWidth="36px"
                    height="36px"
                    backgroundColor="grey.4"
                  >
                    <Icon
                      icon={docs?.legalFields?.icon as IconType}
                      fontSize="24px"
                    />
                  </Avatar>
                  <Paragraph as="h2" weight="semibold">
                    {docs.title}
                  </Paragraph>
                </Box>
                <SeoLink
                  isExternal={false}
                  link={`/legal/${docs.slug}/`}
                  onClick={setCameFrom}
                >
                  <Button size="md" variant="primary">
                    {t("legal.docs_cta")}
                  </Button>
                </SeoLink>
              </Box>
            )
          })}
          {isFeesShowable ? (
            <Box
              as="article"
              key={"fees-legal-box"}
              className="legal-doc"
              p={["16px", "24px"]}
              border="1px solid"
              borderColor="grey.5"
              borderRadius={["16px", "24px"]}
            >
              <Box className="legal-doc-header">
                <Avatar
                  shape="circle"
                  minWidth="36px"
                  height="36px"
                  backgroundColor="grey.4"
                >
                  <Icon
                    icon={t("legal.fees_card.icon") as IconType}
                    fontSize="24px"
                  />
                </Avatar>
                <Paragraph as="h2" weight="semibold">
                  {t("legal.fees_card.title")}
                </Paragraph>
              </Box>
              <SeoLink isExternal={false} link={`/legal/fees/`}>
                <Button size="md" variant="primary">
                  {t("legal.docs_cta")}
                </Button>
              </SeoLink>
            </Box>
          ) : null}
        </Container>
      </StyledBox>
    )
  return null
}

const query = graphql`
  query PriceAndFeesLegalCategory {
    wpLegalCategory(databaseId: { eq: 17807 }) {
      name
      translations {
        name
      }
    }
  }
`

export default LegalDocs
