import React from "react"
import { Helmet } from "react-helmet"
import { useI18next } from "gatsby-plugin-react-i18next/dist"
import { SupportedLanguageValue } from "../../../types/footer"
import { useTranslationContext } from "../../translations/context"
import isEmpty from "lodash/isEmpty"
import { removePaginationFromSlug } from "../../translations/utils"
const defaultUrl = process.env.CRAWL_URL as string
const supportedISO = {
  it: "it-IT",
  en: "en-GB",
  fr: "fr-FR",
}

export const AlternateHandler = ({forcedCanonical}:{forcedCanonical?: string}) => {
  const { language, originalPath } = useI18next()
  const { translations, disableTranslations } = useTranslationContext()

  const handleStaticPath = React.useCallback(
    (langPath: SupportedLanguageValue) => {
      return langPath == "it"
        ? originalPath === "/"
          ? ""
          : originalPath.slice(1)
        : langPath + originalPath
    },
    [language]
  )

  const hrefLangGenerator = React.useCallback(
    (customLang?: SupportedLanguageValue) => {
      if (!customLang) {
        return supportedISO[(language as "it" | "en", "fr")]
      }
      return supportedISO[customLang as "it" | "en" | "fr"]
    },
    [language]
  )

  const paginatedHandler = React.useCallback(
    (slug: string, customLang: SupportedLanguageValue) => {
      const langPath =
        customLang.toLocaleLowerCase() === "it"
          ? ""
          : customLang.toLocaleLowerCase() + "/"
      if (!removePaginationFromSlug(slug)) return defaultUrl + langPath + slug
      return defaultUrl + langPath + removePaginationFromSlug(slug)
    },
    [language]
  )

  if (disableTranslations) {
    return (
      <Helmet>
        <link
          href={`${defaultUrl}${handleStaticPath(
            language as SupportedLanguageValue
          )}`}
          rel="canonical"
        />
        <link
          href={`${defaultUrl}${handleStaticPath(
            language as SupportedLanguageValue
          )}`}
          rel="alternate"
          hrefLang={hrefLangGenerator(language as SupportedLanguageValue)}
        />
      </Helmet>
    )
  } else {
    return (
      <Helmet>
        {!isEmpty(translations) && translations
          ? translations
              .map(({ slug, language }) => {
                return (
                  <link
                    href={`${defaultUrl}${slug}`}
                    rel="alternate"
                    hrefLang={hrefLangGenerator(
                      language?.code?.toLowerCase() as SupportedLanguageValue
                    )}
                    key={language.code as string}
                  />
                )
              })
              .concat([
                <link
                  href={paginatedHandler(
                    originalPath.slice(1),
                    language as SupportedLanguageValue
                  )}
                  rel="alternate"
                  hrefLang={hrefLangGenerator(
                    language as SupportedLanguageValue
                  )}
                  key={language as string}
                />,
                <link
                  href={paginatedHandler(
                    forcedCanonical || originalPath.slice(1),
                    language as SupportedLanguageValue
                  )}
                  rel="canonical"
                  key={language as string}
                />,
              ])
          : null}
      </Helmet>
    )
  }
}

export default AlternateHandler
