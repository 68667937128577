import { Box } from "@youngagency/young-ui"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import { CardGrid } from "../../../components/cards"
import { Container } from "../../../components/layout"
import { Text } from "../../../components/texts/temporary"
import { useClubPageContext } from "../context/ClubPageContext"
import { ComparativeProvider } from "../context/ComparativeContext"
import {
  DesktopComparativeTable,
  MobileComparativeTable,
} from "./ComparativeTabs"

export const ComparativeSection = () => {
  const { t } = useTranslation()
  const { ref } = useClubPageContext()
  return (
    <>
      <CardGrid>
        <Container>
          <Text.H3
            fontWeight={400}
            textAlign="center"
            as="h2"
            dangerouslySetInnerHTML={{
              __html: t("clubs_page.comparative_section.title"),
            }}
          />
          <Box mt={["16px", "24px"]}>
            <Text.Body1
              fontWeight={600}
              textAlign="center"
              color="var(--grey-200-v2)"
            >
              {t("clubs_page.comparative_section.description")}
            </Text.Body1>
          </Box>
        </Container>
      </CardGrid>
      <Box ref={ref}>
        <ComparativeProvider>
          <MobileComparativeTable />
          <DesktopComparativeTable />
        </ComparativeProvider>
      </Box>
    </>
  )
}
