import { Box, Button, ButtonProps } from "@youngagency/ui"
import React from "react"
import {
  buttonVariant,
  cardButton,
  cardButtonIcon,
} from "../style/card-button.css"
import clsx from "clsx"
import { DefaultCardProps } from "../types"
import { BoxExtendedProps } from "../../../types/system"

type CardButtonProps = ButtonProps &
  DefaultCardProps & {
    schema: "light" | "dark"
  }

export const CardButton = ({
  schema,
  children,
  className,
  ...rest
}: CardButtonProps) => {
  const buttonSchema = buttonVariant({ schema })

  return (
    <Button
      {...rest}
      variant="solid"
      colorScheme="white"
      className={clsx(cardButton, buttonSchema, className)}
    >
      {children}
    </Button>
  )
}

type CardButtonIconProps = BoxExtendedProps<HTMLDivElement> & {
  schema?: "light" | "dark"
}

export const CardIconButton = ({
  schema = "light",
  children,
  className,
  ...rest
}: CardButtonIconProps) => {
  const buttonSchema = cardButtonIcon({ schema })
  return (
    <Box {...rest} className={clsx(buttonSchema, className)}>
      {children}
    </Box>
  )
}
