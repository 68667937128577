import { useI18next } from "gatsby-plugin-react-i18next"
import { createArrayFromJson } from "../../../utils/general"
import React from "react"

const useFooterData = () => {
  const { t } = useI18next()
  const footerData = React.useMemo(
    () => ({
      company: createArrayFromJson(
        t,
        "footer.company.children",
        6,
        [],
        "text",
        "link",
        "is_external"
      ),

      product: createArrayFromJson(
        t,
        "footer.product.children",
        5,
        [],
        "text",
        "link",
        "is_external"
      ),

      support: createArrayFromJson(
        t,
        "footer.support.children",
        7,
        [],
        "text",
        "link",
        "is_external"
      ),

      learn: createArrayFromJson(
        t,
        "footer.learn.children",
        6,
        [],
        "text",
        "link",
        "is_external"
      ),
    }),
    [createArrayFromJson, t]
  )

  return footerData
}

export default useFooterData

export type FooterProps = ReturnType<typeof useFooterData>
