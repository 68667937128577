import { Box, Paragraph } from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import styled from "styled-components"
import { ResponsiveText } from "../../../components/layout"
import { createArrayFromJson } from "../../../utils/general"

const Wrapper = styled(Box)`
  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
  }
`

const StyledBox = styled(Box)<{ brColor: string; disableArrow?: true }>`
  padding-left: 28px;
  border-left: 1px dashed ${props => props.brColor};
  position: relative;
  min-height: 85px;
  &:last-of-type {
    &:after {
      content: "${"\\25bc"}";
      bottom: 0px;
      left: 0px;
      transform: translate(-7px, 8px);
      position: absolute;
      color: white;
      width: 16px;
      height: 16px;
      font-size: 12px;
      display: ${props => (props.disableArrow ? "none" : "block")};
      @media screen and (min-width: 1024px) {
        content: "${"\\25ba"}";
        top: 0px;
        right: 0px;
        left: unset;
        transform: translate(8px, -8px);
      }
    }
  }

  &:before {
    content: " ";
    width: 16px;
    height: 16px;
    background: var(--green-300);
    border-radius: 50%;
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translateX(-9px);
  }
  @media screen and (min-width: 1024px) {
    width: 100%;
    padding-left: 0px;
    border-left: none;
    border-top: 1px dashed ${props => props.brColor};
    min-height: unset;
    &:before {
      transform: translateY(-9px);
    }

    .box-title {
      margin-top: 24px;
    }
  }
`

export const HeroRoadmap = () => {
  const { t } = useI18next()
  return (
    <Wrapper pb={["48px", "96px"]}>
      <StyledBox brColor="white">
        <Box className="box-title">
          <ResponsiveText
            line={["18px", "20px"]}
            dimension={["14px", "16px"]}
            fontWeight={500}
            color="white"
          >
            10.05.22 - 24.05.22
          </ResponsiveText>
          <Box mt={["8px"]}>
            <ResponsiveText
              line={["25px", "30px"]}
              dimension={["20px", "24px"]}
              fontWeight={500}
              color="white"
            >
              {t("token.topRoadmap.title_1")}
            </ResponsiveText>
          </Box>
        </Box>
      </StyledBox>
      <StyledBox brColor="white">
        <Box className="box-title">
          <ResponsiveText
            line={["18px", "20px"]}
            dimension={["14px", "16px"]}
            color="white"
          >
            31.05.22
          </ResponsiveText>
          <Box mt={["8px"]}>
            <ResponsiveText
              line={["25px", "30px"]}
              dimension={["20px", "24px"]}
              fontWeight={500}
              color="white"
            >
              {t("token.topRoadmap.title_2")}
            </ResponsiveText>
          </Box>
        </Box>
      </StyledBox>
    </Wrapper>
  )
}

export const BottomRoadMap = () => {
  const { t } = useI18next()

  const roadmapArray = React.useMemo(() => {
    return createArrayFromJson(
      t,
      "token.bottomRoadmap.timeline",
      6,
      [],
      "data",
      "description"
    )
  }, [t, createArrayFromJson])

  return (
    <Box>
      <ResponsiveText
        line={["30px", "39px"]}
        dimension={["24px", "34px"]}
        fontWeight={600}
        textAlign="center"
        as="h2"
      >
        {t("token.bottomRoadmap.title")}
      </ResponsiveText>
      <Wrapper mt={["24px", "48px"]}>
        {roadmapArray.map(el => {
          return (
            <StyledBox
              key={el.description as string}
              brColor="black"
              disableArrow
            >
              <Box className="box-title">
                <ResponsiveText
                  line={["25px", "30px"]}
                  dimension={["20px", "24px"]}
                  fontWeight={500}
                  as="h3"
                >
                  {el.data}
                </ResponsiveText>
                <Box mt={["8px"]} maxWidth={["unset", "174px"]}>
                  <Paragraph fontFamily="Matter" size="sm" as="p">
                    {el.description}
                  </Paragraph>
                </Box>
              </Box>
            </StyledBox>
          )
        })}
      </Wrapper>
    </Box>
  )
}
