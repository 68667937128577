import React from "react"
import { DefaultCardProps } from "../types"
import clsx from "clsx"
import {
  absoluteImage,
  cardImage,
  cardImageVariant,
} from "../style/card-image.css"
import { useCardContext } from "../context/CardContext"

type CardImageProps = DefaultCardProps & {
  absolute?: boolean
}

export const CardImage = ({
  className,
  children,
  absolute,
}: CardImageProps) => {
  const { orientation } = useCardContext()
  const imageVariant = cardImageVariant({ orientation })
  const imageStyle = React.useMemo(() => {
    if (absolute) {
      return clsx(cardImage, absoluteImage, className)
    }
    return clsx(cardImage, imageVariant, className)
  }, [absolute])
  return <div className={imageStyle}>{children}</div>
}
