import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Body, Box, Container, Heading, VStack, Tag } from "@youngagency/ui"
import { Container as CustomContainer } from "../../../../components/layout"
import { logosGrids, logoItem } from "./style.css"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "@reach/router"

export const LogosSection = () => {
  const { t } = useTranslation()

  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              color="white"
              dangerouslySetInnerHTML={{
                __html: t("business_user.investors_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              color="grey200"
              style={{
                maxWidth: "700px",
              }}
              fontWeight={600}
            >
              {t("business_user.investors_section.description")}
            </Body>
          </VStack>
        </Container>
        <Box
          marginTop={{
            mobile: 32,
            md: 40,
          }}
        >
          <div className={logosGrids} style={{
            justifyContent: "space-evenly",
            justifyItems: "center",
            alignContent: "space-evenly",
            alignItems: "center"
          }}>
            <div
              className={logoItem}
              onClick={() => navigate("https://www.sella.it/")}
            >
              <StaticImage
                quality={100}
                outputPixelDensities={[1, 2]}
                placeholder="none"
                src="../../images/business-user/sella-black.png"
                alt={t("business-user.investors_section.logos_alt_text.sella")}
              />
            </div>
            <div
              className={logoItem}
              onClick={() => navigate("https://www.azimut-group.com/")}
            >
              <StaticImage
                quality={100}
                outputPixelDensities={[1, 2]}
                placeholder="none"
                src="../../images/business-user/azimut-black.png"
                alt={t("business-user.investors_section.logos_alt_text.azimut")}
              />
            </div>
            <div
              className={logoItem}
            // onClick={() => navigate("https://www.sia.eu/")}
            >
              <StaticImage
                quality={100}
                outputPixelDensities={[1, 2]}
                placeholder="none"
                src="../../images/business-user/united-black.png"
                alt={t("business-user.investors_section.logos_alt_text.united")}
              />
            </div>
          </div>
        </Box>
      </CustomContainer>
    </>
  )
}
