import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import { CardsSection } from "../../components/cards/components/CardsSection"
import { gridBy3 } from "../../components/cards/style/grid-layout.css"
import { SquareCard } from "../../components/cards/components/SquareCard"
import { CardContent } from "../../components/cards/layout/CardContent"
import { CardContainer } from "../../components/cards/layout/CardContainer"
import { Body, Heading, Icon, VStack } from "@youngagency/ui"
import { Avatar } from "../../components/layout"
import { autoHeightCard } from "../../components/cards/style/card.css"
import { customTrustCardPadding } from "./styles/style.css"

export const TrustSection = () => {
  const { t } = useTranslation()

  return (
    <CardsSection>
      <div className={gridBy3}>
        <SquareCard disableHover>
          <CardContainer background="grey10" className={autoHeightCard}>
            <CardContent
              spacing={1}
              width={"full"}
              className={customTrustCardPadding}
            >
              <VStack
                align="center"
                spacing={{
                  mobile: 16,
                  lg: 24,
                }}
              >
                <Avatar
                  shape="circle"
                  width={["56px", "72px"]}
                  height={["56px", "72px"]}
                  background={"var(--white)"}
                >
                  <Icon
                    icon="verifiedFilled"
                    atoms={{
                      width: {
                        mobile: 24,
                        md: 32,
                      },
                      height: {
                        mobile: 24,
                        md: 32,
                      },
                    }}
                  />
                </Avatar>
                <Heading
                  variant="6"
                  atoms={{
                    textAlign: "center",
                  }}
                  fontWeight={600}
                  dangerouslySetInnerHTML={{
                    __html: t("homepage.section_3.card_1.title"),
                  }}
                />
                <Body variant="1" fontWeight={600} color="grey200">
                  {t("homepage.section_3.card_1.description")}
                </Body>
              </VStack>
            </CardContent>
          </CardContainer>
        </SquareCard>
        <SquareCard disableHover>
          <CardContainer background="grey10" className={autoHeightCard}>
            <CardContent
              spacing={1}
              width={"full"}
              className={customTrustCardPadding}
            >
              <VStack
                align="center"
                spacing={{
                  mobile: 16,
                  lg: 24,
                }}
              >
                <Avatar
                  shape="circle"
                  width={["56px", "72px"]}
                  height={["56px", "72px"]}
                  background={"var(--white)"}
                >
                  <Icon
                    icon="securityFilled"
                    atoms={{
                      width: {
                        mobile: 24,
                        md: 32,
                      },
                      height: {
                        mobile: 24,
                        md: 32,
                      },
                    }}
                  />
                </Avatar>
                <Heading
                  variant="6"
                  atoms={{
                    textAlign: "center",
                  }}
                  fontWeight={600}
                  dangerouslySetInnerHTML={{
                    __html: t("homepage.section_3.card_2.title"),
                  }}
                />
                <Body variant="1" fontWeight={600} color="grey200">
                  {t("homepage.section_3.card_2.description")}
                </Body>
              </VStack>
            </CardContent>
          </CardContainer>
        </SquareCard>
        <SquareCard disableHover>
          <CardContainer background="grey10" className={autoHeightCard}>
            <CardContent
              spacing={1}
              width={"full"}
              className={customTrustCardPadding}
            >
              <VStack
                align="center"
                spacing={{
                  mobile: 16,
                  lg: 24,
                }}
              >
                <Avatar
                  shape="circle"
                  width={["56px", "72px"]}
                  height={["56px", "72px"]}
                  background={"var(--white)"}
                >
                  <Icon
                    icon="lockFilled"
                    atoms={{
                      width: {
                        mobile: 24,
                        md: 32,
                      },
                      height: {
                        mobile: 24,
                        md: 32,
                      },
                    }}
                  />
                </Avatar>
                <Heading
                  variant="6"
                  atoms={{
                    textAlign: "center",
                  }}
                  fontWeight={600}
                  dangerouslySetInnerHTML={{
                    __html: t("homepage.section_3.card_3.title"),
                  }}
                />
                <Body variant="1" fontWeight={600} color="grey200">
                  {t("homepage.section_3.card_3.description")}
                </Body>
              </VStack>
            </CardContent>
          </CardContainer>
        </SquareCard>
      </div>
      {/* <div className="trustpilot-widget" style={{ width: "100%", marginTop: "48px" }} data-locale="it-IT" data-template-id="54ad5defc6454f065c28af8b" data-businessunit-id="5fabb308d7384a0001bfb925" data-style-height="240px" data-style-width="100%" data-theme="light" data-stars="5" data-review-languages="it">
        <a href="https://it.trustpilot.com/review/youngplatform.com" target="_blank" rel="noopener">Trustpilot</a>
      </div> */}
    </CardsSection>
  )
}
