import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import CryptoIcons from "../crypto-icons/crypto-icons.component"
import AcademyCarousel from "../shared/academy/AcademyCarousel"
import { Box, Icon, HStack, VStack } from "@youngagency/young-ui"
import ReferralComponents from "./referral.component"
import LogosSection from "./LogosSection"
import { createArrayFromTranslationQuery } from "../../utils/helpers"
import { Container, Avatar, ResponsiveButton, ResponsiveText } from "../layout"
import YP_Pro from "../../images/YP_pro_pittogramma.svg"
import StepIcon from "../../images/YP_step_pittogramma.svg"
import YP_Platform from "../../young-platform.svg"
import { SeoLink } from "../../feature/seo"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { useGetLastAcademyArticleMutation } from "../../redux/services/academy"

const RelativeWrapper = styled(Box)({
  width: "100%",
  maxWidth: "1640px",
  margin: "auto",
  display: "none",
  position: "relative",
  "@media screen and (min-width: 1110px)": {
    display: "block",
  },
})

const CustomBox = styled(Box)({
  marginTop: "48px",
  marginBottom: "0px",
  "@media screen and (min-width: 1110px)": {
    marginTop: "380px",
    marginBottom: "24px",
  },
})

const CustomContainer = styled(Container)({
  paddingBottom: "48px",
  "@media screen and (min-width: 1110px)": {
    paddingBottom: "300px",
  },
})

const StyledBox = styled(Box)`
  & > *:not(:first-of-type) {
    margin-top: 16px;
  }
  @media screen and (min-width: 767px) {
    display: flex;
    & > *:not(:first-of-type) {
      margin-left: 20px;
      margin-top: 0px;
    }
  }
`

const LOGO_IMAGES = [YP_Platform, YP_Pro, StepIcon]

const defaultStringQuery = "whyus_page.claimGroup"

const WhyUsPageComponent = () => {
  const { t, language } = useI18next()

  const testimonialImages = React.useMemo(() => {
    return [
      <StaticImage
        src="./images/placeholder-image1.png"
        alt="Image of Cristian, the reviewer"
        placeholder="blurred"
      />,
      <StaticImage
        src="./images/placeholder-image2.png"
        alt="Image of Slim, the reviewer"
        placeholder="blurred"
      />,
      <StaticImage
        src="./images/placeholder-image3.png"
        alt="Image of Andre, the reviewer"
        placeholder="blurred"
      />,
    ]
  }, [])

  const productsArray = React.useMemo(() => {
    return createArrayFromTranslationQuery(
      t,
      `${defaultStringQuery}.titleText3.cardsGroup.card`,
      3,
      LOGO_IMAGES,
      "icon",
      "title",
      "text"
    )
  }, [t])

  const testimonialCards = React.useMemo(() => {
    return createArrayFromTranslationQuery(
      t,
      `${defaultStringQuery}.titleText6.cardsGroup.card`,
      3,
      testimonialImages,
      "name",
      "text",
      "img"
    )
  }, [t, testimonialImages])

  const [
    getLastAcademyArticle,
    { data, isLoading },
  ] = useGetLastAcademyArticleMutation({
    fixedCacheKey: "shared-last-article",
  })

  const fetchArtilce = React.useCallback(async () => {
    await getLastAcademyArticle(language)
  }, [getLastAcademyArticle, language])

  React.useEffect(() => {
    fetchArtilce()
  }, [fetchArtilce])

  return (
    <Container size="fullwidth" px="24px" mt="72px" mb="0" position="relative">
      <VStack align="center" width="100%">
        <RelativeWrapper>
          <CryptoIcons />
        </RelativeWrapper>
        <Box maxWidth="756px" mx="auto">
          <ResponsiveText
            line={["39px", "96px"]}
            fontWeight={700}
            dimension={["34px", "96px"]}
            as="h1"
            textAlign="center"
          >
            {t(`${defaultStringQuery}.titleText1.title`)}
          </ResponsiveText>
        </Box>
        <Box mt={["24px", "34px"]} maxWidth="505px" mx="auto">
          <ResponsiveText
            fontWeight={500}
            line={["19px", "25px"]}
            dimension={["16px", "20px"]}
            textAlign="center"
            as="h2"
          >
            {t(`${defaultStringQuery}.titleText1.description`)}
          </ResponsiveText>
        </Box>
        <Container size={["fullwidth", "md"]} mt={["24px", "48px"]}>
          <HStack align="center">
            <Icon
              icon="chevron_down"
              style={{
                margin: "auto",
                fontSize: "48px",
              }}
            />
          </HStack>
        </Container>
        <Container size={["fullwidth", "md"]}>
          <Box mt={["48px", "96px"]}>
            <ResponsiveText
              line={["30px", "50px"]}
              fontWeight={700}
              dimension={["24px", "48px"]}
              as="h2"
              textAlign="center"
            >
              {t(`${defaultStringQuery}.titleText3.title`)}
            </ResponsiveText>
          </Box>
          <Box mt={["24px", "24px"]}>
            <ResponsiveText
              line={["19px", "25px"]}
              fontWeight={400}
              dimension={["16px", "20px"]}
              textAlign="center"
              as="p"
            >
              {t(`${defaultStringQuery}.titleText3.description`)}{" "}
            </ResponsiveText>
          </Box>
          <Box mt={["24px", "24px"]} display="flex" justifyContent="center">
            <SeoLink isExternal={false} link="/young-world/">
              <ResponsiveButton
                size="lg"
                variant="primary"
                color="green"
                rightIcon={
                  <Icon icon={t(`${defaultStringQuery}.titleText3.btn`)} />
                }
              >
                {t(`${defaultStringQuery}.titleText3.btn`)}
              </ResponsiveButton>
            </SeoLink>
          </Box>
        </Container>
        <Container size={["fullwidth", "lg"]} mt={["48px", "96px"]}>
          <StyledBox>
            {productsArray.map((product: any) => {
              return (
                <Box
                  width="100%"
                  key={product.title}
                  border="1px solid"
                  borderColor="grey.5"
                  borderRadius="16px"
                  padding="24px"
                >
                  <Avatar width="54px" height="54px" overflow="hidden">
                    <img
                      alt={product.title}
                      src={product.icon}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Avatar>
                  <Box mt={["16px"]}>
                    <ResponsiveText
                      line={["30px"]}
                      fontWeight={600}
                      dimension={["24px"]}
                    >
                      {product.title}
                    </ResponsiveText>
                    <Box mt={["16px"]}>
                      <ResponsiveText line={["20px"]} dimension={["16px"]}>
                        {product.text}
                      </ResponsiveText>
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </StyledBox>
        </Container>

        <Container size={["fullwidth", "md"]}>
          <Box mt={["48px", "96px"]}>
            <ResponsiveText
              line={["30px", "50px"]}
              fontWeight={700}
              dimension={["24px", "48px"]}
              as="h2"
              textAlign="center"
            >
              {t(`${defaultStringQuery}.titleText4.title`)}
            </ResponsiveText>
          </Box>
          <Box mt={["24px", "24px"]}>
            <ResponsiveText
              line={["19px", "25px"]}
              fontWeight={400}
              dimension={["16px", "20px"]}
              textAlign="center"
              as="p"
            >
              {t(`${defaultStringQuery}.titleText4.description`)}
            </ResponsiveText>
          </Box>
        </Container>
        <Box mt={["24px", "34px"]}>
          <LogosSection />
        </Box>
        <Container size={["fullwidth", "md"]}>
          <Box mt={["48px", "96px"]}>
            <ResponsiveText
              line={["30px", "50px"]}
              fontWeight={700}
              dimension={["24px", "48px"]}
              as="h2"
              textAlign="center"
            >
              {t(`${defaultStringQuery}.titleText5.title`)}
            </ResponsiveText>
          </Box>
          <Box mt={["24px", "24px"]}>
            <ResponsiveText
              line={["19px", "25px"]}
              fontWeight={400}
              dimension={["16px", "20px"]}
              textAlign="center"
              as="p"
            >
              {t(`${defaultStringQuery}.titleText5.description`)}{" "}
            </ResponsiveText>
          </Box>
          <Box mt={["24px", "24px"]} display="flex" justifyContent="center">
            <SeoLink
              isExternal
              target="_blank"
              rel="noopener"
              link={`https://academy.youngplatform.com/${language}/`}
            >
              <ResponsiveButton
                size="lg"
                variant="primary"
                color="green"
                rightIcon={
                  <Icon icon={t(`${defaultStringQuery}.titleText5.btn`)} />
                }
              >
                {t(`${defaultStringQuery}.titleText5.btn`)}
              </ResponsiveButton>
            </SeoLink>
          </Box>
        </Container>
        <Container size={["md", "lg"]}>
          <Box mt={["48px", "96px"]}>
            <AcademyCarousel data={data} loading={isLoading} />
          </Box>
        </Container>
        <Container size={["fullwidth", "md"]}>
          <Box mt={["48px", "96px"]}>
            <ResponsiveText
              line={["30px", "50px"]}
              fontWeight={700}
              dimension={["24px", "48px"]}
              as="h2"
              textAlign="center"
            >
              {t(`${defaultStringQuery}.titleText6.title`)}
            </ResponsiveText>
          </Box>
          <Box mt={["24px", "24px"]}>
            <ResponsiveText
              line={["19px", "25px"]}
              fontWeight={400}
              dimension={["16px", "20px"]}
              textAlign="center"
              as="p"
            >
              {t(`${defaultStringQuery}.titleText6.description`)}{" "}
            </ResponsiveText>
          </Box>
          <Box mt={["24px", "24px"]} display="flex" justifyContent="center">
            <SeoLink
              isExternal
              target="_blank"
              rel="noopener"
              link={"https://discord.gg/xsb3Ty2Y8D"}
            >
              <ResponsiveButton
                size="lg"
                variant="primary"
                color="green"
                rightIcon={
                  <Icon icon={t(`${defaultStringQuery}.titleText6.btn`)} />
                }
              >
                {t(`${defaultStringQuery}.titleText6.btn`)}
              </ResponsiveButton>
            </SeoLink>
          </Box>
        </Container>
        <Container size={["fullwidth", "lg"]} mt={["48px", "96px"]}>
          <StyledBox>
            {testimonialCards.map((el: any) => {
              return (
                <Box
                  width="100%"
                  key={el.name}
                  border="1px solid"
                  borderColor="grey.5"
                  borderRadius="16px"
                  padding="24px"
                >
                  <HStack align="center" justify="flex-start" spacing="12px">
                    <Avatar
                      width="44px"
                      height="44px"
                      overflow="hidden"
                      style={{
                        transform: "translateZ(0)",
                      }}
                    >
                      {el.img}
                    </Avatar>
                    <ResponsiveText
                      line={["30px"]}
                      fontWeight={600}
                      dimension={["24px"]}
                    >
                      {el.name}
                    </ResponsiveText>
                  </HStack>
                  <Box mt={["16px"]}>
                    <Box mt={["16px"]}>
                      <ResponsiveText line={["20px"]} dimension={["16px"]}>
                        {el.text}
                      </ResponsiveText>
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </StyledBox>
        </Container>
        <RelativeWrapper>
          <ReferralComponents />
        </RelativeWrapper>
        <CustomContainer size={["fullwidth", "md"]}>
          <CustomBox>
            <ResponsiveText
              line={["30px", "50px"]}
              fontWeight={700}
              dimension={["24px", "48px"]}
              as="h2"
              textAlign="center"
            >
              {t(`${defaultStringQuery}.titleText7.title`)}
            </ResponsiveText>
          </CustomBox>
          <Box mt={["24px", "24px"]}>
            <ResponsiveText
              line={["19px", "25px"]}
              fontWeight={400}
              dimension={["16px", "20px"]}
              textAlign="center"
              as="p"
            >
              {t(`${defaultStringQuery}.titleText7.description`)}
            </ResponsiveText>
          </Box>
          <Box mt={["24px", "24px"]} display="flex" justifyContent="center">
            <SeoLink
              isExternal={false}
              target="_self"
              link={"/talking-about-us/"}
            >
              <ResponsiveButton
                size="lg"
                variant="primary"
                color="green"
                rightIcon={
                  <Icon icon={t(`${defaultStringQuery}.titleText7.btn`)} />
                }
              >
                {t(`team.goals.open_link`)}
              </ResponsiveButton>
            </SeoLink>
          </Box>
        </CustomContainer>
      </VStack>
    </Container>
  )
}

export default WhyUsPageComponent
