import { StaticImage } from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"

const NarrativeCard1Image = React.memo(() => {
  const { language, t } = useI18next()
  switch (language) {
    case "fr":
      return (
        <StaticImage
          quality={100}
          outputPixelDensities={[2]}
          src="../images/fr/narrative-1.png"
          alt={t("clubs_page.narrative_section.card_1.img_alt_text")}
        />
      )
    case "en":
      return (
        <StaticImage
          quality={100}
          outputPixelDensities={[2]}
          src="../images/en/narrative-1.png"
          alt={t("clubs_page.narrative_section.card_1.img_alt_text")}
        />
      )
    default:
      return (
        <StaticImage
          quality={100}
          outputPixelDensities={[2]}
          src="../images/it/narrative-1.png"
          alt={t("clubs_page.narrative_section.card_1.img_alt_text")}
        />
      )
  }
})

const NarrativeCard2Image = React.memo(() => {
  const { language, t } = useI18next()
  switch (language) {
    case "fr":
      return (
        <StaticImage
          quality={100}
          outputPixelDensities={[2]}
          placeholder="blurred"
          src="../images/fr/narrative-2.png"
          alt={t("clubs_page.narrative_section.card_2.img_alt_text")}
        />
      )
    case "en":
      return (
        <StaticImage
          quality={100}
          outputPixelDensities={[2]}
          placeholder="blurred"
          src="../images/en/narrative-2.png"
          alt={t("clubs_page.narrative_section.card_2.img_alt_text")}
        />
      )
    default:
      return (
        <StaticImage
          quality={100}
          outputPixelDensities={[2]}
          placeholder="blurred"
          src="../images/it/narrative-2.png"
          alt={t("clubs_page.narrative_section.card_2.img_alt_text")}
        />
      )
  }
})

export const NarrativeLocalizedImages = {
  card_1: NarrativeCard1Image,
  card_2: NarrativeCard2Image,
}
