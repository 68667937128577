import { OptionType } from "@youngagency/young-ui/dist/src/components/select/shared"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { useConversion } from "../hooks/useConversion"
import { useConversor } from "../hooks/useConversor"
import { CONVERSOR_STATE, reducer } from "../reducer"
import { useReducerActions } from "../reducer/useReducerActions"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { inverseTokenTicker } from "../utils"

export type ConversorEntry = {
  name: string
  symbol: string
  value: number | string
  img?: string | null
}

type ContextProps = CONVERSOR_STATE & {
  handleChange: (param: OptionType | null) => void
  swapConversion: () => void
  changeFromValue: (param: number | string) => void
  handleSubmit: () => void
}

const ConversorContext = React.createContext<null | ContextProps>(null)

type ConversorElement = {
  name: string
  symbol: string
}

export type ProviderProps = {
  children: React.ReactNode
  token: ConversorElement
  fiat: ConversorElement
}

export const ConversorProvider: React.FC<ProviderProps> = ({
  children,
  token,
  fiat,
}) => {
  const { conversorDirection } = useSelector(
    (state: RootState) => state.uiStyle
  )
  const { language } = useI18next()
  const data = useStaticQuery(query) as Queries.TokenConversorQuery

  const { initialConverorState, initialLists, staticTokenList } = useConversor({
    token,
    fiat,
    allAvailableFiat: data.allAvailableFiat,
    allToken: data.allToken,
  })

  const initialState = React.useMemo(
    (): CONVERSOR_STATE => ({
      direction: "token_fiat",
      conversorValue: initialConverorState,
      conversorList: initialLists,
      conversorCta: "convert",
    }),
    [initialConverorState, initialLists]
  )

  const [state, dispatch] = React.useReducer(reducer, initialState)

  const {
    handleChangeDirections,
    handleConversionAction,
    handleNullConversion,
    hanldeFromInputChange,
    switchConversorInput,
    handleConversorCtaType,
  } = useReducerActions(dispatch, state)

  const { handleConversion, initialConversion } = useConversion({
    tokenSymbol: token.symbol,
    fiatSymbol: fiat.symbol,
    handleConversionAction,
    handleNullConversion,
  })

  const handleSubmit = React.useCallback(() => {
    handleConversion(state.conversorValue.from.value)
    handleConversorCtaType("buy")
  }, [handleConversion, state.conversorValue.from.value])

  const handleChange = React.useCallback(
    (item: OptionType | null) => {
      if (!item) return
      const isSwitchingPair = staticTokenList.find(
        el => el.label.toLowerCase() === item.label.toLowerCase()
      )
      const tokenTickerFurbo = {
        item: item?.subtitle?.toLowerCase(),
        token: inverseTokenTicker(token.symbol).toLowerCase(),
      }
      let link = language === "it" ? "exchange" : `${language}/exchange`
      if (isSwitchingPair) {
        link = `/${link}/${tokenTickerFurbo.item}-${fiat.symbol.toLowerCase()}/`
      } else {
        link = `/${link}/${
          tokenTickerFurbo.token
        }-${item!.subtitle!.toLowerCase()}/`
      }
      navigate(link)
    },
    [fiat.symbol, token.symbol, staticTokenList, language]
  )

  const swapConversion = React.useCallback(() => {
    const newDirection =
      state.direction === "token_fiat" ? "fiat_token" : "token_fiat"
    handleChangeDirections(newDirection)
    switchConversorInput()
    handleConversorCtaType()
  }, [
    handleChangeDirections,
    state.direction,
    switchConversorInput,
    handleConversorCtaType,
  ])

  React.useEffect(() => {
    if (conversorDirection === "fiat_token" && initialConversion) {
      switchConversorInput()
      handleChangeDirections(conversorDirection)
    }
  }, [initialConversion])

  const ctx = React.useMemo(
    () => ({
      ...state,
      handleChange,
      swapConversion,
      changeFromValue: hanldeFromInputChange,
      handleSubmit,
    }),
    [state, handleChange, swapConversion, hanldeFromInputChange, handleSubmit]
  )

  return (
    <ConversorContext.Provider value={ctx}>
      {children}
    </ConversorContext.Provider>
  )
}

export const useConversorContext = () => React.useContext(ConversorContext)

const query = graphql`query TokenConversor {
  allToken(
    sort: {details: {marketCap: DESC}}
    filter: {id: {nin: ["EUR", "USD", "GBP"]}}
  ) {
    nodes {
      symbol
      name
      details {
        image
      }
    }
  }
  allAvailableFiat(filter: {symbol: {ne: "GBP"}}) {
    nodes {
      symbol
    }
  }
}`
