const generateTimeStampByDay = (num: number) =>
  Date.now() - 1000 * 60 * 60 * 24 * num

export const ATH_ATV_timeframes = {
  day: generateTimeStampByDay(1),
  week: generateTimeStampByDay(7),
  years2: generateTimeStampByDay(365 * 2),
}

export const BODY_TOKEN_PAGES_DYNAMIC_LINK =
  "https://youngexchange.page.link/stpgb"
export const HEADER_TOKEN_PAGES_DYNAMIC_LINK =
  "https://youngexchange.page.link/stpgm"
