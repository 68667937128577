import { StaticImage } from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { SupportedLanguageValue } from "../../../../types/footer"

type ImagesObj = {
  [x in SupportedLanguageValue]: JSX.Element
}

const proImages: ImagesObj = {
  en: <StaticImage alt="Young Platform App" src="../images/young_pro.png" />,
  it: <StaticImage alt="Young Platform App" src="../images/young_pro.png" />,
  fr: <StaticImage alt="Young Platform App" src="../images/young_pro.png" />,
}

const baseImages: ImagesObj = {
  en: <StaticImage alt="Young Platform App" src="../images/eng_yp_base.png" />,
  it: <StaticImage alt="Young Platform App" src="../images/ita_yp_base.png" />,
  fr: <StaticImage alt="Young Platform App" src="../images/fr_yp_base.png" />,
}

const stepImages: ImagesObj = {
  en: <StaticImage alt="Young Platform App" src="../images/step_eng.png" />,
  it: <StaticImage alt="Young Platform App" src="../images/step_ita.png" />,
  fr: <StaticImage alt="Young Platform App" src="../images/step_fr.png" />,
}

const academyImages: ImagesObj = {
  en: <StaticImage alt="Young Platform App" src="../images/academy_eng.png" />,
  it: <StaticImage alt="Young Platform App" src="../images/academy_ita.png" />,
  fr: <StaticImage alt="Young Platform App" src="../images/academy_fr.png" />,
}

export const useLocalizedImage = () => {
  const { language } = useI18next()
  const localizedImage = React.useMemo(
    () => ({
      exchange_base: baseImages[language as SupportedLanguageValue],
      exchange_pro: proImages[language as SupportedLanguageValue],
      step: stepImages[language as SupportedLanguageValue],
      academy: academyImages[language as SupportedLanguageValue],
    }),
    [language]
  )

  return localizedImage
}
