import { Box } from "@youngagency/young-ui"
import { StaticImage } from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import styled from "styled-components"
import { SeoLink } from "../../seo"
import { ResponsiveText, ResponsiveButton } from "../../../components/layout"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { globalEvents } from "../../../service/tracking"

const CustomStack = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > *:not(:first-of-type) {
    margin-top: 28px;
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row-reverse;
    & > *:not(:last-of-type) {
      margin-left: 117px;
      margin-top: 0px;
    }
    .text-box {
      max-width: 465px;
    }
  }
`

export const TokenHomeComponent = () => {
  const { t } = useI18next()
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)

  return (
    <CustomStack>
      <Box className="text-box">
        <Box>
          <ResponsiveText
            line={["39px", "50px"]}
            dimension={["34px", "48px"]}
            textAlign={["center", "left"]}
            fontWeight={700}
            as="h2"
          >
            {t("homeBanner.token.title")}
          </ResponsiveText>
        </Box>
        <Box mt={["16px", "24px"]}>
          <ResponsiveText
            line={["20px", "25px"]}
            dimension={["16px", "20px"]}
            textAlign={["center", "left"]}
            as="p"
          >
            {t("homeBanner.token.description")}
          </ResponsiveText>
        </Box>
        <Box
          mt={["16px", "24px"]}
          display="flex"
          justifyContent={["center", "flex-start"]}
        >
          <SeoLink isExternal={false} link={"/token-yng/clubs/"}>
            <ResponsiveButton
              size="lg"
              onClick={() => {
                if(gaTracker){
                  gaTracker.trackEvent({
                    eventName: globalEvents.click_cta,
                    payload: {
                      content: "inizia_da_qui_home",
                    }
                  })
                }
              }}
            >
              {t("homeBanner.token.cta")}
            </ResponsiveButton>
          </SeoLink>
        </Box>
      </Box>
      <Box
        maxWidth={["344px", "562px"]}
        borderRadius="50%"
        width="100%"
        mx={["auto", "inherit"]}
        overflow="hidden"
        style={{
          transform: "translateZ(0)",
        }}
      >
        <StaticImage
          alt={t("homeBanner.token.title")}
          src="../clubs/images/hero.png"
          placeholder="dominantColor"
        />
      </Box>
    </CustomStack>
  )
}

export default TokenHomeComponent
