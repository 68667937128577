import React from "react"
import { useTranslation } from "react-i18next"
import { BTCBanner } from "../../../../components/banner/ecosistema/BTCBanner"
import { Button, VStack } from "@youngagency/ui"
import { Box as LegacyBox } from "@youngagency/young-ui"
import "./banner.css"
import { Text } from "../../../../components/texts/temporary"
import { navigate } from "gatsby"
import { useSelector } from "react-redux"
import {
  b2bPrivateUserEvents,
  globalEvents,
} from "../../../../service/tracking"
import { RootState } from "../../../../redux/store"
import { typeformWhiteGloves } from "../../constants"
import { DarkBanner } from "../../../../components/banner/ecosistema/DarkBanner"
import { useHandleExternalPersistUtm } from "../../../navigation/hooks/useHandlePersistUtm"

export const BannerSection = () => {
  const { t } = useTranslation()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)
  const { handleExternalPersistUtmCallback } = useHandleExternalPersistUtm()

  const handleClick = React.useCallback(() => {
    gaTracker?.trackMultipleEvents([
      {
        eventName: b2bPrivateUserEvents.b2b_private_footer,
      },
      {
        eventName: globalEvents.contact_sales,
      },
    ])
    handleExternalPersistUtmCallback({
      defaultLink: typeformWhiteGloves,
      callback: () => navigate(typeformWhiteGloves),
    })
  }, [gaTracker, handleExternalPersistUtmCallback])
  return (
    <DarkBanner withImage background="grey700" justifyContent="center">
      <LegacyBox maxWidth="700px" zIndex={1}>
        <VStack
          spacing={24}
          align="center"
          justify="center"
          atoms={{
            width: "full",
            mx: "auto",
            __zIndex: 1,
          }}
        >
          <Text.H5
            color="white"
            fontWeight={600}
            textAlign="center"
            dangerouslySetInnerHTML={{
              __html: t("business_user.footer.title"),
            }}
            padding={"0 32px"}
          />

          <Button
            size={48}
            colorScheme="green"
            atoms={{
              __zIndex: 1,
            }}
            onClick={handleClick}
          >
            {t("business_user.footer.button")}
          </Button>
        </VStack>
      </LegacyBox>
    </DarkBanner>
  )
}
