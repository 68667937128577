import React from "react"
import { Helmet } from "react-helmet"
import {
  useWebPageSchema,
  useDefinedTermSchema,
  useBreadcrumbSchema,
} from "../hooks"
import { breadCrumbProps, DefinedTermProps } from "../types"

type Props = DefinedTermProps & {
  breadcrumbs: breadCrumbProps[]
}

export const DefinedTermSchema = ({
  name,
  description,
  breadcrumbs,
}: Props) => {
  const schema = useWebPageSchema({
    title: name,
    description: description,
  })

  const breadcrumbsSchema = useBreadcrumbSchema(breadcrumbs)

  const termSchema = useDefinedTermSchema({
    name,
    description,
  })

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          ...schema,
          ["@graph"]: [...schema["@graph"], breadcrumbsSchema, termSchema],
        })}
      </script>
    </Helmet>
  )
}

export default DefinedTermSchema
