import { Box, BoxProps, Heading, HStack, Icon } from "@youngagency/young-ui"
import React from "react"
import styled from "styled-components"
import { SeoLink } from "../../feature/seo"
import { Avatar } from "../layout"
import { useLocation } from "@reach/router"

type Props = {
  page: number
  numOfPages: number
  maxVisiblePages?: number
  link: string
}

const PageBox = styled(Avatar)<{ isActive?: boolean }>(({ isActive }) => ({
  background: isActive ? "var(--r-green-300)" : "unset",
  color: isActive ? "white" : "#000",
  ":hover": {
    background: isActive ? "var(--r-green-300)" : "var(--r-grey-3)",
  },
}))

export const Paginator = ({
  page,
  link,
  numOfPages,
  maxVisiblePages = 2,
  ...rest
}: Props & BoxProps) => {
  const location = useLocation()
  const totalPages = Array.from(
    { length: Number(numOfPages) },
    (_, index) => index + 1
  )

  const prevPageLink = React.useMemo(() => {
    if (page - 1 > 1) {
      return `/${link}/${page - 1}/`
    }
    return `/${link}/`
  }, [])

  const nextPageLink = React.useMemo(() => {
    return `/${link}/${page + 1}/`
  }, [])

  const spliceStart = React.useMemo(() => {
    if (numOfPages > maxVisiblePages) {
      const start = page < maxVisiblePages ? 0 : page - 1
      const end =
        page > numOfPages - maxVisiblePages
          ? numOfPages - maxVisiblePages
          : page - 1
      return start > end ? end : start
    }
    return 0
  }, [])

  const partialArray = React.useMemo(() => {
    return [...totalPages].splice(spliceStart, maxVisiblePages)
  }, [totalPages, spliceStart])
  if (numOfPages <= maxVisiblePages) {
    return (
      <Box {...rest}>
        <HStack spacing="4px" justify="center" align="center">
          {totalPages.map((value, index) => {
            return (
              <SeoLink
                key={"paginator-deafult-box" + index}
                isExternal={false}
                link={value === 1 ? `/${link}/` : `/${link}/${value}/`}
                state={location.state as unknown}
              >
                <PageBox
                  width="36px"
                  height="36px"
                  shape="circle"
                  isActive={page === value}
                >
                  <Heading size="sm" fontWeight={600} fontFamily="Matter">
                    {value}
                  </Heading>
                </PageBox>
              </SeoLink>
            )
          })}
        </HStack>
      </Box>
    )
  }
  return (
    <Box {...rest}>
      <HStack spacing="4px" justify="center" align="center">
        {page > 1 ? (
          <SeoLink
            isExternal={false}
            link={prevPageLink}
            state={location.state as unknown}
          >
            <PageBox width="36px" height="36px" shape="circle">
              <Icon icon="chevron_left" fontSize="24px" />
            </PageBox>
          </SeoLink>
        ) : null}
        {page >= maxVisiblePages ? (
          <React.Fragment key={"first-paginator"}>
            <SeoLink
              isExternal={false}
              link={`/${link}/`}
              state={location.state as unknown}
            >
              <PageBox
                width="36px"
                height="36px"
                shape="circle"
                isActive={page === totalPages[0]}
              >
                <Heading size="sm" fontWeight={600} fontFamily="Matter">
                  {totalPages[0]}
                </Heading>
              </PageBox>
            </SeoLink>
            {page > maxVisiblePages ? (
              <Avatar shape="circle" width="36px" height="36px" mr="4px">
                ...
              </Avatar>
            ) : null}
          </React.Fragment>
        ) : null}
        {partialArray.map((value, index) => {
          return (
            <SeoLink
              key={"paginator-box" + index}
              state={location.state as unknown}
              isExternal={false}
              link={value === 1 ? `/${link}/` : `/${link}/${value}/`}
            >
              <PageBox
                width="36px"
                height="36px"
                shape="circle"
                isActive={page === value}
              >
                <Heading size="sm" fontWeight={600} fontFamily="Matter">
                  {value}
                </Heading>
              </PageBox>
            </SeoLink>
          )
        })}
        {page + maxVisiblePages <= totalPages[totalPages.length - 1] ? (
          <React.Fragment key={"last-pagiator"}>
            {page + maxVisiblePages < totalPages[totalPages.length - 1] ? (
              <Avatar shape="circle" width="36px" height="36px" ml="4px">
                ...
              </Avatar>
            ) : null}

            <SeoLink
              state={location.state as unknown}
              isExternal={false}
              link={`/${link}/${totalPages[totalPages.length - 1]}/`}
            >
              <PageBox
                width="36px"
                height="36px"
                shape="circle"
                isActive={page === totalPages[totalPages.length - 1]}
              >
                <Heading size="sm" fontWeight={600} fontFamily="Matter">
                  {totalPages[totalPages.length - 1]}
                </Heading>
              </PageBox>
            </SeoLink>
          </React.Fragment>
        ) : null}
        {page !== totalPages[totalPages.length - 1] ? (
          <SeoLink
            state={location.state as unknown}
            isExternal={false}
            link={nextPageLink}
          >
            <PageBox width="36px" height="36px" shape="circle">
              <Icon icon="chevron_right" fontSize="24px" />
            </PageBox>
          </SeoLink>
        ) : null}
      </HStack>
    </Box>
  )
}
