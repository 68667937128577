import React from "react"
import {
  useWebPageSchema,
  useBreadcrumbSchema,
  useEventsSchema,
} from "../hooks"
import { breadCrumbProps, EventSchemaProps } from "../types"

type Props = EventSchemaProps & {
  breadcrumbs: breadCrumbProps[]
}

export const EventSchema = ({
  theme,
  description,
  title,
  startDate,
  image,
  breadcrumbs,
}: Props) => {
  const schema = useWebPageSchema({
    title,
    description,
    image,
    publishDate: startDate,
  })

  const breadcrumbSchema = useBreadcrumbSchema(breadcrumbs)

  const eventSchema = useEventsSchema({
    theme,
    description,
    title,
    startDate,
    image,
  })

  return (
    <script type="application/ld+json">
      {JSON.stringify({
        ...schema,
        ["@graph"]: [...schema["@graph"], breadcrumbSchema, eventSchema],
      })}
    </script>
  )
}
