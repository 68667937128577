import { configureStore, combineReducers, Middleware } from "@reduxjs/toolkit"
import { persistStore } from "redux-persist"
import authReducer from "./reducers/authSlice"
import settingsReducer from "./reducers/exchangeSettingSlice"
import { singleTokenApi } from "./services"
import { settingsApi } from "./services/settings"
import { academyApi } from "./services/academy"
import { currencyApi } from "./services/currency/api"
import { pillsApi } from "./services/pills/api"
import { cryptoCompareApi } from "./services/crypto-compare/api"
import uiSlice from "./reducers/uiSlice"
import promoSlice from "./reducers/promoSlice"
import trackingSlice, { initTracker } from "./reducers/trackingSlice"
// import { brazeApi } from "../service/tracking/api/braze/api"
import { brazeApiGCF } from "../service/tracking/api/braze/api-gcf"
import { chartApi } from "../service/charts/api/api"
//LANDING REDUCER
const rootReducer = combineReducers({
  auth: authReducer,
  exchangeSettings: settingsReducer,
  promo: promoSlice,
  uiStyle: uiSlice,
  tracking: trackingSlice,
  [singleTokenApi.reducerPath]: singleTokenApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [academyApi.reducerPath]: academyApi.reducer,
  [currencyApi.reducerPath]: currencyApi.reducer,
  [pillsApi.reducerPath]: pillsApi.reducer,
  [cryptoCompareApi.reducerPath]: cryptoCompareApi.reducer,
  [brazeApiGCF.reducerPath]: brazeApiGCF.reducer,
  [chartApi.reducerPath]: chartApi.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["persist/PERSIST", initTracker().type],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["gaTracker"],
        // Ignore these paths in the state
        ignoredPaths: ["tracking.gaTracker"],
      },
    }).concat(
      singleTokenApi.middleware,
      settingsApi.middleware,
      academyApi.middleware,
      currencyApi.middleware,
      pillsApi.middleware,
      cryptoCompareApi.middleware,
      brazeApiGCF.middleware,
      chartApi.middleware
    )
  },
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const dispatcher = store.dispatch as AppDispatch
// function select(state: RootState) {
//   return state.auth.authorization
// }

// function listener(): void {
//   let token = select(store.getState())
//   instance.defaults.headers.common["Authorization"] = `Bearer ${token}`
//   pollingInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`
// }

// store.subscribe(listener)
