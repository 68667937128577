import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Body, Box, Container, Heading, VStack } from "@youngagency/ui"
import { Container as CustomContainer } from "../../../../components/layout"
import { vanityGrid, vanityGridItem } from "./b2b.css"
import { ResponsiveText } from "../../../../components/layout"

const VanityBox: React.FC<{ card_mumber: number }> = ({ card_mumber }) => {
  const { t } = useTranslation()

  const texts = React.useMemo(
    () => ({
      title: t(`clubs_page.b2b.vanity_section.card_${card_mumber}.data`),
      label: t(`clubs_page.b2b.vanity_section.card_${card_mumber}.label`),
    }),
    [card_mumber, t]
  )
  return (
    <Box className={vanityGridItem}>
      <VStack
        spacing={16}
        atoms={{
          width: "full",
        }}
      >
        <ResponsiveText
          line={["72px", "72px"]}
          dimension={["64px", "64px"]}
          fontWeight={600}
        >
          {texts.title}
        </ResponsiveText>

        <ResponsiveText
          line={["24px", "24px"]}
          dimension={["16px", "16px"]}
          color="var(--grey-200-v2)"
          fontWeight={600}
          style={{
            textTransform: "uppercase",
          }}
        >
          {texts.label}
        </ResponsiveText>
      </VStack>
    </Box>
  )
}

export const ResultSection = () => {
  const { t } = useTranslation()

  return (
    <CustomContainer as="section">
      <Container>
        <VStack
          spacing={24}
          align="center"
          justify="center"
          atoms={{
            width: "full",
            mx: "auto",
          }}
        >
          <Heading
            variant="3"
            atoms={{
              textAlign: "center",
            }}
            fontWeight={400}
            dangerouslySetInnerHTML={{
              __html: t("clubs_page.b2b.vanity_section.title"),
            }}
          />
          <Body
            variant="1"
            atoms={{
              textAlign: "center",
            }}
            color="grey200"
            style={{
              maxWidth: "700px",
            }}
            fontWeight={600}
          >
            {t("clubs_page.b2b.vanity_section.description")}
          </Body>
          <Box
            marginTop={{
              mobile: 32,
              md: 40,
            }}
          >
            <div className={vanityGrid}>
              <VanityBox card_mumber={1} />
              <VanityBox card_mumber={2} />
              <VanityBox card_mumber={3} />
              <VanityBox card_mumber={4} />
              <VanityBox card_mumber={5} />
              <VanityBox card_mumber={6} />
            </div>
          </Box>
        </VStack>
      </Container>
    </CustomContainer>
  )
}
