import {
  Box,
  css,
  HStack,
  Icon,
  SearchInput,
  Button,
  useMediaQuery,
} from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { ResponsiveText } from "../../../components/layout"
import { SeoLink } from "../../seo"
import { Formik, Form, Field } from "formik"
import { SearchBox } from "./SearchBox"
import styled from "styled-components"
import usePopper from "../../navigation/hooks/usePopper"
import { disableScroll } from "../../../utils/general"

type Props = {
  type: "listed" | "all"
}

const FormContainer = styled(Box)<{ isMobileSearch: boolean }>(
  ({ isMobileSearch }) => ({
    position: isMobileSearch ? "fixed" : "inherit",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    background: isMobileSearch ? "rgba(0, 0, 0, 0.5)" : "transparent",
    height: isMobileSearch ? "100vh" : "auto",
    overflowY: isMobileSearch ? "hidden" : "visible",
    ".form-child": {
      marginTop: isMobileSearch ? "90px" : "unset",
      width: isMobileSearch ? "calc( 100% - 32px )" : "auto",
      marginLeft: isMobileSearch ? "auto" : "unset",
      marginRight: isMobileSearch ? "auto" : "unset",
    },
  })
)

export const TokenListHero = ({ type }: Props) => {
  const { t } = useI18next()
  const { isMobile } = useMediaQuery()
  const [isFocused, setIsFocused] = React.useState<boolean>(false)

  const isMobileSearch = React.useMemo(() => {
    return isFocused && isMobile
  }, [isFocused, isMobile])

  const { show } = usePopper({
    referenceID: "search-form",
    tooltipID: "search-box",
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 26],
        },
      },
    ],
    events: {
      open: ["focus", "click", "mousedown"],
      close: [""],
    },
  })

  const initialValues = React.useMemo(
    () => ({
      search: "",
    }),
    []
  )

  const handleFocus = React.useCallback(() => {
    setIsFocused(true)
    show()
    if (isMobile) disableScroll()
  }, [isMobile])

  const handleBlur = React.useCallback(() => {
    setIsFocused(false)
    if (isMobile) disableScroll()
  }, [isMobile])
  return (
    <Box>
      <ResponsiveText
        line={["39px", "50px"]}
        dimension={["34px", "48px"]}
        textAlign={["left", "center"]}
        fontWeight={600}
        as="h1"
      >
        {t("markets.title")}
      </ResponsiveText>
      {/* <Box mt={["16px", "24px"]} maxWidth="756px" mx={["unset", "auto"]}>
        <ResponsiveText
          line={["24px", "25px"]}
          dimension={["16px", "20px"]}
          textAlign={["left", "center"]}
        >
          {t("markets.description")}
        </ResponsiveText>
      </Box> */}
      <FormContainer
        isMobileSearch={isMobileSearch}
        mt="24px"
        mx={["unset", "auto"]}
        maxWidth={["unset", "366px"]}
      >
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          <Form className="form-child">
            <Field
              as={SearchInput}
              name="search"
              type="text"
              id="search-form"
              autoComplete="off"
              onClick={handleFocus}
              onBlur={handleBlur}
            />
            <SearchBox />
          </Form>
        </Formik>
      </FormContainer>
      <HStack mt="48px">
        <SeoLink
          isExternal={false}
          link={"/exchange/"}
          className={
            type === "all"
              ? "label-token-link-active label-token-btn noselect"
              : "noselect label-token-btn"
          }
        >
          <Button
            variant="secondary"
            style={{
              background: "inherit",
              border: "none",
            }}
            size="xs"
            leftIcon={<Icon icon="global" />}
          >
            {t("markets.labels.all_token")}
          </Button>
        </SeoLink>
        <SeoLink
          isExternal={false}
          link={"/exchange/listed/"}
          className={
            type === "listed"
              ? "label-token-link-active label-token-btn noselect"
              : "noselect label-token-btn"
          }
        >
          <Button
            variant="secondary"
            style={{
              background: "inherit",
              border: "none",
            }}
            size="xs"
            leftIcon={<Icon icon="linechart" />}
          >
            {t("markets.labels.listed")}
          </Button>
        </SeoLink>
      </HStack>
    </Box>
  )
}
