import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import {
  Body,
  Box,
  Container,
  Heading,
  VStack,
  HStack,
  Button,
  Subtitle,
  Icon,
} from "@youngagency/ui"
import { Box as LegacyBox } from "@youngagency/young-ui"
import { Container as CustomContainer } from "../../../../components/layout"
import {
  CardContent,
  CardGrid,
  CardImage,
  CardTexts,
} from "../../../../components/cards"
import { Card } from "../../../../components/cards"
import { StaticImage } from "gatsby-plugin-image"
import { gridLeft, gridRight } from "../home/b2b.css"
import { navigate } from "@reach/router"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import {
  b2bPrivateUserEvents,
  globalEvents,
} from "../../../../service/tracking"
import { typeformWhiteGloves } from "../../constants"
import { useHandleExternalPersistUtm } from "../../../navigation/hooks/useHandlePersistUtm"
export const PreviewSection = () => {
  const { t } = useTranslation()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)
  const { handleExternalPersistUtmCallback } = useHandleExternalPersistUtm()

  const handleClick = React.useCallback(() => {
    gaTracker?.trackMultipleEvents([
      {
        eventName: b2bPrivateUserEvents.b2b_private_body_btn,
      },
      {
        eventName: globalEvents.contact_sales,
      },
    ])
    handleExternalPersistUtmCallback({
      defaultLink: typeformWhiteGloves,
      callback: () => navigate(typeformWhiteGloves),
    })
  }, [gaTracker, handleExternalPersistUtmCallback])
  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              fontWeight={400}
              dangerouslySetInnerHTML={{
                __html: t("business_user.priority_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              color="grey200"
              style={{
                maxWidth: "700px",
              }}
              fontWeight={600}
            >
              {t("business_user.priority_section.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>
      <Box
        marginTop={{
          mobile: 32,
          md: 40,
        }}
      >
        <VStack
          spacing={"unset"}
          align="center"
          justify="center"
          atoms={{
            __columnGap: "1rem",
            width: "full",
            mx: "auto",
          }}
        >
          <CardGrid>
            <div className={gridRight}>
              <Card
                isAnimated
                hasShadow
                background="var(--white)"
                orientation="column"
                disableHover
              >
                <CardContent>
                  <CardTexts>
                    <Heading as="h3" variant="6" fontWeight={600}>
                      {t("business_user.priority_section.cards.card_1.title")}
                    </Heading>
                    <Body
                      variant="1"
                      atoms={{
                        __color: "var(--grey-200-v2)",
                      }}
                      fontWeight={600}
                    >
                      {t(
                        "business_user.priority_section.cards.card_1.description"
                      )}
                    </Body>
                  </CardTexts>
                </CardContent>
                <CardImage px={["24px", "48px"]}>
                  <StaticImage
                    quality={100}
                    outputPixelDensities={[1, 2]}
                    src="../../images/business-user/preview_1.png"
                    alt={t(
                      "business_user.priority_section.cards.card_1.img_alt_text"
                    )}
                  />
                </CardImage>
              </Card>
              <Card isAnimated hasShadow orientation="column" disableHover>
                <CardContent>
                  <CardTexts>
                    <Heading as="h3" variant="6" color="white" fontWeight={600}>
                      {t("business_user.priority_section.cards.card_2.title")}
                    </Heading>
                    <Body
                      variant="1"
                      atoms={{
                        __color: "var(--text-light)",
                      }}
                      fontWeight={600}
                    >
                      {t(
                        "business_user.priority_section.cards.card_2.description"
                      )}
                    </Body>
                  </CardTexts>
                </CardContent>
                <CardImage height="100%">
                  <StaticImage
                    quality={100}
                    outputPixelDensities={[1, 2]}
                    style={{
                      width: "100%",
                    }}
                    src="../../images/business-user/preview_2.jpg"
                    alt={t(
                      "business_user.priority_section.cards.card_2.img_alt_text"
                    )}
                  />
                </CardImage>
              </Card>
            </div>
            <div className={gridLeft}>
              <Card
                isAnimated
                hasShadow
                mt="1rem"
                background="var(--white)"
                orientation="column"
                disableHover
              >
                <CardContent>
                  <CardTexts>
                    <Heading as="h3" variant="6" fontWeight={600}>
                      {t("business_user.priority_section.cards.card_3.title")}
                    </Heading>
                    <Body
                      variant="1"
                      atoms={{
                        __color: "var(--grey-200-v2)",
                      }}
                      fontWeight={600}
                    >
                      {t(
                        "business_user.priority_section.cards.card_3.description"
                      )}
                    </Body>
                  </CardTexts>
                  <VStack
                    spacing={24}
                    atoms={{
                      marginTop: {
                        mobile: 24,
                        md: 56,
                      },
                    }}
                  >
                    <VStack spacing={16}>
                      <HStack spacing={16}>
                        <Icon
                          icon="checkCircleFilled"
                          color="green200"
                          size={24}
                        />
                        <Subtitle variant="2" fontWeight={600}>
                          {t(
                            "business_user.priority_section.cards.card_3.line_1.title"
                          )}
                        </Subtitle>
                      </HStack>
                      <Body variant="1" fontWeight={600} color="grey200">
                        {t(
                          "business_user.priority_section.cards.card_3.line_1.description"
                        )}
                      </Body>
                    </VStack>
                    <VStack spacing={16}>
                      <HStack spacing={16}>
                        <Icon
                          icon="checkCircleFilled"
                          color="green200"
                          size={24}
                        />
                        <Subtitle variant="2" fontWeight={600}>
                          {t(
                            "business_user.priority_section.cards.card_3.line_2.title"
                          )}
                        </Subtitle>
                      </HStack>
                      <Body variant="1" fontWeight={600} color="grey200">
                        {t(
                          "business_user.priority_section.cards.card_3.line_2.description"
                        )}
                      </Body>
                    </VStack>
                    <VStack spacing={16}>
                      <HStack spacing={16}>
                        <Icon
                          icon="checkCircleFilled"
                          color="green200"
                          size={24}
                        />
                        <Subtitle variant="2" fontWeight={600}>
                          {t(
                            "business_user.priority_section.cards.card_3.line_3.title"
                          )}
                        </Subtitle>
                      </HStack>
                      <Body variant="1" fontWeight={600} color="grey200">
                        {t(
                          "business_user.priority_section.cards.card_3.line_3.description"
                        )}
                      </Body>
                    </VStack>
                  </VStack>
                </CardContent>
              </Card>
              <Card
                isAnimated
                hasShadow
                mt={["unset", "1rem"]}
                background="var(--blue-400-v2)"
                orientation="column"
                disableHover
              >
                <CardContent>
                  <CardTexts>
                    <Heading as="h3" variant="6" color="white" fontWeight={600}>
                      {t("business_user.priority_section.cards.card_4.title")}
                    </Heading>
                    <Body
                      variant="1"
                      atoms={{
                        __color: "var(--text-light)",
                      }}
                      fontWeight={600}
                    >
                      {t(
                        "business_user.priority_section.cards.card_4.description"
                      )}
                    </Body>
                  </CardTexts>
                </CardContent>
                <CardImage>
                  <StaticImage
                    quality={100}
                    outputPixelDensities={[1, 2]}
                    src="../../images/business-user/preview_4.png"
                    style={{
                      width: "95%",
                    }}
                    alt={t(
                      "business_user.priority_section.cards.card_4.img_alt_text"
                    )}
                  />
                </CardImage>
              </Card>
            </div>
          </CardGrid>
        </VStack>
      </Box>
      <CustomContainer mt={["48px", "96px"]} size={["md", "lg"]}>
        <LegacyBox maxWidth={"700px"} mx="auto">
          <Heading
            variant="3"
            fontWeight={600}
            atoms={{
              textAlign: "center",
              mx: "auto",
            }}
            as="h2"
            dangerouslySetInnerHTML={{
              __html: t("business_user.priority_section.cta.title"),
            }}
          />
        </LegacyBox>
        <LegacyBox mt={["16px", "24px"]} maxWidth={"700px"} mx="auto">
          <Body
            variant="1"
            fontWeight={600}
            color="grey200"
            atoms={{
              textAlign: "center",
            }}
          >
            {t("business_user.priority_section.cta.description")}
          </Body>
        </LegacyBox>
        <HStack
          spacing={"unset"}
          atoms={{
            marginTop: [16, 24],
            mx: "auto",
          }}
          justify="center"
        >
          <Button colorScheme="green" size={48} onClick={handleClick}>
            {t("business_user.priority_section.cta.button")}
          </Button>
        </HStack>
      </CustomContainer>
    </>
  )
}
