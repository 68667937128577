import { Box, Icon } from "@youngagency/young-ui"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import {
  CardTexts,
  Card,
  CardButton,
  CardContent,
  CardImage,
  CardStack,
} from "../../components/cards"
import { Text } from "../../components/texts/temporary"
import { useLocalizedNavigate } from "../../hook/useLocalizedNavigate"
import { SeoLink } from "../seo"
import { RumpUpImage } from "./LocalizedImaged"
import { useHandlePersistUtm } from "../navigation/hooks/useHandlePersistUtm"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { globalEvents, homePageEvents } from "../../service/tracking"


export const RumpUpSection = () => {
  const { t } = useTranslation()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  const navigateToExchange = useLocalizedNavigate()
  const { handlePersistUtmCallback } = useHandlePersistUtm()

  return (
    <>
      <SeoLink
        data-role="card"
        link="/exchange/"
        isExternal={false}
        onClick={() => {
          if (gaTracker)
            gaTracker.trackEvent({
              eventName: homePageEvents["home_body_rump-up"],
              payload: {
                card_clicked: 1,
              },
            })
        }}
        style={{
          transform: "translateY(200px) !important",
        }}
      >
        <Card
          isAnimated
          mt="40px"
          backgroundColor="green.300"
          invertBtn
          orientation="row"
          hasShadow
        >
          <CardContent>
            <CardTexts>
              <Text.H6 fontWeight={600} as="h3" color="white">
                {t("homepage.section_1.card_1.title")}
              </Text.H6>
              <Text.Body1 fontWeight={600} color="var(--text-light)" as="p">
                {t("homepage.section_1.card_1.description")}
              </Text.Body1>
            </CardTexts>

            <CardButton
              mt={["16px", "24px"]}
              textColor="light"
              rightIcon={<Icon icon="arrow_right" />}
            >
              {t("homepage.section_1.card_1.button")}
            </CardButton>
          </CardContent>
          <CardImage>
            <Box maxWidth="100%">
              <RumpUpImage.card1 />
            </Box>
          </CardImage>
        </Card>
      </SeoLink>

      <CardStack mt="1rem">
        <Card
          hasShadow
          isAnimated
          backgroundColor="#ffffff"
          orientation="column"
          data-role="card"
          onClick={() => {
            if (gaTracker)
              gaTracker.trackMultipleEvents([
                {
                  eventName: homePageEvents["home_body_rump-up"],
                  payload: {
                    card_clicked: 2,
                  },
                },
                {
                  eventName: globalEvents.click_to_exchange,
                },
              ])
            handlePersistUtmCallback({
              callback: () => navigateToExchange(),
            })
          }}
        >
          <CardContent>
            <CardTexts>
              <Text.H6 fontWeight={600} as="h3" color="black">
                {t("homepage.section_1.card_2.title")}
              </Text.H6>
            </CardTexts>

            <CardButton mt={"16px"} rightIcon={<Icon icon="arrow_right" />}>
              {t("homepage.section_1.card_2.button")}
            </CardButton>
          </CardContent>
          <CardImage>
            <Box maxWidth="100%">
              <RumpUpImage.card2 />
            </Box>
          </CardImage>
        </Card>

        <Card
          hasShadow
          isAnimated
          background="#CCF6E3" // previous E8DEFF
          orientation="column"
          data-role="card"
          onClick={() => {
            if (gaTracker)
              gaTracker.trackMultipleEvents([
                {
                  eventName: homePageEvents["home_body_rump-up"],
                  payload: {
                    card_clicked: 3,
                  },
                },
                {
                  eventName: globalEvents.click_to_exchange,
                },
              ])
            handlePersistUtmCallback({
              callback: () => navigateToExchange(),
            })
          }}
        >
          <CardContent>
            <CardTexts>
              <Text.H6 fontWeight={600} as="h3">
                {t("homepage.section_1.card_3.title")}
              </Text.H6>
            </CardTexts>

            <CardButton
              mt={["16px", "24px"]}
              rightIcon={<Icon icon="arrow_right" />}
            >
              {t("homepage.section_1.card_3.button")}
            </CardButton>
          </CardContent>
          <CardImage>
            <Box maxWidth="100%">
              <RumpUpImage.card3 />
            </Box>
          </CardImage>
        </Card>
      </CardStack>
    </>
  )
}
