import React from "react"
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  VStack as LegacyVStack,
  Box as LegacyBox,
  Button,
  Checkbox,
  Icon,
} from "@youngagency/young-ui"
import { StaticImage } from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Body, HStack, Input, Stack, VStack, Heading } from "@youngagency/ui"
import {
  useLazyGetUserSubscriptionQuery,
  useSubscribeUserMutation,
} from "../../../../service/tracking/api/braze/api-gcf"
import { newsletterEvents } from "../../../../service/tracking"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { useSmartDCAContext } from "../context/SmartDCAContext"

type Props = {
  closePopUp: () => void
}

const SuccessBox = () => {
  const { t } = useI18next()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  React.useEffect(() => {
    if (gaTracker) {
      gaTracker.trackEvent({
        eventName: newsletterEvents.newsletter_modal_success,
        payload: {
          content: "ecosistema",
        },
      })
    }
  }, [gaTracker])
  return (
    <HStack
      spacing={16}
      align="center"
      atoms={{
        __width: "full",
      }}
    >
      <Icon
        fontSize="24px"
        name="check"
        icon="check_circle"
        color="green.300"
      />
      <VStack
        spacing={4}
        atoms={{
          __width: "full",
        }}
      >
        <Body variant="1" fontWeight={600} color="green200">
          {t("promo.newsletter.pop_up.success.title")}
        </Body>
        <Body variant="1">
          {t("promo.newsletter.pop_up.success.description")}
        </Body>
      </VStack>
    </HStack>
  )
}

export const SmartDCANewsLetterModal = ({ closePopUp }: Props) => {
  const { t, language } = useI18next()
  const { onComplete } = useSmartDCAContext()
  const [getSubscriptionStatus] = useLazyGetUserSubscriptionQuery()

  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  const [subscribeUser] = useSubscribeUserMutation()
  const isSubmitting = React.useRef(false)

  const [email, setEmail] = React.useState("")
  const [policies, setPolicies] = React.useState({
    commercial: false,
    exchange_user: false,
  })
  const [success, setSuccess] = React.useState(false)

  type PoliciesKeys = keyof typeof policies
  type ErrorKeys =
    | PoliciesKeys
    | "invalid_mail"
    | "already-subscribed"
    | "generic"
    | null
  const [error, setError] = React.useState<{
    error: ErrorKeys
    isError: boolean
  }>({
    error: null,
    isError: false,
  })
  const handlePoliciesChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (error.isError) setError({ error: null, isError: false })

      const { name } = e.target
      setPolicies({ ...policies, [name]: !policies[name as PoliciesKeys] })
    },
    [error.isError, policies]
  )

  const handleEmailChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (error.isError) setError({ error: null, isError: false })
      const { value } = e.target
      setEmail(value)
    },
    [error.isError]
  )

  const validateMail = React.useCallback((mail: string) => {
    const mailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return mailRegex.test(mail || "")
  }, [])

  const handleSubscribe = React.useCallback(async () => {
    if (!email || !validateMail(email)) {
      return setError({
        error: "invalid_mail",
        isError: true,
      })
    }
    if (!policies.commercial) {
      return setError({
        error: "commercial",
        isError: true,
      })
    }
    isSubmitting.current = true
    try {
      const { isSubscribed } = await getSubscriptionStatus({ email }).unwrap()
      if (isSubscribed) {
        setError({
          error: "already-subscribed",
          isError: true,
        })
      } else {
        await subscribeUser({
          email,
          alias: {
            alias_label: "websites",
            alias_name: email,
          },
          attributes: {
            lead_privacy_accepted: "Yes",
            lead_acquisition: "yp-blog",
            already_exchange_user: policies.exchange_user ? "Yes" : "No",
            language,
          },
          events: {},
        }).unwrap()
        setSuccess(true)
        setEmail("")
        setPolicies({
          commercial: false,
          exchange_user: false,
        })
        onComplete()
      }
    } catch (error) {
      setError({
        error: "generic",
        isError: true,
      })
    } finally {
      isSubmitting.current = false
    }
  }, [
    email,
    getSubscriptionStatus,
    policies.commercial,
    policies.exchange_user,
    subscribeUser,
    onComplete,
    validateMail,
    language,
  ])

  const closeModal = React.useCallback(() => {
    if (!success) {
      gaTracker?.trackEvent({
        eventName: newsletterEvents.newsletter_modal_skip,
        payload: {
          content: "ecosistema",
        },
      })
    }
    closePopUp()
  }, [closePopUp, gaTracker, success])

  React.useEffect(() => {
    if (gaTracker) {
      gaTracker.trackEvent({
        eventName: newsletterEvents.newsletter_modal_open,
        payload: {
          content: "ecosistema",
        },
      })
    }
  }, [gaTracker])

  React.useEffect(() => {
    if (gaTracker && error.isError) {
      gaTracker.trackEvent({
        eventName: newsletterEvents.newsletter_modal_error,
        payload: {
          content: "ecosistema",
        },
      })
    }
  }, [error, gaTracker])

  return (
    <Modal onClose={closeModal}>
      <ModalContent css>
        <ModalHeader>
          <LegacyBox
            style={{
              transform: "translateZ(0)",
            }}
            borderTopLeftRadius="16px"
            borderTopRightRadius="16px"
            overflow="hidden"
          >
            <StaticImage
              src="../../images/pop_up/newsletter-pop-up.png"
              alt="popup"
            />
          </LegacyBox>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <LegacyVStack align="flex-start" justify="center" spacing="24px">
            <LegacyBox width="100%" maxWidth="183px" mt={["24px", "inherit"]}>
              <StaticImage
                src="../../images/pop_up/yng-logo-light.png"
                alt="popup"
                placeholder="blurred"
              />
            </LegacyBox>
            <LegacyBox>
              <Heading variant="6">
                {t("promo.newsletter.pop_up.title")}
              </Heading>
              <LegacyBox mt="8px">
                <Body variant="1" color="textSecondary">
                  {t("promo.newsletter.pop_up.description")}
                </Body>
              </LegacyBox>
              <LegacyBox mt="16px">
                {!success ? (
                  <Stack
                    direction="column"
                    spacing={{
                      mobile: 16,
                      xl: 8,
                    }}
                    atoms={{
                      __width: "full",
                    }}
                  >
                    <Stack
                      direction={{
                        mobile: "column",
                        xl: "row",
                      }}
                      spacing={{
                        mobile: 16,
                        xl: 8,
                      }}
                      align="center"
                      atoms={{
                        __width: "full",
                      }}
                    >
                      <Input
                        atoms={{
                          width: "full",
                        }}
                        value={email}
                        isInvalid={
                          error.error === "invalid_mail" ||
                          error.error === "already-subscribed"
                        }
                        onChange={handleEmailChange}
                        placeholder={
                          t("promo.newsletter.pop_up.input_placeholder") ||
                          "Email"
                        }
                      />

                      {error.isError && (
                        <LegacyBox display={["block", "none"]}>
                          <Body variant="2" color="red200">
                            {t(`promo.newsletter.pop_up.errors.${error.error}`)}
                          </Body>
                        </LegacyBox>
                      )}
                      <LegacyBox width="100%" maxWidth={["unset", "123px"]}>
                        <Button
                          isDisabled={
                            !email ||
                            !policies.commercial ||
                            isSubmitting.current
                          }
                          size="md"
                          isFullWidth
                          isRounded
                          onClick={handleSubscribe}
                        >
                          {t("promo.newsletter.pop_up.cta")}
                        </Button>
                      </LegacyBox>
                    </Stack>
                    {error.isError && (
                      <LegacyBox display={["none", "block"]}>
                        <Body variant="2" color="red200">
                          {t(`promo.newsletter.pop_up.errors.${error.error}`)}
                        </Body>
                      </LegacyBox>
                    )}
                    <HStack spacing={16} align="center">
                      <Checkbox
                        checked={policies.commercial}
                        onChange={handlePoliciesChange}
                        name="commercial"
                      />
                      <Body variant="1">
                        {t("promo.newsletter.pop_up.policies.commercial")}
                      </Body>
                    </HStack>
                    <HStack spacing={16} align="center">
                      <Checkbox
                        checked={policies.exchange_user}
                        onChange={handlePoliciesChange}
                        name="exchange_user"
                      />
                      <Body variant="1">
                        {t("promo.newsletter.pop_up.policies.exchange_user")}
                      </Body>
                    </HStack>
                  </Stack>
                ) : (
                  <SuccessBox />
                )}
              </LegacyBox>
            </LegacyBox>
          </LegacyVStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
