import React from "react"

export type ClubPageContextType = {
  ref: React.RefObject<HTMLDivElement> | null
  goToRef: () => void
}

const ClubPageContext = React.createContext<ClubPageContextType>({
  ref: null,
  goToRef: () => {},
})

export const ClubPageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const goToRef = React.useCallback(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [])
  return (
    <ClubPageContext.Provider
      value={{
        ref,
        goToRef,
      }}
    >
      {children}
    </ClubPageContext.Provider>
  )
}

export const useClubPageContext = () => React.useContext(ClubPageContext)
