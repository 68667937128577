import { Box } from "@youngagency/young-ui"
import React from "react"
import styled from "styled-components"
import FooterSection from "./FooterSection"
import { useI18next } from "gatsby-plugin-react-i18next"
import useFooterData from "./asset/useFooterData"
import FooterInfo from "./FooterInfo"
import { Container } from "../layout"

const StyledFooterGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 24px;

  & [data-role="_text"] {
    font-family: "Matter" !important;
  }

  @media screen and (min-width: 492px) {
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 1fr;
    column-gap: 24px;
  }

  @media screen and (min-width: 767px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 992px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: unset;

    & [data-role="_footer-box"]:nth-child(3n) {
      max-width: fit-content;
    }
  }
`

type FooterProps = {
  disableMargin?: boolean
}

const Footer: React.FC<FooterProps> = ({ disableMargin }) => {
  const { company, product, support, learn } = useFooterData()
  const { t } = useI18next()

  return (
    <Box
      height="100%"
      backgroundColor="#000"
      py={["48px"]}
      mt={disableMargin ? "0px" : ["48px", "96px"]}
    >
      <Container>
        <StyledFooterGrid width="100%">
          <FooterSection
            title={t("footer.product.title")}
            array={product}
            width="100%"
          />
          <FooterSection
            title={t("footer.learn.title")}
            array={learn}
            width="100%"
          />
          <FooterSection
            title={t("footer.company.title")}
            array={company}
            width="100%"
          />
          <FooterSection
            title={t("footer.support.title")}
            array={support}
            width="100%"
          />
        </StyledFooterGrid>
        <Box mt={["42px", "52px"]}>
          <FooterInfo />
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
