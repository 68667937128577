import { Box, HStack, Icon } from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { ResponsiveText } from "../../../components/layout"
import styled from "styled-components"
import { useHasMounted } from "@youngagency/ui"

const StyledBox = styled(Box)`
  a {
    color: #000;
  }
`

export const LegalFooter = () => {
  const { t } = useI18next()
  const isMounted = useHasMounted()
  React.useEffect(() => {
    if (!isMounted()) return
    const desclaimer = document.getElementById("market-legal-desclaimer")
    if (desclaimer) {
      const disclaimerTarget = desclaimer
        .getElementsByTagName("a")[0]
        .getAttribute("target")
      if (disclaimerTarget !== "_blank")
        desclaimer.getElementsByTagName("a")[0].setAttribute("target", "_blank")
    }
  }, [isMounted])
  return (
    <HStack spacing="8px">
      <Icon fontSize={"16px"} icon="info" color="grey.1" />
      <StyledBox as="span" ml="8px">
        <ResponsiveText
          id="market-legal-desclaimer"
          color="grey.1"
          dimension={["14px", "16px"]}
          line={["18px", "20px"]}
          style={{
            textDecoration: "underline",
            textDecorationColor: "transparent",
          }}
          dangerouslySetInnerHTML={{
            __html: t("single_market.disclaimers.bottom"),
          }}
        />
      </StyledBox>
    </HStack>
  )
}
