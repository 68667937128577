import React from "react"
import { useNavigationContext } from "../../context"
import { Header } from "../Header"
import { EcosistemaSidebar } from "./EcosistemaSidebar"
import { EcosistemaNavigation } from "./EcosistemaNavigation"
import { useI18next } from "gatsby-plugin-react-i18next"
import { PromoBanner } from "../../../promo/components"

export const LandingHeader = () => {
  const context = useNavigationContext()
  const { language } = useI18next()
  return (
    <>
      <Header
        banner={<PromoBanner showedPlatform="website"/>
        }
      >
        <EcosistemaNavigation />
      </Header>
      {context.isSidebarOpen && <EcosistemaSidebar />}
    </>
  )
}
