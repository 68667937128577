import React from "react"
import { BoxExtendedProps } from "../../../types/system"
import { Box } from "@youngagency/ui"
import { cardBackContainer } from "../style/card-back.css"
import clsx from "clsx"
import { useOpacityContext } from "../context/OpacityContext"
import { motion } from "framer-motion"

const variants = {
  open: { opacity: 1, transition: { duration: 0.5 } },
  closed: { opacity: 0, transition: { duration: 0.5 } },
}

export const CardBack = ({
  children,
  className,
  ...rest
}: BoxExtendedProps<HTMLDivElement>) => {
  const { show } = useOpacityContext()

  return (
    <motion.nav
      animate={show ? "open" : "closed"}
      variants={variants}
      style={{
        opacity: 0,
      }}
    >
      <Box className={clsx(cardBackContainer, className)} {...rest}>
        {children}
      </Box>
    </motion.nav>
  )
}
