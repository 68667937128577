import { TrackerProvider } from "./TrackerProvider"
import { Event } from "../types"
import { isBrowser } from "../../../components/blog/utils/helpers"

export class GATracker extends TrackerProvider<Event> {
  static uniqueGATracker: GATracker
  public dataLayer = isBrowser ? window.dataLayer || [] : []

  private sendEvent(event: Event) {
    const { eventName, payload } = event
    if (!this.dataLayer) return // check if we are in browser
    this.dataLayer.push({
      event: eventName,
      ...payload,
    })
  }

  trackEvent(event: Event) {
    this.sendEvent(event)
  }

  trackMultipleEvents(events: Event[]) {
    if (!this.dataLayer) return // check if we are in browser
    events.forEach(event => {
      this.sendEvent(event)
    })
  }

  static init() {
    if (!GATracker.uniqueGATracker) {
      GATracker.uniqueGATracker = new GATracker()
    }
    return GATracker.uniqueGATracker
  }
}
