export const blogArticleEvents = {
  hero_back: "blog_article_hero_back" as const,
  hero_category: "blog_article_hero_category" as const,
  hero_share: "blog_article_hero_share" as const,
  body_banner: "blog_article_body_banner" as const,
  body_related: "blog_article_body_related" as const,
  blog_article_footer: "blog_article_footer" as const,
};

export type BlogArticleEvents = typeof blogArticleEvents;
