import { OptionType } from "@youngagency/young-ui/dist/src/components/select/shared"
import {
  CHANGE_CONVERSOR_CTA_TYPE,
  EDIT_FROM_VALUE_CHANGE,
  MAKE_CONVERSION,
  SET_CONVERSION_VALUE_TO_0,
  SWAP_CONVERSION_DIRECTION,
  SWITCH_CONVERSOR_INPUT,
} from "../constants"
import { ConversorAction, ConversorCtaType, ConversorDirection } from "./types"

type ConversorEntry = {
  name: string
  symbol: string
  value: number | string
  img?: string | null
}

export type CONVERSOR_STATE = {
  conversorList: {
    from: OptionType[]
    to: OptionType[]
  }
  conversorValue: {
    from: ConversorEntry
    to: ConversorEntry
  }
  direction: ConversorDirection
  conversorCta: ConversorCtaType
}

export function reducer(
  state: CONVERSOR_STATE,
  action: ConversorAction
): CONVERSOR_STATE {
  const { type, payload } = action
  switch (type) {
    case SWAP_CONVERSION_DIRECTION:
      return {
        ...state,
        direction: payload,
      }
    case MAKE_CONVERSION:
      return {
        ...state,
        conversorValue: {
          ...state.conversorValue,
          to: { ...state.conversorValue.to, value: payload },
        },
      }
    case SET_CONVERSION_VALUE_TO_0:
      return {
        ...state,
        conversorValue: {
          ...state.conversorValue,
          to: { ...state.conversorValue.to, value: 0 },
        },
      }
    case EDIT_FROM_VALUE_CHANGE: {
      return {
        ...state,
        conversorValue: {
          ...state.conversorValue,
          from: {
            ...state.conversorValue.from,
            value: payload,
          },
        },
      }
    }
    case SWITCH_CONVERSOR_INPUT:
      return {
        ...state,
        conversorValue: {
          to: state.conversorValue.from,
          from: state.conversorValue.to,
        },
        conversorList: {
          to: state.conversorList.from,
          from: state.conversorList.to,
        },
      }
    case CHANGE_CONVERSOR_CTA_TYPE:
      return {
        ...state,
        conversorCta: payload ? payload : "convert",
      }
    default:
      return state
  }
}
