import { Body, Box, Container, VStack, Heading, Tag } from "@youngagency/ui"
import { Container as CustomContainer } from "../../../components/layout"
import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { CardContainer } from "../../../components/cards/layout/CardContainer"
import { CardContent } from "../../../components/cards/layout/CardContent"
import { SquareCard } from "../../../components/cards/components/SquareCard"
import { centeredSquareCard } from "../../../components/cards/style/card-content.css"
import {
  columnGridLayout,
  layoutContainer,
  spacer,
} from "../styles/slider-section.css"
import { unshirkedCard } from "../../../components/cards/style/card.css"

export const SliderSection = () => {
  const { t } = useTranslation()
  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              fontWeight={400}
              dangerouslySetInnerHTML={{
                __html: t("security.best_practice.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              color="grey200"
              style={{
                maxWidth: "700px",
              }}
              fontWeight={600}
            >
              {t("security.best_practice.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>
      <Box
        width="full"
        marginTop={{
          mobile: 32,
          md: 40,
        }}
        className={columnGridLayout}
      >
        <Box className={layoutContainer}>
          <SquareCard disableHover>
            <CardContainer
              width={"full"}
              background={"grey10"}
              className={unshirkedCard}
            >
              <CardContent
                alignItems="center"
                width={"full"}
                className={centeredSquareCard}
              >
                <VStack
                  align="flex-start"
                  spacing={{
                    mobile: 16,
                    lg: 24,
                  }}
                >
                  <Tag colorScheme="purple" size={24}>
                    {t("security.best_practice.cards.card_1.label")}
                  </Tag>
                  <Heading
                    variant="6"
                    fontWeight={600}
                    dangerouslySetInnerHTML={{
                      __html: t("security.best_practice.cards.card_1.title"),
                    }}
                  />
                  <Body variant="1" color="grey200" fontWeight={600}>
                    {t("security.best_practice.cards.card_1.description")}
                  </Body>
                </VStack>
              </CardContent>
            </CardContainer>
          </SquareCard>

          <SquareCard disableHover>
            <CardContainer
              width={"full"}
              background={"grey10"}
              className={unshirkedCard}
            >
              <CardContent
                alignItems="center"
                width={"full"}
                className={centeredSquareCard}
              >
                <VStack
                  align="flex-start"
                  spacing={{
                    mobile: 16,
                    lg: 24,
                  }}
                >
                  <Tag colorScheme="blue" size={24}>
                    {t("security.best_practice.cards.card_2.label")}
                  </Tag>
                  <Heading
                    variant="6"
                    fontWeight={600}
                    dangerouslySetInnerHTML={{
                      __html: t("security.best_practice.cards.card_2.title"),
                    }}
                  />
                  <Body variant="1" color="grey200" fontWeight={600}>
                    {t("security.best_practice.cards.card_2.description")}
                  </Body>
                </VStack>
              </CardContent>
            </CardContainer>
          </SquareCard>
          <SquareCard disableHover>
            <CardContainer
              width={"full"}
              background={"grey10"}
              className={unshirkedCard}
            >
              <CardContent
                alignItems="center"
                width={"full"}
                className={centeredSquareCard}
              >
                <VStack
                  align="flex-start"
                  spacing={{
                    mobile: 16,
                    lg: 24,
                  }}
                >
                  <Tag colorScheme="yellow" size={24}>
                    {t("security.best_practice.cards.card_3.label")}
                  </Tag>
                  <Heading
                    variant="6"
                    fontWeight={600}
                    dangerouslySetInnerHTML={{
                      __html: t("security.best_practice.cards.card_3.title"),
                    }}
                  />
                  <Body variant="1" color="grey200" fontWeight={600}>
                    {t("security.best_practice.cards.card_3.description")}
                  </Body>
                </VStack>
              </CardContent>
            </CardContainer>
          </SquareCard>
          <SquareCard disableHover>
            <CardContainer
              width={"full"}
              background={"grey10"}
              className={unshirkedCard}
            >
              <CardContent
                alignItems="center"
                width={"full"}
                className={centeredSquareCard}
              >
                <VStack
                  align="flex-start"
                  spacing={{
                    mobile: 16,
                    lg: 24,
                  }}
                >
                  <Tag colorScheme="orange" size={24}>
                    {t("security.best_practice.cards.card_4.label")}
                  </Tag>
                  <Heading
                    variant="6"
                    fontWeight={600}
                    dangerouslySetInnerHTML={{
                      __html: t("security.best_practice.cards.card_4.title"),
                    }}
                  />
                  <Body variant="1" color="grey200" fontWeight={600}>
                    {t("security.best_practice.cards.card_4.description")}
                  </Body>
                </VStack>
              </CardContent>
            </CardContainer>
          </SquareCard>
          <SquareCard disableHover>
            <CardContainer
              width={"full"}
              background={"grey10"}
              className={unshirkedCard}
            >
              <CardContent
                alignItems="center"
                width={"full"}
                className={centeredSquareCard}
              >
                <VStack
                  align="flex-start"
                  spacing={{
                    mobile: 16,
                    lg: 24,
                  }}
                >
                  <Tag colorScheme="green" size={24}>
                    {t("security.best_practice.cards.card_5.label")}
                  </Tag>
                  <Heading
                    variant="6"
                    fontWeight={600}
                    dangerouslySetInnerHTML={{
                      __html: t("security.best_practice.cards.card_5.title"),
                    }}
                  />
                  <Body variant="1" color="grey200" fontWeight={600}>
                    {t("security.best_practice.cards.card_5.description")}
                  </Body>
                </VStack>
              </CardContent>
            </CardContainer>
          </SquareCard>
          <SquareCard disableHover>
            <CardContainer
              width={"full"}
              background={"grey10"}
              className={unshirkedCard}
            >
              <CardContent
                alignItems="center"
                width={"full"}
                className={centeredSquareCard}
              >
                <VStack
                  align="flex-start"
                  spacing={{
                    mobile: 16,
                    lg: 24,
                  }}
                >
                  <Tag colorScheme="pink" size={24}>
                    {t("security.best_practice.cards.card_6.label")}
                  </Tag>
                  <Heading
                    variant="6"
                    fontWeight={600}
                    dangerouslySetInnerHTML={{
                      __html: t("security.best_practice.cards.card_6.title"),
                    }}
                  />
                  <Body variant="1" color="grey200" fontWeight={600}>
                    {t("security.best_practice.cards.card_6.description")}
                  </Body>
                </VStack>
              </CardContent>
            </CardContainer>
          </SquareCard>
          <Box className={spacer}></Box>
        </Box>
      </Box>
    </>
  )
}
