import { Box, css, styled, Label, Skeleton } from "@youngagency/young-ui"
import { HStack } from "@youngagency/ui"
import isEmpty from "lodash/isEmpty"
import React from "react"
import { useDispatch } from "react-redux"
import { Avatar } from "../../../components/layout"
import { setTimeFrameToOneDay } from "../../../redux/reducers/uiSlice"
import { SeoLink } from "../../seo"
import { useRelatedToken } from "../hooks/useRelatedToken"

type ReletedToken = {
  type: "popular" | "top_gainers"
  exclude: string
}

const CustomStack = styled(Box)(
  css({
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& >*": {
      marginBottom: "16px",
      "&:not(:last-of-type)": {
        marginRight: ["12px", "8px"],
      },
    },
  })
)

const TokenLabel = styled(Box)(
  css({
    height: ["40px", "56px"],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100px",
    border: "1px solid",
    borderColor: "grey.5",
    minWidth: ["40px", "56px"],
    px: ["12px", "20px"],
    transition: "all 125ms ease",
    ":hover": {
      backgroundColor: "grey.8",
    },
    ".label-img": {
      width: "100%",
      height: "100%",
    },
  })
)

export const ReletedLabelTokens = ({ type, exclude }: ReletedToken) => {
  const { related, allToken, isLoading } = useRelatedToken({ type, exclude })
  const dispatch = useDispatch()
  const handleClick = React.useCallback(
    () => dispatch(setTimeFrameToOneDay()),
    [dispatch]
  )

  return (
    <CustomStack>
      {related.map(token => (
        <SeoLink
          key={token.symbol}
          link={`/exchange/${token.symbol.toLowerCase()}/`}
          isExternal={false}
          onClick={handleClick}
        >
          {isLoading && isEmpty(allToken) ? (
            <Skeleton
              height={["40px", "56px"]}
              width="120px"
              borderRadius="100px"
            />
          ) : (
            <TokenLabel>
              <HStack spacing={8} align="center">
                <Avatar
                  width={["20px", "24px"]}
                  height={["20px", "24px"]}
                  shape="circle"
                >
                  <img
                    height={20}
                    width={20}
                    // @ts-ignore
                    src={token?.image || token?.details?.image}
                    alt={token.name}
                    className="label-img"
                  />
                </Avatar>
                <Label size="md" fontWeight={600} fontFamily="Matter">
                  {`${token.name} ${token.symbol.toUpperCase()}`}
                </Label>
              </HStack>
            </TokenLabel>
          )}
        </SeoLink>
      ))}
    </CustomStack>
  )
}
