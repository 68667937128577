import { Box } from "@youngagency/young-ui"
import React from "react"
import { Description, Display } from "../../../../components"
import { Container } from "../../../../components/layout"
import { useTokenFiatContext } from "../context"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Conversor } from "./Conversor"
import { inverseTokenTicker } from "../utils"

export const Hero = () => {
  const { token, fiat } = useTokenFiatContext()
  const { t } = useTranslation()
  return (
    <Box>
      <Container size={["md", "lg"]}>
        <Display as="h1">
          {t("markets.token_fiat.title", {
            currency: inverseTokenTicker(token.symbol),
            fiat: fiat.symbol,
          })}
        </Display>
        <Box mt={["24px"]} maxWidth="756px">
          <Description as="p">
            {t("markets.token_fiat.description", {
              currency: token.name,
              fiat: fiat.name,
            })}
          </Description>
        </Box>
        <Box mt={["24px"]}>
          <Conversor />
        </Box>
      </Container>
    </Box>
  )
}
