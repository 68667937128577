import React, { Dispatch } from "react"
import { CONVERSOR_STATE } from "."
import {
  CHANGE_CONVERSOR_CTA_TYPE,
  EDIT_FROM_VALUE_CHANGE,
  MAKE_CONVERSION,
  SET_CONVERSION_VALUE_TO_0,
  SWAP_CONVERSION_DIRECTION,
  SWITCH_CONVERSOR_INPUT,
} from "../constants"
import { ConversorAction, ConversorCtaType, ConversorDirection } from "./types"
import { useDispatch } from "react-redux"
import { changeConversorDirection } from "../../../../redux/reducers/uiSlice"

export const useReducerActions = (
  dispatch: Dispatch<ConversorAction>,
  state: CONVERSOR_STATE
) => {
  const reduxDispatch = useDispatch()

  const handleChangeDirections = React.useCallback(
    <T extends ConversorDirection>(direction: T): void => {
      dispatch({ type: SWAP_CONVERSION_DIRECTION, payload: direction })
      reduxDispatch(changeConversorDirection(direction))
    },
    [dispatch, reduxDispatch]
  )

  const handleConversionAction = React.useCallback(
    <T extends Record<"fiat" | "currency" | "value", string | number>>(
      payload: T
    ) => {
      let result
      const decimalPrecision = state.direction === "token_fiat" ? 2 : 6
      if (state.direction === "fiat_token") {
        result = Number(payload.fiat) / Number(payload.currency)
      } else {
        result = Number(payload.currency) * Number(payload.fiat)
      }
      const finalConversion = payload.value
        ? result * Number(payload.value)
        : result

      dispatch({
        type: MAKE_CONVERSION,
        payload: finalConversion.toFixed(decimalPrecision),
      })

      return finalConversion.toFixed(decimalPrecision)
    },
    [dispatch, state.direction]
  )
  const handleNullConversion = React.useCallback(
    (payload?: number) => {
      dispatch({ type: SET_CONVERSION_VALUE_TO_0, payload })
    },
    [dispatch]
  )

  const switchConversorInput = React.useCallback(() => {
    dispatch({ type: SWITCH_CONVERSOR_INPUT })
  }, [dispatch])

  const handleConversorCtaType = React.useCallback(
    <T extends ConversorCtaType>(payload?: T) => {
      dispatch({ type: CHANGE_CONVERSOR_CTA_TYPE, payload })
    },
    [dispatch]
  )

  const hanldeFromInputChange = React.useCallback(
    (value: number | string) => {
      dispatch({ type: EDIT_FROM_VALUE_CHANGE, payload: value })
      handleConversorCtaType()
    },
    [dispatch, handleConversorCtaType]
  )

  return {
    handleChangeDirections,
    handleConversionAction,
    handleNullConversion,
    hanldeFromInputChange,
    switchConversorInput,
    handleConversorCtaType,
  }
}

export type ConversorReducerActions = ReturnType<typeof useReducerActions>
