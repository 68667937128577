import React from "react"
import styled from "styled-components"
import { SeoLink } from "../../seo"
import { BreadCrumbProps } from "../types"
import { Box, BoxProps, Icon } from "@youngagency/young-ui"
import { ResponsiveText } from "../../../components/layout"

const StyledBox = styled(Box)`
  height: 48px;
  min-width: 48px;
  display: flex;
  direction: column;
  justify-content: flex-start;
  align-items: center;
  ul {
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 0px;

    li {
      list-style: none;
      cursor: pointer;
    }
    & .short-breadcrumb:last-of-type {
      display: none;
    }
  }

  .breadcrumb-element {
    &:first-of-type {
      i {
        display: none;
      }
    }
    display: flex;
    align-items: center;
  }

  @media screen and (min-width: 767px) {
    & .short-breadcrumb:last-of-type {
      display: flex !important;
    }
  }
`

export const BreadCrumb = ({
  breadcrumbs,
  removeLast,
  ...rest
}: { breadcrumbs: BreadCrumbProps[]; removeLast?: true } & BoxProps) => {
  return (
    <StyledBox {...rest}>
      <ul>
        {breadcrumbs.map(el => {
          return (
            <li
              key={el.text}
              className={`${
                removeLast
                  ? "breadcrumb-element short-breadcrumb"
                  : `breadcrumb-element`
              }`}
            >
              <Icon icon="chevron_right" fontSize="16px" />
              <SeoLink isExternal={false} link={el.link}>
                <ResponsiveText
                  line={["15px", "17px"]}
                  dimension={["12px", "14px"]}
                  fontWeight={600}
                  color="inherit"
                >
                  {el.text}
                </ResponsiveText>
              </SeoLink>
            </li>
          )
        })}
      </ul>
    </StyledBox>
  )
}
