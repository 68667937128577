import {
  Body,
  Container,
  VStack,
  Heading,
  Icon,
  Subtitle,
} from "@youngagency/ui"
import {
  Avatar,
  Container as CustomContainer,
} from "../../../components/layout"
import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { CardsWrapper } from "../../../components/cards/components/CardsWrapper"
import { VerticalCard } from "../../../components/cards/components/VerticalCard"
import { CardContainer } from "../../../components/cards/layout/CardContainer"
import { CardContent } from "../../../components/cards/layout/CardContent"
import { CardImage } from "../../../components/cards/layout/CardImage"
import { CardIconButton } from "../../../components/cards/layout/CardButton"
import { CardAction } from "../../../components/cards/layout/CardAction"
import { CardCustomText } from "../../../components/cards/layout/CardCustomText"
import { OpacityProvider } from "../../../components/cards/context/OpacityContext"
import { CardBack } from "../../../components/cards/layout/CardBack"
import { CardsSection } from "../../../components/cards/components/CardsSection"
import { AdviceImage } from "./LocalizedImage"

export const AdviceSection = () => {
  const { t } = useTranslation()
  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              fontWeight={400}
              dangerouslySetInnerHTML={{
                __html: t("security.advices_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              color="grey200"
              style={{
                maxWidth: "700px",
              }}
              fontWeight={600}
            >
              {t("security.advices_section.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>

      <CardsSection
        marginTop={{
          mobile: 32,
          md: 40,
        }}
      >
        <VStack
          spacing={16}
          align="center"
          justify="center"
          atoms={{
            __columnGap: "1rem",
            width: "full",
            mx: "auto",
          }}
        >
          <CardsWrapper alignment="center">
            <OpacityProvider>
              <VerticalCard hasShadow hoverVariant="light" isAnimated>
                <CardContainer>
                  <CardContent spacing={2}>
                    <VStack
                      spacing={{
                        mobile: 24,
                        lg: 32,
                      }}
                    >
                      <Avatar
                        shape="circle"
                        width={["56px", "72px"]}
                        height={["56px", "72px"]}
                        background={"var(--grey-10-v2)"}
                      >
                        <Icon
                          icon="lock"
                          atoms={{
                            width: {
                              mobile: 24,
                              md: 32,
                            },
                            height: {
                              mobile: 24,
                              md: 32,
                            },
                          }}
                        />
                      </Avatar>
                      <Heading variant="6" as="h3" fontWeight={600}>
                        {t("security.advices_section.cards.card_1.title")}
                      </Heading>
                    </VStack>
                    <CardImage>
                      <AdviceImage.card_1 />
                    </CardImage>
                  </CardContent>
                  <CardAction position="bottom" justifyContent="flex-end">
                    <CardIconButton>
                      <Icon icon="plus" size={24} />
                    </CardIconButton>
                  </CardAction>
                  <CardBack background="white">
                    <VStack
                      spacing={8}
                      atoms={{
                        width: "full",
                      }}
                    >
                      <Subtitle variant="1" color="grey200">
                        {t(
                          "security.advices_section.cards.card_1.back.description_1"
                        )}
                      </Subtitle>
                      <Subtitle variant="1" color="grey200">
                        {t(
                          "security.advices_section.cards.card_1.back.description_2"
                        )}
                      </Subtitle>
                    </VStack>
                    <CardAction position="bottom" justifyContent="flex-end">
                      <CardIconButton>
                        <Icon icon="close" size={24} />
                      </CardIconButton>
                    </CardAction>
                  </CardBack>
                </CardContainer>
              </VerticalCard>
            </OpacityProvider>
            <OpacityProvider>
              <VerticalCard hasShadow hoverVariant="light" isAnimated>
                <CardContainer>
                  <CardContent spacing={2}>
                    <VStack
                      spacing={{
                        mobile: 24,
                        lg: 32,
                      }}
                    >
                      <Avatar
                        shape="circle"
                        width={["56px", "72px"]}
                        height={["56px", "72px"]}
                        background={"var(--grey-10-v2)"}
                      >
                        <Icon
                          icon="alertCircle"
                          atoms={{
                            width: {
                              mobile: 24,
                              md: 32,
                            },
                            height: {
                              mobile: 24,
                              md: 32,
                            },
                          }}
                        />
                      </Avatar>
                      <Heading variant="6" as="h3" fontWeight={600}>
                        {t("security.advices_section.cards.card_2.title")}
                      </Heading>
                    </VStack>
                    <CardImage>
                      <AdviceImage.card_2 />
                    </CardImage>
                  </CardContent>
                  <CardAction position="bottom" justifyContent="flex-end">
                    <CardIconButton>
                      <Icon icon="plus" size={24} />
                    </CardIconButton>
                  </CardAction>
                  <CardBack background="white">
                    <VStack
                      spacing={8}
                      atoms={{
                        width: "full",
                      }}
                    >
                      <Subtitle variant="1" color="grey200">
                        {t(
                          "security.advices_section.cards.card_2.back.description_1"
                        )}
                      </Subtitle>
                      <Subtitle variant="1" color="grey200">
                        {t(
                          "security.advices_section.cards.card_2.back.description_2"
                        )}
                      </Subtitle>
                    </VStack>
                    <CardAction position="bottom" justifyContent="flex-end">
                      <CardIconButton>
                        <Icon icon="close" size={24} />
                      </CardIconButton>
                    </CardAction>
                  </CardBack>
                </CardContainer>
              </VerticalCard>
            </OpacityProvider>
          </CardsWrapper>
          <CardsWrapper alignment="right">
            <OpacityProvider>
              <VerticalCard hasShadow hoverVariant="light" isAnimated>
                <CardContainer>
                  <CardContent spacing={2}>
                    <VStack
                      spacing={{
                        mobile: 24,
                        lg: 32,
                      }}
                    >
                      <Avatar
                        shape="circle"
                        width={["56px", "72px"]}
                        height={["56px", "72px"]}
                        background={"var(--grey-10-v2)"}
                      >
                        <Icon
                          icon="lock"
                          atoms={{
                            width: {
                              mobile: 24,
                              md: 32,
                            },
                            height: {
                              mobile: 24,
                              md: 32,
                            },
                          }}
                        />
                      </Avatar>
                      <Heading variant="6" as="h3" fontWeight={600}>
                        {t("security.advices_section.cards.card_3.title")}
                      </Heading>
                    </VStack>
                    <CardImage>
                      <AdviceImage.card_3 />
                    </CardImage>
                  </CardContent>
                  <CardAction position="bottom" justifyContent="flex-end">
                    <CardIconButton>
                      <Icon icon="plus" size={24} />
                    </CardIconButton>
                  </CardAction>
                  <CardBack background="white">
                    <VStack
                      spacing={8}
                      atoms={{
                        width: "full",
                      }}
                    >
                      <Subtitle variant="1" color="grey200">
                        {t(
                          "security.advices_section.cards.card_3.back.description_1"
                        )}
                      </Subtitle>
                      <Subtitle variant="1" color="grey200">
                        {t(
                          "security.advices_section.cards.card_3.back.description_2"
                        )}
                      </Subtitle>
                    </VStack>
                    <CardAction position="bottom" justifyContent="flex-end">
                      <CardIconButton>
                        <Icon icon="close" size={24} />
                      </CardIconButton>
                    </CardAction>
                  </CardBack>
                </CardContainer>
              </VerticalCard>
            </OpacityProvider>
            <VerticalCard disableHover hoverVariant="light" isAnimated>
              <CardContainer background="green10">
                <CardContent
                  spacing={2}
                  height="full"
                  paddingBottom={{
                    mobile: 24,
                    md: 48,
                  }}
                >
                  <VStack
                    spacing={"unset"}
                    align="flex-start"
                    justify="flex-end"
                    atoms={{
                      height: "full",
                    }}
                  >
                    <CardCustomText
                      className="brand-strong"
                      variant="h3"
                      dangerouslySetInnerHTML={{
                        __html: t(
                          "security.advices_section.cards.card_4.title"
                        ),
                      }}
                    ></CardCustomText>
                  </VStack>
                </CardContent>
              </CardContainer>
            </VerticalCard>
          </CardsWrapper>
        </VStack>
      </CardsSection>
    </>
  )
}
