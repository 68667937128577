import { Box, Button } from "@youngagency/young-ui"
import React from "react"
import { SubTitle } from "../../../components"
import { BorderBox, RowStack } from "../../../components/layout"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { navigate } from "@reach/router"
import styled from "styled-components"
import { css } from "@styled-system/css"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { isBannerVisible, triggerSaveTheChildrenEvent } from "../utils"

const StyledBox = styled(BorderBox)(
  css({
    borderRadius: "16px",
    maxWidth: "756px",
    width: "100%",
    transition: "all 75ms linear",
    "&:hover": {
      backgroundColor: ["grey.7", "white"],
    },
  })
)

export const ArticlePromoBanner = ({ id }: { id: number }) => {
  const data = useStaticQuery(query) as Queries.SaveTheChildrenArticlesQuery
  const { t } = useTranslation()
  const handleNavigate = React.useCallback(() => {
    triggerSaveTheChildrenEvent()
    navigate(
      "https://exchange.youngplatform.com/signup/?utm_source=blog&utm_banner=button&utm_campaign=savethechildren"
    )
  }, [])

  const isVisible = React.useMemo(
    () => isBannerVisible(id, data?.allWpPost?.nodes),
    [data, id]
  )

  if (isVisible)
    return (
      <StyledBox mt={["24px", "48px"]}>
        <RowStack
          rGap={["16px", "24px"]}
          p={["16px", "24px"]}
          alignItems="center"
        >
          <Box maxWidth={["200px", "518px"]}>
            <SubTitle>{t("blog.save_the_children_banner.title")}</SubTitle>
            <Box mt={["0px", "16px"]}>
              <Button onClick={handleNavigate}>
                {t("blog.save_the_children_banner.cta")}
              </Button>
            </Box>
          </Box>
          <Box
            maxWidth={["96px", "166px"]}
            width="100%"
            borderRadius="16px"
            style={{
              transform: "translateZ(0)",
            }}
          >
            <StaticImage
              src="../images/save_the_children_banner.png"
              alt={t("blog.save_the_children_banner.title")}
            />
          </Box>
        </RowStack>
      </StyledBox>
    )
  return null
}

const query = graphql`
  query SaveTheChildrenArticles {
    allWpPost(filter: { databaseId: { in: [31183, 31200, 32940] } }) {
      nodes {
        databaseId
        translations {
          databaseId
        }
      }
    }
  }
`
