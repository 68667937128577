import { createGlobalStyle } from "styled-components"
import { Box, css } from "@youngagency/young-ui"
import React from "react"

export const TokenGoobalContentStyle = createGlobalStyle(
  {
    fontFamily: "Matter !important",
  },
  css({
    "& h2": {
      fontSize: "34px",
      lineHeight: "39px",
      fontWeight: 600,
      mb: ["16px", "24px"],
    },

    "& p": {
      fontSize: ["16px", "20px"],
      lineHeight: ["20px", "30px"],
      fontWeight: 400,
    },
    "& h2 ~ p": {
      marginBottom: "48px",
    },
    "& a": {
      textDecoration: "none",
      color: "var(--green)",
    },
  })
)

export const TokenContentContainer = ({ content }: { content: string }) => {
  return (
    <Box>
      <TokenGoobalContentStyle />
      <Box
        fontFamily="Matter"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </Box>
  )
}
