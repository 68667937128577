import { ImageDataLike } from "gatsby-plugin-image"
import { TFunction } from "i18next"
import { SupportedLanguageValue } from "../types/footer"
import { navigate } from "@reach/router"
const isBrowser = typeof window !== "undefined"

export type JsonFromArrayProps = string | JSX.Element | string | number

function createArrayFromJson<T extends string>(
  translator: TFunction,
  defaultQuery: string,
  numOfItems: number,
  imgArray: string[] | ImageDataLike[] | JSX.Element[],
  ...rest: T[]
): Array<Record<typeof rest[number] | "id", JsonFromArrayProps>> {
  const newArray = []
  for (let i = 0; i < numOfItems; i++) {
    let tempObj = {} as Record<typeof rest[number] | "id", JsonFromArrayProps>
    rest.forEach(el => {
      tempObj = {
        ...tempObj,
        id: i + 1,
        [el]:
          (el === "icon" || el === "img") && (imgArray && imgArray.length) > 0
            ? imgArray[i]
            : translator(`${defaultQuery}.${el}_${i + 1}`),
      }
    })
    newArray.push(tempObj)
  }
  return newArray
}

function changeLanguageHandler(lng: SupportedLanguageValue) {
  if (isBrowser) {
    try {
      localStorage.setItem("gatsby-i18next-language", lng)
    } catch (e) {
      console.log(e)
    }
  }
}

const rowalizer = <T>(array: T[], itemPerRow = 3) => {
  let newArray: T[][] = []
  const rows = Math.ceil(array.length / itemPerRow)
  Array.from({ length: rows }, (_, index) => {
    let start = index * itemPerRow
    let end = start + itemPerRow
    newArray.push(array.slice(start, end))
  })

  return newArray
}

export function generateUID() {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  const firstPart = (Math.random() * 46656) | 0
  const secondPart = (Math.random() * 46656) | 0
  const firstPartString = ("000" + firstPart.toString(36)).slice(-3)
  const secondPartString = ("000" + secondPart.toString(36)).slice(-3)
  return firstPartString + secondPartString
}

export const getStandardArticles = (language: SupportedLanguageValue) => {
  let articleArray: number[] | [] = []

  switch (language) {
    case "en":
      articleArray = [4179, 4165, 4040]

      break
    case "fr":
      articleArray = [10028, 9987, 9733]

      break
    default:
      articleArray = [4113, 4108, 4035]
      break
  }
  return articleArray
}

const createGAEvent = (event: string, content?: string) => {
  return () => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: event,
        content: content,
      })
    }
  }
}

export const triggerClickToExchangeEvents = () => {
  if (!isBrowser) return
  const { href } = window.location
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "click_to_exchange",
    content: href,
  })
}

export const triggerHeaderEvents = (content: "login" | "signup") => {
  return createGAEvent("click_ecosistema_header", content)
}

const formatInternationalCurrency = (
  language: string,
  number?: number,
  currency?: string,
  decimalPrecision?: number
) => {
  if (number)
    return new Intl.NumberFormat(language, {
      style: "currency",
      currency: currency || "EUR",
      maximumFractionDigits: decimalPrecision,
    }).format(number)
}

const formatInternationalNumber = (
  language: string,
  number?: number,
  decimalPrecision?: number
) => {
  if (typeof number === "number")
    return new Intl.NumberFormat(language, {
      maximumSignificantDigits: decimalPrecision,
    }).format(number)
}

const disableScroll = () => {
  if (typeof window === "undefined") return
  const body = window.document.body
  if (!body.getAttribute("data-scroll")) {
    body.setAttribute("data-scroll", "1")
    body.style.position = "fixed"
    body.style.height = "100vh"
    body.style.overflowY = "hidden"
    return
  }
  body.removeAttribute("data-scroll")
  body.style.position = "relative"
  body.style.height = "auto"
  body.style.overflowY = "scroll"
}

const getParamsFromUrl = (param: string) => {
  if (!isBrowser) return null
  const { search } = window.location
  const params = new URLSearchParams(search)
  return params.get(param)
}

const slugifyString = (string: string) => {
  return string.split(" ").join("-").toLowerCase()
}

export {
  changeLanguageHandler,
  createArrayFromJson,
  rowalizer,
  createGAEvent,
  formatInternationalCurrency,
  formatInternationalNumber,
  disableScroll,
  getParamsFromUrl,
  slugifyString,
}
