import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Body, Box, Container, Heading, VStack, Tag } from "@youngagency/ui"
import { Container as CustomContainer } from "../../../../components/layout"
import {
  CardContent,
  CardGrid,
  CardStack,
  CardTexts,
} from "../../../../components/cards"
import { Card } from "../../../../components/cards"

export const LicenseSections = () => {
  const { t } = useTranslation()

  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              dangerouslySetInnerHTML={{
                __html: t("clubs_page.b2b.licence_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              style={{
                maxWidth: "700px",
              }}
              color="grey200"
              fontWeight={600}
            >
              {t("clubs_page.b2b.licence_section.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>
      <Box
        marginTop={{
          mobile: 32,
          md: 40,
        }}
      >
        <VStack
          spacing={24}
          align="center"
          justify="center"
          atoms={{
            width: "full",
            mx: "auto",
          }}
        >
          <CardGrid>
            <CardStack>
              <Card
                isAnimated
                hasShadow
                disableHover
                background="var(--grey-20-v2)"
                orientation="column"
                shape="square"
              >
                <CardContent shape="square">
                  <CardTexts>
                    <Tag size={24} colorScheme="grey">
                      {t("clubs_page.b2b.licence_section.card_1.label")}
                    </Tag>
                    <Heading as="h3" variant="6" color="black" fontWeight={600}>
                      {t("clubs_page.b2b.licence_section.card_1.title")}
                    </Heading>
                    <Body variant="1" color="grey200" fontWeight={600}>
                      {t("clubs_page.b2b.licence_section.card_1.description")}
                    </Body>
                  </CardTexts>
                  <Body
                    atoms={{
                      marginTop: "auto",
                      marginBottom: {
                        mobile: 24,
                        md: 56,
                      },
                    }}
                    variant="1"
                    color="grey200"
                    fontWeight={600}
                  >
                    {t("clubs_page.b2b.licence_section.card_1.website")}
                  </Body>
                </CardContent>
              </Card>
              <Card
                isAnimated
                hasShadow
                disableHover
                background="var(--grey-20-v2)"
                orientation="column"
                shape="square"
              >
                <CardContent shape="square">
                  <CardTexts>
                    <Tag size={24} colorScheme="grey">
                      {t("clubs_page.b2b.licence_section.card_2.label")}
                    </Tag>
                    <Heading as="h3" variant="6" color="black" fontWeight={600}>
                      {t("clubs_page.b2b.licence_section.card_2.title")}
                    </Heading>
                    <Body variant="1" color="grey200" fontWeight={600}>
                      {t("clubs_page.b2b.licence_section.card_2.description")}
                    </Body>
                  </CardTexts>
                  <Body
                    atoms={{
                      marginTop: "auto",
                      marginBottom: {
                        mobile: 24,
                        md: 56,
                      },
                    }}
                    variant="1"
                    color="grey200"
                    fontWeight={600}
                  >
                    {t("clubs_page.b2b.licence_section.card_2.website")}
                  </Body>
                </CardContent>
              </Card>
            </CardStack>
          </CardGrid>
        </VStack>
      </Box>
    </>
  )
}
