import { Box, Icon } from "@youngagency/young-ui"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import Values from "../../../../components/jobs/Values"
import {
  Container,
  ResponsiveButton,
  ResponsiveText,
} from "../../../../components/layout"
import Positions from "../../../../components/jobs/positions.component.js"

export const LegacyJobsComponent = ({
  positions,
}: {
  positions: readonly any[]
}) => {
  const { t } = useTranslation()

  const PositionsRef = React.useRef<null | HTMLDivElement>(null)

  const scrollToPosition = React.useCallback(() => {
    if (typeof window != undefined && PositionsRef && PositionsRef.current) {
      window.scrollTo({
        top: PositionsRef.current?.getBoundingClientRect()?.top - 96,
        left: 0,
        behavior: "smooth",
      })
    }
  }, [])
  return (
    <>
      <Box maxWidth="756px" mx="auto" mt={["24px", "48px"]}>
        <ResponsiveText
          line={["39px", "96px"]}
          fontWeight={700}
          dimension={["34px", "96px"]}
          as="h1"
          textAlign="center"
        >
          {t("jobs.title")}
        </ResponsiveText>
      </Box>
      <Box display="flex" justifyContent={"center"} mt={["34px", "24px"]}>
        <ResponsiveButton
          variant="primary"
          color="green"
          rightIcon={<Icon icon="chevron_down" />}
          onClick={() => scrollToPosition()}
        >
          {t("jobs.button")}
        </ResponsiveButton>
      </Box>
      <Container mt={["48px", "96px"]} display="block">
        <StaticImage
          src="../images/jobs-image.jpg"
          alt="work with us"
          loading="eager"
          placeholder="dominantColor"
          style={{
            borderRadius: "24px",
            transform: "translateZ(0)",
          }}
        />
      </Container>
      <Values />
      <Positions PositionsRef={PositionsRef} positions={positions} />
    </>
  )
}
