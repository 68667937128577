import "src/feature/b2b/components/home/b2b.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.18.0_webpack@5.88.2/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/feature/b2b/components/home/b2b.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61Uy7LaMAzd8xXqojPchTMJjwBm0z/p+MZK4taxPY4DoR3+veO8MI8LLLrKRI8j6ejI0c+k/aMS3MXwdwZQoihKR4E1Tu9nAJ8s+11Y3ShO4cDsnJDC4omkcUwOi48uQluOlljGRVNTWC5M681HwV1JIYnj7/7XMM6FKigsVt5/nkVj3aSry0VtJDtRyCV2+f5LuLCYOaEVhUzLplLe86upnchPJNPKoXIUMlQOrXcxKQpFhMOqDs3jUH0zQe1FV7tithCKOG0etLekpT6g7QIvZJBMS22vKFn3lMA3URltHVMuYOe98KDs6pqVwgru4XoWSMEMhcRi5W1WH0NDALL+HyDpY5Ap4Y6xTZcwLJxIzB2F1bZXxWi1/UJuzcEKLsZP7ZyuLvaBUg9MRpXde2p3kkih1rLvN/Q92kYv52CMbTeGROfQktqwrJMvGeQthUIy6iqOtvtJRxTiTv+lcNjlIQWlj5aZ/Ts6t/o4mX1SmHxzU16XudRHCqXgHNVV+7snZ3WLGwqiv98AiD0G+uLUnhzn0P0iuasQnFjW2Nrvxmgx5tUZ85tMosRn/aiQCwZ1ZhEVMMVhXgk1CmGTbk370SFF1w/b5RXImMzmSRwfSiCwS03bPWPBEzWq8hyirAYUr3/isDKSOSQ9czWFJLeQROvc9ljTdcR3QOsXQB7Ew70ESgegKWSZ9n0/b7JHvkLaDEgVa0cil7t0JOEV50nsL/ML0idSY9PCZvGA2Lsx0tV7Y1xGOf8DDn3pOcUGAAA=\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.18.0_webpack@5.88.2/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var __esModule = true;
export var gridLeft = '_1xzn1e94';
export var gridRight = '_1xzn1e95';
export var hero = '_1xzn1e90';
export var heroBlackBtn = '_1xzn1e93';
export var heroContent = '_1xzn1e91';
export var parallax = '_1xzn1e98';
export var scroller = '_1xzn1e99';
export var scrollerItem = '_1xzn1e9a';
export var textMargin = '_1xzn1e92';
export var vanityGrid = '_1xzn1e96';
export var vanityGridItem = '_1xzn1e97';