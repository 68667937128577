import React from "react"
import { ThemeProvider } from "styled-components"

const breakpoints = ["768px", "1100px", "1200px"]

breakpoints.lg = breakpoints[2]
breakpoints.md = breakpoints[1]
breakpoints.sm = breakpoints[0]

const theme = {
  breakpoints,
}

const MediaProvider = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default MediaProvider
