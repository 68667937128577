exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-business-tsx": () => import("./../../../src/pages/business.tsx" /* webpackChunkName: "component---src-pages-business-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-glossary-tsx": () => import("./../../../src/pages/glossary.tsx" /* webpackChunkName: "component---src-pages-glossary-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-tsx": () => import("./../../../src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-newsletter-completed-tsx": () => import("./../../../src/pages/newsletter-completed.tsx" /* webpackChunkName: "component---src-pages-newsletter-completed-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-talking-about-us-js": () => import("./../../../src/pages/talking-about-us.js" /* webpackChunkName: "component---src-pages-talking-about-us-js" */),
  "component---src-pages-why-us-js": () => import("./../../../src/pages/why-us.js" /* webpackChunkName: "component---src-pages-why-us-js" */),
  "component---src-pages-young-world-tsx": () => import("./../../../src/pages/young-world.tsx" /* webpackChunkName: "component---src-pages-young-world-tsx" */),
  "component---src-templates-about-us-pages-founder-tsx": () => import("./../../../src/templates/about-us/pages/Founder.tsx" /* webpackChunkName: "component---src-templates-about-us-pages-founder-tsx" */),
  "component---src-templates-blog-wp-article-tsx": () => import("./../../../src/templates/blog/wp/Article.tsx" /* webpackChunkName: "component---src-templates-blog-wp-article-tsx" */),
  "component---src-templates-blog-wp-category-tsx": () => import("./../../../src/templates/blog/wp/Category.tsx" /* webpackChunkName: "component---src-templates-blog-wp-category-tsx" */),
  "component---src-templates-blog-wp-home-tsx": () => import("./../../../src/templates/blog/wp/Home.tsx" /* webpackChunkName: "component---src-templates-blog-wp-home-tsx" */),
  "component---src-templates-blog-wp-paginated-category-tsx": () => import("./../../../src/templates/blog/wp/PaginatedCategory.tsx" /* webpackChunkName: "component---src-templates-blog-wp-paginated-category-tsx" */),
  "component---src-templates-blog-wp-tags-tsx": () => import("./../../../src/templates/blog/wp/Tags.tsx" /* webpackChunkName: "component---src-templates-blog-wp-tags-tsx" */),
  "component---src-templates-clubs-club-home-page-tsx": () => import("./../../../src/templates/clubs/ClubHomePage.tsx" /* webpackChunkName: "component---src-templates-clubs-club-home-page-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/Faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-glossary-term-tsx": () => import("./../../../src/templates/GlossaryTerm.tsx" /* webpackChunkName: "component---src-templates-glossary-term-tsx" */),
  "component---src-templates-landing-b-2-b-business-user-tsx": () => import("./../../../src/templates/landing/b2b/BusinessUser.tsx" /* webpackChunkName: "component---src-templates-landing-b-2-b-business-user-tsx" */),
  "component---src-templates-landing-ooh-index-tsx": () => import("./../../../src/templates/landing/ooh/index.tsx" /* webpackChunkName: "component---src-templates-landing-ooh-index-tsx" */),
  "component---src-templates-legal-home-tsx": () => import("./../../../src/templates/legal/Home.tsx" /* webpackChunkName: "component---src-templates-legal-home-tsx" */),
  "component---src-templates-legal-legal-template-tsx": () => import("./../../../src/templates/legal/LegalTemplate.tsx" /* webpackChunkName: "component---src-templates-legal-legal-template-tsx" */),
  "component---src-templates-token-all-token-list-tsx": () => import("./../../../src/templates/token/AllTokenList.tsx" /* webpackChunkName: "component---src-templates-token-all-token-list-tsx" */),
  "component---src-templates-token-listed-token-page-tsx": () => import("./../../../src/templates/token/ListedTokenPage.tsx" /* webpackChunkName: "component---src-templates-token-listed-token-page-tsx" */),
  "component---src-templates-token-token-fiat-tsx": () => import("./../../../src/templates/token/TokenFiat.tsx" /* webpackChunkName: "component---src-templates-token-token-fiat-tsx" */),
  "component---src-templates-token-token-tsx": () => import("./../../../src/templates/token/Token.tsx" /* webpackChunkName: "component---src-templates-token-token-tsx" */),
  "component---src-templates-token-tradable-token-list-tsx": () => import("./../../../src/templates/token/TradableTokenList.tsx" /* webpackChunkName: "component---src-templates-token-tradable-token-list-tsx" */),
  "component---src-templates-token-unlisted-tsx": () => import("./../../../src/templates/token/Unlisted.tsx" /* webpackChunkName: "component---src-templates-token-unlisted-tsx" */)
}

