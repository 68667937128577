export const DEFAULT_LANGUAGE = [
  {
    slug: null,
    language: {
      code: "it",
      locale: "it_IT",
    },
  },
  {
    slug: null,
    language: {
      code: "en",
      locale: "en_GB",
    },
  },
  {
    slug: null,
    language: {
      code: "fr",
      locale: "fr_FR",
    },
  },
]
