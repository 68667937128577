import { Box, BoxProps, Icon } from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { VStack, HStack, Button, Subtitle, Body, Heading } from "@youngagency/ui"
import { useSmartDCAContext } from "./context/SmartDCAContext"
import { ResponsiveText } from "../../../components/layout"

type Props = BoxProps & {
  onClose: () => void
}

export const ClosableBanner = React.forwardRef<HTMLDivElement, Props>(
  ({ onClose, ...rest }, ref) => {
    const { t } = useI18next()
    const { onOpen } = useSmartDCAContext()
    const handleNavigate = React.useCallback(() => {
      onOpen()
    }, [onOpen])

    return (
      <Box
        position="relative"
        height={"96px"}
        width="100%"
        background="var(--black)"
        {...rest}
        ref={ref}
      >
        <Box position="absolute" height={"100%"} display={["none", "block"]} marginLeft={"calc(calc(100% - 1120px) / 2)"} >
          <StaticImage
            quality={100}
            style={{
              height: "100%",
            }}
            src="../images/dyor-desktop.png"
            alt="survey banner"
            placeholder="none"
          />
        </Box>
        <Box
          as="button"
          onClick={onClose}
          position="absolute"
          zIndex={3}
          display={["block", "none"]}
          top="12px"
          right="12px"
        >
          <Icon icon="close" fontSize="20px" />
        </Box>
        <VStack
          spacing={"unset"}
          align="center"
          justify="center"
          atoms={{
            height: "full",
            __zIndex: 2,
            position: "relative",
          }}
        >
          <Box
            mx="auto"
            display={["flex", "flex"]}
            alignItems={"center"}
            justifyContent="space-between"
            maxWidth={["100%", "720px"]}
            width={["calc(100% - 48px)", "100%"]}
          >
            <Box>

              <ResponsiveText
                line={["24px", "32px"]}
                dimension={["16px", "24px"]}
                fontWeight={600}
                color="white"
              >
                <ResponsiveText
                  line={["24px", "32px"]}
                  dimension={["16px", "24px"]}
                  fontWeight={600}
                  color="white"
                  style={{display: "inline-block", whiteSpace: "break-spaces"}}
                >
                  DYOR
                    <ResponsiveText
                      line={["24px", "32px"]}
                      dimension={["16px", "24px"]}
                      fontWeight={600}
                      color="green.300"
                      style={{display: "inline-block", whiteSpace: "break-spaces"}}
                    >
                      24
                    </ResponsiveText>
                  :
                  {" "}
                </ResponsiveText>
                {t("communication_banner.dyor.banner.title")}
              </ResponsiveText>

              {/* <Subtitle variant="1" color="white" fontWeight={600}>
                {t("communication_banner.dyor.banner.title")}
              </Subtitle> */}

              <Box display={["none", "block"]}>
                <Body
                  color="white"
                  variant="1"
                  atoms={{
                    __maxWidth: "500px",
                  }}
                >
                  {t("communication_banner.dyor.banner.description")}
                  {/* {` `}
                  <Box
                    as="span"
                    display={["initial", "none"]}
                    onClick={handleNavigate}
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    {t("communication_banner.smartDCA.banner.underline_text")}
                  </Box> */}
                </Body>
              </Box>
            </Box>
            <Box style={{minWidth: "84px"}}>
              <Box display={["block", "block"]}>
                <HStack spacing={12}>
                  <Button
                    size={36}
                    // onClick={handleNavigate}
                    onClick={typeof window !== "undefined" ? () => window.open("https://www.eventbrite.it/e/biglietti-dyor24-levento-online-che-riunisce-esperti-di-finanza-e-crypto-in-italia-1023274971987", "_blank") : undefined}
                    colorScheme="white"
                  >
                    {t("communication_banner.dyor.banner.button_text")}
                  </Button>
                  {/* <Button  variant="ghost" size={36} onClick={onClose}>
                    {t(
                      "communication_banner.smartDCA.banner.close_button_text"
                    )}
                  </Button> */}
                </HStack>
              </Box>
            </Box>
          </Box>
        </VStack>
      </Box>
    )
  }
)
