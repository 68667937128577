import {
  Box,
  BoxProps,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  SelectProps,
  Skeleton,
  useHasMounted,
} from "@youngagency/young-ui"
import React from "react"
import { BorderBox, RowStack } from "../../../../components/layout"
import styled from "styled-components"
import { css } from "@styled-system/css"
import { OptionType } from "@youngagency/young-ui/dist/src/components/select/shared"
import { Paragraph } from "../../../../components"
import {
  ConversorEntry,
  useConversorContext,
} from "../context/ConversorContext"
import * as yup from "yup"
import isNumber from "lodash/isNumber"
import isFinite from "lodash/isFinite"

const validation = yup.number().positive()

const OptionBox = styled(Box)(
  css({
    display: "flex",
    cursor: "pointer",
    width: "100%",
    height: "60px",
    alignItems: "center",
    ":hover": {
      backgroundColor: "grey.5",
    },
  })
)

const OptionsContainer = styled(BorderBox)(
  css({
    mt: "16px",
    position: "absolute",
    top: "56px",
    borderRadius: "8px",
    maxHeight: "180px",
    maxWidth: ["unset", "295px"],
    overflowY: "scroll",
    width: "100%",
    background: "white",
    zIndex: 2,
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.08)",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  })
)

const Option = ({ label, value, subtitle, ...rest }: OptionType & BoxProps) => {
  return (
    <OptionBox {...rest}>
      <Box
        width="100%"
        px="8px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex">
          <Paragraph
            weight="semibold"
            style={{
              userSelect: "none",
            }}
          >
            {label}
          </Paragraph>
          <Paragraph
            color="grey.1"
            ml="8px"
            style={{
              userSelect: "none",
            }}
          >
            {subtitle}
          </Paragraph>
        </Box>
        <img
          width={36}
          height={36}
          src={value as string}
          alt={label}
          style={{
            userSelect: "none",
          }}
        />
      </Box>
    </OptionBox>
  )
}

type SelectorProps = ConversorEntry & {
  options: SelectProps["options"]
  readonly?: true
}

export const AbrateSelector: React.FC<SelectorProps> = ({
  options,
  value,
  symbol,
  img,
  readonly,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const { handleChange, changeFromValue } = useConversorContext() || {}
  const hasMounted = useHasMounted()
  const clickOutsideElement = React.useCallback(() => {
    setIsOpen(false)
  }, [])

  const openOption = React.useCallback((e: React.BaseSyntheticEvent) => {
    e.stopPropagation()
    setIsOpen(old => !old)
  }, [])

  React.useEffect(() => {
    if (hasMounted && isOpen) {
      window.addEventListener("click", clickOutsideElement)
      return () => window.removeEventListener("click", clickOutsideElement)
    }
  }, [hasMounted, isOpen, clickOutsideElement])

  const selectOption = React.useCallback(
    (option: OptionType) => {
      if (typeof handleChange === "function") {
        return handleChange(option)
      }
    },
    [handleChange]
  )

  const handleInputValue = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (readonly || !changeFromValue) return
      const { value } = e.target
      const isDeleting = e?.nativeEvent?.inputType === "deleteContentBackward"
      const currentValue = value.replace(",", ".").replace(" ", "")

      const decimal = currentValue.split(".").length - 1
      if (currentValue === "") return changeFromValue(0)
      const lastValueChar = currentValue.charAt(currentValue.length - 1)

      const inputCondition =
        value.length + 1 < 16 &&
        (isNumber(Number(currentValue)) || [".", ","].indexOf(lastValueChar))
      if ((inputCondition && decimal <= 1) || isDeleting) {
        if ([".", ","].indexOf(lastValueChar) > -1 || isDeleting) {
          return changeFromValue(currentValue)
        }
        if (isFinite(Number(currentValue))) {
          const numberValue =
            currentValue.startsWith("0") && !currentValue.includes(".") && 0
          if (numberValue === 0) {
            changeFromValue(Number(lastValueChar))
          } else {
            changeFromValue(currentValue)
          }
        }
      }
    },
    [changeFromValue]
  )
  return (
    <Box position="relative" width="100%" maxWidth={["unset", "295px"]}>
      <InputGroup>
        <Input
          placeholder={symbol}
          value={value}
          autoComplete="false"
          onChange={handleInputValue}
          disabled={readonly}
        />
        <InputRightElement
          onClick={e => openOption(e)}
          style={{
            cursor: "pointer",
          }}
        >
          <RowStack alignItems="center">
            {img ? (
              <img height={36} width={36} src={img} alt={symbol}/>
            ) : (
              <Skeleton height="36px" width="36px" variant="circle" mr="8px" />
            )}

            <Icon
              icon="chevron_down"
              fontSize="24px"
              color="grey.1"
              className={isOpen ? "rotate-icon" : ""}
              style={{
                transition: "175ms ease",
              }}
            />
          </RowStack>
        </InputRightElement>
      </InputGroup>
      {isOpen ? (
        <OptionsContainer onClick={e => e.stopPropagation()}>
          {options?.map(option => {
            return (
              <Option
                label={option.label}
                value={option.value}
                subtitle={option.subtitle}
                key={option.label}
                onClick={() => selectOption(option as OptionType)}
              />
            )
          })}
        </OptionsContainer>
      ) : null}
    </Box>
  )
}
