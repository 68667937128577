import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState = {
  isLoading: false,
  isAuthenticated: null,
  authorization: null,
  referralId: "",
}

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    saveReferralId(state, action: PayloadAction<string>) {
      state.referralId = action.payload
    },
  },
})

export const { saveReferralId } = slice.actions

export default slice.reducer
