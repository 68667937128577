import React from "react"
import styled from "styled-components"
import { css } from "@styled-system/css"
import { BorderBox } from "../../../../components/layout"

export const BannerContainer = styled(BorderBox)(
  css({
    borderRadius: "16px",
    maxWidth: "756px",
    width: "100%",
    transition: "all 75ms linear",
    "&:hover": {
      backgroundColor: ["grey.7", "white"],
    },
  })
)
