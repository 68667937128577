import React from "react"
import styled from "styled-components"
import { css } from "@styled-system/css"
import { Box } from "@youngagency/young-ui"
import { Label } from "../../../components"
import { useI18next } from "gatsby-plugin-react-i18next"
// import { StaticImage } from "gatsby-plugin-image"
import { Container, RowStack } from "../../../components/layout"
import { navigate } from "@reach/router"
import { triggerClickToExchangeEvents } from "../../../utils/general"
import { Text } from "../../../components/texts/temporary"

const Banner = styled(Box)<{ isBlog?: true }>(({ isBlog }) =>
  css({
    background: "#004588",
    height: isBlog ? ["48px", "64px"] : ["48px", "38px"],
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
    position: isBlog ? ["static", "fixed"] : "static",
    bottom: "0px",
  })
)

export const PromoNavigationBanner = ({ isBlog }: { isBlog?: true }) => {
  const { t } = useI18next()
  const handleNavigate = React.useCallback(() => {
    const link = "https://youngexchange.page.link/SPRINGCASHBACK"
    triggerClickToExchangeEvents()
    navigate(link)
  }, [])
  return (
    <Banner onClick={handleNavigate} isBlog={isBlog}>
      <Container size={["md", "lg"]} mx="auto" width="100%" height="100%">
        <RowStack
          rGap={["8px", "48px"]}
          width="100%"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          {/* <Box maxWidth={["84px", "63px"]} alignSelf="end">
            <StaticImage
              src="../images/navigation_promo_img.png"
              alt="Young Xmas Promo"
              placeholder="blurred"
            />
          </Box> */}
          <Box alignSelf="center">
            {isBlog ? (
              <>
                <Box display={["none", "block"]}>
                  <Text.Subtitle2
                    color="white"
                    textAlign="center"
                    style={{
                      textDecoration: "underline",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: t("promo.navigation_banner.text"),
                    }}
                  />
                </Box>
                <Box display={["block", "none"]}>
                  <Text.Caption
                    color="white"
                    textAlign="center"
                    style={{
                      textDecoration: "underline",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: t("promo.navigation_banner.text"),
                    }}
                  />
                </Box>
              </>
            ) : (
              <Label
                color="white"
                textAlign="center"
                style={{
                  textDecoration: "underline",
                }}
                dangerouslySetInnerHTML={{
                  __html: t("promo.navigation_banner.text"),
                }}
              />
            )}
          </Box>
          {/* <Box maxWidth={["84px", "63px"]} alignSelf="end">
            <StaticImage
              src="../images/navigation_promo_img.png"
              alt="Young Xmas Promo"
              placeholder="blurred"
            />
          </Box> */}
        </RowStack>
      </Container>
    </Banner>
  )
}
