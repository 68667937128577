import { useMemo } from "react"
import {
  DEFAULT_TIMEFRAME_LIST,
  DEFAULT_CANDLESTICK_VARIATION_INTERVAL,
  DEFAULT_TIMEFRAME_VARIATION,
  TimeFrame,
  TimeFrameListKeys,
} from "../../constants"
import { useSelector } from "react-redux"
import { RootState } from "../store"

export const useGetTokenTimeFrameByKey = (
  optionalTimeFrame?: TimeFrameListKeys
): TimeFrame => {
  const { chartType, timeFrameSelected } = useSelector(
    (store: RootState) => store.uiStyle
  )

  const usedTimeFrame = useMemo(() => optionalTimeFrame || timeFrameSelected, [
    timeFrameSelected,
  ])

  const chartTimeFrame = useMemo(() => {
    switch (chartType) {
      case "candlestick":
        return DEFAULT_CANDLESTICK_VARIATION_INTERVAL
      default:
        return DEFAULT_TIMEFRAME_LIST
    }
  }, [chartType])
  const finaleTimeframe = useMemo(
    () => chartTimeFrame.find(timeframe => timeframe.key === usedTimeFrame),
    [chartTimeFrame, usedTimeFrame]
  )
  return finaleTimeframe || DEFAULT_TIMEFRAME_VARIATION
}
