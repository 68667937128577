import { Box, css } from "@youngagency/young-ui"
import { graphql, useStaticQuery } from "gatsby"
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { ResponsiveText } from "../../../components/layout"
import styled from "styled-components"

const CustomGridBox = styled(Box)(
  css({
    display: "grid",
    gridTemplateColumns: ["repeat(2, 1fr)", "repeat(6, 1fr)"],
    gridTemplateRows: ["repeat(3, 1fr)", "1fr"],
    columnGap: "20px",
    rowGap: ["20px", "unset"],
  })
)

type Props = {
  allFile: {
    nodes: (ImageDataLike & { name: string; id: string })[]
  }
}

export const Investors = () => {
  const data: Props = useStaticQuery(query)
  const { t } = useI18next()

  return (
    <Box>
      <ResponsiveText
        line={["25px", "39px"]}
        dimension={["20px", "34px"]}
        as="h2"
        fontWeight={600}
      >
        {t("about_us.founders.investors")}
      </ResponsiveText>
      <CustomGridBox mt="24px">
        {data.allFile.nodes.map((img, index) => {
          return (
            <Box maxWidth={["unset", "174px"]}>
              <GatsbyImage
                image={getImage(img) as IGatsbyImageData}
                key={img.id}
                alt={img.name}
              />
            </Box>
          )
        })}
      </CustomGridBox>
    </Box>
  )
}

const query = graphql`
  query CryptoWinterInvestorsImages {
    allFile(filter: { sourceInstanceName: { eq: "about_us_images" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
          id
        }
        name
      }
    }
  }
`
