import React from "react"
import { Box, BoxProps } from "@youngagency/young-ui"
import { ResponsiveText } from "./ResponsiveText"
import { Container } from "./Container"
import styled from "styled-components"

type Props = {
  text: string
  lightText?: true
  children: React.ReactNode
} & BoxProps

const StyledBox = styled(Box)`
  .responsive-download-banner {
    flex-direction: row;
    flex-wrap: wrap;
    img {
      height: 44px !important;
    }
  }
  @media screen and (min-width: 767px) {
    .responsive-download-banner {
      img {
        height: unset !important;
      }
    }
  }
`

export const BottomBanner = ({ text, lightText, children, ...rest }: Props) => {
  return (
    <StyledBox
      py={["48px", "96px"]}
      mt={["52px", "96px"]}
      backgroundColor="grey.4"
      {...rest}
    >
      <Container>
        <Box maxWidth={["344px", "756px"]} mx="auto">
          <ResponsiveText
            textAlign="center"
            line={["30px", "50px"]}
            dimension={["24px", "48px"]}
            as="h2"
            fontWeight={600}
            color={lightText ? "white" : "black"}
          >
            {text}
          </ResponsiveText>
        </Box>
        <Box display="flex" justifyContent="center" mt={["16px", "24px"]}>
          {children}
        </Box>
      </Container>
    </StyledBox>
  )
}

export default BottomBanner
