import React, { useEffect } from "react"
import { CompactFooter } from "./CompactFooter"
import { CookieConsent } from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import { AdvertisingHeader } from "./AdvertisingHeader"
import "../../../components/layout.css"
import "../../../components/landing.css"
import { useHasMounted } from "@youngagency/ui"

export const AdvertisingLayout = ({
  children,
  link,
  customCTAComponent,
  footerColorVariant = "black",
}: {
  children: React.ReactNode
  link: string
  customCTAComponent?: React.ReactNode
  footerColorVariant?: "white" | "black"
}) => {
  const { language } = useI18next()
  const isMounted = useHasMounted()
  useEffect(() => {
    if (isMounted()) {
      const hasBusinessLayout = document.body.classList.contains(
        "business-layout"
      )
      if (!hasBusinessLayout) return
      document.body.classList.remove("business-layout")
    }
  }, [isMounted])
  return (
    <>
      <AdvertisingHeader link={link} customCTAComponent={customCTAComponent} />
      <main className="without-banner">{children}</main>
      <CookieConsent
        lang={language}
        apiKey={process.env.COOKIE_API_KEY as string}
        siteId={Number(process.env.COOKIE_SITE_ID)}
        cookiePolicyId={Number(process.env.COOKIE_POLICY_ID)}
      />
      <CompactFooter footerColorVariant={footerColorVariant} />
    </>
  )
}
