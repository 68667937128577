import React from "react"
import {
  Skeleton,
  Box,
  VStack,
  HStack,
  useHasMounted,
} from "@youngagency/young-ui"
import styled from "styled-components"
import { ImageOverlay, ResponsiveText } from "../../layout"
import { isInViewport } from "../../../utils/mucciaccia"
import { useI18next } from "gatsby-plugin-react-i18next"
import { SingleArticleResponse } from "../../../redux/services/academy"
import { navigate } from "@reach/router"
import {  useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { globalEvents } from "../../../service/tracking"

const StyledBox = styled(HStack)`
  overflow-x: scroll;
  flex-wrap: nowrap;
  align-items: baseline;
  & > *:not(:first-of-type) {
    margin-left: 20px;
  }
  &&::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    & .academy-box:hover {
      cursor: pointer;
      [data-role="_text"] {
        text-decoration: underline;
      }
      [data-role="_overlay"] {
        opacity: 1;
      }
    }
  }
`

const StyledImg = styled.img`
  display: flex;
  width: 100%;
  min-height: 0px;
  max-height: 207px;
  height: 100%;
`

const StyledHStack = styled(HStack)`
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1024px) {
    display: none;
  }
`

const AcademyCarousel = ({
  data,
  loading,
}: {
  data?: Omit<SingleArticleResponse, "description">[]
  loading: boolean
}) => {
  const [visible, setVisible] = React.useState<number>(1)

  if (!loading && data && data.length > 0) {
    return (
      <>
        <StyledBox
          onScroll={e => {
            let event = new CustomEvent("carousel", { bubbles: true })
            e.currentTarget.dispatchEvent(event)
          }}
        >
          {data.map((el, index) => {
            return (
              <AcademyBox
                key={el.title}
                {...el}
                position={index + 1}
                setVisible={setVisible}
              />
            )
          })}
        </StyledBox>
        <StyledHStack mt="24px" spacing="10px">
          {Array.from({ length: 3 }, (_, index) => {
            return (
              <Box
                key={`${index}-carousel`}
                backgroundColor={index + 1 === visible ? "green.300" : "grey.4"}
                width="6px"
                height="6px"
                borderRadius="50%"
              ></Box>
            )
          })}
        </StyledHStack>
      </>
    )
  }
  return (
    <StyledBox mt={["48px", "92px"]} spacing="20px">
      {Array.from({ length: 3 }).map((_, index) => {
        return <SkeletonAcademyArticle key={`skeleton-${index}`} />
      })}
    </StyledBox>
  )
}

const SkeletonAcademyArticle = () => {
  return (
    <Box flex="1 0 auto" maxWidth={"365px"} width="100%">
      <VStack align="initial" spacing="24px">
        <Skeleton width={"368px"} height="207px" borderRadius="16px" />
        <VStack align="initial" spacing="10px">
          <Skeleton width="160px" height="16px" borderRadius="4px" />
          <Skeleton width="100%" height="20px" borderRadius="4px" />
          <Skeleton width="100%" height="20px" borderRadius="4px" />
        </VStack>
      </VStack>
    </Box>
  )
}

const AcademyBox = ({
  category,
  image,
  title,
  slug,
  position,
  setVisible,
}: Omit<SingleArticleResponse, "description"> & {
  position: number
  setVisible: React.Dispatch<React.SetStateAction<number>>
}) => {
  const [load, setLoad] = React.useState<boolean>(true)
  const { language } = useI18next()
  const hasMounted = useHasMounted()
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)

  const ctaLink = React.useMemo(
    () =>
      `https://academy.youngplatform.com${
        language === "it" ? "" : `/${language}`
      }/${category.slug}/${slug}/`,
    [language]
  )

  const handleArticleClick = React.useCallback(() => {
    if(gaTracker){
      gaTracker.trackEvent({
        eventName: globalEvents.click_to_academy
      })
    }
    navigate(ctaLink)
  }, [ctaLink, gaTracker])

  const detectVisibleElement = React.useCallback(
    (id: string) => () => {
      if (!hasMounted) return
      const element = document.getElementById(id) as HTMLElement
      const isVisible = isInViewport(element)
      if (isVisible) {
        setVisible(position)
      }
    },
    [hasMounted]
  )

  React.useEffect(() => {
    if (!hasMounted) return
    window.addEventListener(
      "carousel",
      detectVisibleElement(`academy-${position}-box`)
    )

    return () =>
      window.removeEventListener(
        "carousel",
        detectVisibleElement(`academy-${position}-box`)
      )
  }, [hasMounted, detectVisibleElement])
  return (
    <Box
      id={`academy-${position}-box`}
      as="article"
      flex="1 0 auto"
      maxWidth={["320px", "365px"]}
      width="100%"
      className="academy-box"
      onClick={handleArticleClick}
    >
      <Box>
        <VStack align="initial" spacing="24px">
          <Box
            maxWidth={["unset", "365px"]}
            width="100%"
            borderRadius="16px"
            position="relative"
            overflow="hidden"
          >
            <img
              style={{ display: "none" }}
              src={image.src}
              onLoad={() => setLoad(false)}
              alt={image.altText}
            />
            {!load ? (
              <>
                <StyledImg src={image.src} alt={image.altText} />
                <ImageOverlay data-role="_overlay" />
              </>
            ) : (
              <Skeleton width={"100%"} height="207px" borderRadius="16px" />
            )}
          </Box>
          <VStack align="initial" spacing="10px">
            <ResponsiveText
              line={["18px", "18px"]}
              dimension={["12px", "12px"]}
              fontWeight={600}
              data-role="_text"
            >
              {category.name}
            </ResponsiveText>

            <ResponsiveText
              line={["20px", "29px"]}
              dimension={["18px", "24px"]}
              fontWeight={700}
              data-role="_text"
            >
              {title}
            </ResponsiveText>
          </VStack>
        </VStack>
      </Box>
    </Box>
  )
}

export default AcademyCarousel
