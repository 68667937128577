import {
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react"
import { GetTokenATValuesByTimeframeRequest } from "../crypto-compare/types"
import {
  athAtlparser,
  athAtlparserV2,
  parseCurrencies,
  parseFiatList,
  TokenATH_ATL,
} from "./parser"
import { FiatCurrency, GetCurrenciesAPI, GetCurrenciesResponse } from "./types"
import { getTimeFrame, generteUrl, getTimeFrameURL } from "./utils"
import { environment } from "../../../constants/env"
import { defaultChartsURL } from "../../../service/charts/constants"
import { ChartsV2SingleToken } from "../../../service/charts/types"

const baseUrl =
  environment.isProd || environment.isLocal
    ? process.env.SETUP_BACKEND_URL
    : process.env.SETUP_BACKEND_TEST_URL

export const currencyApi = createApi({
  reducerPath: "currency",
  baseQuery: fetchBaseQuery({
    baseUrl,
  }),
  endpoints: builder => ({
    getAllCurrencies: builder.query<GetCurrenciesResponse, void>({
      query: () => "/api/v2/currencies",
      transformResponse: (data: GetCurrenciesAPI) => parseCurrencies(data),
    }),
    getAllAvailableFiat: builder.query<FiatCurrency[], void>({
      query: () => "/api/v2/currencies",
      transformResponse: (data: GetCurrenciesAPI) => parseFiatList(data),
      keepUnusedDataFor: 60 * 15,
    }),
    getAllTimeFrameATValuesV2: builder.query<
      Record<string, TokenATH_ATL>,
      Omit<GetTokenATValuesByTimeframeRequest, "timestamp">
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const url = `${defaultChartsURL}/charts/${_arg.from}-EUR?`
        const chosenTimeFrame = {
          day: getTimeFrame("day"),
          week: getTimeFrame("week"),
          all: getTimeFrame("2years"),
        }

        const day = ((await fetchWithBQ(
          url + getTimeFrameURL(chosenTimeFrame.day)
        )) as unknown) as { data: ChartsV2SingleToken }
        const week = ((await fetchWithBQ(
          url + getTimeFrameURL(chosenTimeFrame.week)
        )) as unknown) as { data: ChartsV2SingleToken }
        const all = ((await fetchWithBQ(
          url + getTimeFrameURL(chosenTimeFrame.all)
        )) as unknown) as { data: ChartsV2SingleToken }

        const result = {
          day: athAtlparserV2(day.data),
          week: athAtlparserV2(week.data),
          all: athAtlparserV2(all.data),
        }

        return {
          data: result,
        }
      },
    }),
  }),
})

export const {
  useGetAllCurrenciesQuery,
  useGetAllAvailableFiatQuery,
  useGetAllTimeFrameATValuesV2Query,
} = currencyApi
