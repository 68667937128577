import styled from "styled-components"

export const PostHoverBox = styled("div")({
  "&:hover .box_hover": {
    "[data-role='_text']": {
      textDecoration: "underline",
    },
    "[data-role='_overlay']": {
      opacity: 1,
      borderRadius: "16px",
    },
  },
})
