import isEmpty from "lodash/isEmpty"
import { DEFAULT_LANGUAGE } from "../constant"
import {
  ArticleTranslations,
  ParsedTranslation,
  CategoryTranslations,
  TagTranslations,
  PageWithTranslations,
  GlossaryTransaltions,
} from "../types"
import { createWPLanguage, findTranslationCategory } from "../utils"

type TRX = NonNullable<ArticleTranslations>[number] | null

abstract class Translation<TrxType> {
  translations?: readonly TrxType[] | null
  blogPrefix = "blog"
  constructor() {
    this.translations = []
  }

  create(language: string, category?: string | null, slug?: string | null) {}

  parse(category: string | null) {}

  findSlug = (
    trx: TrxType,
    query?: string | null
  ): string | null | undefined => {
    return
  }

  hasTranslation(trx?: any) {
    return !isEmpty(trx)
  }

  translateSlug<T extends ParsedTranslation>(trx: T) {
    return trx?.map(item => ({
      ...item,
      slug: `${
        item.language.code?.toLowerCase() !== "it"
          ? `${item.language.code?.toLowerCase()}/`
          : ""
      }${item.slug}`,
    }))
  }
}

export class ArticleTranslator extends Translation<TRX> {
  constructor(translations?: readonly TRX[] | null) {
    super()
    this.translations = translations
  }

  parse(category?: string | null) {
    if (!this.translations || !category) return null
    const t = [...this.translations]
    const parsedTrx = t
      .filter(x => x)
      .map(trx => ({
        language: {
          code: trx?.language?.code,
          locale: trx?.language?.locale,
        },
        slug: findTranslationCategory(trx)
          ? `${this.blogPrefix}/${findTranslationCategory(trx)}/${trx?.slug}/`
          : null,
      }))
    return parsedTrx
  }

  create(
    language: string,
    category?: string | null,
    slug?: string | null
  ): ParsedTranslation {
    const trx = this.parse(category)
    if (!trx) return null
    trx.unshift({
      language: createWPLanguage(language),
      slug: `${this.blogPrefix}/${category}/${slug}/`,
    })

    return this.translateSlug(trx)
  }
}

type CategoryTRX = NonNullable<CategoryTranslations>[number] | null

export class CategoryTranslator extends Translation<CategoryTRX> {
  constructor(translations?: readonly CategoryTRX[] | null) {
    super()
    this.translations = translations
  }

  create(language: string, slug?: string | null): ParsedTranslation {
    if (!this.translations) return null
    const t = [...this.translations]
    const traslated = t
      .filter(trx => !isEmpty(trx?.posts?.nodes))
      .map(trx => {
        return {
          ...trx,
          language: createWPLanguage(trx!.language!.code!),
          slug: `${this.blogPrefix}/${trx!.slug}/`,
        }
      })

    traslated.unshift({
      language: createWPLanguage(language),
      slug: `${this.blogPrefix}/${slug}/`,
    })

    return this.translateSlug(traslated)
  }
}

type TagTRX = NonNullable<TagTranslations>[number] | null

export class TagTranslator extends Translation<TagTRX> {
  constructor(translations?: readonly TagTRX[] | null) {
    super()
    this.translations = translations
  }

  create(
    language: string,
    slug?: string | null | undefined
  ): ParsedTranslation {
    if (!this.translations) return null
    const t = [...this.translations]
    const translations = t
      .filter(trx => !isEmpty(trx?.posts?.nodes))
      .map(trx => ({
        language: createWPLanguage(trx!.language!.code!),
        slug: `${this.blogPrefix}/tag/${trx!.slug}/`,
      }))
    translations.unshift({
      language: createWPLanguage(language),
      slug: `${this.blogPrefix}/tag/${slug}/`,
    })
    return this.translateSlug(translations)
  }
}

export class StaticTranslator extends Translation<undefined> {
  constructor() {
    super()
    this.translations = []
  }

  create(slug: string): ParsedTranslation {
    const trx = DEFAULT_LANGUAGE.map(lang => ({
      language: createWPLanguage(lang.language.code),
      slug,
    }))
    return this.translateSlug(trx)
  }
}

type PageTRX = PageWithTranslations | null

export class GenericPageTransalator extends Translation<PageTRX> {
  constructor(translations: readonly PageTRX[] | null | undefined) {
    super()
    this.translations = translations
  }

  create(
    language: string,
    prefix?: string | null | undefined,
    slug?: string | null | undefined
  ): ParsedTranslation {
    if (!this.translations) return null
    const t = [...this.translations]
    const trxs = t
      .filter(x => x)
      .map(trx => ({
        language: createWPLanguage(trx!.language!.code!),
        slug: `${prefix ? `${prefix}/` : ""}${trx?.slug || slug}/`,
      }))

    trxs.unshift({
      language: createWPLanguage(language),
      slug: `${prefix ? `${prefix}/` : ""}${slug}/`,
    })

    return this.translateSlug(trxs)
  }
}

type GlossaryTRX = NonNullable<GlossaryTransaltions>[number] | null

export class GlossaryPageTranslator extends Translation<GlossaryTRX> {
  constructor(translations: readonly GlossaryTRX[] | null | undefined) {
    super()
    this.translations = translations
  }

  create(language: string, slug?: string | null | undefined) {
    if (!this.translations) return null
    const t = [...this.translations]
    const trxs = t
      .filter(x => x)
      .map(trx => ({
        language: createWPLanguage(trx!.language!.code!),
        slug: `glossary/${trx?.slug}/`,
      }))

    trxs.unshift({
      language: createWPLanguage(language),
      slug: `glossary/${slug}/`,
    })

    return this.translateSlug(trxs)
  }
}
