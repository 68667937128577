import React from "react"
import { Hero } from "../components/business-user/Hero"
import { Box } from "@youngagency/young-ui"
import { PreviewSection } from "../components/business-user/PreviewSection"
import { LicenseSections } from "../components/business-user/LicenceSection"
import { ResultSection } from "../components/business-user/ResultSection"
import { LogosSection } from "../components/business-user/LogoSections"
import { BannerSection } from "../components/business-user/BannerSection"
import { AdvocacySection } from "../components/business-user/AdvocacySection"
import { FeedbackSection } from "../components/business-user/FeedbackSection"

export const BusinessUserPage = () => {
  return (
    <>
      <Box mt="16px">
        <Hero />
      </Box>
      <Box mt={["48px", "96px"]}>
        <PreviewSection />
      </Box>
      <Box background="black" width="full">
        <Box
          mt={["8px", "16px"]}
          borderRadius={"0px 0px 32px 32px"}
          background="white"
          width="full"
          height={["72px", "48px"]}
          style={{
            transform: "translateY(-1px)",
          }}
        />
        <Box mt={["48px", "96px"]}>
          <LicenseSections />
        </Box>
        <Box mt={["48px", "96px"]}>
          <ResultSection />
        </Box>
        <Box mt={["96px", "192px"]}>
          <LogosSection />
        </Box>
        <Box mt={["48px", "96px"]}>
          <FeedbackSection />
        </Box>
        <Box mt={["48px", "96px"]}>
          <AdvocacySection />
        </Box>
        <Box mt={["48px", "96px"]} pb={["40px", "72px"]}>
          <BannerSection />
        </Box>
      </Box>
    </>
  )
}
