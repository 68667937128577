import { Box, css } from "@youngagency/young-ui"
import styled from "styled-components"

export const TokenRowLayout = styled(Box)<{ hasHover?: true }>(
  css({
    display: ["none", "grid"],
    gridTemplateColumns: ["1fr 1fr", "2fr 1fr 1fr 1fr 1fr 1fr 1fr"],
    justifyContent: ["space-between", "unset"],
    alignItems: "center",
    "& > *": {
      display: ["none", "flex"],
      justifyContent: "center",
    },
    transition: "all 125ms ease",
  }),
  ({ hasHover }) => ({
    ":hover": {
      background: hasHover ? "var(--r-grey-5)" : "unset",
    },
  })
)
