import { VStack } from "@youngagency/young-ui"
import styled from "styled-components"

const ArticleWrapper = styled(VStack)`
  font-family: "Matter";
  & h2 {
    font-size: 34px;
    line-height: 39px;
    font-weight: 600 !important;
    color: #1e1e1f;
  }
  & h3 {
    font-size: 28px;
    line-height: 34px;
    font-weight: 600 !important;
    color: #1c1c1c;
  }
  & h4 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 500 !important;
    color: #1c1c1c;
  }
  & p {
    color: #101010;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    margin-top: 16px;
  }
  & a {
    text-decoration: underline;
    color: var(--green-300-v2);
    transition: all 125ms ease;
    &:hover {
      color: var(--green-300-v2);
    }
  }
  em {
    font-style: italic;
  }
  strong {
    font-weight: 600 !important;
  }
  ul,
  ol {
    width: 100%;
    padding: 0px;
  }

  li {
    list-style: disc;
    margin: auto;
    padding: 4px 0px;
    width: calc(100% - 24px);
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
  }
  ol li {
    list-style: decimal;
  }
  ul li {
    list-style: disc;
  }

  .wp-block-blogapi-custom-btn {
    margin-top: 32px;
    font-weight: 600 !important;
    transition: all 75ms ease;
    margin: inherit auto;

    i {
      color: #fff;
    }
  }

  .wp-block-blogapi-custom-btn:hover {
    background: #00c663 !important;
  }

  .wp-block-blogsandbox-token-banner {
    margin-top: 92px;
    border-radius: 16px;
    width: 100%;
    position: relative;
    overflow: hidden;
    grid-template-columns: 1fr 0.5fr !important;
    padding: 48px 0px;
    text-decoration: none !important;
    img {
      object-fit: contain !important;
      display: block;
    }
    .banner-logo {
      display: flex;
      max-width: 200px;
      img {
        object-fit: contain !important;
        display: block;
      }
    }

    .banner-left {
      padding-left: 24px;
    }

    .banner-title-text {
      font-size: 34px;
      line-height: 39px;
      font-weight: 700;
    }

    [databg="#000000"],
    [databg="#638CFC"],
    [databg="#3664CF"],
    [databg="#0069A0"],
    [databg="#FF298A"],
    [databg="#2F2F2F"] {
      color: white !important;
    }

    .banner-btn {
      align-items: center;
      border-radius: 100px;
      margin-top: 16px;
      padding: 8px 24px;
      transition: all 75ms ease;
      & > * {
        color: white !important;
        &:first-child {
          margin-top: 0px;
        }
      }
      i {
        margin-left: 6px !important;
        color: white;
      }
    }

    .banner-image {
      position: absolute !important;
      bottom: 0px;
      right: 0px;
      width: 100%;
      height: 100%;
      max-height: 282px;
      max-width: 320px;
    }
  }

  .wp-block-image {
    border-radius: 16px;
    a {
      width: 100%;
      height: 100%;

      & img:last-child {
        object-fit: contain !important;
        display: block;
      }
    }
  }

  figure.size-large {
    img {
      width: 100%;
      height: auto;
    }
  }

  @media screen and (max-width: 767px) {
    & h2 {
      font-size: 24px !important;
      line-height: 29px !important;
      font-weight: 600 !important;
      color: #1e1e1f;
    }
    & h3 {
      font-size: 21px;
      line-height: 24px;
      font-weight: 600 !important;
      color: #1c1c1c;
    }
    & h4 {
      font-size: 18px;
      line-height: 21px;
      font-weight: 500 !important;
      color: #1c1c1c;
    }
    & p {
      color: #101010;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      margin-top: 16px;
    }

    .wp-block-embed-twitter {
      display: block;
      .twitter-tweet-rendered {
        margin: 20px auto;
      }
    }
    .wp-block-blogsandbox-token-banner {
      display: flex !important;
      flex-direction: column !important;
      padding-bottom: 0px !important;
      overflow-y: hidden;
      transform: translateZ(0);
      img {
        min-width: 0;
        object-fit: contain !important;
      }

      .banner-logo {
        align-items: center;
        justify-content: center;
        min-width: 0;
        img {
          object-fit: contain !important;
          display: block;
        }
      }

      .banner-left {
        padding-left: 0px;
        align-items: center !important;
        justify-content: center !important;
      }

      .banner-title-text {
        text-align: center;
      }

      .banner-btn {
        align-items: center;
        border-radius: 100px;
        padding: 0px 12px;
        i {
          margin-left: 6px !important;
        }
      }

      .banner-image {
        margin-top: 36px;
        position: relative !important;
        bottom: unset;
        right: unset;
        width: 100% !important;
        max-height: unset;
        max-width: unset;
        display: block;
      }
    }
  }

  & figure {
    display: flex;
    justify-content: center;
  }

  .wp-block-image {
    a {
      width: 100%;
      height: 100%;
      border-radius: 8px !important;

      & img:last-child {
        object-fit: contain !important;
        display: block;
      }
    }
  }

  & figure {
    min-width: 0;
    max-width: 100%;
    width: 100% !important;
    border-radius: 16px;
    .inline-gatsby-image-wrapper {
      max-width: 100%;
      width: 100% !important;
      img {
        width: 100%;
        display: block;
      }
    }
  }

  & iframe {
    margin: auto;
    max-width: 100%;
    @media screen and (min-width: 768px) {
      .ytp-cued-thumbnail-overlay-image {
        background-size: contain;
        -webkit-background-size: contain;
      }
    }
  }
  & [srcset*="Screenshot-2395.png"] {
    object-fit: contain !important;
  }

  & [srcset*="Screenshot-2396.png"] {
    object-fit: contain !important;
  }
`

export default ArticleWrapper
