import React, { useState } from "react"
import { SocialShareCards } from "./SocialCards"
import { I18nextContext } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { Icon, Button, Box } from "@youngagency/young-ui"
import { Container } from "../layout"
import { navigate } from "@reach/router"
import { SocialShareProps } from "../../types/shared"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { blogArticleEvents } from "../../service/tracking"

const CustomBox = styled(Box)`
  height: "fit-content";
  & button {
    background: var(--light-gray-8);
  }
  @media screen and (max-width: 792px) {
    display: none;
  }
`
const Row = styled(Container)`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const RowHeader = styled(Row)`
  margin-top: 48px;

  @media screen and (max-width: 768px) {
    align-items: center;
    flex-direction: column-reverse;
    margin-top: 24px;

    & > * + * {
      margin-bottom: 24px;
    }
  }
`

type Props = {
  children: React.ReactNode
  fallbackLink: string
  social?: SocialShareProps[]
  isBlogPost: boolean
}

export const SinglePageHero = ({
  children,
  fallbackLink,
  social,
  isBlogPost,
}: Props) => {
  const context = React.useContext(I18nextContext)
  const [boxHeight, setBoxHeight] = useState<number | false>(false)
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)
  

  const handleClick = React.useCallback(() => {
    if(gaTracker){
      gaTracker.trackEvent({
        eventName: blogArticleEvents.hero_back
      })
    }
    navigate(-1).catch(_ => navigate(fallbackLink))
  }, [fallbackLink, gaTracker])

  return (
    <RowHeader>
      <CustomBox>
        <div
          style={{
            position: isBlogPost ? "sticky" : "static",
            top: isBlogPost ? "150px" : "unset",
            height: !boxHeight ? "unset" : `${boxHeight}px`,
          }}
        >
          <Button
            variant="ghost"
            onClick={
              handleClick
            }
          >
            <Icon icon="arrow_left" color="black" fontSize="18px" />
          </Button>
        </div>
      </CustomBox>
      {children}
      <SocialShareCards
        path={context.path}
        socialArray={social}
        isBlogPost={isBlogPost}
        setBoxHeight={setBoxHeight}
      />
    </RowHeader>
  )
}
