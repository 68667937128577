import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { useSeoData } from "./useSeoQuery"
import staticSeoData from "../../../seoData.json"
import { GlobalSeoProps } from "../types"

export const useWebPageSchema = ({
  title,
  description,
  image,
  imageAltText,
  publishDate,
  modifiedDate,
}: GlobalSeoProps) => {
  const { language, originalPath } = useI18next()
  const seoTitle = title || staticSeoData?.[originalPath]?.[language]?.title
  const seoDescription =
    description || staticSeoData?.[originalPath]?.[language]?.description

  const {
    site: { siteMetadata },
    languagePath,
  } = useSeoData()

  const siteAddress = React.useMemo(
    () => siteMetadata.siteUrl + languagePath + originalPath,
    [siteMetadata.siteUrl, languagePath, originalPath]
  )
  return {
    "@context": "https://schema.org",
    "@graph": [
      {
        //CREATE DEFAULT SCHEMA FOR WEBSITE
        "@type": "WebSite",
        "@id": siteMetadata.siteUrl + "/" + "#website",
        "url": siteMetadata.siteUrl + "/",
        "name": "Young Platform",
      },
      {
        //CREATE WEBPAGE SCHEMA COMPONENT
        "@type": "WebPage",
        "@id": siteAddress + "#webpage",
        "url": siteAddress,
        "inLanguage": language,
        "name": seoTitle,
        "isPartOf": {
          "@id": siteMetadata.siteUrl + "/" + "#website",
        },
        "image": {
          "@type": "ImageObject",
          "@id": siteAddress + "#primaryImage",
          "url": image ? image : siteMetadata.siteUrl + siteMetadata.image,
          "width": siteMetadata.imageWidth,
          "height": siteMetadata.imageHeight,
          "caption": imageAltText,
        },
        "primaryImageOfPage": {
          "@id": siteAddress + "#primaryImage",
        },
        "datePublished": publishDate,
        "dateModified": modifiedDate,
        "description": seoDescription,
        "breadcrumb": {
          "@id": siteMetadata.siteUrl + "/#breadcrumb",
        },
      },
    ],
  }
}
