import React from "react"
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts"
import { Box } from "@youngagency/young-ui"
import { DistributionDataType } from "../types"
import isArray from "lodash/isArray"
import { ResponsiveText } from "../../../components/layout"
import { truncateNumber } from "../utils"
type Props = {
  data: DistributionDataType[]
  active: string | number | number[] | string[]
}

export const DistributionChart = ({ active, data }: Props) => {
  const isActive = React.useCallback(
    (value: string | number | "all") => {
      if (active === "all") return true

      if (isArray(active)) return active.findIndex(el => el === value) !== -1
      return active === value
    },
    [active]
  )

  const slicedValue = React.useMemo(() => data.slice(1), [data])

  const calculatePercentage = React.useCallback(
    value => {
      return ((value / data[0].value) * 100).toFixed(1) + "%"
    },
    [data[0].value]
  )

  const activeValue = React.useMemo(() => {
    return data.find(el => isActive(el.key))
  }, [isActive])

  return (
    <Box
      width={["344px", "562px"]}
      height={["344px", "562px"]}
      borderRadius="50%"
      flexShrink={0}
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor="white"
      position="relative"
    >
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={slicedValue}
            innerRadius={"60%"}
            paddingAngle={0.48}
            dataKey="value"
          >
            {slicedValue.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  isActive(entry.key) ? entry.color : "rgba(245, 246, 250, 1)"
                }
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <Box
        position="absolute"
        left="50%"
        top="50%"
        style={{
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box maxWidth={["132px", "235px"]}>
          <ResponsiveText
            line={["16px", "18px"]}
            dimension={["12px", "14px"]}
            as="p"
            textAlign="center"
          >
            {activeValue?.name}
          </ResponsiveText>
          <Box mt="4px">
            <ResponsiveText
              line={["39px", "50px"]}
              dimension={["34px", "48px"]}
              as="p"
              fontWeight={600}
              textAlign="center"
            >
              {`${truncateNumber(activeValue?.value)} M`}
            </ResponsiveText>
          </Box>
          <Box mt="4px">
            <ResponsiveText
              line={["16px", "18px"]}
              dimension={["12px", "14px"]}
              as="p"
              textAlign="center"
            >
              {calculatePercentage(activeValue?.value)}
            </ResponsiveText>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
