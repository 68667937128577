import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  IconType,
  Paragraph,
  VStack,
} from "@youngagency/young-ui"
import { StaticImage } from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { EvidenceArticle } from "../../../components/about-us/EvidenceArticle"
import {
  FaqContainer,
  FaqContent,
  FaqTitle,
  SingleFaq,
} from "../../../components/faqs"
import {
  Avatar,
  BottomBanner,
  Container,
  ResponsiveButton,
  ResponsiveText,
} from "../../../components/layout"
import { AdvertisingLayout } from "../../../feature/navigation/components"
import { AlternateHandler, SeoLink, WebPageSchema } from "../../../feature/seo"
import { createArrayFromJson } from "../../../utils/general"
import styled from "styled-components"
import { css } from "@youngagency/young-ui"
import { useGetArticlesByArrayMutation } from "../../../redux/services/academy"
import AcademyCarousel from "../../../components/shared/academy/AcademyCarousel"
import { Helmet } from "react-helmet"
import { TranslationProvider } from "../../../feature/translations/context"
import { graphql } from "gatsby"

export const CustomStack = styled(Box)(
  css({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "stretch",
    flexWrap: "nowrap",
    flexDirection: ["column", "row"],
    "& > *:not(:last-of-type)": {
      mr: ["0px", "20px"],
      mb: ["16px", "0px"],
    },
  })
)

const FirstSectionImages = [
  <StaticImage
    src="../../../feature/home/images/security-1.png"
    alt="security"
  />,
  <StaticImage
    src="../../../feature/home/images/security-2.png"
    alt="security"
  />,
  <StaticImage
    src="../../../feature/home/images/security-3.png"
    alt="security"
  />,
]

const BONUS_LINK = "https://youngexchange.page.link/OOH10"
const standardLink = "https://youngexchange.page.link/OOHNB"

const OOHTest = () => {
  const { t } = useI18next()
  const [
    getAcademyArticle,
    { data, isLoading },
  ] = useGetArticlesByArrayMutation()

  const fetchArtilce = React.useCallback(async () => {
    await getAcademyArticle([110, 4108, 23690])
  }, [getAcademyArticle])

  React.useEffect(() => {
    fetchArtilce()
  }, [fetchArtilce])
  const breadcrumbs = React.useMemo(
    () => [
      { text: "Home", link: "/" },
      { text: "OOH", link: "/ooh/" },
    ],
    []
  )

  const firstSectionTexts = React.useMemo(
    () =>
      createArrayFromJson(
        t,
        "landings.ooh_test.section_1.cards",
        3,
        FirstSectionImages,
        "title",
        "description",
        "img"
      ),
    [t, createArrayFromJson]
  )

  const thirdSectionTexts = React.useMemo(
    () =>
      createArrayFromJson(
        t,
        "landings.ooh_test.section_3.cards",
        3,
        [],
        "description",
        "icon"
      ),
    [t, createArrayFromJson]
  )

  const faqSectionTexts = React.useMemo(
    () =>
      createArrayFromJson(
        t,
        "landings.ooh_test.faqs.cards",
        4,
        [],
        "question",
        "answer"
      ),
    [t, createArrayFromJson]
  )

  return (
    <TranslationProvider
      value={{
        disableTranslations: true,
      }}
    >
      <AdvertisingLayout link={standardLink}>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <WebPageSchema
          title={t("landings.ooh_test.seo.title")}
          description={t("landings.ooh_test.seo.description")}
          breadcrumbs={breadcrumbs}
        />
        <AlternateHandler />
        <Container size={["md", "lg"]}>
          <Box maxWidth="949px" mx="auto" mt="48px">
            <ResponsiveText
              dimension={["34px", "64px"]}
              line={["39px", "70px"]}
              fontWeight={600}
              textAlign="center"
              as="h1"
            >
              {t("landings.ooh_test.hero.title")}
            </ResponsiveText>

            <Box mt="24px">
              <Paragraph size="md" fontFamily="Matter" textAlign="center">
                {t("landings.ooh_test.hero.description")}
              </Paragraph>
            </Box>
            <HStack justify="center" mt="24px">
              <SeoLink isExternal link={standardLink} isConversion>
                <Button size="lg">{t("landings.ooh_test.hero.cta")}</Button>
              </SeoLink>
            </HStack>
            {/* <Box mt="24px">
              <ResponsiveText
                className="link-text"
                textAlign="center"
                dimension={["12px", "16px"]}
                line={["16px", "20px"]}
                fontWeight={400}
                color="grey.1"
                dangerouslySetInnerHTML={{
                  __html: t("landings.ooh_test.hero.disclaimer"),
                }}
              />
            </Box> */}
          </Box>
          <Box
            mt="24px"
            mx="auto"
            borderRadius={"24px"}
            overflow="hidden"
            style={{
              transform: "translateZ(0)",
            }}
          >
            <StaticImage src="../images/OOH-hero.png" alt="OOH" />
          </Box>
          <Box mt={["48px", "96px"]} maxWidth="756px" mx="auto">
            <ResponsiveText
              dimension={["24px", "34px"]}
              line={["30px", "39px"]}
              fontWeight={600}
              textAlign="center"
              as="h2"
            >
              {t("landings.ooh_test.section_1.title")}
            </ResponsiveText>
            <Paragraph
              size="md"
              fontFamily="Matter"
              textAlign="center"
              mt="24px"
            >
              {t("landings.ooh_test.section_1.description")}
            </Paragraph>
          </Box>
          <CustomStack mt="24px">
            {firstSectionTexts.map(text => {
              return (
                <Box
                  border="1px solid"
                  borderColor="grey.5"
                  borderRadius="16px"
                  maxWidth={["unset", "388px"]}
                  width="100%"
                  key={typeof text.title === "string" ? text.title : null}
                >
                  <Box p={["16px", "24px"]}>
                    <VStack align="flex-start" spacing="16px">
                      <Avatar
                        alignSelf="center"
                        width={"56px"}
                        height={["56px"]}
                        shape="circle"
                        backgroundColor="grey.4"
                      >
                        {text.img}
                      </Avatar>
                      <Heading
                        size="md"
                        textAlign="center"
                        fontFamily="Matter"
                        fontWeight={600}
                        width="100%"
                      >
                        {text.title}
                      </Heading>
                      <Paragraph
                        size="md"
                        textAlign="center"
                        fontFamily="Matter"
                      >
                        {text.description}
                      </Paragraph>
                    </VStack>
                  </Box>
                </Box>
              )
            })}
          </CustomStack>
          <VStack mt="24px" spacing="24px">
            <SeoLink isExternal link={standardLink} isConversion>
              <Button size="lg" isRounded>
                {t("landings.ooh_test.section_1.cta")}
              </Button>
            </SeoLink>
          </VStack>
        </Container>
        <Box
          width="100%"
          backgroundColor="blue.100"
          mt={["48px", "96px"]}
          py={["48px", "96px"]}
        >
          <Container size={["md", "lg"]}>
            <Box maxWidth="756px" mx="auto">
              <ResponsiveText
                dimension={["24px", "34px"]}
                line={["30px", "39px"]}
                fontWeight={600}
                textAlign="center"
                as="h2"
              >
                {t("landings.ooh_test.section_2.title")}
              </ResponsiveText>
              {/* <Paragraph
                size="md"
                fontFamily="Matter"
                textAlign="center"
                mt="24px"
              >
                {t("landings.ooh_test.section_2.description")}
              </Paragraph> */}
            </Box>
            <Box mt="24px">
              <AcademyCarousel data={data} loading={isLoading} />
            </Box>
          </Container>
        </Box>
        <Container size={["md", "lg"]}>
          <Box mt={["48px", "96px"]} maxWidth="756px" mx="auto">
            <ResponsiveText
              dimension={["24px", "34px"]}
              line={["30px", "39px"]}
              fontWeight={600}
              textAlign="center"
              as="h2"
            >
              {t("landings.ooh_test.section_3.title")}
            </ResponsiveText>
          </Box>
          <CustomStack mt="24px">
            {thirdSectionTexts.map(text => {
              return (
                <Box
                  border="1px solid"
                  borderColor="grey.5"
                  borderRadius="16px"
                  maxWidth={["unset", "388px"]}
                  width="100%"
                  key={
                    typeof text.description === "string"
                      ? text.description
                      : null
                  }
                >
                  <Box p={["16px", "24px"]}>
                    <VStack align="flex-start" spacing="16px">
                      <HStack spacing="18px">
                        <Avatar
                          width={"36px"}
                          height={["36px"]}
                          shape="circle"
                          backgroundColor="grey.4"
                        >
                          <Icon icon={text.icon as IconType} fontSize="24px" />
                        </Avatar>
                        <HStack spacing="4px">
                          {Array.from({ length: 5 }).map((_, index) => (
                            <Icon
                              key={"star" + index}
                              icon="star_fill"
                              fontSize="24px"
                              color="green.300"
                            />
                          ))}
                        </HStack>
                      </HStack>
                      <Paragraph size="md" fontFamily="Matter">
                        {text.description}
                      </Paragraph>
                    </VStack>
                  </Box>
                </Box>
              )
            })}
          </CustomStack>
          <VStack mt="24px">
            <SeoLink isExternal link={standardLink} isConversion>
              <Button size="lg" isRounded>
                {t("landings.ooh_test.section_3.cta")}
              </Button>{" "}
            </SeoLink>
          </VStack>
          <Box mt={["48px", "96px"]} maxWidth="756px" mx="auto">
            <ResponsiveText
              dimension={["24px", "34px"]}
              line={["30px", "39px"]}
              fontWeight={600}
              textAlign="center"
              as="h2"
            >
              {t("landings.ooh_test.section_4.title")}
            </ResponsiveText>
            {/* <Paragraph
              size="md"
              fontFamily="Matter"
              textAlign="center"
              mt="24px"
            >
              {t("landings.ooh_test.section_1.description")}
            </Paragraph> */}
          </Box>
          <Box mt="24px">
            <EvidenceArticle />
          </Box>
        </Container>
        <Box mt={["48px", "96px"]}>
          <Container size={["md", "lg"]}>
            <FaqContainer>
              <FaqTitle
                textAlign="center"
                text={t("landings.ooh_test.faqs.title")}
              />
              <FaqContent mt="24px">
                {faqSectionTexts.map(faq => (
                  <SingleFaq
                    key={typeof faq.question === "string" ? faq.question : null}
                    isH3
                    title={faq.question}
                    description={faq.answer}
                  />
                ))}
              </FaqContent>
              {/* <ResponsiveText
                className="link-text"
                dimension={["12px", "16px"]}
                line={["16px", "20px"]}
                fontWeight={400}
                color="grey.1"
              >
                {t("landings.ooh_test.faqs.disclaimer")}
              </ResponsiveText> */}
            </FaqContainer>
          </Container>
        </Box>
        <BottomBanner
          text={t("landings.ooh_test.footer.title")}
          mt={["64px", "72px"]}
        >
          <SeoLink isExternal={true} link={BONUS_LINK} isConversion>
            <ResponsiveButton size="xl">
              {t("landings.ooh_test.footer.cta")}
            </ResponsiveButton>
          </SeoLink>
        </BottomBanner>
      </AdvertisingLayout>
    </TranslationProvider>
  )
}

export const query = graphql`
  query OOHLanding($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default OOHTest
