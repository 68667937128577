import React from "react"
import { Box } from "@youngagency/ui"
import { Hero } from "../../security/components"
import { AdviceSection } from "../components/AdviceSection"
import { PlatformSection } from "../components/PlatformSection"
import { ActionSection } from "../components/ActionSection"
import { ScamSection } from "../components/ScamSection"
import { InvertedBody } from "../../../components/layout/InvertedBody"
import { FaqSection } from "../components/FaqSection"
import { SliderSection } from "../components/SliderSection"

const SecurityPage = () => {
  return (
    <>
      <Box marginTop={16}>
        <Hero />
      </Box>
      <Box
        marginTop={{
          mobile: 56,
          md: 96,
        }}
      >
        <AdviceSection />
      </Box>
      <Box
        marginTop={{
          mobile: 56,
          md: 96,
        }}
      >
        <PlatformSection />
      </Box>
      <Box
        marginTop={{
          mobile: 56,
          md: 96,
        }}
      >
        <SliderSection />
      </Box>
      <Box
        marginTop={{
          mobile: 56,
          md: 96,
        }}
      >
        <ActionSection />
      </Box>
      <Box
        marginTop={{
          mobile: 56,
          md: 96,
        }}
      >
        <ScamSection />
      </Box>
      <InvertedBody body="light">
        <Box
          marginTop={{
            mobile: 56,
            md: 96,
          }}
          paddingBottom={{
            mobile: 56,
            md: 96,
          }}
        >
          <FaqSection />
        </Box>
      </InvertedBody>
    </>
  )
}

export default SecurityPage
