const mapPath = require("../mapPath.json")

function isDefaultLang(
  locale,
  defaultLang = process.env.DEFAULT_LANGUAGE.toLowerCase()
) {
  return locale.toLowerCase() === defaultLang
}

/**
 * @param {string} locale
 * @returns {boolean}
 */
function isSupportedLanguage(locale) {
  const supportedLanguages = process.env.AVAILABLE_LANGUAGES.split(",")
  return !!supportedLanguages.find(lang => lang === locale.toLowerCase())
}

function getLocales(raw) {
  let cleanArray = []
  raw.data.allLocale.edges.forEach(lang => {
    cleanArray.push(lang.node.language)
  })
  return cleanArray
}

function remapPath(path, locale) {
  if (mapPath[path] && mapPath[path][locale]) {
    return mapPath[path][locale]
  } else {
    return path
  }
}

function localizePath(defaultLang, locale, path) {
  // The default language isn't prefixed
  if (isDefaultLang(locale, defaultLang)) {
    return remapPath(path, locale)
  }

  const [, base] = path.split(`/`)

  // If for whatever reason we receive an already localized path
  // (e.g. if the path was made with location.pathname)
  // just return it as-is.
  if (base === locale) {
    return localizePath(
      process.env.DEFAULT_LANGUAGE,
      locale,
      path.replace(`/${base}`, "")
    )
  }

  // If it's another language, prefix with the locale
  return `/${locale}${remapPath(path, locale)}`
}

//Unique ID
function generateUID() {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  var firstPart = (Math.random() * 46656) | 0
  var secondPart = (Math.random() * 46656) | 0
  firstPart = ("000" + firstPart.toString(36)).slice(-3)
  secondPart = ("000" + secondPart.toString(36)).slice(-3)
  return firstPart + secondPart
}

/**
 * Crea un Array a partire da un Json Object.
 * @param {function} translator required -- funzione utilizzata per tradurre
 * @param {string} defaultQuery required --restituisce oggetto da iterare
 * @param {number} numOfItems required -- numero di elementi che deve avere array finale
 * @param {any[]} imgArray required -- array di immagini da inserire per ogni elemento
 * @param  {string[]} rest -required -- nomi dei campi da creare
 * @returns {any[]} --Array di oggetti
 */
function createArrayFromTranslationQuery(
  translator,
  defaultQuery,
  numOfItems,
  imgArray,
  ...rest
) {
  let newArray = []
  for (let i = 0; i < numOfItems; i++) {
    let tempObj = {}
    rest.forEach(el => {
      tempObj = {
        ...tempObj,
        id: i + 1,
        [el]:
          (el === "icon" || el === "img") && (imgArray && imgArray.length) > 0
            ? imgArray[i]
            : translator(`${defaultQuery}${i + 1}.${el}`),
      }
    })
    newArray.push(tempObj)
  }
  return newArray
}

const getPercentage = (currency, currencyData) => {
  let charts24h = currencyData
  return charts24h &&
    Object.keys(charts24h).length &&
    charts24h[currency] &&
    charts24h[currency].length
    ? (
        ((charts24h[currency][Object.keys(charts24h[currency]).length - 1] -
          charts24h[currency][0]) /
          charts24h[currency][0]) *
        100
      ).toFixed(2)
    : 0.0
}

const isInViewport = elem => {
  var distance = elem.getBoundingClientRect()
  return (
    (distance.top >= 0 &&
      distance.left >= 0 &&
      distance.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      distance.right <=
        (window.innerWidth || document.documentElement.clientWidth)) ||
    distance.top <= 80
  )
}

function getCategorySlug(name) {
  let slug = name
  if (name.includes(" ")) {
    slug = name.split(" ").join("-")
  }
  return slug.toLowerCase()
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const isBrowser = typeof window !== "undefined"

module.exports = {
  localizePath,
  getLocales,
  isDefaultLang,
  generateUID,
  createArrayFromTranslationQuery,
  getPercentage,
  isInViewport,
  getCategorySlug,
  capitalizeFirstLetter,
  isSupportedLanguage,
  isBrowser,
}
