import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import { Container, ResponsiveButton } from "../components/layout"
import {
  AlternateHandler,
  MetaDecorator,
  SeoLink,
  WebPageSchema,
} from "../feature/seo"
import { Cards } from "../feature/landing/ecosistema/components"
import { BreadCrumb, LandingLayout } from "../feature/navigation/components"
import { TranslationProvider } from "../feature/translations/context"
import { Box, Icon } from "@youngagency/young-ui"
import { Description, Display, HeadingXL } from "../components"

const YoungWorld = () => {
  const { t } = useI18next()

  const breadcrumb = React.useMemo(
    () => [
      {
        text: t("breadcumbs.home_breadcrumb"),
        link: "/",
      },
      {
        text: t("breadcumbs.young_world_breadcrumb"),
        link: "/young-world/",
      },
    ],
    [t]
  )

  return (
    <TranslationProvider>
      <LandingLayout
        dinamycLink="https://youngexchange.page.link/aboutusmenu"
        appleLink="https://youngexchange.page.link/aboutusios"
        playStoreLink="https://youngexchange.page.link/aboutusand"
      >
        <MetaDecorator disableSlogan />
        <WebPageSchema breadcrumbs={breadcrumb} />
        <AlternateHandler />
        <Container>
          <BreadCrumb breadcrumbs={breadcrumb} />
        </Container>

        <Container size={["md", "lg"]} mt={["24px", "48px"]}>
          <Box>
            <Display as="h1" textAlign="center">
              {t("young_world.title")}
            </Display>
          </Box>

          <Box mt={["24px", "34px"]}>
            <Description as="p" textAlign="center">
              {t("young_world.description")}
            </Description>
          </Box>
          <Box mt={["24px", "34px"]} display="flex" justifyContent="center">
            <SeoLink
              link={"https://youngexchange.page.link/aboutusb"}
              isExternal
              rel="nofollow"
              target="_self"
              isConversion
            >
              <ResponsiveButton
                size="lg"
                variant="primary"
                color="green"
                rightIcon={<Icon icon="arrow_right" />}
              >
                {t("young_world.link")}
              </ResponsiveButton>
            </SeoLink>
          </Box>
          <Box mt={["48px", "96px"]}>
            <HeadingXL textAlign="center">
              {t("young_world.paragraph_1.title")}
            </HeadingXL>
            <Box mt={["24px", "48px"]}>
              <Cards />
            </Box>
          </Box>
        </Container>
      </LandingLayout>
    </TranslationProvider>
  )
}

export default YoungWorld
export const query = graphql`
  query YoungWorldMainPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
