import { Box, Paragraph, styled } from "@youngagency/young-ui"
import React from "react"

const StyledBox = styled(Box)`
  display: none;
  border: 1px solid var(--r-grey-5);
  background: var(--r-grey-4);
  border-radius: 16px;
  max-width: 368px;
  padding: 24px;
  width: 100%;
  @media screen and (min-width: 1024px) {
    display: block;
    position: sticky;
    top: 96px;
  }
`

const StyledLink = styled.a`
  &:hover {
    color: var(--r-grey-1) !important;
  }
`

const FrontMatter = ({
  frontMatter,
}: {
  frontMatter: HTMLHeadingElement[]
}) => {
  return (
    <StyledBox>
      {frontMatter.map((item, index) => {
        return (
          <Box py="10px" key={`anchor-${index}`}>
            <StyledLink
              href={`#${index}`}
              style={{
                textDecoration: "none",
              }}
            >
              <Paragraph
                fontFamily="Matter"
                color="inherit"
                size="md"
                fontWeight={500}
              >
                {item.textContent}
              </Paragraph>
            </StyledLink>
          </Box>
        )
      })}
    </StyledBox>
  )
}

export default FrontMatter
