import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { graphql, PageProps } from "gatsby"
import Glossary from "../components/glossary/Glossary"
import { AlternateHandler, MetaDecorator, WebPageSchema } from "../feature/seo"
import { BreadCrumb, LandingLayout } from "../feature/navigation/components"
import { Container } from "../components/layout"
import { TranslationProvider } from "../feature/translations/context"

const GlossaryPage: React.FC<PageProps<Queries.GlossaryMainPageQuery>> = ({
  data,
}) => {
  const { t } = useI18next()

  const breadcrumbs = React.useMemo(() => {
    return [
      {
        text: t("breadcumbs.home_breadcrumb"),
        link: "/",
      },
      {
        text: t("breadcumbs.glossary_breadcrumb"),
        link: "/glossary/",
      },
    ]
  }, [t])

  return (
    <TranslationProvider>
      <LandingLayout dinamycLink="https://youngexchange.page.link/LEARNCRYPTO">
        <MetaDecorator />
        <WebPageSchema breadcrumbs={breadcrumbs} />
        <AlternateHandler />
        <Container>
          <BreadCrumb breadcrumbs={breadcrumbs} />
        </Container>
        <Glossary data={data.allWpGlossary.nodes} />
      </LandingLayout>
    </TranslationProvider>
  )
}

export default GlossaryPage
export const query = graphql`
  query GlossaryMainPage(
    $language: String!
    $pageLanguage: WpLanguageCodeEnum!
  ) {
    allWpGlossary(filter: { language: { code: { eq: $pageLanguage } } }) {
      nodes {
        title
        slug
        seo {
          metaDesc
        }
        language {
          code
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
