import React from "react"
import { useSelector } from "react-redux"
import { PromoElementPlatform } from "../../../redux/reducers/promoSlice"
import { RootState } from "../../../redux/store"
import { usePopUpDispatcher } from "../hook"
import { ClosablePopup } from "./ClosablePopup"

type Config = {
  showedPlatform: PromoElementPlatform[number]
}

const delay = (ms: number) => new Promise(res => setTimeout(res, ms))

export const PromoPopUp: React.FC<Config> = ({ showedPlatform }) => {
  const { openPopUp, closePopUp, catchPopUserPreference } = usePopUpDispatcher()
  const {
    pop_up: { seen, platform, status },
  } = useSelector((state: RootState) => state.promo)

  const isPlatformSupported = React.useMemo(() => {
    return platform.some(p => p === showedPlatform)
  }, [platform, showedPlatform])

  const delayOpenPopUp = React.useCallback(async () => {
    await delay(5000)
    openPopUp()
  }, [openPopUp])

  React.useEffect(() => {
    const bannerUserPreference = catchPopUserPreference()
    switch (bannerUserPreference) {
      case "hidden":
        if (isPlatformSupported) delayOpenPopUp()
        break
      case "closed":
        closePopUp()
        break
      case "open":
        if (isPlatformSupported) delayOpenPopUp()
        break
      default:
        break
    }
  }, [delayOpenPopUp, closePopUp, catchPopUserPreference])

  const isVisible = React.useMemo(() => {
    if (seen) return false
    if (isPlatformSupported) {
      if (status === "open") return true
    }
    return false
  }, [seen, isPlatformSupported, status])

  if (!isVisible) return null
  return <ClosablePopup closePopUp={closePopUp} />
}
