import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import "../components/landing.css"
import { graphql } from "gatsby"
import WhyUsPageComponent from "../components/why-us/why-us-page.component"
import { Container } from "../components/layout"
import { AlternateHandler, MetaDecorator, WebPageSchema } from "../feature/seo"
import { BreadCrumb, LandingLayout } from "../feature/navigation/components"
import { TranslationProvider } from "../feature/translations/context"

const WhyUsPage = () => {
  const { t } = useI18next()
  const breadcrumb = React.useMemo(
    () => [
      {
        text: t("breadcumbs.home_breadcrumb"),
        link: "/",
      },
      {
        text: t("breadcumbs.whyus_breadcrumb"),
        link: "/why-us/",
      },
    ],
    [t]
  )
  return (
    <TranslationProvider
      dinamycLink="https://youngexchange.page.link/aboutusmenu"
      appleLink="https://youngexchange.page.link/aboutusios"
      playStoreLink="https://youngexchange.page.link/aboutusand"
    >
      <LandingLayout>
        <MetaDecorator />
        <WebPageSchema breadcrumbs={breadcrumb} />
        <AlternateHandler />
        <Container>
          <BreadCrumb breadcrumbs={breadcrumb} />
        </Container>
        <WhyUsPageComponent />
      </LandingLayout>
    </TranslationProvider>
  )
}

export default WhyUsPage
export const query = graphql`
  query WhyUsMainPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
