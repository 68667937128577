import { Box, Button } from "@youngagency/young-ui"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import {
  Card,
  CardContent,
  CardGrid,
  CardImage,
  CardTexts,
} from "../../../components/cards"
import { Text } from "../../../components/texts/temporary"
import { useClubPageContext } from "../context/ClubPageContext"
import { clubsEvents } from "../../../service/tracking"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"


const events = [
  {
    eventName: clubsEvents.club_hero_start,
  },
]

export const Hero = () => {
  const { t } = useTranslation()
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)

  const { goToRef } = useClubPageContext()
  const handleOnClick = React.useCallback(() => {
    if(gaTracker) gaTracker.trackMultipleEvents(events)
    goToRef()
  }, [])
  return (
    <CardGrid size="hero">
      <Card
        disableHover
        backgroundColor="black"
        height={["auto !important", "calc(100vh - 98px) !important"]}
        position="relative"
        borderRadius={"32px !important"}
      >
        <CardContent
          pl={["24px", "96px!important"]}
          pr={["24px", "0px !important"]}
        >
          <CardTexts>
            <Text.H2 color="white" as="h1" fontWeight={600}>
              {t("clubs_page.hero.title")}
            </Text.H2>
            <Box maxWidth="616px" width="100%">
              <Text.Subtitle2
                color="var(--grey-200-v2)"
                fontWeight={600}
                as="div"
              >
                {t("clubs_page.hero.description")}
              </Text.Subtitle2>
            </Box>
          </CardTexts>
          <Button size="md" mt={["16px", "24px"]} onClick={handleOnClick}>
            {t("clubs_page.hero.cta")}
          </Button>
        </CardContent>
        <CardImage
          position={["static", "absolute !important"]}
          top={["0px !important"]}
          left="0px !important"
          height={["auto", "100% !important"]}
          bottom="0px !important"
          width="100% !important"
          maxWidth="100% !important"
          p="0px !important"
        >
          <Box width="100%" height="100%" display={["none", "block"]}>
            <StaticImage
              quality={100}
              outputPixelDensities={[2]}
              placeholder="dominantColor"
              loading="eager"
              style={{
                height: "100%",
                transform: "translateX(5%)",
              }}
              src="../images/it/clubs_hero.png"
              alt={t("clubs_page.hero.img_alt_text")}
            />
          </Box>
          <Box width="100%" height="100%" display={["block", "none"]}>
            <StaticImage
              quality={100}
              outputPixelDensities={[2]}
              placeholder="dominantColor"
              loading="eager"
              style={{
                height: "100%",
              }}
              src="../images/it/club_hero-mobile.png"
              alt={t("clubs_page.hero.img_alt_text")}
            />
          </Box>
        </CardImage>
      </Card>
    </CardGrid>
  )
}
