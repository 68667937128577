import { graphql, useStaticQuery } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { SeoDefaultData } from "../types"

export const useSeoData = () => {
  const data: SeoDefaultData & { languagePath: string } = useStaticQuery(query)
  const { language } = useI18next()
  const languagePath = React.useMemo(
    () => (language == "it" ? "" : `/${language}`),
    [language]
  )

  return { ...data, languagePath }
}

const query = graphql`
  query SiteMetaData {
    site {
      siteMetadata {
        siteUrl
        image
        imageWidth
        imageHeight
        facebook
        instagram
        linkedin
        twitter
        youtube
        author
      }
    }
  }
`
