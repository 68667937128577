import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { useGetAllCurrenciesQuery } from "../../../../redux/services/currency/api"
import { useGetAllAvailableFiatQuery } from "../../../../redux/services/currency/api"
import { ConversorReducerActions } from "../reducer/useReducerActions"

type Props = {
  tokenSymbol: string
  fiatSymbol: string
  handleConversionAction: ConversorReducerActions["handleConversionAction"]
  handleNullConversion: ConversorReducerActions["handleNullConversion"]
}

export const useConversion = ({
  tokenSymbol,
  fiatSymbol,
  handleConversionAction,
  handleNullConversion,
}: Props) => {
  const [initialConversion, setInitialConversion] = React.useState<
    string | number
  >(0)
  const { language } = useI18next()
 

  const {
    data: currency,
    isLoading: isAllCurrenciesLoading,
    isFetching: isAllCurrenciesFetching,
    refetch: refetchAllCurrencies,
  } = useGetAllCurrenciesQuery(undefined, {
    selectFromResult: ({ data, isLoading, isFetching }) => ({
      data: data?.currencies[tokenSymbol],
      isLoading,
      isFetching,
    })
  })
  

  const {
    fiat,
    refetch: refetchFiat,
    isLoading: isFiatLoading,
    isFetching: isFiatFetching,
  } = useGetAllAvailableFiatQuery(undefined, {
    selectFromResult: ({ data, isLoading, isFetching }) => ({
      fiat: data?.find(el => el.symbol === fiatSymbol),
      isLoading,
      isFetching,
    }),
  })

  const refetchConversion = React.useCallback(() => {
    refetchFiat()
    refetchAllCurrencies()
  }, [refetchFiat, refetchAllCurrencies])

  const handleConversion = React.useCallback(
    (value?: number | string) => {
      if (!value) return handleNullConversion()
      refetchConversion()
      if (currency && fiat && !isFiatFetching && !isAllCurrenciesFetching) {
        return handleConversionAction({
          fiat: fiat.price,
          currency: currency.price,
          value,
        })
      }
      return handleNullConversion()
    },
    [
      currency,
      fiat,
      currency?.price,
      language,
      fiat?.price,
      isFiatFetching,
      isAllCurrenciesFetching,
      handleConversionAction,
      handleNullConversion,
    ]
  )

  React.useEffect(() => {
    if (currency && fiat && !isFiatLoading && !isAllCurrenciesLoading) {
      const value = handleConversionAction({
        fiat: fiat.price,
        currency: currency.price,
        value: 1,
      })
      setInitialConversion(value)
    } else {
      handleNullConversion()
    }
  }, [currency, fiat, isFiatLoading, isAllCurrenciesLoading])

  return { handleConversion, initialConversion }
}
