import { useDispatch } from "react-redux"
import { useHasMounted } from "@youngagency/young-ui"
import {
  closePromoBanner,
  openPromoBanner,
  PromoElementStatus,
  setPromoBannerSeen,
} from "../../../redux/reducers/promoSlice"
import { useCallback } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"

export const useBannerDispatcher = () => {
  const dispatch = useDispatch()
  const hasMounted = useHasMounted()
  const {
    banner: { key },
  } = useSelector((state: RootState) => state.promo)

  const catchBannerUserPreference = useCallback(() => {
    if (!hasMounted) return
    const bannerUserPreference = (localStorage.getItem(
      `${key}-bannerUserPreference`
    ) || "hidden") as PromoElementStatus
    return bannerUserPreference
  }, [hasMounted, key])

  const setBannerUserBehavior = useCallback(
    (status: PromoElementStatus) => {
      if (!hasMounted) return
      localStorage.setItem(`${key}-bannerUserPreference`, status)
    },
    [hasMounted, key]
  )

  const togglePromoClass = useCallback(() => {
    if (!hasMounted) return
    const hasPromoClass = document.body.classList.contains("promo-active-style")
    function addPromoClass() {
      document.body.classList.add("promo-active-style")
    }

    function removePromoClass() {
      if (hasPromoClass) document.body.classList.remove("promo-active-style")
    }

    return { addPromoClass, removePromoClass }
  }, [hasMounted])

  const openBanner = useCallback(() => {
    dispatch(openPromoBanner())
    const { addPromoClass } = togglePromoClass() || {}
    if (addPromoClass) addPromoClass()
    setBannerUserBehavior("open")
  }, [dispatch, setBannerUserBehavior, togglePromoClass])

  const closeBanner = useCallback(() => {
    dispatch(closePromoBanner())
    const { removePromoClass } = togglePromoClass() || {}
    if (removePromoClass) removePromoClass()
    setBannerUserBehavior("closed")
    dispatch(setPromoBannerSeen())
  }, [dispatch, setBannerUserBehavior, togglePromoClass])

  return {
    closeBanner,
    togglePromoClass,
    catchBannerUserPreference,
    openBanner,
  }
}
