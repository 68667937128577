import { BoxProps, Skeleton } from "@youngagency/young-ui"
import React from "react"
import { useSelector } from "react-redux"

import { RootState } from "../../../redux/store"
import { useMergeTokenChartData } from "../hooks/useMergeTokenChartData"
import { TokenPageChart } from "./TokenPageChart"

type Props = {
  currency: string
  fiat?: string
  amountSymbol?: string
  customLoader?: React.ReactElement
}

const width = 702
const height = 183

const ChartSection = ({
  currency,
  fiat = "EUR",
  amountSymbol = "€",
  customLoader,
  ...rest
}: Props & BoxProps) => {
  const { chartType } = useSelector((store: RootState) => {
    return store.uiStyle
  })

  const { mergedData, isLoading } = useMergeTokenChartData(currency)
  if (mergedData && mergedData?.points && !isLoading) {
    return (
      <>
        <TokenPageChart
          {...rest}
          amountSymbol={amountSymbol}
          currency={{
            image: mergedData?.details?.image as string,
            color: mergedData?.details?.color,
            name: mergedData.name as string,
          }}
          variation={mergedData?.percentageChange}
          lineData={mergedData?.points as any}
          dimension={{
            width,
            height,
          }}
          candlestickData={mergedData?.points as any}
          type={chartType}
        />
      </>
    )
  } else if (!!customLoader) {
    return customLoader
  }

  return (
    <Skeleton
      width="100%"
      height="0"
      maxHeight={["unset", "463px"]}
      paddingTop={["100%", "56%"]}
      borderRadius="16px"
      alignSelf="flex-start"
    />
  )
}

export default ChartSection
