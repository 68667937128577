import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Box, Container, VStack } from "@youngagency/ui"
import { Container as CustomContainer } from "../../../../components/layout"
import {
  CardButton,
  CardContent,
  CardGrid,
  CardImage,
  CardStack,
  CardTexts,
} from "../../../../components/cards"
import { Card } from "../../../../components/cards"
import { StaticImage } from "gatsby-plugin-image"
import { Text } from "../../../../components/texts/temporary"
import { navigate } from "@reach/router"
import { googleScheduleMeet } from "../../constants"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { b2bEvents, globalEvents } from "../../../../service/tracking"
import { textMargin } from "./b2b.css"
export const OverviewSection = () => {
  const { t } = useTranslation()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  const handleClick = React.useCallback(
    (item: 1 | 2) => {
      gaTracker?.trackMultipleEvents([
        {
          eventName: b2bEvents.b2b_home_body_overview,
          payload: {
            action: item,
          },
        },
        {
          eventName: globalEvents.contact_sales,
        },
      ])
      navigate(googleScheduleMeet)
    },
    [gaTracker]
  )

  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={"unset"}
            atoms={{
              width: "full",
              mx: "auto",
              __alignItems: "center",
              __justifyContent: "center",
            }}
          >
            <Text.H3
              as="h2"
              textAlign="center"
              color="white"
              fontWeight={400}
              dangerouslySetInnerHTML={{
                __html: t("clubs_page.b2b.why_section.title"),
              }}
            />
            <Box className={textMargin}>
              <Text.Body1
                textAlign="center"
                color="var(--grey-200-v2)"
                fontWeight={600}
                maxWidth="700px"
              >
                {t("clubs_page.b2b.why_section.description")}
              </Text.Body1>
            </Box>
          </VStack>
        </Container>
      </CustomContainer>
      <CardGrid>
        <Box
          marginTop={{
            mobile: 32,
            md: 40,
          }}
        >
          <VStack
            spacing={"unset"}
            align="center"
            justify="center"
            atoms={{
              __columnGap: "1rem",
              width: "full",
              mx: "auto",
            }}
          >
            <CardStack>
              <Card
                isAnimated
                hasShadow
                background="white"
                orientation="column"
                invertBtn
                onClick={() => {
                  handleClick(1)
                }}
              >
                <CardContent>
                  <CardTexts>
                    <Text.H6 as="h3" color="white" fontWeight={600}>
                      {t("clubs_page.b2b.why_section.card_1.title")}
                    </Text.H6>
                    <Text.Body1 color="var(--grey-10-v2)" fontWeight={600}>
                      {t("clubs_page.b2b.why_section.card_1.description")}
                    </Text.Body1>
                  </CardTexts>
                  <CardButton textColor="light" size="md" mt={["16px", "24px"]}>
                    {t("clubs_page.b2b.why_section.card_1.button")}
                  </CardButton>
                </CardContent>
                <CardImage height={"100%"}>
                  <StaticImage
                    style={{
                      height: "100%",
                    }}
                    src="../../images/overview-card-1.png"
                    alt={t("clubs_page.b2b.why_section.card_1.img_alt_text")}
                  />
                </CardImage>
              </Card>
              <Card
                isAnimated
                hasShadow
                background="#131312"
                orientation="column"
                invertBtn
                onClick={() => {
                  handleClick(2)
                }}
              >
                <CardContent>
                  <CardTexts>
                    <Text.H6 as="h3" color="white" fontWeight={600}>
                      {t("clubs_page.b2b.why_section.card_2.title")}
                    </Text.H6>
                    <Text.Body1 color="var(--grey-200-v2)" fontWeight={600}>
                      {t("clubs_page.b2b.why_section.card_2.description")}
                    </Text.Body1>
                  </CardTexts>
                  <CardButton textColor="light" size="md" mt={["16px", "24px"]}>
                    {t("clubs_page.b2b.why_section.card_2.button")}
                  </CardButton>
                </CardContent>
                <CardImage>
                  <StaticImage
                    src="../../images/overview-card-2.png"
                    alt={t("clubs_page.b2b.why_section.card_2.img_alt_text")}
                  />
                </CardImage>
              </Card>
            </CardStack>
            <CardStack mt="1rem">
              <Card
                isAnimated
                hasShadow
                background="#131312"
                orientation="column"
                disableHover
                shape="square"
              >
                <CardContent shape="square">
                  <CardTexts>
                    <Text.H6 as="h3" color="white" fontWeight={600}>
                      {t("clubs_page.b2b.why_section.card_3.title")}
                    </Text.H6>
                    <Text.Body1 color="var(--grey-10-v2)" fontWeight={600}>
                      {t("clubs_page.b2b.why_section.card_3.description")}
                    </Text.Body1>
                  </CardTexts>
                </CardContent>
                <CardImage>
                  <StaticImage
                    src="../../images/overview-card-3.png"
                    alt={t("clubs_page.b2b.why_section.card_3.img_alt_text")}
                  />
                </CardImage>
              </Card>
              <Card
                isAnimated
                hasShadow
                background="#002A17;"
                orientation="column"
                disableHover
                shape="square"
              >
                <CardContent shape="square">
                  <CardTexts>
                    <Text.H6 as="h3" color="white" fontWeight={600}>
                      {t("clubs_page.b2b.why_section.card_4.title")}
                    </Text.H6>
                    <Text.H5 color="green.300" fontWeight={600}>
                      {t("clubs_page.b2b.why_section.card_4.description")}
                    </Text.H5>
                  </CardTexts>
                </CardContent>
              </Card>
            </CardStack>
          </VStack>
        </Box>
      </CardGrid>
    </>
  )
}
