import { useI18next } from "gatsby-plugin-react-i18next"
import isEmpty from "lodash/isEmpty"
import React from "react"
import { ParsedTranslation } from "../types"
import { StaticTranslator } from "../components/Translator"

type Context = {
  translations?: ParsedTranslation
  disableTranslations?: boolean
}

const initialValue = {
  translations: [],
  disableTranslations: false,
}

const TranslationContext = React.createContext<Context>(initialValue)

export const TranslationProvider: React.FC<{
  children: React.ReactNode
  value?: Context
}> = ({ children, value }) => {
  const { originalPath, language } = useI18next()

  const translations = React.useMemo(() => {
    if (!value?.translations || isEmpty(value?.translations)) {
      const staticTransaltor = new StaticTranslator()
      return staticTransaltor.create(originalPath.slice(1) || "")
    }
    return value.translations
  }, [value?.translations, originalPath, language])

  const alternateTranslations = React.useMemo(() => {
    return translations?.filter(
      x => x.language.code?.toLowerCase() !== language
    )
  }, [translations])

  const ctx = React.useMemo(
    () => ({
      translations: alternateTranslations,
      disableTranslations: value?.disableTranslations,
    }),
    [alternateTranslations, value?.disableTranslations]
  )

  return (
    <TranslationContext.Provider value={ctx}>
      {children}
    </TranslationContext.Provider>
  )
}

export const useTranslationContext = () => React.useContext(TranslationContext)
