import { Box } from "@youngagency/ui"
import React from "react"
import { BoxExtendedProps } from "../../types/system"
import { invertedBody, invertedBodyContainer } from "./styles/inverted-body.css"
import clsx from "clsx"

type InvertedBodyProps = {
  body: "light" | "dark"
} & BoxExtendedProps<HTMLDivElement>

export const InvertedBody = ({
  children,
  className,
  body,
  ...rest
}: InvertedBodyProps) => {
  const container = invertedBodyContainer({ body })
  const content = invertedBody({ body })
  return (
    <Box {...rest} className={clsx(container, className)}>
      <Box className={content} />
      {children}
    </Box>
  )
}
