import {
  Box,
  BoxProps,
  Heading,
  HStack,
  Icon,
  Paragraph,
  VStack,
} from "@youngagency/young-ui"
import React from "react"
import { SeoLink } from "../../feature/seo"
import styled from "styled-components"
import { ResponsiveText } from "../layout"
import { FooterProps } from "./asset/useFooterData"

type Props = {
  title: string
  array: FooterProps["company" | "learn" | "product" | "support"]
  variant?: "dark" | "light"
} & BoxProps

const StyledHStack = styled(HStack)`
  justify-content: space-between;

  @media screen and (min-width: 992px) {
    justify-content: flex-start;
    .footer-icon {
      display: none;
    }
  }
`

const FooterSection = ({ title, array, variant, ...rest }: Props) => {
  const color = variant === "light" ? "black" : "white"
  return (
    <Box {...rest} data-role="_footer-box">
      <StyledHStack width="100%" align="center" justify="space-between">
        <Heading
          size="sm"
          fontWeight={600}
          fontFamily="Matter"
          color={"grey.1"}
        >
          {title}
        </Heading>
        <Icon icon="drop_down" className="footer-icon" />
      </StyledHStack>
      <VStack
        align="initial"
        justify="flex-start"
        id={`footer-${title.split(" ")[0].toLowerCase()}-menu`}
        className="footer-dropdown"
        mt="17px"
      >
        {array.map(({ text, link, is_external }, index) => {
          return (
            <Box
              key={`${title}-footer-${index}`}
              py="9px"
              data-role="_footer-link"
            >
              <SeoLink
                isExternal={is_external === "true"}
                link={link as string}
                rel={is_external === "true" ? "noopener" : "internal"}
                target={is_external === "true" ? "_blank" : "self"}
              >
                <HStack spacing="6px" justify="flex-start" align="center">
                  {typeof text === "string" ? (
                    <Paragraph size="sm" data-role="_text" color={color}>
                      {text}
                    </Paragraph>
                  ) : null}

                  {link === "/token-yng/" ? (
                    <Box
                      borderRadius="21px"
                      maxWidth="35px"
                      height="15px"
                      px="6px"
                      py="3px"
                      backgroundColor="blue.400"
                    >
                      <ResponsiveText
                        dimension={["10px"]}
                        line={["10px"]}
                        style={{
                          textTransform: "uppercase",
                        }}
                        fontWeight={600}
                        color="white"
                      >
                        new
                      </ResponsiveText>
                    </Box>
                  ) : null}
                </HStack>
              </SeoLink>
            </Box>
          )
        })}
      </VStack>
    </Box>
  )
}

export default FooterSection
