import { Box, Label, Button } from "@youngagency/young-ui"
import React, { useState } from "react"
import useClubReward from "../hooks/useClubReward"
import styled from "styled-components"
import { ClubProps } from "../types"
import { ResponsiveButton, ResponsiveText } from "../../../components/layout"
import { useI18next } from "gatsby-plugin-react-i18next"
import { SeoLink } from "../../seo"
import { useSelector } from "react-redux"
import { StaticImage } from "gatsby-plugin-image"
import { RootState } from "../../../redux/store"
import { globalEvents } from "../../../service/tracking"

const CustomStack = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  .label-boxes {
    flex-wrap: nowrap;
    overflow-x: scroll;
    display: flex;
  }

  .text-box {
    max-width: 344px;
    overflow-x: scroll;
  }

  & > *:not(:first-of-type) {
    margin-top: 28px;
  }

  @media screen and (min-width: 440px) {
    .text-box {
      max-width: 465px !important;
    }
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;
    & > *:not(:first-of-type) {
      margin-left: 117px;
      margin-top: 0px;
    }
  }
`

const GridBox = styled(Box)`
  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
  max-width: 344px;
  height: 344px;
  margin: auto;
  .staking-box {
    grid-column: 7 / span 5;
    grid-row: 1 / span 5;

    @media screen and (min-width: 767px) {
      min-height: unset;
    }
  }
  .airdrop-box {
    grid-column: 1 / span 5;
    grid-row: 3 / span 5;
    @media screen and (min-width: 767px) {
      min-height: unset;
    }
  }
  .fee-box {
    grid-column: 6 / span 6;
    grid-row: 7 / span 6;
    transition: all 125ms ease;
    @media screen and (min-width: 767px) {
      min-height: unset;
    }
  }
  .rounded-box {
    border-radius: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  @media screen and (min-width: 767px) {
    max-width: 562px;
    height: 562px;
    max-height: 562px;
  }
`

export const ClubSection = () => {
  const { t, language } = useI18next()
  const [label, setLabel] = useState<ClubProps>("gold")
  const {
    title,
    description,
    fees,
    airdrop,
    staking,
    labelColor,
  } = useClubReward(label)

  const clubButtons: {
    label: ClubProps
    icon: JSX.Element
  }[] = React.useMemo(() => {
    return [
      {
        label: t("token.clubSection.platinum.label"),
        icon: (
          <StaticImage
            src="../images/platinum-club.png"
            alt={t("token.clubSection.platinum.label")}
          />
        ),
      },
      {
        label: t("token.clubSection.gold.label"),
        icon: (
          <StaticImage
            src="../images/gold-club.png"
            alt={t("token.clubSection.gold.label")}
          />
        ),
      },
      {
        label: t("token.clubSection.silver.label"),
        icon: (
          <StaticImage
            src="../images/silver-club.png"
            alt={t("token.clubSection.silver.label")}
          />
        ),
      },
      {
        label: t("token.clubSection.bronze.label"),
        icon: (
          <StaticImage
            src="../images/bronze-club.png"
            alt={t("token.clubSection.bronze.label")}
          />
        ),
      },
    ]
  }, [t])

  const { isAuthenticated, referralId } = useSelector(
    (store: RootState) => store.auth
  )
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)

  const loginLink = React.useMemo(() => {
    let link = `https://exchange.youngplatform.com/${language}`
    if (isAuthenticated) {
      link = `${link}/dashobard`
    } else {
      link = `${link}/signup`
    }
    return referralId ? `${link}?ref=${referralId}` : link
  }, [isAuthenticated, referralId, language])

  return (
    <CustomStack>
      <GridBox>
        <Box className="rounded-box staking-box" backgroundColor={"grey.4"}>
          <ResponsiveText
            dimension={["20px", "34px"]}
            line={["25px", "39px"]}
            fontWeight={600}
          >
            {staking}
          </ResponsiveText>
          <ResponsiveText dimension={["12px", "16px"]} line={["16px", "20px"]}>
            {t("token.clubSection.stakingText")}
          </ResponsiveText>
        </Box>
        <Box className="airdrop-box rounded-box" backgroundColor={"grey.4"}>
          <ResponsiveText
            dimension={["20px", "34px"]}
            line={["25px", "39px"]}
            fontWeight={600}
          >
            {airdrop}
          </ResponsiveText>
          <ResponsiveText dimension={["12px", "16px"]} line={["16px", "20px"]}>
            {t("token.clubSection.airdropText")}
          </ResponsiveText>
        </Box>

        <Box className="rounded-box fee-box" backgroundColor={labelColor}>
          <ResponsiveText
            dimension={["20px", "34px"]}
            line={["25px", "39px"]}
            fontWeight={600}
            color="white"
          >
            {fees}
          </ResponsiveText>
          <ResponsiveText
            dimension={["12px", "16px"]}
            line={["16px", "20px"]}
            color="white"
          >
            {t("token.clubSection.feesText")}
          </ResponsiveText>
        </Box>
      </GridBox>
      <Box className="text-box">
        <Box className="label-boxes">
          {clubButtons.map(el => {
            return (
              <Button
                variant="secondary"
                colorScheme="white"
                key={el.label.toLowerCase()}
                onClick={() => setLabel(el.label.toLowerCase() as ClubProps)}
                leftIcon={el.icon}
                style={{
                  background:
                    el.label.toLowerCase() === label.toLowerCase()
                      ? "var(--r-grey-8)"
                      : "inherit",
                  borderColor:
                    el.label.toLowerCase() === label.toLowerCase()
                      ? "var(--r-grey-8)"
                      : "transparent",
                  flexShrink: 0,
                }}
              >
                <Label size="sm" as="h3" fontWeight={600} fontFamily="Matter">
                  {el.label}
                </Label>
              </Button>
            )
          })}
        </Box>
        <Box mt={["20px", "28px"]}>
          <ResponsiveText
            line={["30px", "39px"]}
            dimension={["24px", "34px"]}
            fontWeight={600}
            as="h2"
          >
            {title}
          </ResponsiveText>
        </Box>
        <Box mt={["16px", "24px"]}>
          <ResponsiveText
            line={["18px", "20px"]}
            dimension={["14px", "16px"]}
            as="p"
          >
            {description}
          </ResponsiveText>
        </Box>
        <Box mt={["16px", "24px"]}>
          <SeoLink
            isExternal
            isConversion
            link={loginLink}
            rel="nofollow"
            target="_self"
          >
            <ResponsiveButton
              size="lg"
              onClick={() => {
                if(gaTracker){
                  gaTracker.trackEvent({
                    eventName: globalEvents.click_cta,
                    payload: {
                      content: "inizia_da_qui_4"
                    }
                  })
                }
              }}
            >
              {t("token.infoSection.cta")}
            </ResponsiveButton>
          </SeoLink>
        </Box>
      </Box>
    </CustomStack>
  )
}
