import React from "react"
import { Hero } from "../components/home/Hero"
import { Box } from "@youngagency/young-ui"
import { OverviewSection } from "../components/home/OverviewSection"
import { EducationSection } from "../components/home/EducationSection"
import { TradingServiceSection } from "../components/home/TradingServiceSection"
import { TaxesSections } from "../components/home/TaxesSection"
import { LicenseSections } from "../components/home/LicenceSection"
import "../components/home/global.css"
import { ResultSection } from "../components/home/ResultSection"
import { PartnerSection } from "../components/home/PartnerSection"
import { Banner } from "../components/home/Banner"
export const B2BHomePage = () => {
  return (
    <Box background="black">
      <Box pt="16px">
        <Hero />
      </Box>
      <Box mt={["56px", "96px"]}>
        <OverviewSection />
      </Box>
      <Box mt={["56px", "96px"]}>
        <EducationSection />
      </Box>
      <Box mt={["56px", "96px"]}>
        <TradingServiceSection />
      </Box>
      <Box mt={["56px", "96px"]}>
        <TaxesSections />
      </Box>
      <Box background="white" width="full">
        <Box
          mt={["8px", "16px"]}
          borderRadius={"0px 0px 32px 32px"}
          background="black"
          width="full"
          height={["72px", "48px"]}
          style={{
            transform: "translateY(-1px)",
          }}
        />
        <Box mt={["48px", "96px"]}>
          <LicenseSections />
        </Box>
        <Box mt={["48px", "96px"]}>
          <ResultSection />
        </Box>
        <Box mt={["48px", "96px"]}>
          <PartnerSection />
        </Box>
        <Box mt={["48px", "96px"]} pb={["40px", "72px"]}>
          <Banner />
        </Box>
      </Box>
    </Box>
  )
}
