import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Box, Icon, IconType } from "@youngagency/young-ui"
import { Container, ResponsiveText, Avatar } from "../layout"
import { createArrayFromTranslationQuery } from "../../utils/helpers"
import styled from "styled-components"

type Props = {
  title: string
  caption: string
  icon: IconType
}

const StyledBox = styled(Box)`
  & > *:not(:first-of-type) {
    margin-top: 16px;
  }
  @media screen and (min-width: 767px) {
    display: flex;
    & > *:not(:first-of-type) {
      margin-left: 20px;
      margin-top: 0px;
    }
  }
`

const Goals = () => {
  const { t } = useI18next()
  const valuesArray: Props[] = React.useMemo(() => {
    return createArrayFromTranslationQuery(
      t,
      "jobs.goals.goal_",
      3,
      [],
      "title",
      "caption",
      "icon"
    )
  }, [t])
  return (
    <Container size="lg" mt={["96px", "48px"]}>
      <Box mt={["34px", "72px"]}>
        <ResponsiveText
          line={["30px", "50px"]}
          fontWeight={700}
          dimension={["24px", "48px"]}
          as="p"
          textAlign="center"
        >
          {t("jobs.goals.title")}
        </ResponsiveText>
        <Box mt={["24px", "34px"]}>
          <StyledBox>
            {valuesArray.map(value => {
              return (
                <Box
                  width="100%"
                  minWidth={["unset", "271px"]}
                  key={value.title}
                  border="1px solid"
                  borderColor="grey.5"
                  borderRadius="16px"
                  padding="24px"
                >
                  <Avatar width="54px" height="54px" backgroundColor="grey.4">
                    <Icon icon={value.icon} fontSize="24px" />
                  </Avatar>
                  <Box mt={["16px"]}>
                    <ResponsiveText
                      line={["30px"]}
                      fontWeight={600}
                      dimension={["24px"]}
                    >
                      {value.title}
                    </ResponsiveText>
                    <Box mt={["16px"]}>
                      <ResponsiveText line={["20px"]} dimension={["16px"]}>
                        {value.caption}
                      </ResponsiveText>
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </StyledBox>
        </Box>
      </Box>
    </Container>
  )
}

export default Goals
