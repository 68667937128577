export const DEFAULT_TIMEFRAME_VARIATION: TimeFrame = {
  key: "day",
  text: "single_market.timeframes.one_day",
  interval: 5,
  limit: 288,
}

export type TimeFrameListKeys = "2years" | "6month" | "month" | "week" | "day"

export const DEFAULT_TIMEFRAME_LIST: TimeFrame[] = [
  {
    key: "2years",
    text: "single_market.timeframes.two_years",
    interval: 10080,
    limit: 104,
  },
  {
    key: "6month",
    text: "single_market.timeframes.six_months",
    interval: 1440,
    limit: 6 * 31,
  },
  {
    key: "month",
    text: "single_market.timeframes.one_month",
    interval: 240,
    limit: 6 * 31,
  },
  {
    key: "week",
    text: "single_market.timeframes.one_week",
    interval: 60,
    limit: 168,
  },
  {
    key: "day",
    text: "single_market.timeframes.one_day",
    interval: 15,
    limit: 24 * 4,
  },
]

export const DEFAULT_CANDLESTICK_VARIATION_INTERVAL: TimeFrame[] = [
  {
    key: "2years",
    text: "single_market.timeframes.two_years",
    interval: 43200,
    limit: 24,
  },
  {
    key: "6month",
    text: "single_market.timeframes.six_months",
    interval: 10080,
    limit: 24,
  },
  {
    key: "month",
    interval: 1440,
    text: "single_market.timeframes.one_month",
    limit: 30,
  },
  {
    key: "week",
    interval: 240,
    text: "single_market.timeframes.one_week",
    limit: 6 * 7,
  },
  {
    key: "day",
    interval: 60,
    text: "single_market.timeframes.one_day",
    limit: 24,
  },
]

export type TokenExclusionConditionProps = {
  locked: boolean
  basic: boolean
  pro: boolean
  symbol: string
}

export const tokenExclusionCondition = (x: TokenExclusionConditionProps) =>
  x.basic && !x.locked && !["USD", "GBP", "EUR"].includes(x.symbol)

export type TokenV2ExclusionConditionProps = {
  isPro: boolean
  isBase: boolean
  isSaving: boolean
  isLocked: boolean
  isProOnly: boolean
  symbol: string
} & Record<string, any>

export const tokenV2ExclusionCondition = <
  T extends TokenV2ExclusionConditionProps
>(
  x: T
) => x.isBase && !x.isLocked && !["USD", "GBP", "EUR"].includes(x.symbol)

export type TimeFrame = {
  key: TimeFrameListKeys
  interval: number
  text: string
  limit: number
}
