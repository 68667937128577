import styled from "styled-components"
import { Box } from "@youngagency/young-ui"

export const ImageOverlay = styled(Box)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.05);
  transition: all 125ms ease-in;
`
