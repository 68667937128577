import styled from "styled-components"
import { Box, css } from "@youngagency/young-ui"

export const FounderStyledBox = styled(Box)<{ disableHover?: boolean }>(
  ({ disableHover }) =>
    css({
      border: "1px solid",
      borderColor: "grey.5",
      borderRadius: "16px",
      transition: "all 75ms ease",
      overflow: "hidden",
      transform: "translateZ(0)",
      cursor: !disableHover ? "pointer" : "unset",
      ":hover": {
        backgroundColor: !disableHover ? "grey.4" : "unset",
      },
    })
)

export const Grid2To4Box = styled(Box)(
  css({
    display: "grid",
    gridTemplateColumns: ["repeat(2, 1fr)", "repeat(4, 1fr)"],
    gridTemplateRows: ["repeat(2, 1fr)", "1fr"],
    columnGap: "20px",
    rowGap: "20px",
  })
)

export const Grid3To2Box = styled(Box)(
  css({
    display: "grid",
    gridTemplateColumns: ["repeat(2, 1fr)", "repeat(3, 1fr)"],
    gridTemplateRows: ["repeat(3, 1fr)", "repeat(2, 1fr)"],
    columnGap: "20px",
    rowGap: "20px",
  })
)
