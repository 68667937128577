import React from "react"
import { useSeoData } from "./useSeoQuery"

export const useOrganizationSchema = () => {
  const {
    site: { siteMetadata },
    languagePath,
  } = useSeoData()

  const url = React.useMemo(() => siteMetadata.siteUrl + languagePath, [
    siteMetadata.siteUrl,
    languagePath,
  ])
  const organizationSchema = React.useMemo(() => {
    return {
      "@type": "Organization",
      "@id": url + "#organization",
      "name": "Young Platform",
      "url": url,
      "sameAs": [
        siteMetadata.facebook,
        siteMetadata.instagram,
        siteMetadata.linkedin,
        siteMetadata.twitter,
        siteMetadata.youtube,
      ],
      "logo": {
        "@type": "ImageObject",
        "@id": url + "#logo",
        "url": url + siteMetadata.image,
        "width": siteMetadata.imageWidth,
        "height": siteMetadata.imageHeight,
        "caption": "Young Platform",
      },
      "image": {
        "@id": url + "#logo",
      },
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Via Francesco Cigna 96/17",
        "addressLocality": "Torino",
        "addressRegion": "Italy",
        "postalCode": "10155",
        "addressCountry": "IT",
      },
      "contactPoint": [
        {
          "@type": "ContactPoint",
          "contactType": "Customer Service",
          "email": "support@youngplatform.com",
          "contactOption": "TollFree",
          "availableLanguage": [
            {
              "@type": "Language",
              "name": "Italian",
              "alternateName": "it",
            },
            {
              "@type": "Language",
              "name": "English",
              "alternateName": "en",
            },
            {
              "@type": "Language",
              "name": "French",
              "alternateName": "fr",
            },
          ],
        },
      ],
    }
  }, [
    siteMetadata.facebook,
    siteMetadata.instagram,
    siteMetadata.linkedin,
    siteMetadata.twitter,
    siteMetadata.youtube,
    url,
    siteMetadata.image,
    siteMetadata.imageHeight,
    siteMetadata.imageWidth,
  ])
  return organizationSchema
}
