import React from "react"
import { Helmet } from "react-helmet"
import { useWebPageSchema, useBreadcrumbSchema, useFaqSchema } from "../hooks"
import { breadCrumbProps, SingleFaqSchemaProps } from "../types"

type Props = {
  faqsArray: SingleFaqSchemaProps[]
  title: string
  description: string
  breadcrumbs: breadCrumbProps[]
}

export const FaqSchema = ({
  faqsArray,
  title,
  description,
  breadcrumbs,
}: Props) => {
  const schema = useWebPageSchema({
    title,
    description,
  })

  const breadcrumbSchema = useBreadcrumbSchema(breadcrumbs)

  const faqSchema = useFaqSchema(faqsArray)
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          ...schema,
          ["@graph"]: [...schema["@graph"], breadcrumbSchema, faqSchema],
        })}
      </script>
    </Helmet>
  )
}

export default FaqSchema
