import { Box, HStack } from "@youngagency/young-ui"
import React from "react"
import { ResponsiveText } from "../../../components/layout"

const PostFooter = ({
  formattedData,
  readingTime,
}: {
  formattedData: string
  readingTime: number
}) => {
  return (
    <Box mt={["8px", "16px"]}>
      <HStack spacing="6px">
        <ResponsiveText
          dimension={["12px", "14px"]}
          line={["16px", "18px"]}
          color="grey.9"
          data-role="_text"
          as="time"
        >
          {formattedData}
        </ResponsiveText>
        <ResponsiveText
          dimension={["6px", "6px"]}
          line={["16px", "18px"]}
          color="grey.9"
          data-role="_text"
        >
          &#9679;
        </ResponsiveText>
        <ResponsiveText
          dimension={["12px", "14px"]}
          line={["16px", "18px"]}
          color="grey.9"
          data-role="_text"
        >
          {readingTime} min
        </ResponsiveText>
      </HStack>
    </Box>
  )
}

export default PostFooter
