import React from "react"
import { BoxExtendedProps } from "../../../types/system"
import { Box } from "@youngagency/ui"
import clsx from "clsx"
import { cardSection } from "../style/card-section.css"

export const CardsSection = ({
  children,
  className,
  ...rest
}: BoxExtendedProps<HTMLDivElement>) => {
  return (
    <Box className={clsx(cardSection, className)} {...rest}>
      {children}
    </Box>
  )
}
