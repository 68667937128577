import { BoxProps, Box } from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { ResponsiveText } from "../layout"
import styled from "styled-components"

const StyledBox = styled(Box)`
  a {
    text-decoration: none;
    color: var(--green-300);
    &:hover {
      color: var(--green-hover);
    }
  }
`

const FaqFooter = ({ ...rest }: BoxProps) => {
  const { t } = useI18next()

  const parseLink = (text: string, target?: "_blank") => {
    const splittedMail = text.split("*link*")
    let array: JSX.Element[] = []

    splittedMail.forEach((p, index) => {
      if (index != 0 && index % 2 != 0) {
        array.push(
          <a
            href={"https://support.youngplatform.com/hc/"}
            target={target ? target : "_self"}
            rel={target ? "noopener" : "internal"}
            className={"link-style link-hover"}
            key={p + index}
          >
            {p}
          </a>
        )
      } else {
        array.push(<span key={"footer" + p}>{p}</span>)
      }
    })
    return array
  }

  return (
    <StyledBox {...rest}>
      <ResponsiveText
        dimension={["20px", "24px"]}
        line={["25px", "32px"]}
        fontWeight={500}
      >
        {parseLink(t("faq_mail_footer") as string, "_blank")}
      </ResponsiveText>
    </StyledBox>
  )
}

export default FaqFooter
