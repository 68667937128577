import { graphql, useStaticQuery } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import React, { useState } from "react"
import { SupportedLanguageValue } from "../../../types/footer"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { blogNavigationEvents, blogPostEvents, globalEvents } from "../../../service/tracking"

export type Props = {
  nodes: {
    language: {
      code: string
    }
  }
  tracking_value?: {
    trackingValue: string
  }
  databaseId: number
  name: string
  slug: string
}

type LngProps = {
  [key in SupportedLanguageValue]: Props[]
}

const useBlogLayout = () => {
  const { language } = useI18next()
  const [blogLinks, setBlogLink] = useState<null | Props[]>([])
 
  const { allWpCategory } = useStaticQuery(query)

     const {gaTracker} = useSelector((state:RootState)=> state.tracking)
  const handleCategoryTracking = React.useCallback((content?:string | null) => {
    if (gaTracker) {
      gaTracker.trackEvent({
        eventName: blogNavigationEvents.blog_header_navigation,
        payload: {
          content: content?.toLowerCase() ?? null
        }
      })
    }
  }, [gaTracker])

  let languageQuery: LngProps = {
    it: [],
    en: [],
    fr: [],
  }

  const getLanguageQuery = React.useCallback(() => {
    for (let index = 0; index < allWpCategory.nodes.length; index++) {
      const element = allWpCategory.nodes[index]
      languageQuery = {
        ...languageQuery,
        [element.language.code.toLowerCase()]: [
          ...languageQuery[
            element.language.code.toLowerCase() as SupportedLanguageValue
          ],
          element,
        ],
      }
    }
  }, [])

  React.useEffect(() => {
    getLanguageQuery()
    setBlogLink(languageQuery[language as SupportedLanguageValue])
  }, [language])

  return { blogLinks,  handleCategoryTracking }
}

const query = graphql`
  {
    allWpCategory(filter: { databaseId: { nin: [30, 37, 5, 3237, 9] } }) {
      nodes {
        language {
          code
        }
        tracking_value{
          trackingValue
        }
        databaseId
        name
        slug
      }
    }
  }
`

export default useBlogLayout
