import React from "react"
import { Header } from "../"
import { BusinessNavigation } from "./BusinessNavigation"
import { useNavigationContext } from "../../context"
import { EcosistemaSidebar } from "../website/EcosistemaSidebar"
import { PromoBanner } from "../../../promo/components"
import { useI18next } from "gatsby-plugin-react-i18next"
import { CryptoHubBanner } from "../../../b2b/components/crypto-hub"

export const BusinessHeader = () => {
  const context = useNavigationContext()
  const { language } = useI18next()
  return (
    <>
      <Header
        variant="dark"
        banner={
          <PromoBanner showedPlatform="website"/>
          
        }
      >
        <BusinessNavigation />
      </Header>
      {context.isSidebarOpen && <EcosistemaSidebar />}
    </>
  )
}
