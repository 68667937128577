import { useI18next } from "gatsby-plugin-react-i18next"
import { Helmet } from "react-helmet"
import React from "react"
import { useSeoData } from "../hooks"
import { MetaDecoratorProps } from "../types"
import SeoJson from "../../../seoData.json"

export const MetaDecorator = ({
  metaTitle,
  metaDescription,
  image,
  author,
  og,
  imageHeight,
  imageWidth,
  disableSlogan = true,
  extraMetaTags,
}: Partial<MetaDecoratorProps>) => {
  const { language, originalPath } = useI18next()
  const {
    site: { siteMetadata },
  } = useSeoData()

  const seoProps = React.useMemo(() => {
    return {
      title: metaTitle || SeoJson?.[originalPath]?.[language]?.title,
      description:
        metaDescription || SeoJson?.[originalPath]?.[language]?.description,
      slogan: disableSlogan
        ? false
        : SeoJson?.[originalPath]?.[language]?.slogan ||
          SeoJson?.["default"]?.[language]?.slogan,
      image: image || siteMetadata.image,
      imageWidth: imageWidth || siteMetadata.imageWidth,
      imageHeight: imageHeight || siteMetadata.imageHeight,
      author: author || "@youngplatform",
      og: og,
    }
  }, [language, originalPath, siteMetadata])

  return (
    <Helmet
      htmlAttributes={{
        lang: language,
      }}
      title={seoProps.title}
      titleTemplate={seoProps.slogan ? `%s | ${seoProps.slogan}` : undefined}
    >
      <link
        href="https://www.googletagmanager.com/gtag/js?id=GTM-MCGBHGH"
        rel="preconnect"
        as="script"
      />
      <link
        href="https://connect.facebook.net/en_US/fbevents.js"
        rel="preconnect"
        as="script"
      />
      <meta name="description" content={seoProps.description} />
      {og ? (
        og.map(meta => {
          return (
            <meta property={`og:${meta.property}`} content={meta.content} />
          )
        })
      ) : (
        <Helmet>
          <meta property="og:title" content={seoProps.title} />
          <meta property="og:description" content={seoProps.description} />
        </Helmet>
      )}

      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:creator" content={author || ``} />
      <meta property="twitter:title" content={seoProps.title} />
      <meta property="twitter:description" content={seoProps.description} />
      <meta property="og:image" content={seoProps.image} />
      <meta
        property="og:image:width"
        content={seoProps.imageWidth.toString()}
      />
      <meta
        property="og:image:height"
        content={seoProps.imageHeight.toString()}
      />
      <meta property="twitter:card" content={seoProps.image} />
      {extraMetaTags && extraMetaTags.length > 0 && extraMetaTags != null
        ? extraMetaTags.map(el => {
            return (
              <meta
                property={el.property}
                content={el.content}
                key={el.content}
              />
            )
          })
        : null}
    </Helmet>
  )
}
