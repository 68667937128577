import { useGetAllCurrenciesQuery } from "../../../redux/services/currency/api"
import {
  Currency,
  GetCurrenciesResponse,
} from "../../../redux/services/currency/types"
import { useGetAllTokenChartV2Query } from "../../../service/charts/api/api"
import { useTokenExclusion } from "./useTokenExclusion"
import { useTokenSort } from "./useTokenSort"
const ITEM_PER_PAGE = 10
export const useTokenList = (
  page: number,
  sortedBy?: string,
  sortOrder: "asc" | "desc" = "desc"
) => {
  const { sortByType, tokenListOrder } = useTokenSort(sortOrder, sortedBy)

  const {
    data: allTokenCharts,
    isLoading: isChartsLoading,
  } = useGetAllTokenChartV2Query({})

  const { currencyDataAndChartsParser } = useTokenExclusion()

  const { token, isLoading } = useGetAllCurrenciesQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => {
      const start = (page - 1) * ITEM_PER_PAGE
      const end = start + ITEM_PER_PAGE
      let tokens: GetCurrenciesResponse["currencies"][number][] = []
      if (!isLoading && !isChartsLoading && data && allTokenCharts) {
        tokens = currencyDataAndChartsParser({
          currencies: data.currencies,
          chartData: allTokenCharts,
        })

        if (tokens) {
          tokens = sortByType(tokens) as Currency[]
          tokens = tokenListOrder(tokens) as Currency[]
        }
      }

      return {
        token: tokens && tokens.slice(start, end),
        isLoading: isLoading && isChartsLoading && data && allTokenCharts,
      }
    },
  })

  return { token, isLoading }
}
