import { graphql, PageProps } from "gatsby"
import React from "react"
import { capitalizeFirstLetter } from "../../../utils/helpers"
import styled from "styled-components"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Container, ResponsiveText } from "../../../components/layout"
import { Display, Heading, Box } from "@youngagency/young-ui"
import { PaginatedPageContextProps } from "../../../types/blog"
import { Paginator } from "../../../components/shared/Paginator"
import {
  MetaDecorator,
  WebPageSchema,
  AlternateHandler,
} from "../../../feature/seo"
import { BreadCrumb, BlogLayout } from "../../../feature/navigation/components"
import { I18NextCustomContext } from "../../../types/shared"
import {
  PostSectionsWrapper,
  PostSection,
  VideoSection,
  ArticleWall,
} from "../../../feature/blog/components"
import { PostType } from "../../../feature/blog/types"
import { TranslationProvider } from "../../../feature/translations/context"
import { CategoryTranslator } from "../../../feature/translations/components/Translator"

const StyledDisplay = styled(Display)`
  @media screen and (max-width: 767px) {
    font-size: 34px !important;
    line-height: 39px !important;
  }
`
type ContextProps = I18NextCustomContext &
  PaginatedPageContextProps & {
    categoryName: string
    slug: string
  }

const CategoryTemplate: React.FC<
  PageProps<Queries.BlogCategoryQuery, ContextProps>
> = ({
  data,
  pageContext: { categoryName, slug, numPages, currentPage, canonicalPath },
}) => {
  const { t, language } = useI18next()
  const { video, allWpPost } = data
  const slicedCanonicalPath = React.useMemo(() => canonicalPath.slice(1), [])

  const last3Posts = React.useMemo(() => allWpPost.nodes.slice(0, 3), [])
  const otherPosts = React.useMemo(() => allWpPost.nodes.slice(3), [])

  const breadcrumb = React.useMemo(
    () => [
      {
        text: "Home",
        link: "/",
      },
      {
        text: "Blog",
        link: "/blog/",
      },
      {
        text: capitalizeFirstLetter(categoryName),
        link: `/blog/${slug}/`,
      },
    ],
    []
  )

  const ctx = React.useMemo(() => {
    const categoryTrx = new CategoryTranslator(
      allWpPost?.nodes?.[0]?.categories?.nodes?.[0]?.translations
    )

    const trx = categoryTrx.create(language, slug)

    return {
      translations: trx,
      disableTranslations: !categoryTrx.hasTranslation(
        allWpPost?.nodes?.[0]?.categories?.nodes?.[0]?.translations
      ),
    }
  }, [
    language,
    slug,
    allWpPost?.nodes?.[0]?.categories?.nodes?.[0]?.translations,
  ])

  return (
    <TranslationProvider value={ctx}>
      <BlogLayout
        slug={slug}
        dynamicLink={"https://youngexchange.page.link/blog3"}
      >
        <MetaDecorator
          metaTitle={categoryName}
          metaDescription={
            allWpPost?.nodes?.[0]?.categories?.nodes?.[0]?.description &&
            allWpPost.nodes[0].categories.nodes[0].description
              .replace(/<[^>]*>/g, " ")
              .replace(/\s{2,}/g, " ")
              .trim()
          }
        />
        <WebPageSchema
          breadcrumbs={breadcrumb}
          title={categoryName}
          description={
            allWpPost?.nodes?.[0]?.categories?.nodes?.[0]?.description &&
            allWpPost.nodes[0].categories.nodes[0].description
              .replace(/<[^>]*>/g, " ")
              .replace(/\s{2,}/g, " ")
              .trim()
          }
        />
        <AlternateHandler />
        <Container size={["md", "lg"]}>
          <BreadCrumb
            breadcrumbs={breadcrumb}
            // mt={["0px", "40px"]}
          />
        </Container>{" "}
        <main>
          <Container
            size={["md", "lg"]}
            mt={["34px", "57px"]}
            mb={["24px", "48px"]}
          >
            <StyledDisplay as="h1" mb="12px" fontFamily="Matter" weight="heavy">
              {categoryName}
            </StyledDisplay>
            <ResponsiveText
              dimension={["14px", "18px"]}
              maxWidth="55ch"
              color="grey.1"
              line={["18px", "24px"]}
            >
              {allWpPost?.nodes?.[0]?.categories?.nodes?.[0]?.description &&
                allWpPost.nodes[0].categories.nodes[0].description
                  .replace(/<[^>]*>/g, " ")
                  .replace(/\s{2,}/g, " ")
                  .trim()}
            </ResponsiveText>
          </Container>
          <Box mt={["48px", "96px"]}>
            <PostSectionsWrapper>
              <PostSection posts={last3Posts as PostType[]} disableTitle />
              {video.nodes.some(el => el) ? (
                <VideoSection posts={video.nodes as readonly PostType[]} />
              ) : null}
            </PostSectionsWrapper>
          </Box>
          {otherPosts.length > 0 && (
            <>
              <Container size={["md", "lg"]} mt={["0px", "96px"]}>
                <Heading fontFamily="Matter" size="xl" weight="heavy">
                  {t("blog.post.relatedSection")}
                </Heading>
              </Container>
              <Box mt={["24px", "48px"]}>
                <ArticleWall posts={otherPosts as PostType[]} />
              </Box>
              {numPages > 1 ? (
                <Box mt={["24px", "48px"]}>
                  <Paginator
                    link={slicedCanonicalPath}
                    page={currentPage}
                    numOfPages={numPages}
                  />
                </Box>
              ) : null}
            </>
          )}
        </main>
      </BlogLayout>
    </TranslationProvider>
  );
}

export const categoryPageQuery = graphql`query BlogCategory($language: String!, $limit: Int!, $videoId: Int!, $slug: String!, $pageLanguage: WpLanguageCodeEnum!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  allWpPost(
    sort: {date: DESC}
    limit: $limit
    filter: {categories: {nodes: {elemMatch: {slug: {eq: $slug}}}}, language: {code: {eq: $pageLanguage}}}
  ) {
    nodes {
      title
      id
      databaseId
      slug
      date
      modified
      seo {
        readingTime
      }
      tags {
        nodes {
          databaseId
          name
          slug
        }
      }
      categories {
        nodes {
          description
          name
          slug
          translations {
            slug
            language {
              code
              locale
            }
            posts {
              nodes {
                id
              }
            }
          }
        }
      }
      author {
        node {
          name
        }
      }
      featuredImage {
        node {
          mediaItemUrl
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
  video: allWpPost(
    sort: {date: DESC}
    limit: 2
    filter: {tags: {nodes: {elemMatch: {databaseId: {eq: $videoId}}}}, categories: {nodes: {elemMatch: {slug: {eq: $slug}}}}, language: {code: {eq: $pageLanguage}}}
  ) {
    nodes {
      title
      id
      databaseId
      slug
      date
      modified
      seo {
        readingTime
      }
      tags {
        nodes {
          databaseId
          name
          slug
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
      author {
        node {
          name
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
          mediaItemUrl
          altText
        }
      }
    }
  }
}`

export default CategoryTemplate
