import "src/components/cards/style/card.css.ts.vanilla.css!=!../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.18.0_webpack@5.88.2/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/cards/style/card.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81Uy27CMBC88xV7qQQSQbbzgJhLv6M3BxtiCnbkBIha8e+VTaBxgPJQpVZKJGdnZ2e8a2e0XLIY7xB89gD0Vpj5Su8o5JJzoaY9gMowVc61WdPDcsUq8dZHA4vtJK9yChihF/tZ6FJWUisKRqxYJbfCRjNtuDCBYVxuSgo4Keppb98bHYSJE86FXOQVhTj10dBDo4h4aOShbFNpq7eWKjjGyAR5jNgxMl0HZc643SgqaojsaxdmkbE+GkLzjFAyaJGTq2RsF1cKDB0QJDaNXEsjbZ2x05ltTKkNhUJLVQnTwic38NTh85WwRo1U7xTQ9BhZGGf89J2xUpa2UdhrFHu4tdkTqiGJvBqzh1W5YzTncOwwgI9AKi5qCthmvq4FlwzKmRFCAVMc+rZiwwnHcVEPXBVv675sY9SGu+7238zZBSae/MC8ZW4cRR1zqJHoXKqQHERaZkiTeWELLYU4npwxww4zIelZTnRfk06hFjVtqN5BsFPo5D0/h+xOhUvzuk+BN8zjD5CgY/FbI8WYdGf6uwfuR+ZNd+5+/Vt3yZ+6+wJ93D0IKwcAAA==\"}!../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.18.0_webpack@5.88.2/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var __esModule = true;
export var autoHeightCard = 'jja51wa';
export var autoHeightHCard = 'jja51wc';
export var cardContainer = 'jja51w0';
export var cardContainerVariant = _7a468({defaultClassName:'jja51w1',variantClassNames:{shape:{horizontal:'jja51w2',vertical:'jja51w3',square:'jja51w4'}},defaultVariants:{},compoundVariants:[]});
export var cardShadow = 'jja51w5';
export var cardSmallLogo = 'jja51wd';
export var darkCardHover = 'jja51w8';
export var lightCardHover = 'jja51w7';
export var shadowVariants = _7a468({defaultClassName:'jja51w5',variantClassNames:{weight:{light:'jja51w6'}},defaultVariants:{},compoundVariants:[]});
export var unshirkedCard = 'jja51w9';
export var unshirkedHCard = 'jja51wb';