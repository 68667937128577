import React from "react"
import { Box } from "@youngagency/young-ui"
import { useTokenFiatContext } from "../context"

import { ConversorProvider } from "../context/ConversorContext"
import { ConversorContent } from "./ConversorContent"

export const Conversor = () => {
  const { token, fiat } = useTokenFiatContext()

  const ctx = React.useMemo(() => {
    return {
      token,
      fiat,
    }
  }, [token, fiat])

  return (
    <Box maxWidth={["756px"]}>
      <ConversorProvider {...ctx}>
        <ConversorContent />
      </ConversorProvider>
    </Box>
  )
}
