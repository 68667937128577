import React from "react"
import {
  AbsoluteCardImageBox,
  Card,
  CardContent,
  CardGrid,
  CardTexts,
} from "../../../components/cards"
import { Button, HStack, Heading, Subtitle } from "@youngagency/ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { antiPhishingEvents, globalEvents } from "../../../service/tracking"
import { navigate } from "@reach/router"

const EVENTS = [
  {
    eventName: globalEvents.click_to_exchange,
  },
  {
    eventName: antiPhishingEvents.security_hero_click,
  },
  {
    eventName: antiPhishingEvents.security_update_settings,
  },
]

export const Hero = () => {
  const { t } = useI18next()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)
  const handleClick = React.useCallback(() => {
    gaTracker?.trackMultipleEvents(EVENTS)
    navigate("https://youngexchange.page.link/QMsz")
  }, [gaTracker])
  return (
    <CardGrid size="hero">
      <Card
        disableHover
        backgroundColor="var(--grey-10-v2)"
        height={[
          "calc(100vh - 200px) !important",
          "calc(100vh - 98px) !important",
        ]}
        position="relative"
        borderRadius={["0px !important", "32px !important"]}
      >
        <CardContent
          maxWidth="100% !important"
          style={{
            zIndex: 1,
          }}
        >
          <CardTexts maxWidth={["unset", "616px"]} width="100%" zIndex={1}>
            <Heading as="h1" variant="2" fontWeight={600}>
              {t("security.hero.title")}
            </Heading>
            <Subtitle color="grey200" fontWeight={600} variant="2">
              {t("security.hero.description")}
            </Subtitle>
          </CardTexts>
          <HStack
            spacing="unset"
            atoms={{
              marginTop: 24,
              __zIndex: 1,
            }}
          >
            <Button onClick={handleClick}>{t("security.hero.button")}</Button>
          </HStack>
          <AbsoluteCardImageBox>
            <StaticImage
              quality={100}
              outputPixelDensities={[1, 2]}
              style={{
                height: "100%",
              }}
              placeholder="none"
              src="../images/hero.png"
              alt={t("security.hero.alt_text_image")}
            />
          </AbsoluteCardImageBox>
        </CardContent>
      </Card>
    </CardGrid>
  )
}
