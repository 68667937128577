import { Box, BoxProps, HStack } from "@youngagency/young-ui"
import React from "react"
import { Text } from "../texts/temporary"

export const Chip: React.FC<{ children: React.ReactNode } & BoxProps> = ({
  children,
  ...rest
}) => {
  return (
    <HStack maxHeight="16px" px="8px" py="3px" borderRadius="100px" {...rest}>
      <Text.Caption color="inherit">{children}</Text.Caption>
    </HStack>
  )
}
