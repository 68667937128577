import React from "react"
import { css } from "@styled-system/css"
import styled from "styled-components"
import { Box } from "@youngagency/young-ui"
import { Container } from "../../../../components/layout"
import ChartSection from "../../components/ChartSection"
import { useTokenFiatContext } from "../context"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { RelatedConversor } from "./RelatedConversor"
import { Heading, Paragraph } from "../../../../components"
import { TokenFiatPriceSection } from "../../components"
import { inverseTokenTicker } from "../utils"
import {
  useGetAllAvailableFiatQuery,
  useGetAllTimeFrameATValuesV2Query,
} from "../../../../redux/services/currency/api"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
const StyledStack = styled(Box)(
  css({
    display: "flex",
    flexDirection: ["column", "row"],
    "& > *:not(:last-of-type)": {
      marginRight: ["0px", "117px"],
      marginBottom: ["48px", "0px"],
    },
  })
)

export const Body = () => {
  const { token, fiat } = useTokenFiatContext()

  const { data, isLoading } = useGetAllTimeFrameATValuesV2Query({
    from: token.symbol,
    to: fiat.symbol,
  })

  const {
    data: allAvailableFiat,
    isLoading: isFiatLoading,
  } = useGetAllAvailableFiatQuery()
  const parsedData = React.useMemo(() => {
    let newData
    if (data && allAvailableFiat) {
      const selectedFiat = allAvailableFiat.find(
        x => x.symbol.toLowerCase() === fiat.symbol.toLowerCase()
      )

      newData = { ...data } as Record<
        string,
        {
          percentageChange: string
          ath: number
          atl: number
        }
      >
      if (selectedFiat) {
        newData = Object.entries(data).reduce<
          Record<
            string,
            {
              percentageChange: string
              ath: number
              atl: number
            }
          >
        >((acc, item) => {
          return Object.assign(acc, {
            [item[0]]: {
              ...item[1],
              ath:
                (item[1].ath * selectedFiat.price).toFixed(2) +
                selectedFiat.amountSymbol,
              atl:
                (item[1].atl * selectedFiat.price).toFixed(2) +
                selectedFiat.amountSymbol,
            },
          })
        }, {})
      }
    }
    return {
      values: newData,
      isLoadingAth: isLoading && isFiatLoading,
    }
  }, [data, allAvailableFiat, isFiatLoading, isLoading, fiat.symbol])

  const {} = useSelector((store: RootState) => store.currency)

  const { t } = useTranslation()
  return (
    <section>
      <Container size={["md", "lg"]}>
        <StyledStack>
          <Box maxWidth="756px" width="100%">
            <ChartSection
              currency={token.symbol}
              fiat={fiat.symbol}
              amountSymbol={fiat.amountSymbol}
              maxWidth="unset"
              maxHeight="472px"
            />
            <Box mt={["24px", "48px"]}>
              <Heading as="h2">
                {t("markets.token_fiat.price_section.title", {
                  currency: inverseTokenTicker(token.symbol),
                  fiat: fiat.symbol,
                })}
              </Heading>
              <Box mt={["16px", "24px"]}>
                <Paragraph>
                  {t("markets.token_fiat.price_section.description", {
                    currency: token.name,
                  })}
                </Paragraph>
              </Box>
            </Box>
            <Box mt={["24px", "48px"]}>
              <TokenFiatPriceSection
                isLoading={parsedData.isLoadingAth}
                high={parsedData.values?.all.ath}
                high_24h={parsedData.values?.day.ath}
                high_7g={parsedData.values?.week.ath}
                low={parsedData.values?.all.atl}
                low_24h={parsedData.values?.day.atl}
                low_7g={parsedData.values?.week.atl}
                percent_change_24h={parsedData.values?.day.percentageChange}
                percent_change_7g={parsedData.values?.week.percentageChange}
                percent_change_2y={parsedData.values?.all.percentageChange}
              />
            </Box>
          </Box>
          <Box maxWidth={["unset", "270px"]} position="relative" width="100%">
            <RelatedConversor />
          </Box>
        </StyledStack>
      </Container>
    </section>
  )
}
