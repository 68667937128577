import { Box, Label } from "@youngagency/young-ui"
import { graphql, PageProps } from "gatsby"
import React from "react"
import { Container, ResponsiveText } from "../../components/layout"
import {
  MetaDecorator,
  AlternateHandler,
  WebPageSchema,
} from "../../feature/seo"
import { useI18next } from "gatsby-plugin-react-i18next"
import { BreadCrumb, LandingLayout } from "../../feature/navigation/components"
import styled from "styled-components"
import { isBrowser } from "../../components/blog/utils/helpers"
import { LegalHtmlWrapper } from "./components"
import { Helmet } from "react-helmet"
import { GenericPageTransalator } from "../../feature/translations/components/Translator"
import { TranslationProvider } from "../../feature/translations/context"

type Props = PageProps<Queries.SingleLegalPageQuery, { slug: string }>

const CustomStack = styled(Box)`
  .front-matter {
    display: none;
  }
  .legal-content {
    max-width: unset;
    width: 100%;
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    align-items: flex-start;

    .legal-content {
      max-width: 756px;
    }

    & > *:not(:first-of-type) {
      margin-left: 117px;
    }

    .front-matter {
      display: block;
      top: 96px;
      & > *:not(:first-of-type) {
        margin-top: 24px;
      }
    }
  }
`

const LegalTemplate: React.FC<Props> = ({ data, pageContext: { slug } }) => {
  const { t, language, originalPath } = useI18next()
  const [isSticky, setIsSticky] = React.useState<boolean>(true)

  const [frontMatter, setFrontMatter] = React.useState<
    HTMLHeadingElement[] | false
  >(false)
  const breadCrumbs = React.useMemo(() => {
    return [
      {
        text: "Home",
        link: "/",
      },
      {
        text: t("legal.title"),
        link: "/legal/",
      },
      {
        text: data?.wpLegalDocs?.title as string,
        link: `/legal/${slug}/`,
      },
    ]
  }, [t])

  const frontMatterRef = React.useRef<HTMLDivElement>(null)

  const moveToHashHeader = React.useCallback((hash: string) => {
    if (isBrowser) {
      const el = document.querySelector(`[data-hash="${hash}"]`)
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" })
      }
    }
  }, [])

  React.useEffect(() => {
    if (isBrowser) {
      const h2Arrays = document.querySelectorAll("h2")
      setFrontMatter(Array.from(h2Arrays))
      h2Arrays.forEach((el, index) => {
        el.setAttribute("data-hash", `#${index + 1}`)
        el.textContent = `${index + 1}. ` + el.textContent
      })
    }
  }, [])

  React.useEffect(() => {
    if (isBrowser) {
      window.addEventListener("hashchange", () => {
        const hash = location.hash
        if (hash) {
          moveToHashHeader(hash)
        }
      })
    }
  }, [])

  const ctx = React.useMemo(() => {
    const translator = new GenericPageTransalator(
      data.wpLegalDocs?.translations
    )
    const trx = translator.create(language, "legal", originalPath.slice(1))

    return {
      disableTranslations: !translator.hasTranslation(
        data.wpLegalDocs?.translations
      ),
      translations: trx,
    }
  }, [])

  React.useEffect(() => {
    if (isBrowser) {
      if (!frontMatterRef?.current) {
        return
      }
      const y = window.innerHeight
      const y2 = frontMatterRef.current.offsetHeight

      setIsSticky(y > y2)
    }
  }, [frontMatterRef?.current, frontMatterRef])
  return (
    <TranslationProvider value={ctx}>
      <LandingLayout dinamycLink="https://youngexchange.page.link/lpM">
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <MetaDecorator
          metaTitle={data?.wpLegalDocs?.title}
          metaDescription={data?.wpLegalDocs?.title}
        />
        <WebPageSchema
          title={data?.wpLegalDocs?.title}
          description={data?.wpLegalDocs?.title}
          breadcrumbs={breadCrumbs}
        />
        <AlternateHandler />

        <Container size={["md", "lg"]}>
          <BreadCrumb breadcrumbs={breadCrumbs} />
          <Box mt={["24px", "48px"]}>
            <CustomStack position="relative">
              <Box
                className="front-matter"
                position={isSticky ? "sticky" : "static"}
                maxWidth="270px"
                width="100%"
                display={["none", "block"]}
                ref={frontMatterRef}
              >
                {frontMatter
                  ? frontMatter.map((el, index) => {
                      return (
                        <Box mr="auto" key={`anchor-${index}`}>
                          <a
                            href={`#${index + 1}`}
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                          >
                            <li
                              style={{
                                listStyle: "none",
                              }}
                            >
                              <Label
                                fontFamily="Matter"
                                color="grey.1"
                                size="sm"
                                fontWeight={600}
                              >
                                {el.textContent
                                  ? el.textContent.split(". ")[1]
                                  : el.textContent}
                              </Label>
                            </li>
                          </a>
                        </Box>
                      )
                    })
                  : null}
              </Box>
              <Box className="legal-content">
                <ResponsiveText
                  textAlign="left"
                  line={["17px", "20px"]}
                  dimension={["14px", "16px"]}
                  fontWeight={600}
                >
                  {t("legal.title")}
                </ResponsiveText>
                {data?.wpLegalDocs?.title ? (
                  <Box mt={["16px", "24px"]}>
                    <ResponsiveText
                      textAlign="left"
                      line={["32px", "60px"]}
                      dimension={["28px", "56px"]}
                      fontWeight={700}
                    >
                      {data.wpLegalDocs.title}
                    </ResponsiveText>
                  </Box>
                ) : null}
                {data?.wpLegalDocs?.content ? (
                  <LegalHtmlWrapper
                    mt={["24px", "48px"]}
                    dangerouslySetInnerHTML={{
                      __html: data.wpLegalDocs.content.replace(
                        /(<p>&nbsp;<\/p>)|(<h2><span>&nbsp;<\/span><\/h2>)/g,
                        ""
                      ),
                    }}
                  />
                ) : null}
              </Box>
            </CustomStack>
          </Box>
        </Container>
      </LandingLayout>
    </TranslationProvider>
  )
}

export const query = graphql`
  query SingleLegalPage(
    $language: String!
    $slug: String!
    $pageLanguage: WpLanguageCodeEnum!
  ) {
    wpLegalDocs(
      slug: { eq: $slug }
      language: { code: { eq: $pageLanguage } }
    ) {
      title
      content
      translations {
        slug
        language {
          code
          locale
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default LegalTemplate
