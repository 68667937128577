import { Box, Button, css, Icon, VStack } from "@youngagency/young-ui"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import { BlackButton, Container } from "../../../components/layout"
import { Text } from "../../../components/texts/temporary"
import styled from "styled-components"
import { dynamicLinks, Tier, tiers } from "../constants"
import { FaqsSection } from "./FaqsSection"
import { navigate } from "@reach/router"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { clubsEvents, globalEvents } from "../../../service/tracking"


const TiersImage = React.memo(({ tier }: { tier: Tier }) => {
  const { t } = useTranslation()

  const tierAltText = React.useMemo(
    () => t(`clubs_page.recap_section.clubs.${tier}.alt_text`),
    [t, tier]
  )
  switch (tier) {
    case "bronze":
      return <StaticImage src="../images/tiers/Bronze.png" alt={tierAltText} />
    case "silver":
      return <StaticImage src="../images/tiers/Silver.png" alt={tierAltText} />
    case "gold":
      return <StaticImage src="../images/tiers/Gold.png" alt={tierAltText} />
    default:
      return (
        <StaticImage src="../images/tiers/Platinum.png" alt={tierAltText} />
      )
  }
})

const TierCard = React.memo(({ tier }: { tier: Tier }) => {
  const { t } = useTranslation()
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)

  const handleClick = React.useCallback(() => {
    if(gaTracker)
    gaTracker.trackMultipleEvents([
      {
        eventName: clubsEvents.club_body_recap,
        payload: {
          card_clicked: tier,
        },
      },
      {
        eventName: globalEvents.click_to_exchange,
      },
    ])
    navigate(dynamicLinks.main)
  }, [gaTracker])
  const tierTexts = React.useMemo(
    () => ({
      title: t(`clubs_page.recap_section.clubs.${tier}.title`),
      description: t(`clubs_page.recap_section.clubs.${tier}.description`),
      label: t(`clubs_page.recap_section.clubs.${tier}.label`),
      cta_text: t(`clubs_page.recap_section.clubs.${tier}.cta_text`),
      getLabelColor() {
        switch (tier) {
          case "bronze":
            return "var(--orange-20-v2)"
          case "silver":
            return "var(--grey-20-v2)"
          case "gold":
            return "var(--yellow-20-v2)"
          default:
            return "var(--blue-20-v2)"
        }
      },
    }),
    [t, tier]
  )
  return (
    <Box
      background="white"
      borderRadius="32px"
      padding="24px"
      width="100%"
      maxWidth={["100%", "368px"]}
    >
      <VStack
        align="flex-start"
        justify="flex-start"
        width="100%"
        height="100%"
        spacing="16px"
      >
        <VStack
          width="100%"
          spacing="8px"
          align="flex-start"
          justify="flex-start"
        >
          <Box height="100%" maxWidth="64px" maxHeight="64px">
            <TiersImage tier={tier} />
          </Box>
          <Box
            height="16px"
            background={tierTexts.getLabelColor()}
            borderRadius="100px"
            display="flex"
            alignItems="center"
            px="8px"
            justifyContent="center"
          >
            <Text.Caption fontWeight={600}>{tier.toUpperCase()}</Text.Caption>
          </Box>
        </VStack>
        <Box>
          <Text.Subtitle1 as="p" fontWeight={600}>
            {tierTexts.title}
          </Text.Subtitle1>
        </Box>
        <Box width="100%" height="1px" background="var(--grey-50-v2)" />

        <Box width="100%" marginBottom="16px !important">
          <Text.Body1 color="var(--grey-200-v2)">
            {tierTexts.description}
          </Text.Body1>
        </Box>
        <BlackButton
          size={"md"}
          isRounded
          isFullWidth
          mt="auto"
          onClick={handleClick}
        >
          {tierTexts.cta_text}
        </BlackButton>
      </VStack>
    </Box>
  )
})

const CardGrid = styled(Box)(
  css({
    display: "grid",
    gridTemplateColumns: ["1fr", "repeat(4, 1fr)"],
    gridGap: ["16px", "24px"],
    width: "100%",
    mx: "auto",
    mt: ["24px", "48px"],
  })
)

export const RecapSection = () => {
  const { t } = useTranslation()
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)

  const handleClick = React.useCallback(() => {
    if(gaTracker)
    gaTracker.trackMultipleEvents([
      {
        eventName: clubsEvents.club_footer_banner,
      },
      {
        eventName: globalEvents.click_to_exchange,
      },
    ])
    navigate(dynamicLinks.main)
  }, [
    gaTracker,
  ])
  return (
    <>
      <Container>
        <Box mt={["48px", "96px"]} maxWidth="756px" mx="auto">
          <Text.H3 color="white" textAlign="center" as="h2">
            {t("clubs_page.recap_section.title")}
          </Text.H3>
        </Box>
        <CardGrid>
          {tiers.map(tier => (
            <TierCard key={tier} tier={tier} />
          ))}
        </CardGrid>
        <Box mt={["48px", "96px"]}>
          <FaqsSection />
        </Box>
      </Container>
      <Box />

      <Box
        maxWidth="756px"
        mx="auto"
        mt={["48px", "96px"]}
        pb={["36px", "72px"]}
      >
        <Text.H3 fontWeight={600} textAlign="center" as="h2" color="white">
          {t("clubs_page.footer.title")}
        </Text.H3>
        <Box mt={["16px", "24px"]} display="flex" justifyContent="center">
          <Button size="md" mx="auto" onClick={handleClick}>
            {t("clubs_page.footer.button")}
          </Button>
        </Box>
      </Box>
    </>
  )
}
