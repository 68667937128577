import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { DefinedTermProps } from "../types"
import { useSeoData } from "./useSeoQuery"

export const useDefinedTermSchema = ({
  name,
  description,
}: DefinedTermProps) => {
  const {
    site: { siteMetadata },
    languagePath,
  } = useSeoData()

  const { originalPath } = useI18next()

  const url = React.useMemo(
    () => siteMetadata.siteUrl + languagePath + originalPath,
    [siteMetadata.siteUrl, languagePath, originalPath]
  )

  const escapeJson = (str: string | null | undefined) => {
    return str?.replace(/"/g, '\\"'); // Esegue l'escape delle virgolette
  };

  const definedSchema = React.useMemo(() => {
    return {
      "@type": "DefinedTerm",
      "name": name,
      "description": escapeJson(description),
      "mainEntityOfPage": url + "#webpage",
      "url": url,
      "inDefinedTermSet": url,
    }
  }, [name, description, url])
  return definedSchema
}
