import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { DistributionType } from "../types"

const useDistribution = (type: DistributionType) => {
  const { t } = useI18next()
  if (type === "total") {
    return React.useMemo(() => {
      return [
        {
          key: "all",
          name: t("token_yng.distribution.box_2.labels.title_1"),
          color: t("token_yng.distribution.box_2.labels.color_1"),
          value: 100000000,
        },
        {
          key: "incentivi",
          name: t("token_yng.distribution.box_2.labels.title_2"),
          color: t("token_yng.distribution.box_2.labels.color_2"),
          value: 63600000,
        },
        {
          key: "liquidity-provider",
          name: t("token_yng.distribution.box_2.labels.title_3"),
          color: t("token_yng.distribution.box_2.labels.color_3"),
          value: 17600000,
        },
        {
          key: "team",
          name: t("token_yng.distribution.box_2.labels.title_4"),
          color: t("token_yng.distribution.box_2.labels.color_4"),
          value: 10000000,
        },
        {
          key: "advisor",
          name: t("token_yng.distribution.box_2.labels.title_5"),
          color: t("token_yng.distribution.box_2.labels.color_5"),
          value: 5000000,
        },

        {
          key: "seedrs",
          name: t("token_yng.distribution.box_2.labels.title_6"),
          color: t("token_yng.distribution.box_2.labels.color_6"),
          value: 3800000,
        },
      ]
    }, [t])
  }
  return React.useMemo(() => {
    return [
      {
        key: "all",
        name: t("token_yng.distribution.box_1.labels.title_1"),
        color: t("token_yng.distribution.box_1.labels.color_1"),
        value: 16730000,
      },
      {
        key: "yp",
        name: t("token_yng.distribution.box_1.labels.title_2"),
        color: t("token_yng.distribution.box_1.labels.color_2"),
        value: 10573360,
      },
      {
        key: "advisor",
        name: t("token_yng.distribution.box_1.labels.title_3"),
        color: t("token_yng.distribution.box_1.labels.color_3"),
        value: 200760,
      },
      {
        key: "pool",
        name: t("token_yng.distribution.box_1.labels.title_4"),
        color: t("token_yng.distribution.box_1.labels.color_4"),
        value: 3998470,
      },
      {
        key: "stakeholder",
        name: t("token_yng.distribution.box_1.labels.title_5"),
        color: t("token_yng.distribution.box_1.labels.color_5"),
        value: 769580,
      },
      {
        key: "step",
        name: t("token_yng.distribution.box_1.labels.title_6"),
        color: t("token_yng.distribution.box_1.labels.color_6"),
        value: 1187830,
      },
    ]
  }, [t])
}

export default useDistribution
