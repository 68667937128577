import React from "react"
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
} from "@youngagency/young-ui"
import { StaticImage } from "gatsby-plugin-image"
import { Paragraph, SubTitle, Label } from "../../../components"
import { useI18next } from "gatsby-plugin-react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { RootState } from "../../../redux/store"
import styled from "styled-components"
import { changeUserFirstVisit } from "../../../redux/reducers/uiSlice"
import {
  getParamsFromUrl,
  triggerClickToExchangeEvents,
} from "../../../utils/general"
import { navigate } from "@reach/router"

const StyledBox = styled(Box)({
  "& > div": {
    background: "rgba(0, 0, 0, 0.4)",
    color: "white",
    "&:hover": {
      color: "black",
    },
  },
})

const PromoImage = React.memo(() => {
  const { language, t } = useI18next()
  switch (language) {
    case "en":
      return (
        <StaticImage
          src="../images/cashback.png"
          alt={t("promo.pop_up.title")}
        />
      )

    case "fr":
      return (
        <StaticImage
          src="../images/cashback.png"
          alt={t("promo.pop_up.title")}
        />
      )

    default:
      return (
        <StaticImage
          src="../images/cashback.png"
          alt={t("promo.pop_up.title")}
        />
      )
  }
})

export const PromoModal = () => {
  const { t } = useI18next()
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = React.useState<boolean>(false)
  const { userFirstVisit } = useSelector((store: RootState) => store.uiStyle)
  const handleClose = React.useCallback(() => {
    const visitType = "cashbackCampaign"
    dispatch(changeUserFirstVisit(visitType))
    setIsVisible(false)
  }, [dispatch])
  const handleNavigate = React.useCallback(() => {
    const link = "https://youngexchange.page.link/SPRINGCASHBACK"
    const visitType = "cashbackCampaign"

    triggerClickToExchangeEvents()
    dispatch(changeUserFirstVisit(visitType))
    navigate(link)
  }, [dispatch])

  React.useEffect(() => {
    if (userFirstVisit) {
      const timer = setTimeout(() => {
        setIsVisible(true)
      }, 5000)
      const popUpVisibility = getParamsFromUrl("mk-comms")
      const shouldIgnore = popUpVisibility === "hidden"
      if (shouldIgnore) {
        clearTimeout(timer)
        setIsVisible(false)
      }
      return () => clearTimeout(timer)
    }
  }, [userFirstVisit])

  if (isVisible)
    return (
      <Modal onClose={handleClose}>
        <Box
          maxWidth={"343px"}
          backgroundColor="white"
          zIndex={1}
          borderRadius="16px"
          border="1px solid"
          borderColor="grey.5"
        >
          <Box
            position="relative"
            overflow="hidden"
            borderRadius="16px 16px 0px 0px"
            maxWidth="343px"
            maxHeight={["256px", "unset"]}
            style={{
              transform: "translateZ(0)",
            }}
          >
            <Box maxWidth={"343px"}>
              <PromoImage />
            </Box>
            <StyledBox position="absolute" top={["0px"]} right={["0px"]}>
              <ModalCloseButton />
            </StyledBox>
          </Box>
          <ModalBody mt={["16px", "0px"]}>
            <VStack width="100%">
              <SubTitle disableResponsive>{t("promo.pop_up.title")}</SubTitle>
              <Box mt="16px">
                <Paragraph disableResponsive>
                  {t("promo.pop_up.description")}
                </Paragraph>
              </Box>
              <Button mt="16px" isFullWidth isRounded onClick={handleNavigate}>
                {t("promo.pop_up.cta")}
              </Button>
              {/* {language === "fr" ? (
                <Box mt="10px" display="flex" justifyContent="center">
                  <SeoLink
                    isExternal={false}
                    link="/legal/program-regulations-welcome-bonus-50/"
                    rel="nofollow"
                  >
                    <Label>Règlement de la promotion</Label>
                  </SeoLink>
                </Box>
              ) : null} */}
            </VStack>
          </ModalBody>
        </Box>
      </Modal>
    )
  return null
}
