import { BoxProps } from "@youngagency/ui"
import React from "react"
import { gridLeft, gridCenter, gridRight } from "../style/grid-layout.css"

type Props = Omit<BoxProps, "color"> & {
  alignment?: "left" | "center" | "right"
  children: React.ReactNode
}

export const CardsWrapper = ({ children, alignment }: Props) => {
  const cardsStyle = React.useMemo(() => {
    switch (alignment) {
      case "left":
        return gridLeft
      case "center":
        return gridCenter
      case "right":
        return gridRight
      default:
        return gridCenter
    }
  }, [alignment])
  return <div className={cardsStyle}>{children}</div>
}
