import React from "react"
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  VStack,
  Box,
  Button,
} from "@youngagency/young-ui"
import { StaticImage } from "gatsby-plugin-image"
import { Text } from "../../../components/texts/temporary"
import { useI18next } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { navigate } from "@reach/router"

const ModalStyleWrapper = styled(Box)({
  "a": {
    color: "var(--green-200-v2)",
  },
})

type Props = {
  closePopUp: () => void
}

export const ClosablePopup = ({ closePopUp }: Props) => {
  const { t } = useI18next()
  const handleNavigate = React.useCallback(() => {
    navigate("https://form.typeform.com/to/Ue7WgyMc?platforms=blog")
  }, [])

  return (
    <Modal onClose={closePopUp}>
      <ModalContent css>
        <ModalHeader>
          <Box
            style={{
              transform: "translateZ(0)",
            }}
            borderTopLeftRadius="16px"
            borderTopRightRadius="16px"
            overflow="hidden"
          >
            <StaticImage
              src="../images/pop_up/survey-modal-header.png"
              alt="popup"
            />
          </Box>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <VStack align="flex-start" justify="center" spacing="24px">
            <Box width="100%" maxWidth="183px" mt={["24px", "inherit"]}>
              <StaticImage
                src="../images/pop_up/yng-logo-light.png"
                alt="popup"
                placeholder="blurred"
              />
            </Box>
            <Box>
              <Text.H6>{t("promo.newsletter.survey.pop_up.title")}</Text.H6>
              <Box mt="8px">
                <Text.Body1 color="var(--text-secondary)">
                  {t("promo.newsletter.survey.pop_up.description")}
                </Text.Body1>
              </Box>
              <Box mt="16px">
                <Button size="md" isRounded onClick={handleNavigate}>
                  {t("promo.newsletter.survey.pop_up.cta")}
                </Button>
              </Box>
            </Box>
          </VStack>
        </ModalBody>
        {/* <ModalFooter>
          <ModalStyleWrapper as="aside">
            <Text.Caption
              fontWeight={700}
              dangerouslySetInnerHTML={{
                __html: t("promo.newsletter.survey.pop_up.policy"),
              }}
            />
          </ModalStyleWrapper>
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  )
}
