import styled from "styled-components"
import { Box } from "@youngagency/young-ui"

export const LegalHtmlWrapper = styled(Box)`
  font-family: "Matter";
  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 8px;
  }
  h3 {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 6px;
  }

  ul {
    padding-left: 16px;
    li {
      font-size: 14px;
      line-height: 17px;
      &:not(:first-of-type) {
        margin-top: 8px;
      }
    }
  }

  ol {
    padding-left: 16px;
    li {
      font-size: 14px;
      line-height: 17px;
      &:not(:first-of-type) {
        margin-top: 6px;
      }
    }
  }

  & a {
    text-decoration: underline;
    color: var(--green-300-v2);
    transition: all 125ms ease;
    word-break: break-word;
    &:hover {
      color: var(--green-300-v2);
    }
  }
  em {
    font-style: italic;
  }
  strong {
    font-weight: 600 !important;
  }
  ul,
  ol {
    width: 100%;
    padding: 0px;
  }

  li {
    list-style: disc;
    margin: auto;
    padding: 4px 0px;
    width: calc(100% - 24px);
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
  }
  ol li {
    list-style: decimal;
  }
  ul li {
    list-style: disc;
  }
  @media screen and (min-width: 767px) {
    h2 {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 16px;
    }
    h3 {
      font-size: 16px;
      line-height: 20px;
    }

    a {
      color: var(--green-300) !important;
      font-size: 16px;
      line-height: 20px;
    }

    ul {
      padding-left: 24px;
      li {
        font-size: 16px;
        line-height: 20px;
        &:not(:first-of-type) {
          margin-top: 16px;
        }
      }
    }

    ol {
      padding-left: 16px;
      li {
        font-size: 16px;
        line-height: 20px;
        &:not(:first-of-type) {
          margin-top: 12px;
        }
      }
    }
    p {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 16px;
    }
  }
`
export * from "./CategorySelector"
export * from "./LegalDocs"
