import { Box } from "@youngagency/young-ui"
import React from "react"
import styled from "styled-components"
import { css } from "@styled-system/css"

export const CardStack = styled(Box)(
  css({
    display: "flex",
    flexDirection: ["column", "row"],
    width: "100%",
    "& >*:not(:last-of-type)": {
      mb: ["1rem", "initial"],
      mr: ["initial", "1rem"],
    },
  })
)

