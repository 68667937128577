import { Box, Heading, HStack, Skeleton, VStack } from "@youngagency/young-ui"
import isEmpty from "lodash/isEmpty"
import React from "react"
import { ResponsiveText } from "../../../components/layout"
import { SeoLink } from "../../seo"
import { useTokenContext } from "../context/TokenListProvider"
import { MergedTokenProps } from "../types"
import { SingleTokenRowV2 } from "./SingleTokenRow"
import { TokenRowLayout } from "./TokenRowLayout"

export const TokenList = ({ tokens }: { tokens: any[] }) => {
  const { isLoading, token } = useTokenContext()
  const isLoaded = React.useMemo(() => !isLoading && !isEmpty(token), [
    isLoading,
    token,
  ])
  if (!isLoaded)
    return (
      <Box>
        <SkeletonPairs tokens={tokens} />
      </Box>
    )
  return (
    <Box>
      {token.map(
        (
          el: JSX.IntrinsicAttributes & {
            isUnlisted: boolean
          } & MergedTokenProps & {
              isUnlisted: boolean
              points: number[]
              percentage: number
            }
        ) => {
          return (
            <SingleTokenRowV2
              key={el.symbol}
              {...el}
              isUnlisted={
                ["LUNA", "LUNA2"].includes(el.symbol) || el?.isUnlisted
              }
            />
          )
        }
      )}
    </Box>
  )
}

const SkeletonPairs = ({ tokens }: { tokens: any[] }) => {
  return (
    <Box>
      {tokens.map(token => {
        return (
          <SeoLink
            isExternal={false}
            link={`/exchange/${token.symbol.toLowerCase()}/`}
            key={token.symbol}
          >
            <SkeletonTokenRow {...token} />
          </SeoLink>
        )
      })}
    </Box>
  )
}

export const SkeletonTokenRow = ({
  name,
  symbol,
}: {
  name: string
  symbol: string
}) => {
  return (
    <TokenRowLayout
      hasHover
      height="72px"
      px={["16px", "24px"]}
      display={"grid !important"}
    >
      <HStack
        display={"flex !important"}
        spacing="16px"
        style={{
          justifyContent: "flex-start",
        }}
      >
        <Skeleton width="36px" height="36px" variant="circle"></Skeleton>
        <VStack spacing="2px" align="flex-start">
          <Heading size="sm" fontWeight={600} fontFamily="Matter">
            {name}
          </Heading>
          <ResponsiveText
            line={["18px", "20px"]}
            dimension={["14px", "16px"]}
            color="grey.1"
            mt="2px"
          >
            {symbol}
          </ResponsiveText>
        </VStack>
      </HStack>

      <Box
        display="flex !important"
        flexDirection={["column", "row"]}
        alignItems={["flex-end", "inherit"]}
      >
        <Skeleton height="20px" width="100px" />
        <Skeleton display={["block", "none"]} height="20px" width="50px" />
      </Box>
      <Box
        display={["none", "flex"]}
        width="50px"
        m="auto"
        justifyContent="center"
      >
        <Skeleton height="20px" width="100%" />
      </Box>
      <Box display={["none", "inherit"]}>
        <Skeleton height="20px" width="100px" />
      </Box>

      <Box display={["none", "inherit"]}>
        <Skeleton height="20px" width="100px" />
      </Box>

      <Box display={["none", "inherit"]}>
        <Skeleton height="20px" width="100px" />
      </Box>

      <Box display={["none", "inherit"]} justifySelf="flex-end">
        <Skeleton height="40px" width="100px" borderRadius="100px" />
      </Box>
    </TokenRowLayout>
  )
}
