import {
  borderRadius,
  Box,
  BoxProps,
  css,
  HStack,
  Icon,
} from "@youngagency/young-ui"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { ResponsiveText } from "../../../components/layout"
import { FounderItem } from "../types"
import { FounderStyledBox } from "./layout"
import { SeoLink } from "../../../feature/seo"
import { useFounderContext } from "../context/founder"

export const FounderBox = ({
  title,
  featuredImage,
  about_us,
  allWpAboutGroups,
  databaseId,
  disableFn,
  disableHover,
  ...rest
}: Omit<BoxProps, "title"> &
  FounderItem & { disableFn?: true; disableHover?: boolean }) => {
  const image = React.useMemo(
    () => (featuredImage?.node ? getImage(featuredImage?.node) : null),
    []
  )

  const ctx = useFounderContext()

  const handleLinkedinClick = React.useCallback(
    (e: React.BaseSyntheticEvent) => {
      if (!about_us?.linkedin) return
      e.stopPropagation()
    },
    []
  )

  return (
    <FounderStyledBox
      {...rest}
      onClick={() => !disableFn && ctx?.openModal(databaseId)}
      disableHover={disableHover}
    >
      {image ? (
        <GatsbyImage image={image} alt={title || "Young Member Placeholder"} />
      ) : null}
      <Box p={["16px", "24px"]}>
        <HStack justify={["flex-start", "space-between"]} align="flex-start">
          <Box>
            <ResponsiveText
              line={["18px", "25px"]}
              dimension={["14px", "20px"]}
              fontWeight={600}
            >
              {title}
            </ResponsiveText>
            {about_us?.role ? (
              <ResponsiveText
                line={["16px", "18px"]}
                dimension={["12px", "14px"]}
              >
                {about_us?.role}
              </ResponsiveText>
            ) : null}
          </Box>
          {about_us?.linkedin ? (
            <Box
              display={["none", "block"]}
              role="button"
              onClick={handleLinkedinClick}
            >
              <SeoLink
                isExternal={true}
                target="_blank"
                rel="nofollow"
                link={about_us.linkedin}
              >
                <Icon icon="linkedin" fontSize="24px" />
              </SeoLink>
            </Box>
          ) : null}
        </HStack>
      </Box>
    </FounderStyledBox>
  )
}
