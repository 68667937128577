import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Button, HStack } from "@youngagency/ui"
import { Box as LegacyBox } from "@youngagency/young-ui"
import {
  AbsoluteCardImageBox,
  Card,
  CardContent,
  CardGrid,
  CardTexts,
} from "../../../../components/cards"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import {
  b2bPrivateUserEvents,
  globalEvents,
} from "../../../../service/tracking"
import { navigate } from "@reach/router"
import { Text } from "../../../../components/texts/temporary"
import { useHandleExternalPersistUtm } from "../../../navigation/hooks/useHandlePersistUtm"
import { StaticImage } from "gatsby-plugin-image"
import { heroBtnsStack } from "./style.css"
import { typeformWhiteGloves } from "../../constants"
export const Hero = () => {
  const { t } = useI18next()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  const { handleExternalPersistUtmCallback } = useHandleExternalPersistUtm()

  const handleClick = React.useCallback(() => {
    gaTracker?.trackMultipleEvents([
      {
        eventName: b2bPrivateUserEvents.b2b_private_hero_start,
      },
      {
        eventName: globalEvents.contact_sales,
      },
    ])
    handleExternalPersistUtmCallback({
      defaultLink: typeformWhiteGloves,
      callback: () => navigate(typeformWhiteGloves),
    })
  }, [gaTracker, handleExternalPersistUtmCallback])
  return (
    <CardGrid size="hero">
      <Card
        disableHover
        backgroundColor="var(--grey-10-v2)"
        height={["auto !important", "calc(100vh - 98px) !important"]}
        position="relative"
        borderRadius={["0px !important", "32px !important"]}
      >
        <CardContent
          maxWidth="100% !important"
          alignItems={["center", "center"] as any}
          mr="0px !important"
        >
          <CardTexts mx="auto">
            <Text.H2 as="h1" textAlign="center" mx="auto" fontWeight={600} style={{ whiteSpace: "pre-wrap" }} >
              {t("business_user.hero.title")}
            </Text.H2>
            <LegacyBox maxWidth="616px" width="100%" mx="auto" mt="24px">
              <Text.Subtitle2
                as="p"
                textAlign="center"
                fontWeight={600}
                color={"var(--grey-200-v2)"}
              >
                {t("business_user.hero.description")}
              </Text.Subtitle2>
            </LegacyBox>
          </CardTexts>
          <div className={heroBtnsStack}>
            <Button
              variant="solid"
              size={48}
              colorScheme="green"
              onClick={handleClick}
            >
              {t("business_user.hero.button")}
            </Button>
          </div>
        </CardContent>
        <AbsoluteCardImageBox
          style={{
            height: "100%",
          }}>
          <StaticImage
            quality={100}
            outputPixelDensities={[1, 2]}
            style={{
              height: "100%",
            }}
            placeholder="none"
            src="../../images/business-user/business-user-hero.png"
            alt={t("business_user.hero.alt_text_image")}
          />
        </AbsoluteCardImageBox>
      </Card>
    </CardGrid>
  )
}
