import { getParamsFromUrl } from "../../../utils/general"
import { LegalDocProps } from "../types"

export const legalParser = ({ category, docs }: LegalDocProps) => {
  if (!category) return docs
  return docs.filter(doc => {
    return doc.allLegalCategories?.nodes?.some(
      doc_category =>
        doc_category?.name?.toLowerCase() === category?.toLowerCase()
    )
  })
}

export const getLegalParams = () => getParamsFromUrl("category")

export type ParsedDocs = ReturnType<typeof legalParser>
