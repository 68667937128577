import { Box } from "@youngagency/ui"
import React from "react"
import { columnGridLayout, layoutContainer } from "../style/card-slider.css"
import { BoxExtendedProps } from "../../../types/system"
import clsx from "clsx"

export const CardSlider: React.FC<BoxExtendedProps<HTMLDivElement>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <Box {...rest} width="full" className={clsx(columnGridLayout, className)}>
      <Box className={layoutContainer}>{children}</Box>
    </Box>
  )
}
