import React from "react"
import { DefaultCardProps } from "../types"
import { BoxProps } from "@youngagency/ui"
import { Box } from "@youngagency/ui"
import { cardAction, cardActionVariant } from "../style/card-action.css"
import clsx from "clsx"

type CardActionProps = Omit<BoxProps, "color" | "position"> &
  DefaultCardProps & { position?: "bottom" }

export const CardAction = ({
  className,
  children,
  position,
  ...rest
}: CardActionProps) => {
  const actionVariant = cardActionVariant({ position })

  return (
    <Box {...rest} className={clsx(cardAction, actionVariant, className)}>
      {children}
    </Box>
  )
}
