import React from "react"
import { Box } from "@youngagency/young-ui"
import styled from "styled-components"
import { Container } from "../../../components/layout"
import { SeoLink } from "../../seo"
import ExtendedLogo from "../../../images/extended-yp-logo.svg"
import ExtendedBlogLogo from "../images/young-blog-logo-esteso.svg"
import HamburgerMenu from "react-hamburger-menu"
import BusinessLogo from "../images/young-business-logo.svg"
import { HeaderTrasnaltion } from "../types"
import { useNavigationContext } from "../context"
import { css } from "@styled-system/css"
import { CompanyTooltip } from "./website/CompanyTooltip"
import { HeaderAction } from "./HeaderAction"
import { ProductTooltip } from "./website/ProductTooltip"

type Props = {
  children?: React.ReactNode
  variant?: "light" | "dark"
  banner?: React.ReactNode
} & HeaderTrasnaltion

const StyledBox = styled(Box)`
  .responsive-nav-handler {
    display: none;
    @media screen and (min-width: 1024px) {
      display: block;
    }
  }
`

const BoxLogo = styled(Box)`
  flex-grow: 1;
  height: 30px !important;

  .image-logo {
    height: 30px !important;
    width: auto !important;
  }
  @media screen and (min-width: 1024px) {
    height: 34px !important;

    flex-grow: unset;
    .image-logo {
      height: 34px !important;
    }
  }
`

const NavigationBox = styled(Box)`
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
    .link-item {
      transition: all 75ms ease;
      cursor: pointer;
      &:hover {
        background: var(--r-grey-8);
      }
    }
  }
`

const MenuContainer = styled.div`
  cursor: pointer;
  padding: 8px 7px;
  border-radius: 18px;
  display: flex;
  transition: 0.3s;
  align-items: center;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`

const CustomStack = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media screen and (min-width: 1024px) {
    justify-content: space-between;
  }
`

const NavigationContainer = styled(Box)<{ variant?: "light" | "dark" }>(
  ({ variant = "light" }) =>
    css({
      display: "flex",
      width: "100%",
      height: "56px",
      background:
        variant === "dark" ? "rgb(0, 0, 0)" : "rgba(255, 255, 255, 0.8)",
      backdropFilter:
        variant === "light" ? "saturate(180%) blur(20px)" : undefined,
      WebkitBackdropFilter:
        variant === "light" ? "saturate(180%) blur(20px)" : undefined,
    })
)

export const Header = ({ children, banner, variant = "light" }: Props) => {
  const context = useNavigationContext()
  const link = React.useMemo(() => {
    switch (context.platform) {
      case "business":
      case "consumer":
        return "/"
      case "blog":
        return "/blog/"
      default:
        return "/"
    }
  }, [context.platform])

  const logoImage = React.useMemo(() => {
    switch (context.platform) {
      case "business":
        return BusinessLogo
      case "consumer":
        return ExtendedLogo
      case "blog":
        return ExtendedBlogLogo
      default:
        return ExtendedLogo
    }
  }, [context.platform])

  return (
    <StyledBox position="fixed" top="0px" width="100%" zIndex={10}>
      {banner && banner}
      <NavigationContainer variant={variant} style={{}}>
        <Container size={["md", "lg"]}>
          <CustomStack>
            <BoxLogo>
              <SeoLink
                className="desktop-header"
                style={{
                  height: "34px",
                }}
                link={link}
                isExternal={false}
              >
                <img
                  alt="Young Platform"
                  className="image-logo"
                  height={34}
                  width={195}
                  src={logoImage}
                />
              </SeoLink>
            </BoxLogo>
            <NavigationBox>{children}</NavigationBox>
            <HeaderAction />
            <MenuContainer onClick={context?.toggleSidebar}>
              <HamburgerMenu
                isOpen={context.isSidebarOpen}
                menuClicked={() => ""}
                width={15}
                height={12}
                strokeWidth={2}
                rotate={0}
                color={context.platform === "business" ? "white" : "black"}
                borderRadius={0}
                animationDuration={0.5}
              />{" "}
            </MenuContainer>
          </CustomStack>
        </Container>
        {context.platform === "consumer" || context.platform === "business" ? (
          <>
            <ProductTooltip />
            <CompanyTooltip />
          </>
        ) : null}
      </NavigationContainer>
    </StyledBox>
  )
}
