import React from "react"
import { Box, BoxProps, Container } from "@youngagency/ui"
import { bannerGenericStyle } from "./styles/generic-banner.css"
import { Container as CustomContainer } from "../../layout"
import clsx from "clsx"

type BannerProps = Omit<BoxProps, "color"> & {
  children: React.ReactNode
  color?: any
  className?: string
}

export const UiBanner: React.FC<BannerProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <CustomContainer>
      <Container>
        <Box {...rest} className={clsx(bannerGenericStyle, className)}>
          {children}
        </Box>
      </Container>
    </CustomContainer>
  )
}
