import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  hasGeneralError: false,
  hasNetworkError: false,
}

const slice = createSlice({
  name: "exchangeSettings",
  initialState,
  reducers: {
    setErrorStatus(state, action) {
      state.hasGeneralError = action.payload
    },
    setNetworkErrorStatus(state, action) {
      state.hasNetworkError = action.payload
    },
  },
})

export const { setErrorStatus, setNetworkErrorStatus } = slice.actions

export default slice.reducer
