import { Box, BoxProps } from "@youngagency/young-ui"
import React from "react"
import { ResponsiveText } from "../layout"
import { BlockDescriptionProps } from "../../types/shared"

type Props = {
  hasCta?: true
} & BlockDescriptionProps

export const CenterTextBox = ({
  title,
  description,
  hasCta,
  children,
  ...rest
}: Props & BoxProps) => (
  <Box maxWidth="756px" mx="auto" {...rest}>
    <ResponsiveText
      textAlign="center"
      line={["30px", "39px"]}
      fontWeight={600}
      dimension={["24px", "34px"]}
      as="h3"
    >
      {title}
    </ResponsiveText>
    <Box mt="16px">
      <ResponsiveText
        textAlign="center"
        line={["18px", "20px"]}
        dimension={["14px", "16px"]}
        as="p"
      >
        {description}
      </ResponsiveText>
    </Box>
    {hasCta ? children : null}
  </Box>
)
