import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export type CurrencySettingsResponse = {
  enabled: string[]
}

export type FiatListResponse = {
  fiatList: string[]
}

export type ExchangeStatusResponse = {
  maintainance: boolean
}

type SingleTokenConfig = {
  shortName: string
  fullName: string
  buyServiceCharge: number
  sellServiceCharge: number
  withdrawalServiceCharge: number
  withdrawalServiceChargeInBTC: number
  confirmationCount: number
  contractAddress: string | null
  minWithdrawalLimit: number
  maxWithdrawalLimit: number
  decimalPrecision: number
  makerFeePro: number
  takerFeePro: number
  tradeEnabled: boolean
  tradeEnabled_Buy: boolean
  tradeEnabled_Sell: boolean
  depositEnabled: boolean
  withdrawalEnabled: boolean
  secondaryWalletType?: string
  addressSeparator?: string
  walletType: string
  withdrawalServiceChargeType: "Fixed" | "Percentage"
  currencyEnabled: boolean
}
export const settingsApi = createApi({
  reducerPath: "settings",
  baseQuery: fetchBaseQuery(),
  endpoints: builder => ({
    getCurrencySettings: builder.query<CurrencySettingsResponse, void>({
      query: () => `${process.env.SETUP_BACKEND_URL}/api/CurrencySettings`,
      transformResponse: ({
        data,
      }: {
        data: SingleTokenConfig[]
        status: "string"
      }) => {
        return {
          enabled: data
            .filter(currency => currency.currencyEnabled)
            .map(el => el.shortName),
        }
      },
      keepUnusedDataFor: 60 * 5,
    }),
    getExchangeStatus: builder.query<ExchangeStatusResponse, void>({
      query: () =>
        "https://europe-west1-youngplatform-exchange.cloudfunctions.net/exchange-status",
      transformResponse: (data: any) => ({
        maintainance: data?.maintenance,
      }),
    }),
    getFiatList: builder.query<FiatListResponse, void>({
      query: () => `${process.env.SETUP_BACKEND_URL}/api/getSettings`,
      transformResponse: ({ data }: { data: any }) => {
        return {
          fiatList: data.fiat_List.split(","),
        }
      },
      keepUnusedDataFor: 60 * 60 * 24,
    }),
  }),
})

export const {
  useGetCurrencySettingsQuery,
  useGetExchangeStatusQuery,
  useGetFiatListQuery,
} = settingsApi
