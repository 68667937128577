import React from "react"

export type CardContextProps = {
  orientation: "row" | "column"
  shape: "horizontal" | "vertical" | "square"
  disableHover?: boolean
  hoverVariant?: "dark" | "light"
  isAnimated?: boolean
  hasShadow?: boolean
  shadowVariant?: "light" | undefined
}

const defaultContext = {
  orientation: "column" as const,
  shape: "vertical" as const,
}

const CardContext = React.createContext<CardContextProps>(defaultContext)

export const CardProvider = ({
  hasShadow,
  shadowVariant,
  disableHover,
  hoverVariant,
  isAnimated,
  children,
  shape,
  orientation,
}: CardContextProps & { children: React.ReactNode }) => {
  const ctx = React.useMemo(
    () => ({
      hasShadow,
      disableHover,
      shadowVariant,
      hoverVariant,
      isAnimated,
      shape,
      orientation,
    }),
    [
      hasShadow,
      disableHover,
      hoverVariant,
      isAnimated,
      shape,
      orientation,
      shadowVariant,
    ]
  )
  return <CardContext.Provider value={ctx}>{children}</CardContext.Provider>
}

export const useCardContext = () => React.useContext(CardContext)
