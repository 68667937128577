import {
  Box,
  Heading,
  HStack,
  Icon,
  IconType,
  Skeleton,
  Button,
  VStack,
} from "@youngagency/young-ui"
import React from "react"
import { Avatar, ResponsiveText } from "../../../components/layout"
import { SeoLink } from "../../seo"
import { MergedTokenProps, SingleTokenProps } from "../types"
import { TokenRowLayout } from "./TokenRowLayout"
import { Sparklines, SparklinesLine } from "react-sparklines"
import { useI18next } from "gatsby-plugin-react-i18next"
import {
  formatInternationalCurrency,
  triggerClickToExchangeEvents,
} from "../../../utils/general"
import { useLazyGetPillsChartLineQuery } from "../../../redux/services"
import { replaceTokenWithCustomTicker } from "../utils"
import { Text } from "../../../components/texts/temporary"

export const SingleTokenRow = ({
  points,
  symbol,
  coin_details,
  color,
  isUnlisted,
  percentageChange,
  price,
  image,
  name,
}: SingleTokenProps & { isUnlisted: boolean }) => {
  const { language, t, navigate } = useI18next()
  const [trigger, { data, isLoading }] = useLazyGetPillsChartLineQuery()

  React.useEffect(() => {
    if (isUnlisted) {
      trigger({ coin: symbol, interval: "15m" })
    }
  }, [])

  const variationColor = React.useMemo(() => {
    if (Number(percentageChange) >= 0)
      return {
        color: "var(--green-200-v2)",
        icon: "arrow_upright" as IconType,
      }

    return {
      color: "var(--red-300-v2)",
      icon: "arrow_downleft" as IconType,
    }
  }, [percentageChange])

  const formatBigNumber = React.useCallback(
    (value: number) => {
      if (value / 1000000000 > 1)
        return (value / 1000000000).toFixed(2) + " " + t("markets.b")

      if (value / 1000000 > 1)
        return (value / 1000000).toFixed(2) + " " + t("markets.m")

      if (value / 1000 > 1)
        return (value / 1000).toFixed(2) + " " + t("markets.k")
    },
    [t]
  )

  const tokenSymbol = replaceTokenWithCustomTicker(symbol, "symbol")
  return (
    <SeoLink
      link={`/exchange/${tokenSymbol.toLowerCase()}/`}
      isExternal={false}
    >
      <TokenRowLayout
        height="72px"
        hasHover
        px={["16px", "24px"]}
        display={"grid !important"}
      >
        <HStack
          display={"flex !important"}
          spacing="16px"
          style={{
            justifyContent: "flex-start",
          }}
        >
          <Avatar width="36px" height="36px">
            <img
              src={image}
              alt={name}
              style={{
                width: "100%",
              }}
            />
          </Avatar>
          <VStack spacing="2px" align="flex-start">
            <Heading size="sm" fontWeight={600} fontFamily="Matter">
              {name}
            </Heading>
            <ResponsiveText
              line={["18px", "20px"]}
              dimension={["14px", "16px"]}
              color="grey.1"
              mt="2px"
            >
              {tokenSymbol}
            </ResponsiveText>
          </VStack>
        </HStack>
        <Box
          display="flex !important"
          flexDirection={["column", "row"]}
          alignItems={["flex-end", "inherit"]}
        >
          <Text.Body2 size="md" fontFamily="Matter">
            {formatInternationalCurrency(language, parseFloat(price))}
          </Text.Body2>
          <Box
            color={variationColor.color}
            display={["block", "none"]}
            mt="2px"
          >
            <HStack align="center" justify="flex-start" color="inherit">
              <HStack align="center" justify="flex-start" color="inherit">
                <Icon
                  icon={variationColor.icon}
                  fontSize={"14px"}
                  color="inherit"
                />
                <Text.Body2 size="md" fontFamily="Matter" color="inherit">
                  {percentageChange}%
                </Text.Body2>
              </HStack>
            </HStack>
          </Box>
        </Box>
        <Box width="50px" m="auto" justifyContent="center">
          {isLoading ? (
            <Skeleton display={["block", "none"]} height="20px" width="50px" />
          ) : (
            <Sparklines
              data={points || data?.map(el => el.value).slice(data.length - 97)}
            >
              <SparklinesLine
                style={{
                  fill: "none",
                  strokeWidth: "10px",
                  stroke: color,
                }}
              />
            </Sparklines>
          )}
        </Box>

        <Box color={variationColor.color}>
          <HStack align="center" justify="flex-start" color="inherit">
            <Icon
              icon={variationColor.icon}
              fontSize={"20px"}
              color="inherit"
            />
            <Text.Body2 size="md" fontFamily="Matter" color="inherit">
              {percentageChange}%
            </Text.Body2>
          </HStack>
        </Box>
        <Box>
          <Text.Body2 size="md" fontFamily="Matter" color="inherit">
            {formatBigNumber(coin_details.market_cap)}
          </Text.Body2>
        </Box>
        <Box>
          <Text.Body2 size="md" fontFamily="Matter" color="inherit">
            {formatBigNumber(coin_details.volume_24h) || "N.A"}
          </Text.Body2>
        </Box>
        <Box justifySelf="flex-end">
          {!isUnlisted ? (
            <Button
              size="sm"
              onClick={e => {
                navigate(`/exchange/${tokenSymbol.toLowerCase()}/`)

                e.preventDefault()
              }}
            >
              {t("markets.btn_buy")}
            </Button>
          ) : null}
        </Box>
      </TokenRowLayout>
    </SeoLink>
  )
}

export const SingleTokenRowV2 = ({
  points,
  symbol,
  isUnlisted,
  percentageChange,
  price,
  name,
  details,
}: MergedTokenProps & {
  isUnlisted: boolean
  percentageChange: number
  points: number[]
}) => {
  const { marketCap, color, image, volume24h } = details
  const { language, t, navigate } = useI18next()
  const [trigger, { data, isLoading }] = useLazyGetPillsChartLineQuery()

  React.useEffect(() => {
    if (isUnlisted) {
      trigger({ coin: symbol, interval: "15m" })
    }
  }, [])

  const variationColor = React.useMemo(() => {
    if (Number(percentageChange) >= 0)
      return { color: "var(--green-200-v2)", icon: "arrow_upright" as IconType }

    return { color: "var(--red-300-v2)", icon: "arrow_downleft" as IconType }
  }, [percentageChange])

  const formatBigNumber = React.useCallback(
    (value: number) => {
      if (value / 1000000000 > 1)
        return (value / 1000000000).toFixed(2) + " " + t("markets.b")

      if (value / 1000000 > 1)
        return (value / 1000000).toFixed(2) + " " + t("markets.m")

      if (value / 1000 > 1)
        return (value / 1000).toFixed(2) + " " + t("markets.k")
    },
    [t]
  )

  const tokenSymbol = replaceTokenWithCustomTicker(symbol, "symbol")
  return (
    <SeoLink
      link={`/exchange/${tokenSymbol.toLowerCase()}/`}
      isExternal={false}
    >
      <TokenRowLayout
        height="72px"
        hasHover
        px={["16px", "24px"]}
        display={"grid !important"}
      >
        <HStack
          display={"flex !important"}
          spacing="16px"
          style={{
            justifyContent: "flex-start",
          }}
        >
          <Avatar width="36px" height="36px">
            <img
              src={image}
              alt={name}
              style={{
                width: "100%",
              }}
            />
          </Avatar>
          <VStack spacing="2px" align="flex-start">
            <Heading size="sm" fontWeight={600} fontFamily="Matter">
              {name}
            </Heading>
            <ResponsiveText
              line={["18px", "20px"]}
              dimension={["14px", "16px"]}
              color="grey.1"
              mt="2px"
            >
              {tokenSymbol}
            </ResponsiveText>
          </VStack>
        </HStack>
        <Box
          display="flex !important"
          flexDirection={["column", "row"]}
          alignItems={["flex-end", "inherit"]}
        >
          <Text.Body2 size="md">
            {formatInternationalCurrency(language, price || 0)}
          </Text.Body2>
          <Box
            color={variationColor.color}
            display={["block", "none"]}
            mt="2px"
          >
            <HStack align="center" justify="flex-start" color="inherit">
              <Icon
                icon={variationColor.icon}
                fontSize={"14px"}
                color="inherit"
              />
              <Text.Body2 size="sm" color="inherit">
                {Number(percentageChange).toFixed(2)}%
              </Text.Body2>
            </HStack>
          </Box>
        </Box>
        <Box width="50px" m="auto" justifyContent="center">
          {isLoading ? (
            <Skeleton display={["block", "none"]} height="20px" width="50px" />
          ) : (
            <Sparklines
              data={points || data?.map(el => el.value).slice(data.length - 97)}
            >
              <SparklinesLine
                style={{
                  fill: "none",
                  strokeWidth: "10px",
                  stroke: color,
                }}
              />
            </Sparklines>
          )}
        </Box>

        <Box color={variationColor.color}>
          <HStack align="center" justify="flex-start" color="inherit">
            <Icon
              icon={variationColor.icon}
              fontSize={"20px"}
              color="inherit"
            />
            <Text.Body2 size="md" fontFamily="Matter" color="inherit">
              {Number(percentageChange).toFixed(2)}%
            </Text.Body2>
          </HStack>
        </Box>
        <Box>
          <Text.Body2 size="md" fontFamily="Matter" color="inherit">
            {formatBigNumber(marketCap)}
          </Text.Body2>
        </Box>
        <Box>
          <Text.Body2 size="md" fontFamily="Matter" color="inherit">
            {formatBigNumber(volume24h) || "N.A"}
          </Text.Body2>
        </Box>
        <Box justifySelf="flex-end">
          {!isUnlisted ? (
            <Button
              size="sm"
              onClick={e => {
                navigate(`/exchange/${tokenSymbol.toLowerCase()}/`)

                e.preventDefault()
              }}
            >
              {t("markets.btn_buy")}
            </Button>
          ) : null}
        </Box>
      </TokenRowLayout>
    </SeoLink>
  )
}
