import { GlossaryTermProps } from "../../types/glossary"

export type GlossaryStorageProps = {
  text: string
  link: string
  words_list: GlossaryTermProps[] | any[]
  disabled: boolean
}

const MENU_ANCHOR_LIST: GlossaryStorageProps[] = [
  {
    text: "#",
    link: "menu_anchor_general",
    words_list: [],
    disabled: false,
  },
  {
    text: "A",
    link: "menu_anchor_a",
    words_list: [],
    disabled: false,
  },
  {
    text: "B",
    link: "menu_anchor_b",
    words_list: [],
    disabled: false,
  },
  {
    text: "C",
    link: "menu_anchor_c",
    words_list: [],
    disabled: false,
  },
  {
    text: "D",
    link: "menu_anchor_d",
    words_list: [],
    disabled: false,
  },
  {
    text: "E",
    link: "menu_anchor_e",
    words_list: [],
    disabled: false,
  },
  {
    text: "F",
    link: "menu_anchor_f",
    words_list: [],
    disabled: false,
  },
  {
    text: "G",
    link: "menu_anchor_g",
    words_list: [],
    disabled: false,
  },
  {
    text: "H",
    link: "menu_anchor_h",
    words_list: [],
    disabled: false,
  },
  {
    text: "I",
    link: "menu_anchor_i",
    words_list: [],
    disabled: false,
  },
  {
    text: "J",
    link: "menu_anchor_j",
    words_list: [],
    disabled: false,
  },
  {
    text: "K",
    link: "menu_anchor_k",
    words_list: [],
    disabled: false,
  },
  {
    text: "L",
    link: "menu_anchor_l",
    words_list: [],
    disabled: false,
  },
  {
    text: "M",
    link: "menu_anchor_m",
    words_list: [],
    disabled: false,
  },
  {
    text: "N",
    link: "menu_anchor_n",
    words_list: [],
    disabled: false,
  },
  {
    text: "O",
    link: "menu_anchor_o",
    words_list: [],
    disabled: false,
  },
  {
    text: "P",
    link: "menu_anchor_p",
    words_list: [],
    disabled: false,
  },
  {
    text: "Q",
    link: "menu_anchor_q",
    words_list: [],
    disabled: false,
  },
  {
    text: "R",
    link: "menu_anchor_r",
    words_list: [],
    disabled: false,
  },
  {
    text: "S",
    link: "menu_anchor_s",
    words_list: [],
    disabled: false,
  },
  {
    text: "T",
    link: "menu_anchor_t",
    words_list: [],
    disabled: false,
  },
  {
    text: "U",
    link: "menu_anchor_u",
    words_list: [],
    disabled: false,
  },
  {
    text: "V",
    link: "menu_anchor_v",
    words_list: [],
    disabled: false,
  },
  {
    text: "W",
    link: "menu_anchor_w",
    words_list: [],
    disabled: false,
  },
  {
    text: "X",
    link: "menu_anchor_x",
    words_list: [],
    disabled: false,
  },
  {
    text: "Y",
    link: "menu_anchor_y",
    words_list: [],
    disabled: false,
  },
  {
    text: "Z",
    link: "menu_anchor_z",
    words_list: [],
    disabled: false,
  },
]

export { MENU_ANCHOR_LIST }
