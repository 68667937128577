import { Box, Icon, StackProps, Button } from "@youngagency/young-ui"
import { HStack } from "@youngagency/ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import useDropDownMenu from "../../../hook/useDropDown"
import { LanguageSwitcher } from "../../../components/shared"
import { SeoLink } from "../../seo"
import { useSelector } from "react-redux"
import { useNavigationContext } from "../context"
import { useTranslationContext } from "../../translations/context"
import { triggerHeaderEvents } from "../../../utils/general"
import styled from "styled-components"
import { useHandlePersistUtm } from "../hooks/useHandlePersistUtm"
import { businessLoginButton } from "./b2b/style.css"

const StyledStack = styled(Box)`
  & > *:not(:first-of-type) {
    margin-left: 12px;
  }

  @media screen and (min-width: 1024px) {
    & > *:not(:first-of-type) {
      margin-left: 4px !important;
    }
  }
`

const StyledBox = styled(Box)`
  &:hover {
    cursor: pointer;
  }

  #header-language-menu {
    display: none;
  }

  .lang-icon {
    &:hover {
      color: #545454;
    }
  }
`

export const HeaderAction = ({ ...rest }: StackProps) => {
  const { t, language } = useI18next()
  const { disableTranslations: disable } = useTranslationContext() || {}
  const { toggleMenu } = useDropDownMenu(["header-language"])
  //@ts-ignore
  const { isAuthenticated, referralId } = useSelector(store => store.auth)
  const { dinamycLink } = useNavigationContext()
  const loginLink = React.useMemo(() => {
    let link = `https://exchange.youngplatform.com/${language}`
    if (dinamycLink) return dinamycLink
    if (isAuthenticated) {
      link = `${link}/dashobard`
    } else {
      link = `${link}/signup`
    }
    return referralId ? `${link}?ref=${referralId}` : link
  }, [isAuthenticated, referralId, language, dinamycLink])

  const { handlePersistUtmLink } = useHandlePersistUtm()
  const { platform } = useNavigationContext()
  return (
    <StyledStack
      {...rest}
      zIndex={9}
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {!disable ? (
        <StyledBox
          as="button"
          title="Language Selector"
          background="none"
          border="none"
          onClick={() => toggleMenu("header-language")}
          position="relative"
        >
          <HStack
            spacing={4}
            align="center"
            atoms={{
              __marginRight: "4px",
            }}
          >
            <Icon
              icon="global"
              fontSize="24px"
              color={platform === "business" ? "white" : "black"}
              className="lang-icon"
            />
          </HStack>
          <LanguageSwitcher
            id="header-language-menu"
            position="absolute"
            top="150%"
            left="50%"
            style={{
              transform: "translateX(-50%)",
            }}
          />
        </StyledBox>
      ) : null}

      <SeoLink
        isExternal
        isConversion
        link={handlePersistUtmLink(
          referralId
            ? `https://exchange.youngplatform.com/${language}/dashboard?ref=${referralId}`
            : `https://exchange.youngplatform.com/${language}/dashboard`
        )}
        rel="noopener"
        target="_self"
        className="responsive-nav-handler"
      >
        <Button
          size="xs"
          variant="ghost"
          isRounded
          name="login"
          onClick={triggerHeaderEvents("login")}
          className={platform === "business" ? businessLoginButton : undefined}
        >
          {t("header.login")}
        </Button>
      </SeoLink>

      <SeoLink
        isExternal
        isConversion
        link={handlePersistUtmLink(loginLink)}
        rel="noopener"
        target="_self"
        className="responsive-nav-handler"
      >
        <Button
          size="xs"
          variant="primary"
          isRounded
          onClick={triggerHeaderEvents("signup")}
        >
          {t("header.signup")}
        </Button>
      </SeoLink>
    </StyledStack>
  )
}
