import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { ClubProps, ClubType } from "../types"

const useClubReward = (label: ClubProps, type: ClubType = "circle") => {
  const { t } = useI18next()

  const defaultQuery = React.useMemo(
    () => (type === "card" ? "clubs.clubOverview" : "token.clubSection"),
    [type]
  )

  const labelColor = React.useMemo(() => {
    let value: string =
      type === "card" ? "rgba(203, 165, 62, 0.25)" : "yellow.400"

    switch (label) {
      case "bronze":
        value = type === "card" ? "rgba(226, 89, 32, 0.25)" : "orange.600"
        break
      case "silver":
        value = type === "card" ? "grey.3" : "grey.1"
        break
      case "platinum":
        value = type === "card" ? "rgba(0, 153, 102, 0.25)" : "green.600"
      default:
        break
    }

    return value
  }, [label])

  const clubValue = React.useMemo(() => {
    return {
      label: t(`${defaultQuery}.${label}.label`),
      title: t(`${defaultQuery}.${label}.title`),
      description: t(`${defaultQuery}.${label}.description`),
      freeName: t(`${defaultQuery}.${label}.freename`),
      fees: t(`${defaultQuery}.${label}.fees`),
      airdrop: t(`${defaultQuery}.${label}.airdrop`),
      required: t(`${defaultQuery}.${label}.required`),
      labelColor,
      hasReport: label !== "bronze",
      cashback: t(`${defaultQuery}.${label}.cashback`),
      bonusClub: t(`${defaultQuery}.${label}.bonus_club`),
      hasVip: Boolean(t(`${defaultQuery}.${label}.hasVip`)),
    }
  }, [t, label, labelColor, defaultQuery])

  return clubValue
}

export default useClubReward
