import { Box, VStack } from "@youngagency/young-ui"
import React from "react"
import { SubTitle, BaseTypography } from "../../../../components"
import { BorderBox, RowStack } from "../../../../components/layout"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { useTokenFiatContext } from "../context"
import { graphql, useStaticQuery } from "gatsby"
import { SeoLink } from "../../../seo"
import { inverseTokenTicker } from "../utils"
import styled from "styled-components"
import { css } from "@styled-system/css"
import { useRelatedToken } from "../../hooks/useRelatedToken"

const LinkContainer = styled(Box)(
  css({
    minWidth: "103px",
    height: "39px",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    "&:hover": {
      "p": {
        color: "black",
      },
    },
  })
)

export const RelatedConversor = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(query) as Queries.TokenConversorQuery
  const { token } = useTokenFiatContext()
  const { related } = useRelatedToken({
    type: "top_gainers",
    exclude: token.symbol,
  })

  return (
    <BorderBox
      width="100%"
      borderRadius="16px"
      position={["static", "sticky"]}
      top={["104px"]}
      backgroundColor="grey.4"
    >
      <Box px="16px" py={["16px", "24px"]}>
        <SubTitle as="h2">
          {t("markets.token_fiat.related_conversors.title")}
        </SubTitle>
        <VStack spacing="8px" mt="16px">
          {related.map(token => {
            return (
              <RowStack
                key={token.symbol}
                alignItems="center"
                rGap={["62px", "8px"]}
                width="100%"
              >
                {data.allAvailableFiat.nodes.map(fiat => {
                  return (
                    <SeoLink
                      isExternal={false}
                      key={`${token.symbol}-${fiat.symbol}`}
                      link={`/exchange/${inverseTokenTicker(
                        token.symbol
                      ).toLowerCase()}-${fiat.symbol?.toLowerCase()}/`}
                    >
                      <LinkContainer>
                        <BaseTypography
                          as="p"
                          key={`${token.symbol}-${fiat.symbol}`}
                          color="grey.1"
                          fontSize={"14px"}
                          fontWeight="regular"
                          lineHeight="18px"
                        >
                          {`${inverseTokenTicker(token.symbol)}-${fiat.symbol}`}
                        </BaseTypography>
                      </LinkContainer>
                    </SeoLink>
                  )
                })}
              </RowStack>
            )
          })}
        </VStack>
      </Box>
    </BorderBox>
  )
}

const query = graphql`
  query RelatedConversorFiats {
    allAvailableFiat(filter: { symbol: { ne: "GBP" } }) {
      nodes {
        symbol
      }
    }
  }
`
