import React from "react"
import { Box, Button } from "@youngagency/young-ui"
import styled from "styled-components"
import { Container } from "../../../components/layout"
import { SeoLink } from "../../seo"
import ExtendedLogo from "../../../images/extended-yp-logo.svg"
import { useI18next } from "gatsby-plugin-react-i18next"
import { triggerHeaderEvents } from "../../../utils/general"
import { navigate } from "gatsby"
import { useHandleExternalPersistUtm } from "../hooks/useHandlePersistUtm"

type Props = {
  link: string
  customCTAComponent?: React.ReactNode
}

const StyledBox = styled(Box)`
  .responsive-nav-handler {
    display: none;
    @media screen and (min-width: 1024px) {
      display: block;
    }
  }
`

const BoxLogo = styled(Box)`
  flex-grow: 1;
  height: 30px !important;

  .image-logo {
    height: 30px !important;
    width: auto !important;
  }
  @media screen and (min-width: 1024px) {
    height: 34px !important;

    flex-grow: unset;
    .image-logo {
      height: 34px !important;
    }
  }
`

const CustomStack = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media screen and (min-width: 1024px) {
    justify-content: space-between;
  }
`

const NavigationBox = styled(Box)`
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
`

export const AdvertisingHeader = ({ link, customCTAComponent }: Props) => {
  const { t } = useI18next()
  const { handleExternalPersistUtmCallback } = useHandleExternalPersistUtm()
  const handleClick = React.useCallback(() => {
    triggerHeaderEvents("signup")
    handleExternalPersistUtmCallback({
      defaultLink: link,
      callback: () => navigate(link),
    })
  }, [handleExternalPersistUtmCallback])
  return (
    <StyledBox position="fixed" top="0px" width="100%" zIndex={10000}>
      <NavigationBox height={["56px", "56px"]} width="100%" display="flex">
        <Container size={["md", "lg"]}>
          <CustomStack>
            <BoxLogo>
              <SeoLink
                className="desktop-header"
                style={{
                  height: "34px",
                }}
                link={"/"}
                isExternal={false}
              >
                <img
                  alt="Young Platform"
                  className="image-logo"
                  height={34}
                  width={195}
                  src={ExtendedLogo}
                />
              </SeoLink>
            </BoxLogo>
            {React.isValidElement(customCTAComponent) ? (
              customCTAComponent
            ) : (
              <Button
                size="xs"
                variant="primary"
                isRounded
                onClick={handleClick}
              >
                {t("ooh.header_cta")}
              </Button>
            )}
          </CustomStack>
        </Container>
      </NavigationBox>
    </StyledBox>
  )
}
