import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { parsePill } from "./parser"
import { PillResponse, PillsApi } from "./types"

export const pillsApi = createApi({
  reducerPath: "pills",
  baseQuery: fetchBaseQuery(),
  endpoints: builder => ({
    getPillsV2: builder.query<PillsApi[], void>({
      query: () => `${process.env.GATSBY_EDUCATION_URL}/api/pills`,
      transformResponse: (data: PillResponse[]) =>
        data.map(pill => parsePill(pill)).filter(x => !x.tradable),
    }),
  }),
})

export const { useGetPillsV2Query } = pillsApi
