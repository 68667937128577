import React from "react"
import styled from "styled-components"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Stack, Box, Heading } from "@youngagency/young-ui"
import { graphql, PageProps } from "gatsby"
import { Container, BottomBanner, ResponsiveText } from "../components/layout"
import WordList from "../components/glossary/WordList"
import { SocialShareProps } from "../types/shared"
import { FromStoreBtn, SinglePageHero } from "../components/shared"
import {
  MetaDecorator,
  DefinedTermSchema,
  AlternateHandler,
} from "../feature/seo"
import { BreadCrumb, LandingLayout } from "../feature/navigation/components"
import { TranslationProvider } from "../feature/translations/context"
import { GlossaryPageTranslator } from "../feature/translations/components/Translator"
import { useSelector } from "react-redux"
import { RootState } from "../redux/store"
import { globalEvents } from "../service/tracking"

const WordWrapper = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  max-width: 650px;
  padding-left: 56px;
  width: 100%;
  @media screen and (max-width: 792px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`

const WordBox = styled(Box)`
  align-self: flex-start;
  justify-self: flex-start;
  @media screen and (min-width: 792px) {
    align-self: unset;
    justify-self: unset;
  }
`

const StyledWrapper = styled.div`
  font-family: "Matter";
  & h2 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600 !important;
    color: #1e1e1f;
  }
  & h3 {
    font-size: 21px;
    line-height: 24px;
    font-weight: 600 !important;
    color: #1c1c1c;
  }
  & h4 {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500 !important;
    color: #1c1c1c;
  }
  & p {
    color: #101010;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    margin-top: 16px;
  }

  & a {
    text-decoration: none;
    color: var(--green);
    transition: all 125ms ease;
    &:hover {
      color: var(--green);
    }
  }
  li {
    margin: auto;
    padding: 4px 0px;
    width: calc(100% - 24px);
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
  }
  @media screen and (min-width: 767px) {
    & h2 {
      font-size: 34px;
      line-height: 39px;
      font-weight: 600 !important;
      color: #1e1e1f;
    }
    & h3 {
      font-size: 28px;
      line-height: 34px;
      font-weight: 600 !important;
      color: #1c1c1c;
    }
    & h4 {
      font-size: 24px;
      line-height: 29px;
      font-weight: 500 !important;
      color: #1c1c1c;
    }
    & p {
      font-size: 18px;
      line-height: 26px;
    }
  }
`

const SingleGlossaryTemplate: React.FC<
  PageProps<Queries.SingleGlossaryPageQuery>
> = ({ data: { word, related_words } }) => {
  const { t, language, originalPath } = useI18next()

  /**
   * Array of Socials
   * @link https://www.npmjs.com/package/react-share
   * @param {string} icon - Required
   * @param {string} url - Required. (Link che viene condiviso)
   */
  const social: SocialShareProps[] = React.useMemo(
    () => [
      {
        icon: "twitter",
        url: "",
        via: "youngplatform",
        title: word?.title,
      },
      {
        icon: "facebook",
        url: "",
        quote: word?.title,
        hashtag: `#${word?.title?.replace(/\s/g, "")}`,
      },
      {
        icon: "linkedin",
        url: "",
        title: word?.title,
        summary: word?.seo?.metaDesc,
      },
    ],
    [language]
  )

  const breadcrumb = React.useMemo(
    () => [
      {
        text: t("breadcumbs.home_breadcrumb"),
        link: "/",
      },
      {
        text: t("breadcumbs.glossary_breadcrumb"),
        link: "/glossary/",
      },
      {
        text: word?.title,
        link: `/glossary/${word?.slug}/`,
      },
    ],
    [language]
  )

  const ctx = React.useMemo(() => {
    const translator = new GlossaryPageTranslator(word?.translations)
    const trx = translator.create(language, word?.slug)
    return {
      translations: trx,
      disableTranslations: !translator.hasTranslation(word?.translations),
    }
  }, [word?.translations, language])

  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  return (
    <TranslationProvider value={ctx}>
      <LandingLayout
        disableFooterMargin
        dinamycLink="https://youngexchange.page.link/LEARNCRYPTO"
      >
        <MetaDecorator
          metaTitle={word?.title}
          metaDescription={word?.seo?.metaDesc}
        />

        <DefinedTermSchema
          name={word?.title}
          description={word?.seo?.metaDesc as string}
          breadcrumbs={breadcrumb as any}
        />
        <Container>
          <BreadCrumb breadcrumbs={breadcrumb as any} removeLast />
        </Container>
        <AlternateHandler />
        <Container mt="16px">
          <Box>
            <SinglePageHero
              fallbackLink="/glossary/"
              social={social}
              isBlogPost
            >
              <WordWrapper>
                <WordBox>
                  <ResponsiveText
                    line={["34px", "77px"]}
                    dimension={["44px", "64px"]}
                    as="h1"
                    style={{
                      paddingTop: "0px",
                      maxWidth: "650px",
                    }}
                  >
                    {word?.title}
                  </ResponsiveText>
                </WordBox>
                {word?.content ? (
                  <Stack spacing="18px" direction="column">
                    <StyledWrapper
                      dangerouslySetInnerHTML={{
                        __html: word?.content,
                      }}
                    />
                  </Stack>
                ) : null}
              </WordWrapper>
            </SinglePageHero>
            {related_words.nodes.length > 0 && (
              <Box mt={["48px", "72px"]}>
                <Heading as="h2" fontFamily={"Matter"} py="0px" size="xl">
                  {t("glossary.correlated_words_title")}
                </Heading>
                <Box mt={["16px", "24px"]}>
                  <WordList data={related_words.nodes} />
                </Box>
              </Box>
            )}
          </Box>
        </Container>
        <BottomBanner text={t("download-app.title") as string}>
          <FromStoreBtn
            event={() => {
              if (!gaTracker) return
              gaTracker.trackEvent({
                eventName: globalEvents.action_exchange_app_download,
                payload: {
                  content: "glossary",
                },
              })
            }}
            className="responsive-download-banner"
            appleLink="https://youngexchange.page.link/Xj7L"
            playLink="https://youngexchange.page.link/ahrr"
          />
        </BottomBanner>
      </LandingLayout>
    </TranslationProvider>
  )
}

export default SingleGlossaryTemplate

export const query = graphql`
  query SingleGlossaryPage(
    $language: String!
    $pageLanguage: WpLanguageCodeEnum!
    $slug: String!
    $themeSlug: String!
    $exclude: Int!
  ) {
    word: wpGlossary(
      slug: { eq: $slug }
      language: { code: { eq: $pageLanguage } }
    ) {
      title
      slug
      content
      translations {
        id
        slug
        language {
          code
          locale
        }
      }
      seo {
        metaDesc
      }
    }
    related_words: allWpGlossary(
      filter: {
        databaseId: { ne: $exclude }
        language: { code: { eq: $pageLanguage } }
        allTemi: { nodes: { elemMatch: { slug: { eq: $themeSlug } } } }
      }
    ) {
      nodes {
        title
        slug
        language {
          code
        }
        seo {
          metaDesc
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
