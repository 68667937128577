import React from "react"
import { FaqEnitySchema, SingleFaqSchemaProps } from "../types"

type Props = {
  "@type": "FAQPage"
  mainEntity: FaqEnitySchema[]
}

export const useFaqSchema = (faqArray: SingleFaqSchemaProps[]) => {
  const faqSchema = React.useMemo(() => {
    const faqObj: Props = {
      "@type": "FAQPage",

      "mainEntity": [],
    }

    faqArray.forEach(el => {
      const { question, answer } = el
      let entity = {
        "@type": "Question",
        "name": question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": answer,
        },
      }

      faqObj.mainEntity.push(entity)
    })

    return faqObj
  }, [faqArray])
  return faqSchema
}
