import { variant } from "styled-system"
import { css } from "@styled-system/css"
import { HStack } from "@youngagency/young-ui"
import styled from "styled-components"

const Row = styled(HStack)(
  variant({
    prop: "gap",
    variants: {
      none: css({
        "& > * + *": {
          ml: "unset !important",
        },
      }),
      lg: css({
        "& > * + *": {
          ml: "117px !important",
        },
      }),
      md: css({
        "& > * + *": {
          ml: "72px !important",
        },
      }),
      sm: css({
        "& > * + *": {
          ml: "48px !important",
        },
      }),
      xs: css({
        "& > * + *": {
          ml: "24px !important",
        },
      }),
      xxs: css({
        "& > * + *": {
          ml: "16px !important",
        },
      }),
      dot: css({
        "& > * + *": {
          ml: "6px !important",
        },
      }),
    },
  })
)

Row.defaultProps = {
  gap: "lg",
}

export default Row
