import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { graphql, PageProps } from "gatsby"
import { AlternateHandler, MetaDecorator, WebPageSchema } from "../feature/seo"
import { TranslationProvider } from "../feature/translations/context"
import SecurityPage from "../feature/security/pages/SecurityPage"
import { LandingLayout } from "../feature/navigation/components"

const BREADCRUMB = [
  {
    text: "home",
    link: "/",
  },
  {
    text: "Security",
    link: "/security",
  },
]

const BusinessPage: React.FC<PageProps<Queries.BusinessPageQuery>> = () => {
  const { t } = useI18next()
  return (
    <TranslationProvider>
      <LandingLayout
        disableFooterMargin
        dinamycLink="https://youngexchange.page.link/W1Pm"
      >
        <MetaDecorator
          metaTitle={t("security.seo.title")}
          metaDescription={t("security.seo.description")}
          og={[
            {
              property: "og:title",
              content: t("security.seo.opengraph.title"),
            },
            {
              property: "og:description",
              content: t("security.seo.opengraph.description"),
            },
          ]}
        />
        <WebPageSchema
          title={t("security.seo.title")}
          description={t("security.seo.description")}
          breadcrumbs={BREADCRUMB}
        />
        <AlternateHandler />
        <SecurityPage />
      </LandingLayout>
    </TranslationProvider>
  )
}

export default BusinessPage
export const query = graphql`
  query BusinessPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
