import { Box, BoxProps } from "@youngagency/ui"
import clsx from "clsx"
import {
  ecosistemaNavigationItem,
  ecosistemaNavigationItemChild,
  ecosistemaTooltipItemChild,
} from "./style.css"
import React, { HTMLAttributes } from "react"

type NavigationChipContainerProps = Omit<BoxProps, "color"> & {
  children: React.ReactNode
  color?: any
  id?: string
  className?: string
}

export const NavigationChipContainer: React.FC<NavigationChipContainerProps> = ({
  children,
  ...rest
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="full"
      className={clsx("link-item", "toogle-tooltip", ecosistemaNavigationItem)}
      {...rest}
    >
      {children}
    </Box>
  )
}

export const NavigationTootltipChipContainer: React.FC<NavigationChipContainerProps> = ({
  children,
  ...rest
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="full"
      className={clsx("link-item", ecosistemaNavigationItem)}
      {...rest}
    >
      {children}
    </Box>
  )
}

type NavigationChipContentProps = HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode
}

export const NavigationChipContent: React.FC<NavigationChipContentProps> = ({
  children,
  ...rest
}) => {
  return (
    <div className={ecosistemaNavigationItemChild} {...rest}>
      {children}
    </div>
  )
}

export const NavigationTooltipChipContent: React.FC<NavigationChipContentProps> = ({
  children,
  ...rest
}) => {
  return (
    <div className={ecosistemaTooltipItemChild} {...rest}>
      {children}
    </div>
  )
}
