import { OptionType } from "@youngagency/young-ui/dist/src/components/select/shared"

export const inverseTokenTicker = (symbol: string) => {
  switch (symbol) {
    case "LUNA":
      return "LUNC"
    case "LUNA2":
      return "LUNA"
    default:
      return symbol
  }
}

export const parseAsOptions = <
  T extends Record<string, Queries.Maybe<string> | Queries.Maybe<number>>
>(
  total: OptionType[],
  current: T
) => {
  return [...total, parseOption(current)]
}

export const parseOption = (
  option: Record<string, Queries.Maybe<string> | Queries.Maybe<number>>
): OptionType => {
  return Object.assign({
    label: option.name,
    subtitle: option.symbol,
    value: option.image,
  })
}
