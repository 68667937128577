import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { MergedTokenProps } from "../types"

export const useTokenSort = (sortOrder: "desc" | "asc", sortedBy?: string) => {
  const { t } = useI18next()
  const tokenListOrder = React.useCallback(
    (tokens: any[]) => {
      if (sortOrder === "desc") return tokens.reverse()
      return tokens
    },
    [sortOrder]
  )

  const sortByType = React.useCallback(
    (tokens: MergedTokenProps[]) => {
      if (sortedBy === t("markets.header_price")) {
        return tokens.sort((a, b) => {
          return (a.price || 0) - (b.price || 0)
        })
      }

      if (sortedBy === t("markets.header_volume_24")) {
        return tokens.sort((a, b) => {
          return a.details.volume24h - b.details.volume24h
        })
      }
      if (sortedBy === t("markets.header_variation")) {
        return tokens.sort((a, b) => {
          return (
            a.details?.priceChange24h || 0 - (b.details?.priceChange24h || 0)
          )
        })
      }
      return tokens.sort((a, b) => {
        return a.details.marketCap - b.details.marketCap
      })
    },
    [sortedBy]
  )
  return { tokenListOrder, sortByType }
}
