export const prefilledMailValue =
  "mailto:business@youngplatform.com?subject=I%20want%20more%20info%20about%20Platform%20B2B%20services&body=Insert%20your%20message%20here"

export const privateUserMailValue =
  "mailto:business@youngplatform.com?subject=Voglio%20più%20informazioni%20sui%20servizi%20per%20privati&body=Scrivi%20il%20tuo%20messaggio"

export const googleScheduleMeet =
  "https://calendar.app.google/cCajpPcW1fVPxdGA6"

export const typeformWhiteGloves = "https://hnwi.typeform.com/to/rMvnGPSw";
