import { Button, Box } from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { Container } from "../../../components/layout"
import { SeoLink } from "../../seo"
import { useSelector } from "react-redux"
import { triggerHeaderEvents } from "../../../utils/general"
import { useNavigationContext } from "../context"

export const SidebarAction = () => {
  const { t, language } = useI18next()
  const { dinamycLink } = useNavigationContext()
  //@ts-ignore
  const { isAuthenticated, referralId } = useSelector(store => store.auth)

  const loginLink = React.useMemo(() => {
    let link = `https://exchange.youngplatform.com/${language}`
    if (isAuthenticated) {
      link = `${link}/dashobard`
    } else {
      link = `${link}/signup`
    }
    return referralId ? `${link}?ref=${referralId}` : link
  }, [isAuthenticated, referralId, language])
  return (
    <Container size="md">
      <SeoLink
        isExternal
        link={
          referralId
            ? `https://exchange.youngplatform.com/${language}/dashboard?ref=${referralId}`
            : `https://exchange.youngplatform.com/${language}/dashboard`
        }
        rel="noopener"
        className="responsive-nav-handler"
        isConversion
      >
        <Button
          isRounded
          isFullWidth
          variant="secondary"
          onClick={triggerHeaderEvents("login")}
        >
          {t("header.login")}
        </Button>
      </SeoLink>
      <Box mt="8px">
        <SeoLink
          isExternal
          link={dinamycLink || loginLink}
          isConversion
          rel="noopener"
          className="responsive-nav-handler"
        >
          <Button isRounded isFullWidth onClick={triggerHeaderEvents("signup")}>
            {t("header.signup")}
          </Button>
        </SeoLink>
      </Box>
    </Container>
  )
}
