import {
  HStack,
  Icon,
  Paragraph,
  styled,
  Box,
  VStack,
} from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { useTranslationContext } from "../../feature/translations/context"
import useDropDownMenu from "../../hook/useDropDown"
import { LanguageSwitcher } from "../shared"

const LanguageBox = styled(Box)`
  display: table;
  &:hover {
    cursor: pointer;
  }
  & [id$="menu"] {
    display: none;
  }

  & .show-dropdown {
    display: inherit !important;
    z-index: 9;
  }
`

const LanguageSelector = ({
  pageLanguage,
  variant = "dark",
}: {
  pageLanguage: string | null
  variant?: "light" | "dark"
}) => {
  const color = variant === "light" ? "black" : "white"
  const { toggleMenu } = useDropDownMenu(["language"])
  const { language } = useI18next()
  const { disableTranslations } = useTranslationContext()
  return (
    <LanguageBox position="relative">
      <VStack align="flex-start">
        <HStack
          spacing="10px"
          onClick={() => !disableTranslations && toggleMenu("language")}
        >
          <img
            src={`/${language}_flags.svg`}
            alt={`${language}-flags`}
            height={20}
            width={20}
          />
          <Paragraph fontFamily="Matter" color={color}>
            {pageLanguage}
          </Paragraph>
          {!disableTranslations ? (
            <Icon icon="drop_down" color={color} />
          ) : null}
        </HStack>
        {!disableTranslations ? (
          <LanguageSwitcher
            id="language-menu"
            position="absolute"
            zIndex={0}
            top={["-120px", "-50%", "-50%"]}
            left={["0px", "80px"]}
          />
        ) : null}
      </VStack>
    </LanguageBox>
  )
}

export default LanguageSelector
