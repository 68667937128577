import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import { Container } from "../components/styledUi"
import { LandingLayout } from "../feature/navigation/components"
import { TranslationProvider } from "../feature/translations/context"
import { Helmet } from "react-helmet"
import { Body, Button, Heading, VStack } from "@youngagency/ui"
import { useLocalizedNavigate } from "../hook/useLocalizedNavigate"

const NewsletterSubscribed = () => {
  const { t } = useI18next()
  const handleLocalizedClicktoExchange = useLocalizedNavigate()
  return (
    <TranslationProvider>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <LandingLayout>
        <Container py={["48px", "96px"]}>
          <VStack spacing={24}>
            <Heading variant="2" fontWeight={600}>
              {t("newsletter_subscribed.title")}
            </Heading>
            <Body variant="1">{t("newsletter_subscribed.caption")}</Body>
            <Button
              size={40}
              atoms={{
                __width: "fit-content",
              }}
              onClick={handleLocalizedClicktoExchange}
            >
              {t("newsletter_subscribed.link")}
            </Button>
          </VStack>
        </Container>
      </LandingLayout>
    </TranslationProvider>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default NewsletterSubscribed
