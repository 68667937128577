import React from "react"
import { Box } from "@youngagency/young-ui"
import styled from "styled-components"
import { SeoLink } from "../../feature/seo"
import WordCard from "./WordCard"
import { GlossaryTermProps } from "../../types/glossary"

type Props = { data: Queries.SingleGlossaryPageQuery["related_words"]["nodes"] }

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  & > *:not(:first-child) {
    margin-top: 12px;
  }
  @media screen and (min-width: 768px) {
    & > *:not(:first-child) {
      margin-top: 16px;
    }
  }
`

const WordList = ({ data }: Props) => {
  return (
    <StyledBox>
      {data.map(item => {
        return (
          <SeoLink
            isExternal={false}
            link={`/glossary/${item.slug}/`}
            key={item.slug}
          >
            <WordCard
              title={item?.title}
              description={item?.seo?.metaDesc || "Description not provided"}
            />
          </SeoLink>
        )
      })}
    </StyledBox>
  )
}

export default WordList
