import { DEFAULT_LANGUAGE } from "../constant"
import { ArticleTranslations } from "../types"

export const findTranslationCategory = (
  trx: NonNullable<ArticleTranslations>[number]
) => {
  return trx?.categories?.nodes?.[0]?.slug
}

export const createWPLanguage = (lang: string) => {
  return {
    code: lang.toUpperCase() as Queries.WpLanguageCodeEnum,
    locale: DEFAULT_LANGUAGE.find(x => x.language.code === lang.toLowerCase())
      ?.language?.locale,
  }
}

export const removePaginationFromSlug = (slug: string) => {
  if (!slug) return
  const splittedString = slug.split("/").filter(x => Boolean(x))
  const paginationPosition = splittedString.findIndex(
    path => !isNaN(Number(path))
  )
  if (paginationPosition < 0) {
    return slug
  }
  splittedString.splice(paginationPosition, 1)
  return splittedString.join("/") + "/"
}
