import { Box } from "@youngagency/young-ui"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import styled from "styled-components"
import { ResponsiveText } from "../layout"
import { SeoLink } from "../../feature/seo"

const CustomStack = styled(Box)`
  display: flex;
  flex-direction: column;
  & > *:first-of-type {
    .text-box {
      padding: 16px;
    }
  }

  article {
    transition: all 75ms ease;
    &:hover {
      background: #e3e4e8 !important;
    }
  }

  & > *:not(:first-of-type) {
    margin-top: 8px;
    margin-left: 0px;
    article {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding: 16px;
      height: 100%;
      .image-box {
        max-width: 96px;
        width: 100%;
        height: 96px;
        border-radius: 16px;
        flex: 1;
      }
      .text-box {
        padding: 0px;
        max-width: unset;

        flex: 1;
      }
      & > *:not(:first-of-type) {
        margin-right: 16px;
      }
    }
  }
  @media screen and (min-width: 992px) {
    flex-direction: row;

    & > *:first-of-type {
      .text-box {
        padding: 24px;
        max-width: unset;
      }
    }
    & > *:not(:first-of-type) {
      margin-top: 0px;
      margin-left: 22px;
      article {
        display: block;
        padding: 0px;
        .image-box {
          max-width: unset;
          height: auto;
          border-radius: 16px 16px 0px 0px;
        }
        .text-box {
          padding: 24px;
        }
        & > *:not(:first-of-type) {
          margin-right: 0px;
        }
      }
    }
  }
`

export const EvidenceArticle = () => {
  const { language } = useI18next()
  const keyLanguage = React.useMemo(() => {
    return language === "it" ? "it" : "en"
  }, [language])
  const newsList: NonNullable<Queries.EvidenceArticleQuery> = useStaticQuery(
    query
  )
  const previewListImage = React.useMemo(
    () => [
      <StaticImage src="./images/fanpage.png" alt="Fanpage.it" key="fanpage" />,
      <StaticImage
        src="./images/sole24.png"
        alt="Il Sole 24 ore"
        key="ilsole24"
      />,
      <StaticImage
        src="./images/repubblica.png"
        alt="La Repubblica"
        key="repubblica-vai"
      />,
    ],
    []
  )
  const evidenceArticle = React.useMemo(() => {
    return newsList?.aboutUsJson?.News?.filter(el => el?.evidence)
  }, [newsList?.aboutUsJson?.News])

  return (
    <Box>
      <CustomStack>
        {evidenceArticle?.map((article, index) => {
          return (
            <SeoLink
              key={article?.title?.[keyLanguage] as string}
              link={article?.link as string}
              isExternal
              target="_blank"
              rel="noopener"
            >
              <Box
                as="article"
                border="1px solid"
                borderColor="grey.5"
                borderRadius="16px"
                overflow="hidden"
                style={{
                  transform: "translateZ(0)",
                }}
                maxWidth={["unset", "368px"]}
                width="100%"
              >
                {previewListImage?.[index] ? (
                  <Box
                    width="100%"
                    borderRadius="16px 16px 0px 0px"
                    overflow="hidden"
                    className="image-box"
                    style={{
                      transform: "translateZ(0)",
                    }}
                  >
                    {previewListImage[index]}
                  </Box>
                ) : null}

                <Box className="text-box" p={["0px", "24px"]}>
                  <ResponsiveText
                    line={["20px", "22px"]}
                    fontWeight={600}
                    pt="0px"
                    dimension={["16px", "18px"]}
                    mt="0px"
                    as="h2"
                  >
                    {article?.title?.[keyLanguage]}
                  </ResponsiveText>
                  <Box mt={["12px"]}>
                    <ResponsiveText
                      line={["18px", "18px"]}
                      dimension={["14px", "14px"]}
                    >
                      {article?.description?.[keyLanguage]}
                    </ResponsiveText>
                  </Box>
                </Box>
              </Box>
            </SeoLink>
          )
        })}
      </CustomStack>
    </Box>
  )
}

const query = graphql`
  query EvidenceArticle {
    aboutUsJson(News: { elemMatch: { evidence: { eq: true } } }) {
      News {
        evidence
        img_link
        link
        title {
          en
          it
        }
        description {
          en
          it
        }
      }
    }
  }
`
