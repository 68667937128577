import { Box, css } from "@youngagency/young-ui"
import React from "react"
import { ResponsiveText } from "../../../components/layout"
import { useFounderContext } from "../context/founder"
import { getCategoryValue } from "../utils"
import { FounderBox } from "./FounderBox"
import styled from "styled-components"

const CustomGridBox = styled(Box)(
  css({
    display: "grid",
    gridTemplateColumns: ["repeat(2, 1fr)", "repeat(3, 1fr)"],
    gridTemplateRows: ["repeat(2, 1fr)", "1fr"],
    columnGap: "20px",
    rowGap: ["20px", "unset"],
  })
)

export const AdvisorsSection = () => {
  const ctx = useFounderContext()

  return (
    <Box>
      {ctx && getCategoryValue(ctx, "advisors") ? (
        <ResponsiveText
          line={["25px", "39px"]}
          dimension={["20px", "34px"]}
          as="h2"
          fontWeight={600}
        >
          {getCategoryValue(ctx, "advisors")}
        </ResponsiveText>
      ) : null}

      <CustomGridBox mt="24px">
        {ctx?.item?.["advisors"]
          ? ctx?.item?.["advisors"].map(tizio => {
              return (
                <FounderBox
                  {...tizio}
                  key={tizio.title + "leader"}
                  maxWidth={["unset", "365px"]}
                  width="100%"
                />
              )
            })
          : null}
      </CustomGridBox>
    </Box>
  )
}
