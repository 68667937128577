import { Box } from "@youngagency/young-ui"
import { StaticImage } from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"

export const HeroImage = React.memo(() => {
  const { language, t } = useI18next()

  switch (language) {
    case "fr":
      return (
        <>
          <Box width="100%" height="100%" display={["none", "block"]}>
            <StaticImage
              quality={100}
              outputPixelDensities={[2]}
              placeholder="dominantColor"
              style={{
                height: "100%",
              }}
              src="./images/fr/hero-img.jpg"
              alt={t("homepage.hero.img_alt_text")}
            />
          </Box>
          <Box width="100%" display={["block", "none"]}>
            <StaticImage
              quality={100}
              placeholder="dominantColor"
              src="./images/fr/hero-image-mobile.png"
              alt={t("homepage.hero.img_alt_text")}
            />
          </Box>
        </>
      )
    case "en":
      return (
        <>
          <Box width="100%" height="100%" display={["none", "block"]}>
            <StaticImage
              quality={100}
              outputPixelDensities={[2]}
              placeholder="dominantColor"
              style={{
                height: "100%",
              }}
              src="./images/en/hero-img.jpg"
              alt={t("homepage.hero.img_alt_text")}
            />
          </Box>
          <Box width="100%" display={["block", "none"]}>
            <StaticImage
              quality={100}
              placeholder="dominantColor"
              src="./images/en/hero-image-mobile.png"
              alt={t("homepage.hero.img_alt_text")}
            />
          </Box>
        </>
      )
    default:
      return (
        <>
          <Box width="100%" height="100%" display={["none", "block"]}>
            <StaticImage
              quality={100}
              outputPixelDensities={[2]}
              placeholder="dominantColor"
              style={{
                height: "100%",
              }}
              src="./images/it/hero-img.jpg"
              alt={t("homepage.hero.img_alt_text")}
            />
          </Box>
          <Box width="100%" display={["block", "none"]}>
            <StaticImage
              quality={100}
              placeholder="dominantColor"
              src="./images/it/hero-image-mobile.png"
              alt={t("homepage.hero.img_alt_text")}
            />
          </Box>
        </>
      )
  }
})

const RumpUpCard1Image = React.memo(() => {
  const { language, t } = useI18next()
  switch (language) {
    case "fr":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/fr/rump-up-1.png"
          alt={t("homepage.section_1.card_1.img_alt_text")}
        />
      )
    case "en":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/en/rump-up-1.png"
          alt={t("homepage.section_1.card_1.img_alt_text")}
        />
      )
    default:
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/it/rump-up-1.png"
          alt={t("homepage.section_1.card_1.img_alt_text")}
        />
      )
  }
})

const RumpUpCard2Image = React.memo(() => {
  const { language, t } = useI18next()
  switch (language) {
    case "fr":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/fr/rump-up-2.png"
          alt={t("homepage.section_1.card_2.img_alt_text")}
        />
      )
    case "en":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/en/rump-up-2.png"
          alt={t("homepage.section_1.card_2.img_alt_text")}
        />
      )
    default:
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/it/rump-up-2.png"
          alt={t("homepage.section_1.card_2.img_alt_text")}
        />
      )
  }
})

const RumpUpCard3Image = React.memo(() => {
  const { language, t } = useI18next()
  switch (language) {
    case "fr":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/fr/rump-up-3.png"
          alt={t("homepage.section_1.card_3.img_alt_text")}
        />
      )
    case "en":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/en/rump-up-3.png"
          alt={t("homepage.section_1.card_3.img_alt_text")}
        />
      )
    default:
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/it/rump-up-3.png"
          alt={t("homepage.section_1.card_3.img_alt_text")}
        />
      )
  }
})

export const RumpUpImage = {
  card1: RumpUpCard1Image,
  card2: RumpUpCard2Image,
  card3: RumpUpCard3Image,
}

const NextCard1Image = React.memo(() => {
  const { language, t } = useI18next()
  switch (language) {
    case "fr":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/fr/next-1.png"
          alt={t("homepage.section_2.card_1.img_alt_text")}
        />
      )
    case "en":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/en/next-1.png"
          alt={t("homepage.section_2.card_1.img_alt_text")}
        />
      )
    default:
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/it/next-1.png"
          alt={t("homepage.section_2.card_1.img_alt_text")}
        />
      )
  }
})

const NextCard2Image = React.memo(() => {
  const { language, t } = useI18next()
  switch (language) {
    case "fr":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/fr/next-2.png"
          alt={t("homepage.section_2.card_2.img_alt_text")}
        />
      )
    case "en":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/en/next-2.png"
          alt={t("homepage.section_2.card_2.img_alt_text")}
        />
      )
    default:
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/it/next-2.png"
          alt={t("homepage.section_2.card_2.img_alt_text")}
        />
      )
  }
})

const NextCard3Image = React.memo(() => {
  const { language, t } = useI18next()
  switch (language) {
    case "fr":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/fr/next-3.png"
          alt={t("homepage.section_2.card_3.img_alt_text")}
        />
      )
    case "en":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/en/next-3.png"
          alt={t("homepage.section_2.card_3.img_alt_text")}
        />
      )
    default:
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/it/next-3.png"
          alt={t("homepage.section_2.card_3.img_alt_text")}
        />
      )
  }
})

export const NextImage = {
  card1: NextCard1Image,
  card2: NextCard2Image,
  card3: NextCard3Image,
}

const BoostCard1Image = React.memo(() => {
  const { t } = useI18next()
  return (
    <StaticImage
      placeholder="dominantColor"
      src="./images/boost-1.png"
      alt={t("homepage.section_4.card_1.img_alt_text")}
    />
  )
})

export const BoostImage = {
  card1: BoostCard1Image,
}

const EduCard1Image = React.memo(() => {
  const { language, t } = useI18next()
  switch (language) {
    case "fr":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/fr/edu-1.png"
          alt={t("homepage.section_6.card_1.img_alt_text")}
        />
      )
    case "en":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/en/edu-1.png"
          alt={t("homepage.section_6.card_1.img_alt_text")}
        />
      )
    default:
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/it/edu-1.png"
          alt={t("homepage.section_6.card_1.img_alt_text")}
        />
      )
  }
})

const EduCard2Image = React.memo(() => {
  const { language, t } = useI18next()
  switch (language) {
    case "fr":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/fr/edu-2.png"
          alt={t("homepage.section_6.card_2.img_alt_text")}
        />
      )
    case "en":
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/en/edu-2.png"
          alt={t("homepage.section_6.card_2.img_alt_text")}
        />
      )
    default:
      return (
        <StaticImage
          placeholder="dominantColor"
          src="./images/it/edu-2.png"
          alt={t("homepage.section_6.card_2.img_alt_text")}
        />
      )
  }
})

export const EduImage = {
  card1: EduCard1Image,
  card2: EduCard2Image,
}
