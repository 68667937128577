import React from "react"
import {
  Box,
  HStack,
  css,
  styled,
  BoxProps,
  Skeleton,
  Icon,
} from "@youngagency/young-ui"
import {
  HeroStack,
  ResponsiveButton,
  ResponsiveText,
} from "../../../../components/layout"
import { useI18next } from "gatsby-plugin-react-i18next"
import {
  formatInternationalCurrency,
  formatInternationalNumber,
} from "../../../../utils/general"
import { SeoLink, MetaDecorator } from "../../../seo"
import { graphql, useStaticQuery } from "gatsby"

import ChartSection from "../../components/ChartSection"
import { useGetAllCurrenciesQuery } from "../../../../redux/services/currency/api"

type ImageProps = {
  file: {
    publicURL: string
  }
}

const StyledStack = styled(Box)(
  css({
    display: "flex",
    flexDirection: ["column", "row"],
    alignItems: "center",
    "& > *:not(:first-of-type)": {
      marginTop: ["24px", "0px"],
      marginLeft: ["0px", "20px"],
    },
    ".icon-box": {
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
)

const SkeletonData = () => {
  return (
    <Box width="100%">
      <Skeleton width="100px" height={["18px", "20px"]} />
      <Skeleton mt={["4px", "8px"]} width="140px" height={["16px", "20px"]} />
    </Box>
  )
}

const CoinData = ({
  text,
  value,
  ...rest
}: { text: string; value?: string | null } & BoxProps) => {
  if (!value) {
    return <Box></Box>
  }
  return (
    <Box {...rest}>
      <Box>
        <ResponsiveText
          line={["22px", "25px"]}
          dimension={["18px", "20px"]}
          fontWeight={600}
          as="p"
        >
          {text}
        </ResponsiveText>
      </Box>
      <Box mt={["4px", "8px"]}>
        <ResponsiveText
          line={["20px", "25px"]}
          dimension={["16px", "20px"]}
          as="p"
          color="green.400"
        >
          {value}
        </ResponsiveText>
      </Box>
    </Box>
  )
}

export const TopSection = () => {
  const { t, language } = useI18next()
  const data: ImageProps = useStaticQuery(query)
  const isoLanguage = React.useMemo(() => {
    return `${language}-${language.toUpperCase()}`
  }, [language])

  const { YNG } = useGetAllCurrenciesQuery(undefined, {
    selectFromResult: ({ data, isLoading }) => ({
      isLoading,
      "YNG": data?.currencies["YNG"],
    }),
  })

  const priceDetailsV2 = React.useMemo(() => {
    if (!YNG) return
    return [
      {
        text: t("token_yng.tokenData.price"),
        value: formatInternationalCurrency(
          isoLanguage,
          Number(YNG.price),
          undefined,
          YNG.decimalPrecision
        ),
      },

      {
        text: t("token_yng.tokenData.supply"),
        value: formatInternationalNumber(isoLanguage, YNG.details.maxSupply),
      },

      {
        text: t("token_yng.tokenData.avialable"),
        value: formatInternationalNumber(
          isoLanguage,
          YNG.details.circulatingSupply
        ),
      },

      {
        text: t("token_yng.tokenData.volume"),
        value: YNG.details.marketCap
          ? formatInternationalCurrency(isoLanguage, YNG.details.marketCap)
          : formatInternationalCurrency(
              isoLanguage,
              Number(YNG.price) * YNG.details.circulatingSupply
            ),
      },
    ]
  }, [
    YNG,
    YNG?.price,
    YNG?.details?.marketCap,
    YNG?.details?.circulatingSupply,
    YNG?.details?.maxSupply,
    YNG?.decimalPrecision,
    t,
    isoLanguage,
    formatInternationalCurrency,
    formatInternationalNumber,
  ])

  return (
    <Box>
      <MetaDecorator
        metaTitle={t("token_yng.title")}
        metaDescription={t("token_yng.description")}
        image={data.file.publicURL}
        disableSlogan
      />
      <HeroStack reverse="row">
        <Box>
          <ResponsiveText
            line={["39px", "60px"]}
            fontWeight={600}
            dimension={["34px", "56px"]}
            as="h1"
          >
            {t("token_yng.title")}
          </ResponsiveText>

          <Box mt={["16px", "24px"]}>
            <ResponsiveText
              line={["20px", "20px"]}
              dimension={["16px", "16px"]}
              as="p"
            >
              {t("token_yng.description")}
            </ResponsiveText>
          </Box>
          <HStack justify={["flex-start"]} spacing="16px" mt={["16px", "24px"]}>
            <SeoLink
              isExternal
              link={
                "https://storage.googleapis.com/young-documents/YNG-whitepaper.pdf"
              }
              target="_self"
              rel="nofollow"
            >
              <ResponsiveButton
                size="lg"
                variant="primary"
                leftIcon={<Icon icon="letter" />}
                style={{
                  background: "#000",
                  border: "#000",
                  //@ts-ignore
                  "&:hover": {
                    background: "#000",
                  },
                }}
              >
                {t("token_yng.cta_1")}
              </ResponsiveButton>
            </SeoLink>
            <SeoLink
              link={"https://youngexchange.page.link/yngb"}
              isConversion
              isExternal
              target="_self"
              rel="nofollow"
            >
              <ResponsiveButton size="lg">
                {t("token_yng.cta_2")}
              </ResponsiveButton>
            </SeoLink>
          </HStack>
        </Box>

        <ChartSection
          currency="YNG"
          customLoader={
            <Skeleton
              width="100%"
              maxWidth={["100%", "463px"]}
              height="0"
              maxHeight={["unset", "463px"]}
              paddingTop={["100%", "38%"]}
              borderRadius="16px"
              alignSelf="flex-start"
            />
          }
        />
      </HeroStack>
      <Box mt={["32px", "96px"]}>
        <StyledStack>
          {priceDetailsV2 ? (
              <>
                <HStack
                  align="center"
                  spacing={["16px", "20px"]}
                  justify="space-between"
                  width="100%"
                >
                  {priceDetailsV2.slice(0, 2).map(el => (
                    <CoinData key={el.text} {...el} width="100%" />
                  ))}
                </HStack>
                <HStack
                  align="center"
                  spacing={["16px", "20px"]}
                  justify="space-between"
                  width="100%"
                >
                  {priceDetailsV2.slice(2).map(el => (
                    <CoinData key={el.text} {...el} width="100%" />
                  ))}
                </HStack>
              </>
          ) : (

            <>
              <HStack
                spacing={["16px", "20px"]}
                width="100%"
                align="center"
                justify="space-between"
              >
                {Array.from({ length: 2 }).map((_, index) => (
                  <SkeletonData key={`skeleton-${index}`} />
                ))}
              </HStack>
              <HStack
                spacing={["16px", "20px"]}
                width="100%"
                align="center"
                justify="space-between"
              >
                {Array.from({ length: 2 }).map((_, index) => (
                  <SkeletonData key={`skeleton-data-${index}`} />
                ))}
              </HStack>
            </>
          )}
        </StyledStack>
      </Box>
    </Box>
  )
}

const query = graphql`
  {
    file(
      relativePath: { eq: "token-rounded.png" }
      sourceInstanceName: { eq: "tokenImage" }
    ) {
      publicURL
    }
  }
`
