import React from "react"
import { Tier, TierValuesProps, tierArray } from "../constants"

type ContextProps = {
  tier: Tier
  setTier: (tier: Tier) => void
  tierValues: TierValuesProps
}

const defaultContext: ContextProps = {
  tier: "bronze",
  setTier: () => {},
  tierValues: tierArray[0],
}
const ComparativeContext = React.createContext<ContextProps>(defaultContext)

export const ComparativeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [tier, setTier] = React.useState<Tier>("bronze")
  const tierValues = React.useMemo(() => {
    return tierArray.find(t => t.name === tier) || tierArray[0]
  }, [tier, tierArray])

  return (
    <ComparativeContext.Provider value={{ tier, setTier, tierValues }}>
      {children}
    </ComparativeContext.Provider>
  )
}

export const useComparativeContext = () => React.useContext(ComparativeContext)
