export const homePageEvents = {
    home_hero_start: "home_hero_start" as const,
    "home_body_rump-up": "home_body_rump-up" as const,
    home_body_next: "home_body_next" as const,
    home_body_boost: "home_body_boost" as const,
    home_body_trust: "home_body_trust"as const,
    home_body_edu: "home_body_edu" as const,
    home_footer_banner: "home_footer_banner" as const,
}

export type HomePageEvents = typeof homePageEvents;