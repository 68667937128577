import React from "react"
import styled from "styled-components"
import { css } from "@styled-system/css"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, HStack, Icon } from "@youngagency/young-ui"
import { ResponsiveText } from "../../../components/layout"
import { PostTag } from "./PostTag"
import { useI18next } from "gatsby-plugin-react-i18next"
import { SeoLink } from "../../seo"
import { PostImageContainer } from "./PostImageContainer"
import { PostHoverBox } from "./PostHoverBox"
import { usePostContext } from "../context/PostContext"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { blogHomeEvents, blogPostEvents, homePageEvents } from "../../../service/tracking"

const CustomBox = styled(Box)(
  css({
    display: "flex",
    flexDirection: ["column", "row-reverse"],
    alignItems: "center",
    "& > *:not(:first-of-type)": {
      marginRight: ["0px", "117px"],
      marginTop: ["24px", "0px"],
    },
  })
)

export const FocusPost = () => {
  const { t } = useI18next()
  const {
    categorySlug,
    slug,
    toggleBoxHoverAnimation,
    image,
    imageAltText,
    handleNavigation,
    tagName,
    tagSlug,
    title,
    targetRef,
  } = usePostContext() || {}

  const {gaTracker} = useSelector((state:RootState)=> state.tracking)
  const handleTracking = React.useCallback(() => {
    if(gaTracker) {
      gaTracker.trackMultipleEvents([{
        eventName: blogPostEvents.post_click,
      }, {
        eventName: blogHomeEvents.most_recent_click,
      }])
    }
  }, [gaTracker])

  return (
    <PostHoverBox onClickCapture={handleTracking}>
      <CustomBox ref={targetRef} className="box_hover">
        {image ? (
          <PostImageContainer
            onClick={handleNavigation}
            maxWidth={["unset", "562px"]}
            display="flex"
            borderRadius="16px"
            overflow="hidden"
            style={{
              transform: "translateZ(0)",
            }}
          >
            <GatsbyImage
              image={image}
              alt={imageAltText || (title as string)}
            />
          </PostImageContainer>
        ) : null}
        <Box maxWidth={["unset", "465px"]} className="post-text">
          {tagName ? (
            <Box mb={["14px", "16px"]}>
              <SeoLink
                isExternal={false}
                link={`/blog/tag/${tagSlug}/`}
                onMouseEnter={toggleBoxHoverAnimation}
                onMouseLeave={toggleBoxHoverAnimation}
              >
                <PostTag text={tagName} />
              </SeoLink>
            </Box>
          ) : null}
          <SeoLink isExternal={false} link={`/blog/${categorySlug}/${slug}/`}>
            <ResponsiveText
              data-role="_text"
              line={["29px", "64px"]}
              dimension={["24px", "56px"]}
              fontWeight={600}
              as="h3"
            >
              {title}
            </ResponsiveText>
          </SeoLink>
          <Box mt={["16px", "24px"]}>
            <SeoLink isExternal={false} link={`/blog/${categorySlug}/${slug}/`}>
              <HStack spacing="4px" align="center">
                <ResponsiveText
                  line={["20px", "29px"]}
                  dimension={["16px", "24px"]}
                  data-role="_text"
                >
                  {t("blog.cta.most-recent")}
                </ResponsiveText>
                <Icon icon="arrow_right" />
              </HStack>
            </SeoLink>
          </Box>
        </Box>
      </CustomBox>
    </PostHoverBox>
  )
}
