import { Stack } from "@youngagency/young-ui"
import styled from "styled-components"
import { variant } from "styled-system"

const Flex = styled(Stack)(
  variant({
    prop: "direction",
    variants: {
      row: {
        direction: "row",
      },
      column: {
        direction: "column",
      },
    },
  }),
  variant({
    prop: "justify",
    variants: {
      center: {
        justifyContent: "center",
      },
      start: {
        justifyContent: "flex-start",
      },
      end: {
        justifyContent: "flex-end",
      },
      between: {
        justifyContent: "space-between",
      },
      around: {
        justifyContent: "space-around",
      },
    },
  }),
  variant({
    prop: "align",
    variants: {
      center: {
        alignItems: "center",
      },
      start: {
        alignItems: "flex-start",
      },
      end: {
        alignItems: "flex-end",
      },
    },
  })
)

Flex.defaultProps = {
  direction: "row",
}

export default Flex
