import styled from "styled-components"
import { Box, BoxProps } from "@youngagency/young-ui"
import { variant } from "styled-system"
import React from "react"

type Props = {
  shape?: "square" | "circle"
} & BoxProps

const AvatarBox = styled(Box)<Props>(
  {
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  variant({
    prop: "shape",
    variants: {
      square: {},
      circle: {
        borderRadius: "50%",
      },
    },
  })
)

export const Avatar = ({ shape = "circle", children, ...rest }: Props) => {
  return (
    <AvatarBox shape={shape} {...rest}>
      {children}
    </AvatarBox>
  )
}

export default Avatar
