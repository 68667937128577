export const FIATIMAGES = {
  "EUR": "https://images.youngplatform.com/coins/eur_light.png",
  "USD": "https://images.youngplatform.com/coins/usd_light.png",
}

export const SWAP_CONVERSION_DIRECTION = "SWAP_CONVERSION_DIRECTION"
export const MAKE_CONVERSION = "MAKE_CONVERSION"
export const SET_CONVERSION_VALUE_TO_0 = "SET_CONVERSION_VALUE_TO_0"
export const EDIT_FROM_VALUE_CHANGE = "EDIT_FROM_VALUE_CHANGE"
export const SET_OPTIONS_LIST = "SET_OPTION_LISTS"
export const SET_CONVERSOR_VALUE = "SET_CONVERSOR_VALUE"
export const SWITCH_CONVERSOR_INPUT = "SWITCH_CONVERSOR_INPUT"
export const CHANGE_CONVERSOR_CTA_TYPE = "CHANGE_CONVERSOR_CTA_TYPE"

export type CONVERSOR_ACTION_KIND =
  | typeof SWAP_CONVERSION_DIRECTION
  | typeof MAKE_CONVERSION
  | typeof EDIT_FROM_VALUE_CHANGE
  | typeof SET_OPTIONS_LIST
  | typeof SET_CONVERSOR_VALUE
  | typeof SET_CONVERSION_VALUE_TO_0
  | typeof SWITCH_CONVERSOR_INPUT
  | typeof CHANGE_CONVERSOR_CTA_TYPE
