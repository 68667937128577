import { Box } from "@youngagency/young-ui"
import { graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import {
  Container,
  BottomBanner,
  ResponsiveButton,
} from "../../components/layout"
import { BreadCrumb, LandingLayout } from "../../feature/navigation/components"
import { WebPageSchema, AlternateHandler, SeoLink } from "../../feature/seo"
import { BottomRoadMap } from "../../feature/token/components"
import {
  CardSection,
  ClubBox,
  Hero,
  TopSection,
  InitialSupply,
  EmissionSupply,
} from "../../feature/token/yng/components"
import { TranslationProvider } from "../../feature/translations/context"

const Token = () => {
  const { t, originalPath } = useI18next()
  const breadcrumbs = React.useMemo(
    () => [
      {
        text: "Home",
        link: "/",
      },
      {
        text: "Token YNG",
        link: originalPath,
      },
    ],
    [originalPath]
  )

  return (
    <TranslationProvider>
      <LandingLayout
        disableFooterMargin
        dinamycLink="https://youngexchange.page.link/yngmenu"
        appleLink="https://youngexchange.page.link/yngios"
        playStoreLink="https://youngexchange.page.link/yngand"
      >
        <WebPageSchema
          breadcrumbs={breadcrumbs}
          title={t("token_yng.metaTitle")}
          description={t("token_yng.metaDescription")}
        />
        <AlternateHandler />
        <Container size={["md", "lg"]}>
          <BreadCrumb breadcrumbs={breadcrumbs} />
          <Box mt={["24px", "48px"]}>
            <TopSection />
          </Box>
        </Container>
        <Box mt={["48px", "121px"]}>
          <Container size={["md", "lg"]}>
            <Hero />
          </Container>
        </Box>
        <Box mt={["48px", "121px"]}>
          <ClubBox />
        </Box>
        <Box mt={["48px", "96px"]}>
          <Container size={["md", "lg"]}>
            <CardSection />
          </Container>
        </Box>
        <Box
          mt={["48px", "96px"]}
          py={["48px", "96px"]}
          backgroundColor="grey.4"
        >
          <Container size={["md", "lg"]}>
            <InitialSupply />
            <Box mt="48px">
              <EmissionSupply />
            </Box>
          </Container>
        </Box>
        {
          /*
        <Box py={["48px", "96px"]}>
          <Container size={["md", "lg"]}>
            <BottomRoadMap />
          </Container>
        </Box>
          */
        }

        <BottomBanner
          text={t("token_yng.footer.title")}
          backgroundColor="blue.400"
          lightText
          mt="0px"
        >
          <SeoLink
            isExternal
            rel="nofollow"
            link={"https://youngexchange.page.link/yngb"}
            isConversion
            target="_self"
          >
            <ResponsiveButton size="lg" variant="primary">
              {t("token_yng.footer.cta")}
            </ResponsiveButton>
          </SeoLink>
        </BottomBanner>
      </LandingLayout>
    </TranslationProvider>
  )
}

export const query = graphql`
  query YoungTokenMainPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Token
