import { Box, Select, useHasMounted } from "@youngagency/young-ui"
import { graphql, useStaticQuery } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import isEmpty from "lodash/isEmpty"
import React from "react"
import { useLegalProvider } from "../context"

export const CategorySelector = () => {
  const { handleSelect, category } = useLegalProvider() || {}
  const { language, t } = useI18next()
  const data: Queries.LegalSelectorCategoriesQuery = useStaticQuery(query)
  const hasMounted = useHasMounted()
  const parsedCategories = React.useMemo(() => {
    const x = data.allWpLegalCategory.nodes.filter(
      category => category.language?.code?.toLowerCase() === language
    )
    if (isEmpty(x)) return
    const parsed = x.map(el => ({
      label: el.name as string,
      value: el.name as string,
    }))
    parsed.unshift({ value: "all", label: t("legal.selector.all_documenents") })
    return parsed
  }, [t])

  const defaultCategory = React.useMemo(() => {
    if (!hasMounted) return
    const cameFrom = localStorage.getItem("comeFrom")
    if (cameFrom) {
      localStorage.removeItem("comeFrom")
      const x = parsedCategories?.find(el => el.value === cameFrom)
      if (x) {
        handleSelect && handleSelect(x.value)
        return x
      }
    }
    if (category) return { value: category, label: category }
    return { value: "all", label: t("legal.selector.all_documenents") }
  }, [hasMounted, parsedCategories, category, t, handleSelect])


  if (parsedCategories)
    return (
      <Box maxWidth={["unset", "362px"]}>
        <Select
          value={defaultCategory}
          label={t("legal.selector.placeholder")}
          onChange={e =>
            handleSelect && handleSelect(e?.value === "all" ? null : e?.value)
          }
          options={parsedCategories}
        />
      </Box>
    )
  return null
}

const query = graphql`
  query LegalSelectorCategories {
    allWpLegalCategory {
      nodes {
        name
        language {
          code
        }
      }
    }
  }
`
