import React from "react"
import styled from "styled-components"
import { css } from "@styled-system/css"
import { Card, CardImage } from "./Card"
import { Heading, HeadingXL, Paragraph } from "../../../../components"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Box, Button } from "@youngagency/young-ui"
import { navigate } from "@reach/router"
import BaseImg from "../../../../images/extended-yp-logo.svg"
import ProImg from "../../../../images/YP_Pro_Esteso.svg"
import StepLogo from "../../../../images/YP_Step_Esteso_Negative.svg"
import AcademyLogo from "../../../../images/YP_Academy_Contratto.svg"
import { useLocalizedImage } from "../hooks/useLocalizedImage"
import { RootState } from "../../../../redux/store"
import { useSelector } from "react-redux"
import { triggerClickToExchangeEvents } from "../../../../utils/general"
import { globalEvents } from "../../../../service/tracking"

const CardRow = styled("div")(
  css({
    display: "flex",
    flexDirection: ["column", "row"],
    justifyContent: "center",
    mx: "auto",
    "& > *:not(first-of-type)": {
      marginTop: ["20px", "0px"],
      marginLeft: ["0px", "20px"],
    },
  })
)


const handleNavigation = (link: string) => () => navigate(link)

export const Cards = () => {
  const { t, language } = useI18next()
  const { referralId } = useSelector((store: RootState) => store.auth)
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)

  const localizedImages = useLocalizedImage()
  const referralLink = React.useMemo(() => {
    if (referralId) {
      return `https://exchange.youngplatform.com/${language}/signup?ref=${referralId}`
    }
    return `https://exchange.youngplatform.com/${language}/signup`
  }, [language, referralId])

  const referralProLink = React.useMemo(() => {
    if (referralId) {
      return `https://pro.youngplatform.com/${language}/signup?ref=${referralId}`
    }
    return `https://pro.youngplatform.com/${language}/signup`
  }, [language, referralId])

  const handleConversionNavigation = React.useCallback(() => {
    triggerClickToExchangeEvents()
    handleNavigation(referralLink)()
  }, [referralLink])

  return (
    <Box>
      <CardRow>
        <Card variant="light">
          <Box>
            <img
              src={BaseImg}
              alt="Logo Young Platform Base"
              width={207}
              height={36}
            />
          </Box>
          <Box mt={["24px", "34px"]}>
            <Heading as="h2">
              {t("young_world.paragraph_1.card_1.title")}
            </Heading>
          </Box>
          <Box mt={["24px", "34px"]}>
            <Paragraph as="p">
              {t("young_world.paragraph_1.card_1.caption")}
            </Paragraph>
          </Box>
          <Box mt={["24px", "34px"]}>
            <Button size="md" onClick={handleConversionNavigation}>
              {t("young_world.paragraph_1.card_1.link")}
            </Button>
          </Box>
          <CardImage mt={["24px", "34px"]}>
            {localizedImages.exchange_base}
          </CardImage>
        </Card>
        <Card variant="dark">
          <img
            src={ProImg}
            alt="Logo Young Platform Pro"
            width={207}
            height={36}
          />
          <Box mt={["24px", "34px"]}>
            <Heading as="h3">
              {t("young_world.paragraph_1.card_2.title")}
            </Heading>
          </Box>
          <Box mt={["24px", "34px"]}>
            <Paragraph as="p">
              {t("young_world.paragraph_1.card_2.caption")}
            </Paragraph>
          </Box>
          <Box mt={["24px", "34px"]}>
            <Button size="md" onClick={handleNavigation(referralProLink)}>
              {t("young_world.paragraph_1.card_2.link")}
            </Button>
          </Box>
          <CardImage mt={["24px", "34px"]}>
            {localizedImages.exchange_pro}
          </CardImage>
        </Card>
      </CardRow>
      <Box mt={["48px", "96px"]}>
        <HeadingXL as="h2" textAlign="center" maxWidth="756px" mx="auto">
          {t("young_world.paragraph_2.title")}
        </HeadingXL>
        <Box mt={["24px", "48px"]}></Box>
        <CardRow>
          <Card variant="dark">
            <img
              src={StepLogo}
              alt="Logo Young Platform Pro"
              width={207}
              height={36}
            />
            <Box mt={["24px", "34px"]}>
              <Heading as="h3">
                {t("young_world.paragraph_2.card_1.title")}
              </Heading>
            </Box>
            <Box mt={["24px", "34px"]}>
              <Paragraph as="p">
                {t("young_world.paragraph_2.card_1.caption")}
              </Paragraph>
            </Box>
            <Box mt={["24px", "34px"]}>
              <Button
                size="md"
                onClick={handleNavigation(
                  `https://step.youngplatform.com/${language}`
                )}
              >
                {t("young_world.paragraph_2.card_1.link")}
              </Button>
            </Box>
            <CardImage mt={["24px", "34px"]}>{localizedImages.step}</CardImage>
          </Card>

          <Card variant="light">
            <Box
              display="flex"
              justifyContent="flex-start"
              width={207}
              height={36}
            >
              <img src={AcademyLogo} alt="Logo Young Platform Base" />
            </Box>
            <Box mt={["24px", "34px"]}>
              <Heading as="h3">
                {t("young_world.paragraph_2.card_2.title")}
              </Heading>
            </Box>
            <Box mt={["24px", "34px"]}>
              <Paragraph as="p">
                {t("young_world.paragraph_2.card_2.caption")}
              </Paragraph>
            </Box>
            <Box mt={["24px", "34px"]}>
              <Button
                size="md"
                onClick={() => {
                  if(gaTracker)
                  gaTracker.trackEvent({
                    eventName: globalEvents.click_to_academy
                  })
                  handleNavigation(
                    `https://academy.youngplatform.com/${language}/`
                  )()
                }}
              >
                {t("young_world.paragraph_2.card_2.link")}
              </Button>
            </Box>
            <CardImage mt={["24px", "34px"]}>
              {localizedImages.academy}
            </CardImage>
          </Card>
        </CardRow>
      </Box>
    </Box>
  )
}
