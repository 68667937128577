import { Body, Box, Container, VStack, Heading, Button } from "@youngagency/ui"
import { Container as CustomContainer } from "../../../components/layout"
import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { CardsWrapper } from "../../../components/cards/components/CardsWrapper"
import { VerticalCard } from "../../../components/cards/components/VerticalCard"
import { CardContainer } from "../../../components/cards/layout/CardContainer"
import { CardContent } from "../../../components/cards/layout/CardContent"
import { CardImage } from "../../../components/cards/layout/CardImage"
import { StaticImage } from "gatsby-plugin-image"
import { CardAction } from "../../../components/cards/layout/CardAction"
import { CardButton } from "../../../components/cards/layout/CardButton"
import { notificationImage } from "../styles/action-section.css"
import { bannerStack } from "../../../components/banner/ecosistema/styles/banner-layout.css"
import { CardsSection } from "../../../components/cards/components/CardsSection"
import { navigate } from "@reach/router"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { antiPhishingEvents, globalEvents } from "../../../service/tracking"
import { ActionImage } from "./LocalizedImage"
const EVENTS = [
  {
    eventName: globalEvents.click_to_academy,
  },
  {
    eventName: antiPhishingEvents.security_body_academy,
  },
]

export const ActionSection = () => {
  const { t, language } = useI18next()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  const handleLocalizedBannerClick = React.useCallback(() => {
    gaTracker?.trackMultipleEvents(EVENTS)
    let link = "https://academy.youngplatform.com/"
    if (language !== "it") {
      link += language
    }

    navigate(link)
  }, [language, gaTracker])

  const handleOnClick = React.useCallback(
    (card_clicked: number) => {
      if (gaTracker)
        gaTracker.trackMultipleEvents([
          {
            eventName: antiPhishingEvents.security_body_best_practice,
            payload: {
              card_clicked: card_clicked,
            },
          },
          {
            eventName: antiPhishingEvents.security_update_settings,
          },
        ])
      navigate("https://youngexchange.page.link/QMsz")
    },
    [gaTracker]
  )
  return (
    <>
      <CustomContainer>
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              fontWeight={400}
              dangerouslySetInnerHTML={{
                __html: t("security.action_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              color="grey200"
              style={{
                maxWidth: "700px",
              }}
              fontWeight={600}
            >
              {t("security.action_section.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>

      <CardsSection
        marginTop={{
          mobile: 32,
          md: 40,
        }}
      >
        <VStack
          spacing={"unset"}
          align="center"
          justify="center"
          atoms={{
            __rowGap: "1rem",
            width: "full",
            mx: "auto",
          }}
        >
          <CardsWrapper alignment="center">
            <VerticalCard hasShadow hoverVariant="dark" isAnimated>
              <CardContainer onClick={() => handleOnClick(1)}>
                <CardContent spacing={2}>
                  <VStack
                    atoms={{
                      __zIndex: 2,
                    }}
                    spacing={{
                      mobile: 24,
                      lg: 32,
                    }}
                  >
                    <Heading variant="6" color="white" as="h3" fontWeight={600}>
                      {t("security.action_section.cards.card_1.title")}
                    </Heading>
                  </VStack>
                  <CardImage absolute>
                    <StaticImage
                      style={{
                        height: "100%",
                      }}
                      src="../images/it/action_1.png"
                      alt={t(
                        "security.action_section.cards.card_1.img_alt_text"
                      )}
                    />
                  </CardImage>
                  <Box className={notificationImage}>
                    <Box __maxWidth="278px">
                      <ActionImage.card_1 />
                    </Box>
                  </Box>
                </CardContent>
                <CardAction
                  marginTop={{
                    mobile: 16,
                    md: 24,
                  }}
                >
                  <CardButton schema="light" rightIcon="arrowRight">
                    {t("security.action_section.cards.card_1.button")}
                  </CardButton>
                </CardAction>
              </CardContainer>
            </VerticalCard>
            <VerticalCard hasShadow hoverVariant="light" isAnimated>
              <CardContainer onClick={() => handleOnClick(2)}>
                <CardContent spacing={2}>
                  <VStack
                    spacing={{
                      mobile: 16,
                      lg: 24,
                    }}
                  >
                    <Heading variant="6" as="h3" fontWeight={600}>
                      {t("security.action_section.cards.card_2.title")}
                    </Heading>
                  </VStack>

                  <CardImage>
                    <ActionImage.card_2 />
                  </CardImage>
                </CardContent>

                <CardAction
                  marginTop={{
                    mobile: 16,
                    md: 24,
                  }}
                >
                  <CardButton schema="dark" rightIcon="arrowRight">
                    {t("security.action_section.cards.card_2.button")}
                  </CardButton>
                </CardAction>
              </CardContainer>
            </VerticalCard>
          </CardsWrapper>

          <Box
            background="purple10"
            width="full"
            borderRadius={16}
            p={{
              mobile: 24,
              lg: 48,
            }}
          >
            <Box className={bannerStack}>
              <VStack spacing={8}>
                <Heading variant="6" fontWeight={600}>
                  {t("security.action_section.banner.title")}
                </Heading>
                <Body variant="1">
                  {t("security.action_section.banner.description")}
                </Body>
              </VStack>
              <Button
                variant="solid"
                colorScheme="purple"
                onClick={handleLocalizedBannerClick}
              >
                {t("security.action_section.banner.button")}
              </Button>
            </Box>
          </Box>
        </VStack>
      </CardsSection>
    </>
  )
}
