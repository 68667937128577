import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { Body, Box, Container, Heading, VStack, Tag } from "@youngagency/ui"
import { Container as CustomContainer } from "../../../../components/layout"
import {
  CardContent,
  CardGrid,
  CardImage,
  CardStack,
  CardTexts,
} from "../../../../components/cards"
import { Card } from "../../../../components/cards"
import { StaticImage } from "gatsby-plugin-image"

export const TaxesSections = () => {
  const { t } = useTranslation()

  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              color="white"
              dangerouslySetInnerHTML={{
                __html: t("clubs_page.b2b.taxes_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              style={{
                maxWidth: "700px",
              }}
              color="grey200"
              fontWeight={600}
            >
              {t("clubs_page.b2b.taxes_section.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>
      <Box
        marginTop={{
          mobile: 32,
          md: 40,
        }}
      >
        <VStack
          spacing={24}
          align="center"
          justify="center"
          atoms={{
            width: "full",
            mx: "auto",
          }}
        >
          <CardGrid>
            <CardStack>
              <Card
                isAnimated
                hasShadow
                background="#131312"
                orientation="column"
                disableHover
                shape="square"
              >
                <CardContent shape="square">
                  <CardTexts>
                    <Heading as="h3" variant="6" color="white" fontWeight={600}>
                      {t("clubs_page.b2b.taxes_section.card_1.title")}
                    </Heading>
                    <Body variant="1" color="grey200" fontWeight={600}>
                      {t("clubs_page.b2b.taxes_section.card_1.description")}
                    </Body>
                  </CardTexts>
                </CardContent>
                <CardImage>
                  <StaticImage
                    src="../../images/taxes-card-1.png"
                    alt={t("clubs_page.b2b.taxes_section.card_1.img_alt_text")}
                  />
                </CardImage>
              </Card>
              <Card
                isAnimated
                hasShadow
                background="#131312"
                orientation="column"
                disableHover
                shape="square"
              >
                <CardContent shape="square">
                  <CardTexts>
                    <Tag size={16} colorScheme="grey">
                      {t("clubs_page.b2b.taxes_section.card_2.label")}
                    </Tag>
                    <Heading as="h3" variant="6" color="white" fontWeight={600}>
                      {t("clubs_page.b2b.taxes_section.card_2.title")}
                    </Heading>
                    <Body variant="1" color="grey200" fontWeight={600}>
                      {t("clubs_page.b2b.taxes_section.card_2.description")}
                    </Body>
                  </CardTexts>
                </CardContent>
              </Card>
            </CardStack>
          </CardGrid>
        </VStack>
      </Box>
    </>
  )
}
