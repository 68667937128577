import React from "react"
import { Box, BoxProps } from "@youngagency/ui"

import { UiBanner } from "./UiBanner"
import { StaticImage } from "gatsby-plugin-image"
import { bannerWavesImage } from "./styles/generic-banner.css"

type BannerProps = Omit<BoxProps, "color"> & {
  children: React.ReactNode
  color?: any
  className?: string
  withImage?: true
}

export const DarkBanner: React.FC<BannerProps> = ({
  children,
  withImage,
  ...rest
}) => {
  return (
    <UiBanner {...rest}>
      {children}
      {withImage ? (
        <Box className={bannerWavesImage}>
          <StaticImage
            style={{
              height: "100%",
            }}
            quality={100}
            outputPixelDensities={[1, 2]}
            src="./images/banner-bg-waves.png"
            alt="young-banner-image"
          />
        </Box>
      ) : null}
    </UiBanner>
  )
}
