import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { defaultChartsURL } from "../constants"
import {
  ChartsV2SingleToken,
  GetAllChartV2Request,
  GetAllChartsV2ApiResponse,
  GetTokenChartRequest,
  ParsedGetAllChartsV2ApiResponse,
  SingleChartApiResponse,
} from "../types"
import { parseGetAllToken, parseSingleChartToken } from "./parser"

export const chartApi = createApi({
  reducerPath: "chartApi",
  baseQuery: fetchBaseQuery({
    baseUrl: defaultChartsURL,
  }),
  endpoints: builder => ({
    getAllTokenChartV2: builder.query<
      ParsedGetAllChartsV2ApiResponse,
      GetAllChartV2Request
    >({
      query: ({ fiat = "EUR", timeframe = { interval: 60, limit: 24 } }) =>
        `/charts?interval=${timeframe.interval}&limit=${timeframe.limit}`,
      transformResponse: (data: GetAllChartsV2ApiResponse, _, { fiat }) =>
        parseGetAllToken({ data, fiat: fiat as "EUR" | "USD" }),
    }),
    getSingleTokenChartV2: builder.query<
      SingleChartApiResponse,
      GetTokenChartRequest
    >({
      query: ({ currency, timeframe, fiat = "EUR" }) =>
        `/charts/${currency}-${fiat}?interval=${timeframe.interval}&limit=${timeframe.limit}`,
      transformResponse: (data: ChartsV2SingleToken[]) =>
        parseSingleChartToken(data),
      keepUnusedDataFor: 60,
    }),
  }),
})

export const {
  useGetAllTokenChartV2Query,
  useGetSingleTokenChartV2Query,
} = chartApi
