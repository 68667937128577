import { Box, variant, styled } from "@youngagency/young-ui"

export const HeroStack = styled(Box)<{ reverse: "row" | "inverse" }>(
  variant({
    prop: "reverse",
    variants: {
      row: {
        "@media screen and (min-width: 992px)": {
          flexDirection: "row",
          "& > *:not(:first-of-type)": {
            marginTop: "0px",
            marginLeft: "117px",
          },
        },
      },
      inverse: {
        "@media screen and (min-width: 992px)": {
          flexDirection: "row-reverse",
          "& > *:not(:first-of-type)": {
            marginTop: "0px",
            marginRight: "117px",
          },
        },
      },
    },
  }),
  {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *:not(:first-of-type)": {
      marginTop: "24px",
    },
  }
)
