import uniqBy from "lodash/uniqBy"
import {
  ChartsV2SingleToken,
  GetAllChartsV2ApiResponse,
  ParsedGetAllChartsV2ApiResponse,
  SingleChartApiResponse,
} from "../types"

type ParsedByFiatToken = GetAllChartsV2ApiResponse

const getFiatParsedToken = (
  data: GetAllChartsV2ApiResponse,
  fiat: "USD" | "EUR" = "EUR"
): ParsedByFiatToken => {
  const fiatParsedToken: ParsedByFiatToken = {}
  Object.keys(data).forEach(key => {
    if (key.includes(fiat)) {
      const tokenKey = key
        .split(fiat)
        .filter(el => el.toUpperCase() !== fiat.toUpperCase())[0]
        .replace("-", "")
      if (tokenKey) {
        fiatParsedToken[tokenKey] = data[key]
      }
    }
  })

  return fiatParsedToken
}

const parseSingleToken = (
  data: ParsedByFiatToken
): ParsedGetAllChartsV2ApiResponse => {
  const parsedToken: ParsedGetAllChartsV2ApiResponse = {}
  Object.keys(data).forEach(key => {
    const token = data[key]
    const uniqeTokenByPoints = uniqBy(token, "closeAt")
    const points = Object.values(uniqeTokenByPoints)
      .map(x => x.close)
      .reverse()

    const percentageChange = Number(
      ((points[points.length - 1] - points[0]) / points[0]) * 100
    ).toFixed(2)

    parsedToken[key] = {
      points,
      percentageChange,
    }
  })

  return parsedToken
}

export const parseGetAllToken = ({
  data,
  fiat,
}: {
  data: GetAllChartsV2ApiResponse
  fiat: "USD" | "EUR"
}): ParsedGetAllChartsV2ApiResponse => {
  const fiatParsedToken = getFiatParsedToken(data, fiat)
  const parsedToken = parseSingleToken(fiatParsedToken)

  return parsedToken
}

export const parseSingleChartToken = (
  data: ChartsV2SingleToken[]
): SingleChartApiResponse => {
  const points = data
    .sort(
      (a, b) => new Date(a.closeAt).valueOf() - new Date(b.closeAt).valueOf()
    )
    .map(e => ({
      ...e,
      time: new Date(e.closeAt).valueOf(),
      value: Number(e.close),
    }))

  const firstValue = points.find(x => x.value)?.value || 0
  const lastValue = points[points.length - 1].value

  const percentageChange = (
    ((lastValue - firstValue) / firstValue) *
    100
  ).toFixed(2)

  return {
    points,
    percentageChange,
  }
}
