import { StaticImage } from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"

const AdviceImage1 = React.memo(() => {
  const { language, t } = useI18next()

  switch (language) {
    case "fr":
      return (
        <>
          <StaticImage
            quality={100}
            outputPixelDensities={[2]}
            placeholder="blurred"
            src="../images/fr/advice_1.png"
            alt={t("security.advices_section.cards.card_1.img_alt_text")}
          />
        </>
      )
    case "en":
      return (
        <>
          <StaticImage
            quality={100}
            outputPixelDensities={[2]}
            placeholder="blurred"
            src="../images/en/advice_1.png"
            alt={t("security.advices_section.cards.card_1.img_alt_text")}
          />
        </>
      )
    default:
      return (
        <>
          <StaticImage
            quality={100}
            outputPixelDensities={[2]}
            placeholder="blurred"
            src="../images/it/advice_1.png"
            alt={t("security.advices_section.cards.card_1.img_alt_text")}
          />
        </>
      )
  }
})

const AdviceImage2 = React.memo(() => {
  const { language, t } = useI18next()

  switch (language) {
    case "fr":
      return (
        <>
          <StaticImage
            quality={100}
            outputPixelDensities={[2]}
            placeholder="blurred"
            src="../images/fr/advice_2.png"
            alt={t("security.advices_section.cards.card_2.img_alt_text")}
          />
        </>
      )
    case "en":
      return (
        <>
          <StaticImage
            quality={100}
            outputPixelDensities={[2]}
            placeholder="blurred"
            src="../images/en/advice_2.png"
            alt={t("security.advices_section.cards.card_2.img_alt_text")}
          />
        </>
      )
    default:
      return (
        <>
          <StaticImage
            quality={100}
            outputPixelDensities={[2]}
            placeholder="blurred"
            src="../images/it/advice_2.png"
            alt={t("security.advices_section.cards.card_2.img_alt_text")}
          />
        </>
      )
  }
})

const AdviceImage3 = React.memo(() => {
  const { language, t } = useI18next()

  switch (language) {
    case "fr":
      return (
        <>
          <StaticImage
            quality={100}
            outputPixelDensities={[2]}
            placeholder="blurred"
            src="../images/fr/advice_3.png"
            alt={t("security.advices_section.cards.card_3.img_alt_text")}
          />
        </>
      )
    case "en":
      return (
        <>
          <StaticImage
            quality={100}
            outputPixelDensities={[2]}
            placeholder="blurred"
            src="../images/en/advice_3.png"
            alt={t("security.advices_section.cards.card_3.img_alt_text")}
          />
        </>
      )
    default:
      return (
        <>
          <StaticImage
            quality={100}
            outputPixelDensities={[2]}
            placeholder="blurred"
            src="../images/it/advice_3.png"
            alt={t("security.advices_section.cards.card_3.img_alt_text")}
          />
        </>
      )
  }
})

export const AdviceImage = {
  card_1: AdviceImage1,
  card_2: AdviceImage2,
  card_3: AdviceImage3,
}

export const NotificationImage = React.memo(() => {
  const { language, t } = useI18next()

  switch (language) {
    case "fr":
      return (
        <>
          <StaticImage
            quality={100}
            outputPixelDensities={[2]}
            placeholder="blurred"
            src="../images/fr/notification.png"
            alt={t("security.action_section.cards.card_1.img_alt_text")}
          />
        </>
      )
    case "en":
      return (
        <>
          <StaticImage
            quality={100}
            outputPixelDensities={[2]}
            placeholder="blurred"
            src="../images/en/notification.png"
            alt={t("security.action_section.cards.card_1.img_alt_text")}
          />
        </>
      )
    default:
      return (
        <>
          <StaticImage
            quality={100}
            outputPixelDensities={[2]}
            placeholder="blurred"
            src="../images/it/notification.png"
            alt={t("security.action_section.cards.card_1.img_alt_text")}
          />
        </>
      )
  }
})

const ActionImage2 = React.memo(() => {
  const { language, t } = useI18next()

  switch (language) {
    case "fr":
      return (
        <>
          <StaticImage
            quality={100}
            outputPixelDensities={[2]}
            placeholder="blurred"
            src="../images/fr/action_2.png"
            alt={t("security.action_section.cards.card_2.img_alt_text")}
          />
        </>
      )
    case "en":
      return (
        <>
          <StaticImage
            quality={100}
            outputPixelDensities={[2]}
            placeholder="blurred"
            src="../images/en/action_2.png"
            alt={t("security.action_section.cards.card_2.img_alt_text")}
          />
        </>
      )
    default:
      return (
        <>
          <StaticImage
            quality={100}
            outputPixelDensities={[2]}
            placeholder="blurred"
            src="../images/it/action_2.png"
            alt={t("security.action_section.cards.card_2.img_alt_text")}
          />
        </>
      )
  }
})

export const ActionImage = {
  card_1: NotificationImage,
  card_2: ActionImage2,
}
