import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"

const useNavigationMenu = () => {
  const { t } = useI18next()
  const menu = React.useMemo(() => {
    return [
      {
        title: t("menu.product.text") as string,
        link: null,
        isExternal: false,
        hasLabel: false,
      },
      {
        title: t("menu.markets") as string,
        link: "/exchange/",
        isExternal: false,
        hasLabel: false,
      },
      {
        title: t("menu.clubs") as string,
        link: "/token-yng/clubs/",
        hasLabel: false,
        isExternal: false,
      },
      {
        title: t("menu.b2b") as string,
        link: "/business/",
        isExternal: false,
        hasLabel: false,
      },
      {
        title: t("menu.company.text") as string,
        link: null,
        isExternal: false,
        hasLabel: false,
      },
      {
        title: t("menu.blog") as string,
        link: "/blog/",
        isExternal: false,
        hasLabel: false,
      },

      {
        title: t("menu.support") as string,
        link: "https://support.youngplatform.com/hc/",
        isExternal: true,
        hasLabel: false,
      },
    ]
  }, [t])

  return { menu }
}

export default useNavigationMenu
