import { Button, Box, css } from "@youngagency/young-ui"
import styled from "styled-components"

export const ResponsiveButton = styled(Button)`
  @media screen and (max-width: 767px) {
    height: 48px;
    min-width: 48px;
    padding: 0px 16px;
    font-size: 16px;
  }
`

export const BtnContainer = styled(Box)(
  css({
    display: "flex",
    ".xs-fullwidth": {
      flex: [1, "inherit"],
    },
  })
)

export const RoundedButton = styled(Button)<{
  radius: string[] | string
}>(
  css({
    borderRadius: "50%",
    background: "#fff",
    border: "1px solid",
    borderColor: "grey.5",
  }),
  ({ radius }) => ({
    width: radius as any,
    height: radius as any,
  })
)
