import { useHasMounted } from "@youngagency/young-ui"
import React from "react"

export const useFrontMatter = () => {
  const hasMounted = useHasMounted()
  const [frontMatter, setFrontMatter] = React.useState<
    HTMLHeadingElement[] | null
  >(null)
  const moveToHashHeader = React.useCallback(
    (hash: string) => {
      if (hasMounted) {
        const el = document.querySelector(`[data-hash="${hash}"]`)
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "center" })
        }
      }
    },
    [hasMounted]
  )

  React.useEffect(() => {
    if (hasMounted) {
      const h2Arrays = document.querySelectorAll("h2")
      setFrontMatter(Array.from(h2Arrays))
      h2Arrays.forEach((el, index) => el.setAttribute("data-hash", `#${index}`))
    }
  }, [hasMounted])

  React.useEffect(() => {
    if (hasMounted) {
      window.addEventListener("hashchange", () => {
        const hash = location.hash
        if (hash) {
          moveToHashHeader(hash)
        }
      })
    }
  }, [hasMounted])

  return frontMatter
}
