import { graphql, useStaticQuery } from "gatsby"
import React, { createContext, useContext } from "react"
import { slugifyString } from "../../../utils/general"
import { legalParser, getLegalParams, ParsedDocs } from "../utils"

export type ProviderContext = {
  docs: ParsedDocs
  category: Queries.WpLegalCategoryConnection["nodes"][number]["name"] | null
  handleSelect: (param?: string | null) => void
}

type ProviderProps = {
  docs: ParsedDocs
  children: React.ReactNode
}

const LegalContext = createContext<ProviderContext | null>(null)

export const LegalProvider = ({ docs, children }: ProviderProps) => {
  const categories: Queries.LegalContextCategoryQueryQuery = useStaticQuery(
    query
  )

  const defaultValue = React.useMemo(() => {
    let ref = getLegalParams()
    if (!ref) return null
    ref = ref.toLowerCase()
    const exist = categories.allWpLegalCategory.nodes.find(category => {
      if (!category.name) return false
      const sluggedCategory = slugifyString(category.name)
      if (sluggedCategory === ref) return true
      if (!category.translations) return false
      if (
        category.translations.some(item => {
          if (!item?.name) return false
          return slugifyString(item.name) === ref
        })
      ) {
        return true
      }
    })
    if (!exist) return null
    const label = exist.translations?.find(item => {
      if (!item?.name) return
      return slugifyString(item.name) === ref
    })

    return label?.name || exist.name
  }, [categories])

  const [selectedCategory, setSelectedCategory] = React.useState<null | string>(
    defaultValue
  )
  const handleSelect = React.useCallback(
    (value?: string | null) => setSelectedCategory(value || null),
    []
  )
  const parsedDocs = React.useMemo(
    () => legalParser({ category: selectedCategory, docs }),
    [selectedCategory]
  )
  return (
    <LegalContext.Provider
      value={{
        category: selectedCategory,
        docs: parsedDocs,
        handleSelect,
      }}
    >
      {children}
    </LegalContext.Provider>
  )
}

export const useLegalProvider = () => {
  return useContext(LegalContext)
}

const query = graphql`
  query LegalContextCategoryQuery {
    allWpLegalCategory {
      nodes {
        id
        name
        translations {
          name
        }
      }
    }
  }
`
