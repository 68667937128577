import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import Arrow from "../../images/green-arrow.svg"
import { Box } from "@youngagency/young-ui"
import { Row } from "../styledUi"
import { Container, ResponsiveText } from "../layout"
import { SeoLink } from "../../feature/seo"
const Positions = ({ PositionsRef, positions }) => {
  const { t } = useI18next()
  return (
    <Container size={["lg", "md"]} mt={["96px", "48px"]} ref={PositionsRef}>
      <ResponsiveText
        line={["30px", "50px"]}
        fontWeight={700}
        dimension={["24px", "48px"]}
        as="p"
        textAlign="left"
      >
        {t("jobs.position_title")}
      </ResponsiveText>
      <Box mt={["24px", "24px"]}>
        <ResponsiveText
          line={["19px", "25px"]}
          fontWeight={400}
          dimension={["16px", "20px"]}
          textAlign="left"
          as="p"
        >
          {t("jobs.position_caption")}
        </ResponsiveText>
      </Box>
      <Box mt={["24px", "48px"]}>
        {positions.map(({ title, slug }) => {
          return (
            <SeoLink key={title} link={`/jobs/${slug}/`} isExternal={false}>
              <Row
                gap="none"
                height={["72px", "72px", "100px", "100px"]}
                borderBottom="1px solid var(--light-gray-5)"
                align="center"
              >
                <ResponsiveText
                  line={["19px", "25px"]}
                  fontWeight={600}
                  dimension={["16px", "20px"]}
                  textAlign="left"
                  color="black"
                  as="h2"
                >
                  {title}
                </ResponsiveText>
              </Row>
            </SeoLink>
          )
        })}
      </Box>
      <a
        style={{ color: "var(--green)", textDecoration: "none" }}
        href={"mailto:jobs@youngplatform.com?subject=Job Application"}
      >
        <Row
          gap="none"
          align="center"
          mt="34px"
          style={{
            cursor: "pointer",
          }}
        >
          <ResponsiveText
            line={["19px", "25px"]}
            fontWeight={600}
            dimension={["16px", "20px"]}
            textAlign="left"
            color="green.300"
            as="p"
          >
            {t("jobs.application")}
          </ResponsiveText>
          <img
            src={Arrow}
            alt="arrow"
            style={{ width: "24px", marginLeft: "4px" }}
          />
        </Row>
      </a>
    </Container>
  )
}

export default Positions
