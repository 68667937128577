import { Box, Icon, IconType, useHasMounted } from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { ResponsiveText } from "../../../components/layout"
import { TokenRowLayout } from "./TokenRowLayout"
import styled from "styled-components"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"

const HoverBox = styled(Box)({
  cursor: "pointer",
  "-webkit-touch-callout": "none",
  "-webkit-user-select": "none",
  "-khtml-user-select": "none",
  "-moz-user-select": "none",
  "-ms-user-select": "none",
  "user-select": "none",
})

export const TokenListHeader = () => {
  const { t } = useI18next()
  const location = useLocation()
  const state = location.state as any
  const pathName = location.pathname
  const handleClick = React.useCallback(
    (value: string | undefined) => {
      if (!value) return
      const currentState = { ...state }
      if (currentState.sortedBy === value) {
        currentState.sortOrder =
          currentState.sortOrder === "asc" ? "desc" : "asc"
      } else {
        currentState.sortOrder = "desc"
      }
      currentState.sortedBy = value
      navigate(pathName, {
        state: currentState,
        replace: false,
      })
    },
    [state, pathName]
  )
  const handleSortIcon = React.useCallback(
    (value: string) => {
      if (value === state?.sortedBy) {
        let icon: IconType
        switch (state?.sortOrder) {
          case "asc":
            icon = "chevron_up"
            break
          default:
            icon = "chevron_down"
            break
        }
        return <Icon icon={icon} fontSize="24px" />
      }
      return null
    },
    [state, state?.sortedBy, state?.sortOrder]
  )

  return (
    <TokenRowLayout px={["16px", "24px"]}>
      <Box justifySelf="flex-start" height="48px" alignItems="center">
        <ResponsiveText
          fontWeight={500}
          line={["17px", "17px"]}
          dimension={["14px", "14px"]}
        >
          {t("markets.header_name")}
        </ResponsiveText>
      </Box>
      <HoverBox
        height="48px"
        alignItems="center"
        onClick={() => handleClick(t("markets.header_price"))}
      >
        <ResponsiveText
          fontWeight={500}
          line={["17px", "17px"]}
          dimension={["14px", "14px"]}
        >
          {t("markets.header_price")}
        </ResponsiveText>
        {handleSortIcon(t("markets.header_price"))}
      </HoverBox>
      <Box height="48px" alignItems="center">
        <ResponsiveText
          fontWeight={500}
          line={["17px", "17px"]}
          dimension={["14px", "14px"]}
        >
          {t("markets.header_grafico")}
        </ResponsiveText>
      </Box>
      <HoverBox
        height="48px"
        alignItems="center"
        onClick={() => handleClick(t("markets.header_variation"))}
      >
        <ResponsiveText
          fontWeight={500}
          line={["17px", "17px"]}
          dimension={["14px", "14px"]}
        >
          {t("markets.header_variation")}
        </ResponsiveText>
        {handleSortIcon(t("markets.header_variation"))}
      </HoverBox>
      <HoverBox
        height="48px"
        alignItems="center"
        onClick={() => handleClick(t("markets.header_market_cap"))}
      >
        <ResponsiveText
          fontWeight={500}
          line={["17px", "17px"]}
          dimension={["14px", "14px"]}
        >
          {t("markets.header_market_cap")}
        </ResponsiveText>
        {state?.sortedBy ? (
          handleSortIcon(t("markets.header_market_cap"))
        ) : (
          <Icon icon={"chevron_down"} fontSize="24px" />
        )}
      </HoverBox>
      <HoverBox
        height="48px"
        alignItems="center"
        onClick={() => handleClick(t("markets.header_volume_24"))}
      >
        <ResponsiveText
          fontWeight={500}
          line={["17px", "17px"]}
          dimension={["14px", "14px"]}
        >
          {t("markets.header_volume_24")}
        </ResponsiveText>
        {handleSortIcon(t("markets.header_volume_24"))}
      </HoverBox>
      <Box height="48px" alignItems="center" justifySelf="flex-end" mr="25%">
        <ResponsiveText
          fontWeight={500}
          line={["17px", "17px"]}
          dimension={["14px", "14px"]}
        >
          {t("markets.header_trade")}
        </ResponsiveText>
      </Box>
    </TokenRowLayout>
  )
}
