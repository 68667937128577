import { createGAEvent } from "../../../utils/general"
import isEmpty from "lodash/isEmpty"
export const getLocalePostFormattedDate = (
  date: string,
  lng: string,
  dateOptions = {
    month: "long",
    year: "numeric",
    day: "numeric",
  }
) => {
  let postRowDate: string | Date | string[] = new Date(Date.parse(date))

  postRowDate = new Intl.DateTimeFormat(
    lng.toLocaleLowerCase(),
    dateOptions as any
  ).format(postRowDate)

  postRowDate = postRowDate.split(" ")

  let rawFormattedData = `${postRowDate[0]} ${postRowDate[1]}, ${postRowDate[2]}`
  if (lng === "en") {
    return rawFormattedData.replace(",", "")
  }

  return rawFormattedData
}

export const triggerSaveTheChildrenEvent = () =>
  createGAEvent("stc_click_banner", "banner")()

type TRX = Pick<Queries.WpPost, "translations">
type ArticleProps = {
  readonly databaseId: number
  readonly translations: ReadonlyArray<{
    readonly databaseId: number
  } | null> | null
}

export const isBannerVisible = (
  id: number,
  articles: readonly ArticleProps[]
) => {
  if (isEmpty(articles)) return null
  return articles.some(
    node =>
      node?.databaseId === id ||
      node?.translations?.some(trx => trx?.databaseId === id)
  )
}
