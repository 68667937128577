import { Box, Button, HStack } from "@youngagency/young-ui"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import { Container } from "../../../components/layout"
import { Text } from "../../../components/texts/temporary"
import { navigate } from "@reach/router"
import { dynamicLinks } from "../constants"
import {  clubsEvents, globalEvents } from "../../../service/tracking"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"

const events = [
  {
    eventName: clubsEvents.club_body_standard,
  },
  {
    eventName: globalEvents.click_to_exchange,
  },
]

export const MiddleConversion = () => {
  const { t } = useTranslation()
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)

  const handleOnClick = React.useCallback(() => {
    if(gaTracker) gaTracker.trackMultipleEvents(events)
    navigate(dynamicLinks.main)
  }, [gaTracker])
  return (
    <Container size={["md", "lg"]}>
      <Box maxWidth={"700px"} mx="auto">
        <Text.H3
          fontWeight={600}
          textAlign="center"
          as="h2"
          dangerouslySetInnerHTML={{
            __html: t("clubs_page.middle_conversion.title"),
          }}
        />
      </Box>
      <Box mt={["16px", "24px"]} maxWidth={"700px"} mx="auto">
        <Text.Body1
          fontWeight={600}
          textAlign="center"
          color="var(--grey-200-v2)"
        >
          {t("clubs_page.middle_conversion.description")}
        </Text.Body1>
      </Box>
      <HStack mt={["16px", "24px"]} mx="auto" justify="center">
        <Button size="md" onClick={handleOnClick}>
          {t("clubs_page.middle_conversion.button")}
        </Button>
      </HStack>
    </Container>
  )
}
