import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"

const initialState = {
  open: false,
  onOpen: () => {},
  onComplete: () => {},
  onClose: () => {},
}

type State = typeof initialState

const SmartDCAContext = React.createContext<State>(initialState)

export const SmartDCAProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { navigate } = useI18next()
  const [open, setOpen] = React.useState(false)
  const onClose = React.useCallback(() => {
    setOpen(false)
  }, [])

  const onOpen = React.useCallback(() => {
    setOpen(true)
  }, [])

  const onComplete = React.useCallback(() => {
    navigate("/newsletter-completed")
  }, [navigate])

  const ctx = React.useMemo(() => ({ open, onOpen, onClose, onComplete }), [
    open,
    onOpen,
    onClose,
    onComplete,
  ])

  return (
    <SmartDCAContext.Provider value={ctx}>{children}</SmartDCAContext.Provider>
  )
}

export const useSmartDCAContext = () => React.useContext(SmartDCAContext)
