import React from "react"
import {
  Box,
  VStack,
  Paragraph,
  BoxProps,
  HStack,
  Heading,
  Icon,
} from "@youngagency/young-ui"
import { I18nextContext, useI18next } from "gatsby-plugin-react-i18next"
import LanguageSelector from "./LanguageSelector"
import styled from "styled-components"
import ExtendedLogo from "./images/footer-white-logo.svg"
import GooglePlayStore from "./images/google-store.svg"
import { SeoLink } from "../../feature/seo"
import { useFooterContext } from "./context"

const StyledBox = styled(Box)`
  @media screen and (max-width: 315px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    column-gap: unset;
  }

  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  row-gap: 16px;

  .last-grid-element {
    grid-row-end: 4;
  }

  @media screen and (min-width: 992px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: unset;

    .last-grid-element {
      grid-row-end: unset;
    }
  }
`

const FooterTextBox = styled(Box)`
  display: flex;
  flex-direction: column-reverse;

  & > *:not(:first-of-type) {
    margin-bottom: 16px;
  }
  @media screen and (min-width: 1140px) {
    flex-direction: row;
    align-items: center;
    & > *:not(:first-of-type) {
      margin-left: 20px;
      margin-bottom: 0px;
    }
    .footer-copy {
      min-width: 832px;
    }

    .iubenda-cs-preferences-link {
      cursor: pointer;
    }
  }
`

type Props = {
  variant?: "light" | "dark"
} & BoxProps

const FooterInfo = ({ variant, ...rest }: Props) => {
  const { t } = useI18next()
  const { language } = React.useContext(I18nextContext)
  const { appleLink, playStoreLink } = useFooterContext()

  const color = variant === "light" ? "black" : "grey.1"
  return (
    <Box {...rest}>
      <VStack align="initial" spacing="18px">
        <StyledBox>
          <img src={ExtendedLogo} alt="footer-logo" />
          <LanguageSelector pageLanguage={language} variant="light" />
          <HStack justify="start" align="center" spacing="16px">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              height="49px"
            >
              <Heading
                fontWeight={600}
                size="sm"
                fontFamily="Matter"
                color={color}
              >
                {t("footer.download")}
              </Heading>
            </Box>
            <SeoLink
              isConversion
              isExternal={true}
              link={appleLink}
              rel="nofollow"
            >
              <Icon icon="apple" fontSize="18px" color={color} />
            </SeoLink>
            <SeoLink
              isConversion
              isExternal={true}
              link={playStoreLink}
              rel="nofollow"
            >
              <img
                src={GooglePlayStore}
                height={18}
                width={18}
                alt="play-store-logo"
              />
            </SeoLink>
          </HStack>

          <HStack
            justify="start"
            align="center"
            spacing="16px"
            className="last-grid-element"
          >
            <SeoLink
              isExternal={true}
              rel="noopener"
              target="_blank"
              link={t("footer.social.twitter")}
            >
              <Icon icon="twitter" fontSize="24px" color={color} />
            </SeoLink>
            <SeoLink
              isExternal={true}
              rel="noopener"
              target="_blank"
              link={t("footer.social.facebook")}
            >
              <Icon icon="facebook" fontSize="24px" color={color} />
            </SeoLink>
            <SeoLink
              isExternal={true}
              rel="noopener"
              target="_blank"
              link={t("footer.social.instagram")}
            >
              <Icon icon="instagram" fontSize="24px" color={color} />
            </SeoLink>
            <SeoLink
              isExternal={true}
              rel="noopener"
              target="_blank"
              link={t("footer.social.tiktok")}
            >
              <Icon icon="tiktok" fontSize="24px" color={color} />
            </SeoLink>
            <SeoLink
              isExternal={true}
              rel="noopener"
              target="_blank"
              link={t("footer.social.youtube")}
            >
              <Icon icon="youtube" fontSize="24px" color={color} />
            </SeoLink>
            <SeoLink
              aria-label="Discord"
              isExternal={true}
              rel="noopener"
              target="_blank"
              link={"https://discord.gg/xsb3Ty2Y8D"}
            >
              <Icon icon="discord" fontSize="24px" color={color} />
            </SeoLink>
          </HStack>
        </StyledBox>
        <FooterTextBox>
          <Box className="footer-copy">
            <Paragraph size="xs" color={color} fontFamily="Matter">
              {new Date().getFullYear()} {t("footer.top_copyright")}-
              {t("footer.sub_copyright")}
            </Paragraph>
          </Box>
          <Box
            minWidth="271px"
            className="iubenda-cs-preferences-link"
            aria-label="Handle Cookie"
            role="button"
          >
            <Paragraph size="xs" color={color} fontFamily="Matter">
              {t("footer.cookie_text")}
            </Paragraph>
          </Box>
        </FooterTextBox>
      </VStack>
    </Box>
  )
}

export default FooterInfo
