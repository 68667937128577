import { Box, Button, Icon } from "@youngagency/young-ui"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import { Text } from "../../components/texts/temporary"
import { useLocalizedNavigate } from "../../hook/useLocalizedNavigate"
import { useHandlePersistUtm } from "../navigation/hooks/useHandlePersistUtm"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { globalEvents, homePageEvents } from "../../service/tracking"


export const FooterBanner = () => {
  const { t } = useTranslation()
  const navigateToExchange = useLocalizedNavigate()
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)

  const { handlePersistUtmCallback } = useHandlePersistUtm()

  return (
    <Box maxWidth="756px" mx="auto">
      <Text.H3 fontWeight={600} textAlign="center" as="h2" color="white">
        {t("homepage.footer.title")}
      </Text.H3>
      <Box mt={["16px", "24px"]} display="flex" justifyContent="center">
        <Button
          size="xl"
          rightIcon={<Icon icon="arrow_right" />}
          mx="auto"
          onClick={() => {
            if(gaTracker)
            gaTracker.trackMultipleEvents([
              {
                eventName: homePageEvents.home_footer_banner,
              },
              {
                eventName: globalEvents.click_to_exchange,
              },
            ])
            handlePersistUtmCallback({
              callback: () => navigateToExchange(),
            })
          }}
        >
          {t("homepage.footer.button")}
        </Button>
      </Box>
    </Box>
  )
}
