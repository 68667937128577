import React from "react"
import { DarkBanner } from "../../../../components/banner/ecosistema/DarkBanner"
import { VStack } from "@youngagency/ui"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { WhiteButton } from "../../../../components/button/Button"
import { Text } from "../../../../components/texts/temporary"
import { navigate } from "@reach/router"
import { googleScheduleMeet } from "../../constants"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux/store"
import { b2bEvents, globalEvents } from "../../../../service/tracking"
import { darkBannerText } from "./global.css"

export const Banner = () => {
  const { t } = useTranslation()

  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  const handleClick = React.useCallback(() => {
    gaTracker?.trackMultipleEvents([
      {
        eventName: b2bEvents.b2b_home_footer,
      },
      {
        eventName: globalEvents.contact_sales,
      },
    ])
    navigate(googleScheduleMeet)
  }, [gaTracker])
  return (
    <DarkBanner withImage background="grey700">
      <VStack
        spacing={24}
        align={"center"}
        justify="center"
        atoms={{
          width: "full",
          mx: "auto",
          height: "full",
          __zIndex: 1,
        }}
        style={{
          maxWidth: "748px",
        }}
      >
        <Text.H5
          color="white"
          textAlign={"center"}
          fontWeight={600}
          className={darkBannerText}
          dangerouslySetInnerHTML={{
            __html: t("clubs_page.b2b.footer.title"),
          }}
        />
        <WhiteButton size={48} onClick={handleClick}>
          {t("clubs_page.b2b.footer.button")}
        </WhiteButton>
      </VStack>
    </DarkBanner>
  )
}
