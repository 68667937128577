import styled, { StyledComponent } from "styled-components"
import {
  layout,
  typography,
  space,
  color,
  LayoutProps,
  TypographyProps,
  SpaceProps,
  ColorProps,
} from "styled-system"

type responsiveSize = string[]
type responsiveLineHeight = string[]

export type ResponsiveFont = {
  dimension: responsiveSize
  line: responsiveLineHeight
}

const StyledText: StyledComponent<
  "div",
  LayoutProps & TypographyProps & SpaceProps & ColorProps
> = styled("div")(layout, typography, space, color)

export const ResponsiveText = styled(StyledText)<
  ResponsiveFont & LayoutProps & TypographyProps & SpaceProps & ColorProps
>`
  font-family: "Matter";
  font-size: ${props => props.dimension[0]};
  line-height: ${props => props.line[0]};
  @media screen and (min-width: 767px) {
    font-size: ${props => props.dimension[1]};
    line-height: ${props => props.line[1]};
  }
`

ResponsiveText.defaultProps = {
  marginY: "0px",
}

export default ResponsiveText
