import React from "react"
import { GetTokenChartResponse } from "../../../redux/services"
import {
  Box,
  Chart,
  Heading,
  HStack,
  Paragraph,
  MouseEventParams,
  Label,
  styled,
  Skeleton,
  BoxProps,
  css,
  Icon,
} from "@youngagency/young-ui"
import { formatInternationalCurrency } from "../../../utils/general"
import { Avatar, ChartWrapper, RoundedButton } from "../../../components/layout"
import { useI18next } from "gatsby-plugin-react-i18next"
import { getPercentageColor } from "../utils"
import {
  DEFAULT_TIMEFRAME_LIST,
  DEFAULT_CANDLESTICK_VARIATION_INTERVAL,
  TimeFrameListKeys,
} from "../../../constants"
import moment from "moment"
import {
  changeChartType,
  changeTimeFrameSelected,
} from "../../../redux/reducers/uiSlice"
import { useDispatch, useSelector } from "react-redux"
import { useTheme } from "styled-components"
import { RootState } from "../../../redux/store"

const TimeFrameButton = styled(Box)<{ isActive: boolean }>(
  css({
    width: ["36px", "40px"],
    height: ["36px", "40px"],
    borderRadius: "50%",
    transition: "all 75 ease",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  ({ isActive }) => ({
    background: isActive ? "var(--light-gray-8)" : "white",
    ":hover": {
      cursor: "pointer",
      background: "var(--light-gray-7)",
    },
  })
)

type Props = {
  lineData?: GetTokenChartResponse["points"]
  variation?: GetTokenChartResponse["percentageChange"]
  currency: {
    name: string
    image: string
    color?: string
  }
  amountSymbol?: string
  dimension?: {
    width: number
    height: number
  }
  type: "line" | "candlestick"
  candlestickData: GetTokenChartResponse["points"]
}

export const TokenPageChart = React.memo(
  ({
    lineData,
    variation,
    currency,
    amountSymbol,
    dimension,
    candlestickData,
    type,
    ...rest
  }: Props & BoxProps) => {
    const { language, t } = useI18next()
    const dispatch = useDispatch()
    const { timeFrameSelected } = useSelector(
      (state: RootState) => state.uiStyle
    )
    const [selectedPrice, setSelectedPrice] = React.useState<string | null>(
      null
    )
    const [selectedTime, setSelectedTime] = React.useState<null | string>(null)
    const formattedSelectedTime = React.useMemo(() => {
      if (timeFrameSelected === "day") {
        const formatPattern = language === "it" ? "HH:mm" : "h:mm A"
        return moment(Number(selectedTime)).format(formatPattern)
      }
      return moment(Number(selectedTime)).format("DD/MM/YYYY")
    }, [timeFrameSelected, selectedTime, language])
    const tooltip = React.useRef<HTMLDivElement>(null)
    const prices = React.useMemo(() => {
      if (!lineData) return
      const values = lineData
        .map(el => el.value)
        .filter(value => Boolean(value))
      return { ath: Math.max(...values), atd: Math.min(...values) }
    }, [lineData])
    const handleCrosshairMove = React.useCallback(
      ({ time, point, seriesPrices }: MouseEventParams) => {
        if (!tooltip?.current) return

        const { width, height } = tooltip.current.getBoundingClientRect()
        const x = point?.x || NaN
        const y = point?.y || NaN
        if (!time || x < 0 || x >= width || y <= 0 || y >= height) {
          if (lineData && lineData.length > 0) {
            setSelectedPrice(lineData[lineData.length - 1].value.toFixed(2))
            setSelectedTime(null)
          }
          return null
        }

        let left = x - 60
        if (left > width - 120) {
          left = width - 120
        } else if (left < 0) {
          left = 0
        }

        if (!tooltip.current) {
          return
        }

        const prices = Array.from(seriesPrices.values())
        const priceObj = prices[prices.length - 1]
        const price = typeof priceObj === "number" ? priceObj : priceObj.close
        setSelectedPrice(price.toFixed(2))
        setSelectedTime(time.toString())
      },
      [lineData]
    )
    const theme = useTheme()
    const red300 = css({ color: "red.300" })(theme).color
    const green300 = css({ color: "green.300" })(theme).color
    const background = css({ color: "background" })(theme).color

    const timeFrameVariation = React.useMemo(() => {
      if (type === "line") {
        return DEFAULT_TIMEFRAME_LIST
      }
      return DEFAULT_CANDLESTICK_VARIATION_INTERVAL
    }, [])

    const areaData = React.useMemo(
      () => candlestickData.map(val => ({ ...val, value: val.volume })),
      [candlestickData]
    )

    const handleChange = React.useCallback(
      (key: TimeFrameListKeys) => {
        if (timeFrameSelected === key) return
        dispatch(changeTimeFrameSelected(key))
      },
      [dispatch, timeFrameSelected]
    )

    React.useEffect(() => {
      if (lineData) {
        setSelectedPrice(lineData[lineData.length - 1].value.toFixed(2))
      }
    }, [lineData])

    return (
      <ChartWrapper
        maxWidth={["100%", "463px"]}
        borderRadius="16px"
        p={["18px", "36px"]}
        border="1px solid"
        borderColor="grey.5"
        ref={tooltip}
        {...rest}
      >
        <Box mb={["18px", "24px"]}>
          <HStack justify="space-between" align="center">
            <Box>
              <Heading
                fontFamily="Matter"
                size="sm"
                color="grey.1"
                weight="heavy"
              >
                {currency.name}
              </Heading>
              {selectedPrice ? (
                <Heading fontFamily="Matter" size="xxxl">
                  {selectedPrice}
                  {" "}
                  {amountSymbol}
                </Heading>
              ) : (
                <Skeleton width="140px" height="24px" />
              )}
              {!variation ? (
                <Skeleton size="16px" width="50px" />
              ) : (
                <Paragraph
                  size="md"
                  fontFamily="Matter"
                  color={
                    selectedTime ? "inherit" : getPercentageColor(variation)
                  }
                  fontWeight={selectedTime ? "inherit" : 500}
                >
                  {selectedTime ? formattedSelectedTime : `${variation} %`}
                </Paragraph>
              )}
            </Box>
            <Avatar width="40px" height="40px">
              <img
                alt={currency.name}
                src={currency.image}
                width={40}
                height={40}
              />
            </Avatar>
          </HStack>
        </Box>
        {!prices ? (
          <Skeleton height="12px" width="80px" ml="auto" mb="8px" />
        ) : (
          <Label
            color="grey.1"
            fontFamily="Matter"
            textAlign="right"
            size="xs"
            mb="8px"
            fontWeight={400}
          >
            {prices.ath.toFixed(2)}
            {amountSymbol}
          </Label>
        )}
        {!currency || !lineData || !candlestickData ? (
          <Skeleton width="100%" height="0" paddingBottom="56.25%" />
        ) : (
          <Chart
            onCrosshairMove={handleCrosshairMove}
            lineSeries={{
              data: type === "line" ? lineData : [],
              options: {
                color: currency.color || "#00D272",
                visible: type === "line",
                lineWidth: 1,
                lastValueVisible: false,
                priceLineVisible: false,
              },
            }}
            candlestickSeries={{
              data: candlestickData,
              options: {
                priceLineVisible: false,
                visible: type === "candlestick",
                upColor: green300,
                downColor: red300,
                borderUpColor: green300,
                borderDownColor: red300,
                wickUpColor: green300,
                wickDownColor: red300,
              },
            }}
            histogramSeries={{
              data: areaData,
              options: {
                priceLineVisible: false,
                visible: type === "candlestick",
                priceScaleId: "",
                color: background,
                priceFormat: {
                  type: "volume",
                },

                scaleMargins: {
                  top: 0.75,
                  bottom: 0,
                },
              },
            }}
            options={{
              width: dimension?.width || 395,
              height: dimension?.height || 223,
              timeScale: {
                visible: false,
              },
              layout: {
                fontSize: 12,
                fontFamily: "Matter",
              },
              overlayPriceScales: {
                mode: 1,
              },
              grid: {
                horzLines: { visible: false },
                vertLines: { visible: false },
              },
              crosshair: {
                horzLine: {
                  color: "#fff",
                  style: 1,
                  visible: false,
                  labelVisible: false,
                },
                vertLine: {
                  color: "#fff",
                  style: 0,
                  visible: false,
                  labelVisible: false,
                },
              },
              leftPriceScale: {
                visible: false,
                scaleMargins: {
                  top: 0,
                  bottom: 0,
                },
              },
              rightPriceScale: {
                visible: false,
                scaleMargins: {
                  top: 0,
                  bottom: 0,
                },
                borderVisible: false,
                drawTicks: false,
                alignLabels: false,
              },

              handleScale: false,
              handleScroll: false,
            }}
          />
        )}

        {!prices ? (
          <Skeleton height="12px" width="80px" mt="8px" ml="auto" />
        ) : (
          <Label
            mt="8px"
            color="grey.1"
            fontFamily="Matter"
            textAlign="right"
            size="xs"
            fontWeight={400}
          >
            {prices.atd.toFixed(2)}
            {amountSymbol}
          </Label>
        )}
        <Box mt={["12px", "18px"]}>
          <HStack justify="space-between" align="center">
            <HStack
              spacing={["4px", "16px"]}
              justify="flex-start"
              align="center"
            >
              {timeFrameVariation.map(timeframe => {
                return (
                  <TimeFrameButton
                    role="button"
                    key={timeframe.key}
                    isActive={timeFrameSelected === timeframe.key}
                    onClick={() =>
                      handleChange(timeframe.key as TimeFrameListKeys)
                    }
                  >
                    <Label
                      fontFamily="Matter"
                      size="sm"
                      color="grey.1"
                      weight="heavy"
                    >
                      {t(timeframe.text)}
                    </Label>
                  </TimeFrameButton>
                )
              })}
            </HStack>
            <RoundedButton
              radius={"40px"}
              variant="ghost"
              size="sm"
              onClick={() => dispatch(changeChartType())}
            >
              <Icon
                icon={type === "candlestick" ? "linechart" : "candle_chart"}
                fontSize="24px"
                color="#000"
              />
            </RoundedButton>
          </HStack>
        </Box>
      </ChartWrapper>
    )
  }
)
