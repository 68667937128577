import { Body, Container, Heading, Box, Tag, VStack } from "@youngagency/ui"
import { Container as CustomContainer } from "../../../../components/layout"
import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { HorizontalCard } from "../../../../components/cards/components/HorizontalCard"
import { CardContent } from "../../../../components/cards/layout/CardContent"
import { CardContainer } from "../../../../components/cards/layout/CardContainer"
import { CardImage } from "../../../../components/cards/layout/CardImage"
import {
  autoHeightHCard as autoHeightCard,
  cardSmallLogo,
  unshirkedHCard as unshirkedCard,
} from "../../../../components/cards/style/card.css"
import { StaticImage } from "gatsby-plugin-image"
import { CardSlider } from "../../../../components/cards/components/CardSlider"
import { customAdvocacyCard } from "../../styles/crypto-hub.css"
import clsx from "clsx"
import { spacer } from "../../../security/styles/slider-section.css"
import { navigate } from "gatsby"
import { SeoLink } from "../../../seo"

export const AdvocacySection = () => {
  const { t } = useI18next()

  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              color="white"
              dangerouslySetInnerHTML={{
                __html: t("business_user.advocacy_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              color="grey200"
              style={{
                maxWidth: "700px",
              }}
              fontWeight={600}
            >
              {t("business_user.advocacy_section.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>

      <CardSlider
        marginTop={{
          mobile: 24,
          md: 32,
        }}
        style={{ padding: "8px 24px" }}
      >
        <HorizontalCard disableHover hasShadow shadowVariant="light">
          <CardContainer
            width={"full"}
            className={clsx(autoHeightCard, unshirkedCard)}
          >
            <SeoLink
              isExternal={true}
              target="_blank"
              rel="nofollow"
              link={"https://www.youtube.com/watch?v=ARDclcQ4GN8"}
            >
              <CardContent
                width={"full"}
                height={"full"}
                className={customAdvocacyCard}
              >
                <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} height="full">
                  <StaticImage
                    quality={100}
                    outputPixelDensities={[1, 2]}
                    className={cardSmallLogo}
                    src="../../images/business-user/youngers.png"
                    alt={"youngers-logo"}
                  />
                  <VStack spacing={[8, 4]} style={{ zIndex: "1" }}>
                    <Tag colorScheme="grey" size={[24, 16]} style={{ borderRadius: "8px", width: "fit-content" }}>
                      {t("business_user.advocacy_section.card1.label")}
                    </Tag>
                    <Heading as="h3" variant="6" fontWeight={600} color={"white"}
                      dangerouslySetInnerHTML={{
                        __html: t("business_user.advocacy_section.card1.title"),
                      }}
                      style={{
                        whiteSpace: "break-spaces",
                        textTransform: "uppercase",
                      }} />
                  </VStack>
                </Box>
                <CardImage height="100%" style={{ zIndex: "-1" }} absolute>
                  <StaticImage
                    quality={100}
                    outputPixelDensities={[1, 2]}
                    style={{
                      height: "100%",
                    }}
                    src="../../images/business-user/advocacy_1.jpg"
                    alt={t("business_user.advocacy_section.card1.label")}
                  />
                </CardImage>
              </CardContent>
            </SeoLink>
          </CardContainer>
        </HorizontalCard>
        <HorizontalCard disableHover hasShadow shadowVariant="light">
          <CardContainer
            width={"full"}
            className={clsx(autoHeightCard, unshirkedCard)}
          >
            <SeoLink
              isExternal={true}
              target="_blank"
              rel="nofollow"
              link={"https://www.youtube.com/watch?v=xmpqbb6aIyQ"}
            >
              <CardContent
                width={"full"}
                height={"full"}
                className={customAdvocacyCard}
              >
                <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} height="full">
                  <StaticImage
                    quality={100}
                    outputPixelDensities={[1, 2]}
                    className={cardSmallLogo}
                    src="../../images/business-user/youngers.png"
                    alt={"youngers-logo"}
                  />
                  <VStack spacing={[8, 4]} style={{ zIndex: "1" }}>
                    <Tag colorScheme="grey" size={[24, 16]} style={{ borderRadius: "8px", width: "fit-content" }}>
                      {t("business_user.advocacy_section.card2.label")}
                    </Tag>
                    <Heading as="h3" variant="6" fontWeight={600} color={"white"}
                      dangerouslySetInnerHTML={{
                        __html: t("business_user.advocacy_section.card2.title"),
                      }}
                      style={{
                        whiteSpace: "break-spaces",
                        textTransform: "uppercase",
                      }} />
                  </VStack>
                </Box>
                <CardImage height="100%" style={{ zIndex: "-1" }} absolute>
                  <StaticImage
                    quality={100}
                    outputPixelDensities={[1, 2]}
                    style={{
                      height: "100%",
                    }}
                    src="../../images/business-user/advocacy_2.jpg"
                    alt={t("business_user.advocacy_section.card2.label")}
                  />
                </CardImage>
              </CardContent>
            </SeoLink>
          </CardContainer>
        </HorizontalCard>
        <Box className={spacer} />
      </CardSlider >
    </>
  )
}
