import React from "react"
import { navigate } from "gatsby"
import { useHasMounted } from "@youngagency/young-ui"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import { AlternateHandler, MetaDecorator } from "../feature/seo"
import { TranslationProvider } from "../feature/translations/context"

const NotFoundPage = () => {
  const hasMounted = useHasMounted()
  const { t } = useTranslation()
  const { language } = useI18next()
  const handleRedirect = React.useCallback(() => {
    if (!hasMounted) return
    let link = "/"
    if (language !== "it") link = link + language + "/"

    navigate(link)
  }, [hasMounted, language])

  React.useEffect(() => {
    handleRedirect()
  }, [handleRedirect])
  return (
    <>
      <TranslationProvider>
        <MetaDecorator
          metaTitle={t("404.seo.title")}
          metaDescription={t("404.seo.description")}
        />
        <AlternateHandler />
      </TranslationProvider>
    </>
  )
}

export default NotFoundPage
