import React from "react"
import styled from "styled-components"
import { SeoLink } from "../../feature/seo"

const CryptoIconContainer = styled.div`
  width: 137px;
  height: 137px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f4f5f7;
  transition: 0.3s;
  animation: pulse-icon 0.3s alternate;
  &:hover {
    > img {
      filter: brightness(1000%);
    }
    animation: pulse-icon 0.3s alternate;
  }
  &.hide-icon-mobile {
    display: flex;
  }
  &:hover {
    background-color: ${props => props.color};
  }
  @media screen and (max-width: 768px) {
    &.hide-icon-mobile {
      display: none;
    }
    &.show-icon-mobile {
      width: 69px;
      height: 69px;
    }
  }
`

const CryptoIconImage = styled.img`
  width: 100%;
  height: auto;
  margin: auto;
  object-fit: contain;
  transition: 0.3s;
  ${props => (props.isActive ? "filter: brightness(1000%);" : "")}
  @media screen and (max-width: 768px) {
    width: 60%;
    height: 60%;
  }
`

const CryptoIcon = ({ icon, color, symbol }) => {
  return (
    <SeoLink link={`/exchange/${symbol.toLowerCase()}/`} isExternal={false}>
      <CryptoIconContainer
        isActive
        key={"card-" + symbol}
        color={color}
        className="show-icon-mobile"
      >
        <CryptoIconImage
          alt="crypto"
          style={{ pointerEvents: "none" }}
          src={icon}
          key={symbol + "icon"}
        />
      </CryptoIconContainer>
    </SeoLink>
  )
}

export default CryptoIcon
