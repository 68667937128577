import React from "react"
import "../landing.css"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Box } from "@youngagency/young-ui"
import FaqsCards from "./FaqCards"
import { Container, ResponsiveText, ResponsiveButton } from "../layout"
import { SeoLink } from "../../feature/seo"

const FaqsPage = () => {
  const { t } = useI18next()
  return (
    <Container size="fullwidth">
      <Container
        position="relative"
        display="flex"
        flexDirection="column"
        size="lg"
      >
        <Container size="md" textAlign="center" mt={["24px", "48px"]}>
          <Box>
            <ResponsiveText
              line={["39px", "96px"]}
              fontWeight={700}
              dimension={["34px", "96px"]}
              as="h1"
              textAlign="center"
            >
              {t("faqs_page.title")}
            </ResponsiveText>
          </Box>
          <Box mt={["24px", "48px"]}>
            <ResponsiveText
              fontWeight={500}
              line={["19px", "25px"]}
              dimension={["16px", "20px"]}
              textAlign="center"
              as="h4"
            >
              {t("faqs_page.description")}
            </ResponsiveText>
          </Box>
        </Container>
        <Box mt={["48px", "96px"]}>
          <FaqsCards />
        </Box>
      </Container>
      <Box py={["48px", "96px"]} mt={["52px", "96px"]} backgroundColor="grey.4">
        <Container>
          <Box maxWidth={["344px", "756px"]} mx="auto">
            <ResponsiveText
              textAlign="center"
              line={["30px", "50px"]}
              dimension={["24px", "48px"]}
              fontWeight={700}
            >
              {t("faqs_page.footer.title")}
            </ResponsiveText>
          </Box>
          <Box display="flex" justifyContent="center" mt={["16px", "24px"]}>
            <SeoLink
              isExternal
              isConversion
              link={"https://exchange.youngplatform.com/signup"}
              rel="noopener"
              target="_blank"
            >
              <ResponsiveButton size="lg" variant="primary" isRounded>
                {t("faqs_page.footer.cta")}
              </ResponsiveButton>
            </SeoLink>
          </Box>
        </Container>
      </Box>
    </Container>
  )
}

export default FaqsPage
