import React, { useEffect } from "react"
import styled from "styled-components"
import { graphql, PageProps } from "gatsby"
import {
  Container,
  BottomBanner,
  ResponsiveText,
} from "../../../components/layout"
import { getAuthorLogo } from "../../../components/blog/utils/helpers"
import {
  Stack,
  Label,
  Display,
  Paragraph,
  Box,
  Skeleton,
  useHasMounted,
  VStack,
  HStack,
} from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import { capitalizeFirstLetter } from "../../../utils/helpers"

import AuthorBox from "../../../components/blog/shared/AuthorBox"
import { FromStoreBtn, SinglePageHero } from "../../../components/shared"
import ArticleWrapper from "../../../components/blog/shared/ArticleWrapper"
import { handleWpBannerSpaceInjection } from "../../../components/blog/utils/helpers"
import { SocialShareProps } from "../../../types/shared"
import {
  MetaDecorator,
  ArticleSchema,
  AlternateHandler,
  SeoLink,
} from "../../../feature/seo"
import { BlogLayout, BreadCrumb } from "../../../feature/navigation/components"
import { PostType } from "../../../feature/blog/types"
import {
  ArticlePromoBanner,
  RelatedPostSection,
} from "../../../feature/blog/components"
import { getLocalePostFormattedDate } from "../../../feature/blog/utils"
import { breadCrumbProps } from "../../../feature/seo/types"
import { ArticleTranslator } from "../../../feature/translations/components/Translator"
import { TranslationProvider } from "../../../feature/translations/context"
import { FreenameBanner } from "../../../feature/blog/components/banner/FreenameBanner"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { blogArticleEvents, globalEvents } from "../../../service/tracking"
import { NewsLetterPopUp } from "../../../feature/promo/components/NewsletterPopUp"

const TextContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  .post-link {
    transition: all 125ms ease-in-out;
    width: fit-content;
    text-decoration-color: #00d272 !important;
    &:hover {
      color: var(--green-hover) !important;
      text-decoration: underline !important;
      text-decoration-color: #00d272 !important;
    }
  }
  [data-style="post-title"] {
    font-size: 48px !important;
    line-height: 50px !important;
  }
  [data-style="author-title"] {
    font-size: 14px !important;
    line-height: 17px !important;
  }
  @media screen and (max-width: 767px) {
    [data-style="post-title"] {
      font-size: 25px !important;
      line-height: 30px !important;
    }
    [data-style="author-title"] {
      font-size: 14px !important;
      line-height: 18px !important;
    }
  }
`

const Point = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #65646b;
`

type PageContext = { id: number }

const SinglePost: React.FC<
  PageProps<Queries.BlogArticleQuery, PageContext>
> = ({ data, pageContext }) => {
  const hasMounted = useHasMounted()
  const { t, language } = useI18next()
  const {
    allWpPost: { nodes: postsQuery },
  } = data

  const { wpPost } = data
  const {
    title,
    content,
    excerpt,
    seo,
    slug,
    date,
    modified,
    featuredImage,
    categories,
    translations,
    author,
    databaseId,
  } = wpPost || {}

  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  const url =
    featuredImage?.node?.mediaItemUrl ||
    "https://husmen.xyz/portfolio/kafka-stream/featured.png"
  const altText = featuredImage?.node?.altText || "Immagine Articolo Default"

  const social: SocialShareProps[] | undefined = React.useMemo(() => {
    if (!title) return
    return [
      {
        icon: "twitter",
        url: "",
        via: "youngplatform",
        title: title,
      },
      {
        icon: "facebook",
        url: "",
        quote: title,
      },
      {
        icon: "linkedin",
        url: "",
        title: title,
        summary: title,
      },
    ]
  }, [])

  //Fallback da usare quando autore non ha avatar
  const authorLogo = React.useMemo(() => {
    if (!author?.node?.name) return
    return getAuthorLogo(author.node.name)
  }, [!author?.node?.name])
  const breadcrumb = React.useMemo(() => {
    if (!categories?.nodes?.[0]?.slug) return
    return [
      {
        text: "Home",
        link: "/",
      },

      {
        text: "Blog",
        link: "/blog/",
      },
      {
        text: capitalizeFirstLetter(categories.nodes[0].name),
        link: `/blog/${categories.nodes[0].slug}/`,
      },
      {
        text: title,
        link: `/blog/${categories.nodes[0].slug}/${slug}/`,
      },
    ]
  }, [categories?.nodes?.[0]?.slug])

  const image = React.useMemo(
    () => getImage(featuredImage?.node?.localFile as ImageDataLike),
    [featuredImage?.node?.localFile]
  )

  const attachConversionTrigger = React.useMemo(() => {
    if (!hasMounted) return
    const articleBody = document.querySelector(".post-container")
    if (!articleBody) return
    const ctas = articleBody.querySelectorAll(
      "[href*='youngexchange.page.link']"
    )
    if (!ctas) return
    return Array.from(ctas) as HTMLAnchorElement[]
  }, [hasMounted])

  const ctx = React.useMemo(() => {
    const articleTrx = new ArticleTranslator(translations)
    const trx = articleTrx.create(
      language,
      wpPost?.categories?.nodes?.[0]?.slug,
      slug
    )
    return {
      translations: trx,
      disableTranslations: !articleTrx.hasTranslation(translations),
    }
  }, [language, wpPost?.categories?.nodes?.[0]?.slug, slug, translations])

  const triggerConversionBannerEvents = React.useCallback(() => {
    if (!gaTracker) return
    gaTracker.trackMultipleEvents([
      {
        eventName: blogArticleEvents.body_banner,
      },
      {
        eventName: globalEvents.click_to_exchange,
      },
    ])
  }, [gaTracker])

  React.useEffect(() => {
    if (!attachConversionTrigger) return
    attachConversionTrigger.forEach(el =>
      el.addEventListener("click", triggerConversionBannerEvents)
    )
    return () =>
      attachConversionTrigger.forEach(el =>
        el.removeEventListener("click", triggerConversionBannerEvents)
      )
  }, [attachConversionTrigger, triggerConversionBannerEvents])

  useEffect(
    () =>
      handleWpBannerSpaceInjection(".banner-title-text", [
        "su young platform",
        "on young platform",
      ]),
    []
  )

  return (
    <TranslationProvider value={ctx}>
      <BlogLayout
        disableFooterMargin
        slug={categories?.nodes?.[0]?.slug}
        dynamicLink={"https://youngexchange.page.link/blog3"}
      >
        <ArticleSchema
          title={seo?.title || (title as string)}
          image={url}
          description={
            seo?.metaDesc ||
            (excerpt as string)
              .replace(/<\/?[^>]+(>|$)/g, "")
              .replace(/(<p>&nbsp;<\/p>)|(<h2><span>&nbsp;<\/span><\/h2>)/g, "")
          }
          imageAltText={altText}
          publishDate={date}
          modifiedDate={modified}
          breadcrumbs={breadcrumb || ([] as breadCrumbProps[])}
          authorName={author?.node?.name}
        />
        <MetaDecorator
          image={url}
          imageHeight={288}
          imageWidth={520}
          metaTitle={seo?.title || title}
          metaDescription={
            seo?.metaDesc ||
            (excerpt as string)
              .replace(/<\/?[^>]+(>|$)/g, "")
              .replace(/(<p>&nbsp;<\/p>)|(<h2><span>&nbsp;<\/span><\/h2>)/g, "")
          }
        />
        <AlternateHandler />
        {breadcrumb ? (
          <Container size={["md", "lg"]}>
            <BreadCrumb
              breadcrumbs={breadcrumb}
              removeLast
              // mt={["0px", "40px"]}
            />
          </Container>
        ) : null}
        <Box mt={["34px", "57px"]}>
          <SinglePageHero
            fallbackLink={
              categories?.nodes?.[0]?.slug
                ? `/blog/${categories?.nodes?.[0]?.slug}/`
                : "/blog/"
            }
            social={social}
            isBlogPost
          >
            <Container size={["md"]}>
              <TextContainer size={["fullwidth", "md"]} my="0px">
                <VStack align="start" spacing="24px">
                  {categories?.nodes?.[0]?.slug && categories.nodes[0].name ? (
                    <SeoLink
                      onClick={() => {
                        if (gaTracker) {
                          gaTracker.trackEvent({
                            eventName: blogArticleEvents.hero_category,
                          })
                        }
                      }}
                      isExternal={false}
                      className="post-link"
                      link={`/blog/${categories.nodes[0].slug}/`}
                    >
                      <Label
                        fontFamily="Matter"
                        size="md"
                        color="green.300"
                        weight="heavy"
                        as="h2"
                        my="0px"
                      >
                        {categories.nodes[0].name}
                      </Label>
                    </SeoLink>
                  ) : null}
                  <Display
                    as="h1"
                    fontFamily="Matter"
                    data-style="post-title"
                    fontWeight={600}
                  >
                    {title}
                  </Display>
                  <HStack spacing="8px" align="center" justify="start">
                    {authorLogo ? (
                      <Box display={["none", "block"]}>
                        <AuthorBox>
                          <Paragraph fontSize="md" fontFamily="Matter">
                            {authorLogo}
                          </Paragraph>
                        </AuthorBox>
                      </Box>
                    ) : null}
                    {author?.node?.name ? (
                      <Label
                        fontFamily="Matter"
                        data-style="author-text"
                        as="h5"
                        my="0px"
                        ml={["0px", "6px"]}
                      >
                        {author?.node?.name}
                      </Label>
                    ) : null}
                    <Point />
                    {date ? (
                      <Label
                        data-style="author-text"
                        fontFamily="Matter"
                        as="time"
                        my="0px"
                      >
                        {getLocalePostFormattedDate(date, language)}
                      </Label>
                    ) : null}

                    <Point />

                    <Label
                      data-style="author-text"
                      fontFamily="Matter"
                      color="#65646b"
                      as="p"
                      my="0px"
                    >
                      {seo?.readingTime
                        ? `${seo?.readingTime} min`
                        : t(`blog.post.readtime`)}
                    </Label>
                  </HStack>
                </VStack>
              </TextContainer>
              <Container size="fullwidth" mt={["24px", "48px"]} pb={"0px"}>
                <Stack
                  margin="auto"
                  justify="center"
                  borderRadius="16px"
                  overflow="hidden"
                  className="gatsby-safari-border-radius"
                >
                  <Box
                    maxWidth="950px"
                    height="100%"
                    style={{
                      transform: "translateZ(0)",
                    }}
                  >
                    {image ? (
                      <GatsbyImage
                        className="gatsby-safari-border-radius"
                        style={{
                          margin: "auto",
                          borderRadius: "16px",
                        }}
                        image={image}
                        alt={altText}
                      />
                    ) : (
                      <Skeleton width="100%" height="0" pb="56.25%" />
                    )}
                  </Box>
                </Stack>
                {content ? (
                  <ArticleWrapper
                    mt={["24px", "48px"]}
                    spacing="24px"
                    align="start"
                    justify="center"
                    className="post-container"
                    dangerouslySetInnerHTML={{
                      __html: content.replace(
                        /(<p>&nbsp;<\/p>)|(<h2><span>&nbsp;<\/span><\/h2>)/g,
                        ""
                      ),
                    }}
                  />
                ) : null}
                {databaseId || pageContext.id ? (
                  <>
                    <ArticlePromoBanner id={databaseId || pageContext.id} />
                    <FreenameBanner id={databaseId || pageContext.id} />
                  </>
                ) : null}
              </Container>
            </Container>
          </SinglePageHero>
        </Box>

        <Box mt={["48px", "124px"]}>
          <Container size={["md", "lg"]}>
            <ResponsiveText
              line={["22px", "29px"]}
              dimension={["18px", "24px"]}
              as="h2"
              fontWeight={600}
              color="inherit"
              style={{
                textTransform: "capitalize",
              }}
            >
              {t(`blog.post.relatedSection`)}
            </ResponsiveText>
          </Container>
          <Box mt="24px">
            <RelatedPostSection
              posts={postsQuery as readonly PostType[]}
              disableTitle
            />
          </Box>
        </Box>
        <BottomBanner
          text={t("download-app.title")}
          background="#0084be"
          lightText
        >
          <FromStoreBtn
            className="responsive-download-banner"
            appleLink="https://youngexchange.page.link/blog1"
            playLink="https://youngexchange.page.link/blog2"
            event={() => {
              if (gaTracker) {
                gaTracker.trackEvent({
                  eventName: blogArticleEvents.blog_article_footer,
                })
              }
            }}
          />
        </BottomBanner>
      </BlogLayout>
      {/* <NewsLetterPopUp /> */}
    </TranslationProvider>
  );
}

export const postQuery = graphql`query BlogArticle($id: Int!, $language: String!, $pageLanguage: WpLanguageCodeEnum!) {
  wpPost(databaseId: {eq: $id}) {
    title
    databaseId
    slug
    seo {
      metaDesc
      title
      readingTime
    }
    content
    date
    excerpt
    modified
    author {
      node {
        name
      }
    }
    tags {
      nodes {
        slug
      }
    }
    featuredImage {
      node {
        mediaItemUrl
        altText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    categories {
      nodes {
        name
        slug
        databaseId
      }
    }
    translations {
      categories {
        nodes {
          slug
        }
      }
      slug
      language {
        code
        locale
      }
    }
  }
  allWpPost(
    limit: 3
    sort: {date: DESC}
    filter: {language: {code: {eq: $pageLanguage}}, status: {eq: "publish"}, databaseId: {ne: $id}}
  ) {
    nodes {
      databaseId
      slug
      modified
      date
      seo {
        metaDesc
        title
        readingTime
      }
      featuredImage {
        node {
          altText
          mediaItemUrl
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      tags {
        nodes {
          databaseId
          name
          slug
        }
      }
      title
      categories {
        nodes {
          slug
          name
          databaseId
        }
      }
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}`

export default SinglePost
