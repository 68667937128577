import React from "react"
import {
  WebPageSchema,
  AlternateHandler,
  MetaDecorator,
} from "../../feature/seo"
import { Box } from "@youngagency/young-ui"
import { Container, ResponsiveButton } from "../../components/layout"
import { graphql, PageProps } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Helmet } from "react-helmet"
import { LandingLayout, BreadCrumb } from "../../feature/navigation/components/"
import { HeadingXL, Heading, Description } from "../../components"
import { LegalProvider } from "./context"
import { CategorySelector, LegalDocs } from "./components"
import { TranslationProvider } from "../../feature/translations/context"

const Home: React.FC<PageProps<Queries.LegalHomePageQuery>> = ({ data }) => {
  const { t } = useI18next()

  const breadCrumbs = React.useMemo(() => {
    return [
      {
        text: "Home",
        link: "/",
      },
      {
        text: t("legal.title"),
        link: "/legal",
      },
    ]
  }, [t])

  return (
    <TranslationProvider>
      <LandingLayout
        disableFooterMargin
        dinamycLink="https://youngexchange.page.link/lpM"
      >
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <MetaDecorator metaTitle={t("legal.title")} />
        <WebPageSchema
          title={t("legal.title")}
          description={t("legal.description")}
          breadcrumbs={breadCrumbs}
        />
        <AlternateHandler />
        <Container size={["md", "lg"]}>
          <BreadCrumb breadcrumbs={breadCrumbs} />
        </Container>
        <LegalProvider docs={data.allWpLegalDocs.nodes}>
          <Box mt={["24px", "48px"]}>
            <Container size={["md", "lg"]}>
              <HeadingXL as="h1">{t("legal.title")}</HeadingXL>
              <Box mt={["16px", "24px"]}>
                <Description as="p">{t("legal.description")}</Description>
              </Box>
              <Box mt={["24px", "48px"]}>
                <CategorySelector />
              </Box>
              <Box mt={["24px", "48px"]}>
                <Heading as="h3" weight="semibold">
                  {t("legal.heading")}
                </Heading>
              </Box>
            </Container>
          </Box>
          <LegalDocs />
        </LegalProvider>
        <Box
          py={["48px", "96px"]}
          mt={["52px", "96px"]}
          backgroundColor="grey.4"
        >
          <Container size={["md", "lg"]}>
            <Box maxWidth={["344px", "756px"]} mx="auto">
              <Heading textAlign="center" weight="semibold">
                {t("legal.footer_title")}
              </Heading>
            </Box>
            <Box display="flex" justifyContent="center" mt={["16px", "24px"]}>
              <a
                style={{
                  textDecoration: "none",
                }}
                href="https://support.youngplatform.com/hc/"
              >
                <ResponsiveButton size="xl" variant="primary" isRounded>
                  {t("legal.footer_cta")}
                </ResponsiveButton>
              </a>
            </Box>
          </Container>
        </Box>
      </LandingLayout>
    </TranslationProvider>
  )
}

export const query = graphql`query LegalHomePage($language: String!, $pageLanguage: WpLanguageCodeEnum!) {
  allWpLegalDocs(
    filter: {language: {code: {eq: $pageLanguage}}}
    sort: {legalFields: {order: ASC}}
  ) {
    nodes {
      databaseId
      slug
      title
      legalFields {
        icon
      }
      allLegalCategories {
        nodes {
          name
        }
      }
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}`

export default Home
