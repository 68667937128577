import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { useSeoData } from "./useSeoQuery"
import moment from "moment"
import { EventSchemaProps } from "../types"

export const useEventsSchema = ({
  theme,
  description,
  title,
  startDate,
  image,
}: EventSchemaProps) => {
  const { originalPath, language } = useI18next()
  const {
    site: { siteMetadata },
    languagePath,
  } = useSeoData()
  const url = React.useMemo(
    () => siteMetadata.siteUrl + languagePath + originalPath,
    [siteMetadata.siteUrl, languagePath, originalPath]
  )

  const eventSchema = React.useMemo(() => {
    return {
      "@type": "EducationEvent",
      "@id": url + "#events",
      "assesses": theme,
      "educationalLevel": "beginner",
      "teaches": description,
      "name": title,
      "eventStatus": "EventScheduled",
      "eventAttendanceMode": "OnlineEventAttendanceMode",
      "startDate": moment(startDate || "16/02/2022", "DD/MM/YYYY"),
      "endDate": moment("16/08/2022", "DD/MM/YYYY"),
      "location": {
        "@type": "VirtualLocation",
        "url": "https://youngplatform.com/crypto-attack",
      },
      "offers": {
        "areaServed": {
          "@type": "GeoShape",
          "addressCountry": "IT",
        },
        "availabilityStarts": moment(startDate || "16/02/2022", "DD/MM/YYYY"),
      },
      "image": url + image,
      "description": description,
      "inLanguage": language,
      "isAccessibleForFree": true,
      "organizer": {
        "@id": siteMetadata.siteUrl + "/" + "#organization",
        "url": siteMetadata.siteUrl + "/",
        "name": "Young Platform",
      },
      "sponsor": {
        "@id": siteMetadata.siteUrl + "/#organization",
      },
      "performer": {
        "@type": "Person",
        "name": "Giovanni Mucciaccia",
      },
      "mainEntityOfPage": url + "#webpage",
    }
  }, [
    url,
    siteMetadata.siteUrl,
    language,
    image,
    description,
    startDate,
    title,
    theme,
  ])
  return eventSchema
}
