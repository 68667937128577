import React from "react"
import { Body, Box, Container, VStack, Heading, Icon } from "@youngagency/ui"
import {
  Avatar,
  Container as CustomContainer,
} from "../../../components/layout"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { SquareCard } from "../../../components/cards/components/SquareCard"
import { CardContainer } from "../../../components/cards/layout/CardContainer"
import { CardContent } from "../../../components/cards/layout/CardContent"
import { CardAction } from "../../../components/cards/layout/CardAction"
import { CardIconButton } from "../../../components/cards/layout/CardButton"
import { StaticImage } from "gatsby-plugin-image"
import {
  platformLayoutContainer,
  platformSection,
} from "../styles/platform-section.css"
import { unshirkedCard } from "../../../components/cards/style/card.css"
import { OpacityProvider } from "../../../components/cards/context/OpacityContext"
import { CardBack } from "../../../components/cards/layout/CardBack"

export const PlatformSection = () => {
  const { t } = useTranslation()
  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              fontWeight={400}
              dangerouslySetInnerHTML={{
                __html: t("security.platform_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              color="grey200"
              style={{
                maxWidth: "700px",
              }}
              fontWeight={600}
            >
              {t("security.platform_section.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>
      <Box
        width="full"
        marginTop={{
          md: 40,
        }}
        className={platformLayoutContainer}
      >
        <Box className={platformSection}>
          <OpacityProvider>
            <SquareCard hasShadow hoverVariant="light">
              <CardContainer width={"full"} className={unshirkedCard}>
                <CardContent alignItems="center" width={"full"}>
                  <VStack
                    align="center"
                    spacing={{
                      mobile: 16,
                      lg: 24,
                    }}
                  >
                    <Avatar
                      shape="circle"
                      width={["56px", "72px"]}
                      height={["56px", "72px"]}
                      background={"var(--grey-10-v2)"}
                    >
                      <StaticImage
                        src="../images/icon/Whatsapp.png"
                        alt={t(
                          "security.platform_section.cards_.card_1.img_alt_text"
                        )}
                        placeholder="blurred"
                      />
                    </Avatar>
                    <Heading
                      variant="6"
                      atoms={{
                        textAlign: "center",
                      }}
                      fontWeight={600}
                      dangerouslySetInnerHTML={{
                        __html: t(
                          "security.platform_section.cards_.card_1.title"
                        ),
                      }}
                    />
                    <Body
                      variant="1"
                      atoms={{
                        textAlign: "center",
                      }}
                      color="grey200"
                      style={{
                        maxWidth: "700px",
                      }}
                      fontWeight={600}
                    >
                      {t("security.platform_section.cards_.card_1.caption")}
                    </Body>
                  </VStack>
                </CardContent>
                <CardAction position="bottom" justifyContent="flex-end">
                  <CardIconButton>
                    <Icon icon="plus" size={24} />
                  </CardIconButton>
                </CardAction>
                <CardBack background="white">
                  <Body
                    variant="1"
                    color="grey200"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "security.platform_section.cards_.card_1.back.description_1"
                      ),
                    }}
                  />
                  <CardAction position="bottom" justifyContent="flex-end">
                    <CardIconButton>
                      <Icon icon="close" size={24} />
                    </CardIconButton>
                  </CardAction>
                </CardBack>
              </CardContainer>
            </SquareCard>
          </OpacityProvider>
          <OpacityProvider>
            <SquareCard hasShadow hoverVariant="light">
              <CardContainer width={"full"} className={unshirkedCard}>
                <CardContent
                  alignItems="center"
                  width={"full"}
                  justifyContent="center"
                  height={"full"}
                  paddingTop={{
                    mobile: 16,
                    lg: 48,
                  }}
                >
                  <VStack
                    align={"center"}
                    spacing={{
                      mobile: 16,
                      lg: 24,
                    }}
                  >
                    <Avatar
                      shape="circle"
                      width={["56px", "72px"]}
                      height={["56px", "72px"]}
                      background={"var(--grey-10-v2)"}
                    >
                      <StaticImage
                        src="../images/icon/Telegram.png"
                        alt={t(
                          "security.platform_section.cards_.card_2.img_alt_text"
                        )}
                        placeholder="blurred"
                      />{" "}
                    </Avatar>
                    <Heading
                      variant="6"
                      atoms={{
                        textAlign: "center",
                      }}
                      fontWeight={600}
                      dangerouslySetInnerHTML={{
                        __html: t(
                          "security.platform_section.cards_.card_2.title"
                        ),
                      }}
                    />
                    <Body
                      variant="1"
                      atoms={{
                        textAlign: "center",
                      }}
                      color="grey200"
                      style={{
                        maxWidth: "700px",
                      }}
                      fontWeight={600}
                    >
                      {t("security.platform_section.cards_.card_2.caption")}
                    </Body>
                  </VStack>
                </CardContent>
                <CardAction position="bottom" justifyContent="flex-end">
                  <CardIconButton>
                    <Icon icon="plus" size={24} />
                  </CardIconButton>
                </CardAction>
                <CardBack background="white">
                  <Body
                    variant="1"
                    color="grey200"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "security.platform_section.cards_.card_2.back.description_1"
                      ),
                    }}
                  />

                  <CardAction position="bottom" justifyContent="flex-end">
                    <CardIconButton>
                      <Icon icon="close" size={24} />
                    </CardIconButton>
                  </CardAction>
                </CardBack>
              </CardContainer>
            </SquareCard>
          </OpacityProvider>
          <OpacityProvider>
            <SquareCard hasShadow hoverVariant="light">
              <CardContainer width={"full"} className={unshirkedCard}>
                <CardContent alignItems="center" width={"full"}>
                  <VStack
                    align={"center"}
                    spacing={{
                      mobile: 16,
                      lg: 24,
                    }}
                  >
                    <Avatar
                      shape="circle"
                      width={["56px", "72px"]}
                      height={["56px", "72px"]}
                      background={"var(--grey-10-v2)"}
                    >
                      <StaticImage
                        src="../images/icon/Instagram.png"
                        alt={t(
                          "security.platform_section.cards_.card_3.img_alt_text"
                        )}
                        placeholder="blurred"
                      />
                    </Avatar>
                    <Heading
                      variant="6"
                      atoms={{
                        textAlign: "center",
                      }}
                      fontWeight={600}
                      dangerouslySetInnerHTML={{
                        __html: t(
                          "security.platform_section.cards_.card_3.title"
                        ),
                      }}
                    />
                    <Body
                      variant="1"
                      atoms={{
                        textAlign: "center",
                      }}
                      color="grey200"
                      style={{
                        maxWidth: "700px",
                      }}
                      fontWeight={600}
                    >
                      {t("security.platform_section.cards_.card_3.caption")}
                    </Body>
                  </VStack>
                </CardContent>
                <CardAction position="bottom" justifyContent="flex-end">
                  <CardIconButton>
                    <Icon icon="plus" size={24} />
                  </CardIconButton>
                </CardAction>
                <CardBack background="white">
                  <Body
                    variant="1"
                    color="grey200"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "security.platform_section.cards_.card_3.back.description_1"
                      ),
                    }}
                  />

                  <CardAction position="bottom" justifyContent="flex-end">
                    <CardIconButton>
                      <Icon icon="close" size={24} />
                    </CardIconButton>
                  </CardAction>
                </CardBack>
              </CardContainer>
            </SquareCard>
          </OpacityProvider>
          <OpacityProvider>
            <SquareCard hasShadow hoverVariant="light">
              <CardContainer width={"full"} className={unshirkedCard}>
                <CardContent alignItems="center" width={"full"}>
                  <VStack
                    align={"center"}
                    spacing={{
                      mobile: 16,
                      lg: 24,
                    }}
                  >
                    <Avatar
                      shape="circle"
                      width={["56px", "72px"]}
                      height={["56px", "72px"]}
                      background={"var(--grey-10-v2)"}
                    >
                      <StaticImage
                        src="../images/icon/Phone.png"
                        alt={t(
                          "security.platform_section.cards_.card_4.img_alt_text"
                        )}
                        placeholder="blurred"
                      />
                    </Avatar>
                    <Heading
                      variant="6"
                      atoms={{
                        textAlign: "center",
                      }}
                      fontWeight={600}
                      dangerouslySetInnerHTML={{
                        __html: t(
                          "security.platform_section.cards_.card_4.title"
                        ),
                      }}
                    />
                    <Body
                      variant="1"
                      atoms={{
                        textAlign: "center",
                      }}
                      color="grey200"
                      style={{
                        maxWidth: "700px",
                      }}
                      fontWeight={600}
                    >
                      {t("security.platform_section.cards_.card_4.caption")}
                    </Body>
                  </VStack>
                </CardContent>
                <CardAction position="bottom" justifyContent="flex-end">
                  <CardIconButton>
                    <Icon icon="plus" size={24} />
                  </CardIconButton>
                </CardAction>
                <CardBack background="white">
                  <Body
                    variant="1"
                    color="grey200"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "security.platform_section.cards_.card_4.back.description_1"
                      ),
                    }}
                  />

                  <CardAction position="bottom" justifyContent="flex-end">
                    <CardIconButton>
                      <Icon icon="close" size={24} />
                    </CardIconButton>
                  </CardAction>
                </CardBack>
              </CardContainer>
            </SquareCard>
          </OpacityProvider>
          <OpacityProvider>
            <SquareCard hasShadow hoverVariant="light">
              <CardContainer width={"full"} className={unshirkedCard}>
                <CardContent alignItems="center" width={"full"}>
                  <VStack
                    align={"center"}
                    spacing={{
                      mobile: 16,
                      lg: 24,
                    }}
                  >
                    <Avatar
                      shape="circle"
                      width={["56px", "72px"]}
                      height={["56px", "72px"]}
                      background={"var(--grey-10-v2)"}
                    >
                      <StaticImage
                        src="../images/icon/Mail.png"
                        alt={t(
                          "security.platform_section.cards_.card_5.img_alt_text"
                        )}
                        placeholder="blurred"
                      />
                    </Avatar>
                    <Heading
                      variant="6"
                      atoms={{
                        textAlign: "center",
                      }}
                      fontWeight={600}
                      dangerouslySetInnerHTML={{
                        __html: t(
                          "security.platform_section.cards_.card_5.title"
                        ),
                      }}
                    />
                    <Body
                      variant="1"
                      atoms={{
                        textAlign: "center",
                      }}
                      color="grey200"
                      style={{
                        maxWidth: "700px",
                      }}
                      fontWeight={600}
                    >
                      {t("security.platform_section.cards_.card_5.caption")}
                    </Body>
                  </VStack>
                </CardContent>
                <CardAction position="bottom" justifyContent="flex-end">
                  <CardIconButton>
                    <Icon icon="plus" size={24} />
                  </CardIconButton>
                </CardAction>
                <CardBack background="white">
                  <Body
                    variant="1"
                    color="grey200"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "security.platform_section.cards_.card_5.back.description_1"
                      ),
                    }}
                  />

                  <CardAction position="bottom" justifyContent="flex-end">
                    <CardIconButton>
                      <Icon icon="close" size={24} />
                    </CardIconButton>
                  </CardAction>
                </CardBack>
              </CardContainer>
            </SquareCard>
          </OpacityProvider>
          <OpacityProvider>
            <SquareCard hasShadow hoverVariant="light">
              <CardContainer width={"full"} className={unshirkedCard}>
                <CardContent alignItems="center" width={"full"}>
                  <VStack
                    align={"center"}
                    spacing={{
                      mobile: 16,
                      lg: 24,
                    }}
                  >
                    <Avatar
                      shape="circle"
                      width={["56px", "72px"]}
                      height={["56px", "72px"]}
                      background={"var(--grey-10-v2)"}
                    >
                      <StaticImage
                        src="../images/icon/fb.png"
                        alt={t(
                          "security.platform_section.cards_.card_6.img_alt_text"
                        )}
                        placeholder="blurred"
                      />
                    </Avatar>
                    <Heading
                      variant="6"
                      atoms={{
                        textAlign: "center",
                      }}
                      fontWeight={600}
                      dangerouslySetInnerHTML={{
                        __html: t(
                          "security.platform_section.cards_.card_6.title"
                        ),
                      }}
                    />
                    <Body
                      variant="1"
                      atoms={{
                        textAlign: "center",
                      }}
                      color="grey200"
                      style={{
                        maxWidth: "700px",
                      }}
                      fontWeight={600}
                    >
                      {t("security.platform_section.cards_.card_6.caption")}
                    </Body>
                  </VStack>
                </CardContent>
                <CardAction position="bottom" justifyContent="flex-end">
                  <CardIconButton>
                    <Icon icon="plus" size={24} />
                  </CardIconButton>
                </CardAction>
                <CardBack background="white">
                  <Body
                    variant="1"
                    color="grey200"
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "security.platform_section.cards_.card_6.back.description_1"
                      ),
                    }}
                  />

                  <CardAction position="bottom" justifyContent="flex-end">
                    <CardIconButton>
                      <Icon icon="close" size={24} />
                    </CardIconButton>
                  </CardAction>
                </CardBack>
              </CardContainer>
            </SquareCard>
          </OpacityProvider>
        </Box>
      </Box>
    </>
  )
}
