import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import { useI18next } from "gatsby-plugin-react-i18next"
import { rowalizer } from "../../utils/general"
import { Box, Paragraph, Heading, Icon, HStack } from "@youngagency/young-ui"
import { Container, ResponsiveButton, ResponsiveText } from "../layout"
import { StaticImage } from "gatsby-plugin-image"
import { SeoLink } from "../../feature/seo"
import { EvidenceArticle } from "./EvidenceArticle"
import isEmpty from "lodash/isEmpty"

const StyledBoxStack = styled(Box)<{ reverse?: true }>`
  display: flex;
  flex-direction: ${props => (props.reverse ? "column-reverse" : "column")};
  & > *:not(:first-of-type) {
    margin-top: ${props => (props.reverse ? "0px" : "24px")};
    margin-bottom: ${props => (props.reverse ? "24px" : "0px")};
  }
  @media screen and (min-width: 767px) {
    flex-direction: row;
    align-items: center;
    & > *:not(:first-of-type) {
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 117px;
    }
  }
`

const StyledBox = styled(Box)`
  & article {
    margin-top: 8px;
  }

  .legal-doc {
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    justify-content: space-between;
    cursor: pointer;
    & > *:not(:first-child) {
      margin-left: 16px;
    }
  }

  @media screen and (min-width: 400px) {
    & article {
      margin-top: 16px;
    }
  }
`

const AboutUs = () => {
  const { t, language } = useI18next()
  const [row, setRow] = React.useState(1)
  const [isExpandable, setIsExpandable] = React.useState("expand")

  const newsList: Queries.TalkAboutUsPageQuery = useStaticQuery(query)
  const otherArticle = React.useMemo(() => {
    return newsList?.aboutUsJson?.News?.filter(el => !el?.evidence)
  }, [language, newsList?.aboutUsJson?.News])

  const keyLanguage = React.useMemo(() => {
    if (language === "it") return "it"
    return "en"
  }, [language])

  const rowalizedArticle = React.useMemo(() => {
    if (otherArticle && !isEmpty(otherArticle))
      return rowalizer(otherArticle, row * 5)
  }, [otherArticle, row])

  const handleClick = React.useCallback(() => {
    if (!otherArticle?.length) return

    if (isExpandable === "collapse") {
      setRow(1)
      setIsExpandable("expand")
    } else {
      setRow(el => el + 1)
    }

    if ((row + 1) * 5 >= otherArticle.length) {
      if (isExpandable === "collapse") {
        return
      }
      setIsExpandable("collapse")
    }
  }, [
    row,
    rowalizedArticle,
    isExpandable,
    setIsExpandable,
    otherArticle?.length,
  ])

  return (
    <Container size="fullwidth">
      <Container>
        <Box mt={["24px", "48px"]}>
          <ResponsiveText
            line={["39px", "50px"]}
            fontWeight={700}
            dimension={["34px", "48px"]}
            as="h1"
            textAlign="left"
          >
            {t("talking_about_us.title")}
          </ResponsiveText>
        </Box>
        <Box mt={["24px", "48px"]}>
          <ResponsiveText
            line={["22px", "30px"]}
            fontWeight={700}
            dimension={["18px", "24px"]}
            as="p"
          >
            {t("talking_about_us.preview_title")}
          </ResponsiveText>
        </Box>
      </Container>

      <Box mt={["24px"]}>
        <Container size={["md", "lg"]}>
          <EvidenceArticle />
        </Container>
      </Box>

      <Container mt={["48px", "96px"]}>
        <ResponsiveText
          line={["22px", "30px"]}
          fontWeight={700}
          dimension={["18px", "24px"]}
          as="h3"
        >
          {t("talking_about_us.all_news")}
        </ResponsiveText>
        <StyledBox mt={["16px", "18px"]}>
          {rowalizedArticle?.[0].map(article => {
            return (
              <SeoLink
                key={article?.title?.[keyLanguage]}
                link={article?.link as string}
                isExternal
                target="_blank"
                rel="noopener"
                className="link-card"
              >
                <Box
                  as="article"
                  key={article?.title?.[keyLanguage]}
                  className="legal-doc"
                  p={["16px", "24px"]}
                  border="1px solid"
                  borderColor="grey.5"
                  borderRadius={["16px", "24px"]}
                >
                  <Box
                    className="legal-doc-header"
                    maxWidth={["272px", "unset"]}
                    width="100%"
                  >
                    <Heading
                      as="h2"
                      fontFamily="Matter"
                      size="sm"
                      mt="0px"
                      weight="heavy"
                    >
                      {article?.title?.[keyLanguage]}
                    </Heading>
                    <Box mt={["8px", "10px"]}>
                      <Paragraph size="sm" fontFamily="Matter">
                        {article?.description?.[keyLanguage]}
                      </Paragraph>
                    </Box>
                  </Box>
                  <Icon icon="arrow_upright" fontSize="24px" />
                </Box>
              </SeoLink>
            )
          })}
        </StyledBox>
        <HStack mt={["16px", "24px"]} justify="center">
          <ResponsiveButton variant="secondary" size="lg" onClick={handleClick}>
            {t(`talking_about_us.${isExpandable}_btn`)}
          </ResponsiveButton>
        </HStack>
      </Container>
      <Box mt={["48px", "96px"]}>
        <Container>
          <StyledBoxStack>
            <Box maxWidth="465px">
              <ResponsiveText
                line={["32px", "50px"]}
                fontWeight={600}
                dimension={["28px", "48px"]}
              >
                {t("talking_about_us.card_1.title")}
              </ResponsiveText>
              <Box mt={["16px", "24px"]}>
                <ResponsiveText
                  line={["20px", "25px"]}
                  fontWeight={400}
                  dimension={["16px", "20px"]}
                >
                  {t("talking_about_us.card_1.description")}
                </ResponsiveText>
              </Box>
              <Box mt={["16px", "24px"]}>
                <SeoLink
                  isExternal
                  rel="noopener"
                  link={t("talking_about_us.card_1.link")}
                >
                  <ResponsiveButton
                    variant="primary"
                    leftIcon={<Icon icon={t("talking_about_us.card_1.icon")} />}
                    size="xl"
                  >
                    {t("talking_about_us.card_1.btn")}
                  </ResponsiveButton>
                </SeoLink>
              </Box>
            </Box>
            <Box
              maxWidth="560px"
              width="100%"
              borderRadius="16px"
              overflow="hidden"
              style={{
                transform: "translateZ(0)",
              }}
            >
              <StaticImage
                src="./images/talk-us-top.png"
                alt={"Young Platform Name"}
                style={{
                  height: "100%",
                }}
              />
            </Box>
          </StyledBoxStack>
          <Box mt={["48px", "144px"]}>
            <StyledBoxStack reverse>
              <Box
                maxWidth="560px"
                width="100%"
                borderRadius="16px"
                overflow="hidden"
                style={{
                  transform: "translateZ(0)",
                }}
              >
                <StaticImage
                  src="./images/talk-us-bottom.png"
                  alt={"Young Platform Logo Design"}
                  style={{
                    height: "100%",
                  }}
                />
              </Box>
              <Box maxWidth="465px">
                <ResponsiveText
                  line={["32px", "50px"]}
                  fontWeight={600}
                  dimension={["28px", "48px"]}
                >
                  {t("talking_about_us.card_2.title")}
                </ResponsiveText>
                <Box mt={["16px", "24px"]}>
                  <ResponsiveText
                    line={["20px", "25px"]}
                    fontWeight={400}
                    dimension={["16px", "20px"]}
                  >
                    {t("talking_about_us.card_2.description")}
                  </ResponsiveText>
                </Box>
                <Box mt={["16px", "24px"]}>
                  <SeoLink
                    isExternal={false}
                    link={t("talking_about_us.card_2.link")}
                  >
                    <ResponsiveButton variant="primary" size="xl">
                      {t("talking_about_us.card_2.btn")}
                    </ResponsiveButton>
                  </SeoLink>
                </Box>
              </Box>
            </StyledBoxStack>
          </Box>
        </Container>
      </Box>
      <Box mt={["48px", "96px"]} py={["48px", "96px"]} backgroundColor="grey.4">
        <Container>
          <ResponsiveText
            line={["30px", "39px"]}
            fontWeight={600}
            dimension={["24px", "34px"]}
            as="p"
            textAlign="center"
          >
            {t("talking_about_us.banner.text")}
          </ResponsiveText>
          <HStack justify="center" mt={["16px", "24px"]}>
            <a
              style={{
                textDecoration: "none",
              }}
              href="mailto:social@youngplatform.com"
            >
              <ResponsiveButton size="xl">
                {t("talking_about_us.banner.cta")}
              </ResponsiveButton>
            </a>
          </HStack>
        </Container>
      </Box>
    </Container>
  )
}

const query = graphql`
  query TalkAboutUsPage {
    aboutUsJson {
      News {
        visible
        evidence
        img_link
        link
        title {
          en
          it
        }
        description {
          en
          it
        }
      }
    }
  }
`

export default AboutUs
