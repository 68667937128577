import { Box } from "@youngagency/young-ui"
import { graphql, PageProps } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import { Container } from "../../components/layout"
import { Paginator } from "../../components/shared/Paginator"
import { BreadCrumb, LandingLayout } from "../../feature/navigation/components"
import {
  AlternateHandler,
  MetaDecorator,
  WebPageSchema,
} from "../../feature/seo"
import {
  TokenList,
  TokenListHeader,
  TokenListHero,
} from "../../feature/token/components"
import { TokenProvider } from "../../feature/token/context/TokenListProvider"
import { useTokenList } from "../../feature/token/hooks/useTokenList"
import { TranslationProvider } from "../../feature/translations/context"
import { HEADER_TOKEN_PAGES_DYNAMIC_LINK } from "../../feature/token/constants"

type ContextProps = {
  hasNextPage: boolean
  isFirstPage: boolean
  page: number
  numPages: number
}

type LocationState = {
  sortOrder?: "asc" | "desc"
  sortedBy?: string
}

const ListedTokenTemplate = ({
  data,
  pageContext: { page, numPages },
  location,
}: PageProps<any, ContextProps, LocationState>) => {
  const { t } = useI18next()
  const state = location.state || {}
  const { sortOrder, sortedBy } = state
  const breadcrumbs = React.useMemo(
    () => [
      {
        text: "Home",
        link: "/",
      },
      {
        text: t("breadcumbs.markets_breadcrumb"),
        link: "/exchange/",
      },
      {
        text: t("markets.labels.listed"),
        link: "/exchange/listed",
      },
    ],
    [t]
  )

  const { token, isLoading } = useTokenList(page, sortedBy, sortOrder)
  const ctx = React.useMemo(() => ({ token, isLoading, page }), [
    token,
    isLoading,
    page,
  ])

  return (
    <TranslationProvider>
      <LandingLayout
        dinamycLink={HEADER_TOKEN_PAGES_DYNAMIC_LINK}
        appleLink={"https://youngexchange.page.link/stpgfios"}
        playStoreLink={"https://youngexchange.page.link/stpgfa"}
      >
        <Container size={["md", "lg"]}>
          <BreadCrumb breadcrumbs={breadcrumbs} />
          <MetaDecorator
            metaTitle={t("markets.metaData.listed.title")}
            metaDescription={t("markets.metaData.listed.description")}
          />
          <WebPageSchema
            title={t("markets.metaData.listed.title")}
            description={t("markets.metaData.listed.description")}
            breadcrumbs={breadcrumbs}
          />
          <AlternateHandler 
          forcedCanonical="exchange/"
          />
        </Container>
        <Container size={["md", "lg"]} mt={["24px", "54px"]}>
          <TokenListHero type="listed" />

          <TokenProvider value={ctx}>
            <Box
              border="1px solid"
              borderColor="grey.5"
              borderRadius="18px"
              mt="16px"
            >
              <TokenListHeader />
              <TokenList tokens={data.allToken.nodes} />
            </Box>
            <Box mt="48px">
              <Paginator
                page={page}
                numOfPages={numPages}
                maxVisiblePages={2}
                link="exchange/listed"
              />
            </Box>
          </TokenProvider>
        </Container>
      </LandingLayout>
    </TranslationProvider>
  )
}

export const query = graphql`query AllTradableTokenPage($language: String!, $limit: Int!, $skip: Int!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  allToken(
    sort: {details: {marketCap: DESC}}
    filter: {id: {nin: ["EUR", "USD", "GBP"]}, isUnlisted: {eq: null}}
    limit: $limit
    skip: $skip
  ) {
    nodes {
      symbol
      name
    }
    totalCount
  }
}`

export default ListedTokenTemplate
