export const createFlexOrderValue = (id: number) => {
  switch (id) {
    case 5154:
    case 5100:
      return 3
    case 5150:
    case 5104:
      return 4
    case 5112:
    case 5166:
      return 6
    case 5095:
    case 5158:
      return 2
    case 5162:
    case 5108:
      return 5
    default:
      return 1
  }
}
