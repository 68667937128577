import isInteger from "lodash/isInteger"
import { CurrencyLookupTable } from "../../../redux/services/currency/types"
const dataBella = new Date(Date.parse("5/25/2022"))
import { tokenV2ExclusionCondition } from "../../../constants"
import { ParsedGetAllChartsV2ApiResponse } from "../../../service/charts/types"

export const secondToDHM = () => {
  const endDate = dataBella
  const seconds = Number(new Date(Number(endDate) - Date.now()))
  let d = Math.floor(seconds / (3600 * 24 * 1000))
  let h = Math.floor((seconds / (1000 * 60 * 60)) % 24)
  let m = Math.floor((seconds / 1000 / 60) % 60)

  return [
    {
      label: "D",
      value: d,
    },
    {
      label: "H",
      value: h,
    },
    {
      label: "M",
      value: m,
    },
  ]
}

export const truncateNumber = (element?: number, cb?: Function) => {
  if (!element) {
    return
  }
  let value: number | string = element / 1000000
  if (isInteger(value)) {
    value = value
  } else {
    value = value.toFixed(2)
  }
  if (cb) {
    return cb(value)
  }
  return value
}

export const getPercentageColor = (value?: string | number | null) => {
  if (!value) return
  let number = value
  if (typeof value === "string") {
    number = parseFloat(value)
  }
  if (typeof number === "number" && number < 0) {
    return "red.300"
  }
  return "green.400"
}

const retardedToken = [
  {
    key: "LUNA2",
    ticker: "LUNA",
    symbol: "LUNA",
  },
  {
    key: "LUNA",
    ticker: "LUNA",
    symbol: "LUNC",
  },
]

export const isRetardedCoins = (token: string) =>
  retardedToken.find(x => x.key.toUpperCase() === token?.toUpperCase())

export const replaceTokenWithCustomTicker = (
  token: string,
  kind: "ticker" | "symbol"
) => {
  const tokenToSwitch = isRetardedCoins(token)
  if (!tokenToSwitch) return token
  return tokenToSwitch[kind]
}

export type AllChartTokenResponse = {
  [x: string]: {
    points: number[]
    percentage: number
  }
}

type Props = {
  currencies?: CurrencyLookupTable
  chartData: ParsedGetAllChartsV2ApiResponse
}

export const currencyDataAndChartsParser = ({ currencies, chartData }: Props) =>
  currencies
    ? Object.entries(currencies)
        .map(el => ({ ...el[1], ...chartData[el[1].symbol] }))
        .filter(x => tokenV2ExclusionCondition(x))
    : []

export type ParsedCurrencyAndCharts = ReturnType<
  typeof currencyDataAndChartsParser
>
