import styled from "styled-components"

const AbsoluteContainer = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  cursor: ${props => props?.cursor || "auto"};
  z-index: ${props => props.zIndex || "auto"};
  top: ${props => props.top || "unset"};
  left: ${props => props.left || "unset"};
  right: ${props => props.right || "unset"};
  bottom: ${props => props.bottom || "unset"};
  @media screen and (max-width: ${props =>
      `${props.mediaScreen}px` || "1200px"}) {
    display: none;
  }
  & > .hide-icon-mobile {
    display: flex;
  }
  @media screen and (max-width: 768px) {
    & > .hide-icon-mobile {
      display: none;
    }
    & > .show-icon-mobile {
      width: 69px;
      height: 69px;
      top: ${props => props.topMobile || "unset"};
      left: ${props => props.leftMobile || "unset"};
      right: ${props => props.rightMobile || "unset"};
      bottom: ${props => props.bottomMobile || "unset"};
    }
  }
`

export default AbsoluteContainer
