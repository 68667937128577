import React from "react"
import { graphql, PageProps } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Container, BottomBanner } from "../../../components/layout"
import { FromStoreBtn } from "../../../components/shared"
import {
  WebPageSchema,
  MetaDecorator,
  AlternateHandler,
} from "../../../feature/seo"
import { BreadCrumb, BlogLayout } from "../../../feature/navigation/components"
import {
  FocusPost,
  PostSection,
  PostSectionsWrapper,
  PostWrapper,
  VideoSection,
} from "../../../feature/blog/components"
import { Box } from "@youngagency/young-ui"
import { PostType } from "../../../feature/blog/types"
import { Mutable } from "../../../types/shared"
import { TranslationProvider } from "../../../feature/translations/context"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { blogHomeEvents } from "../../../service/tracking"

const Blog: React.FC<PageProps<Queries.BlogHomeQuery>> = ({ data }) => {
  const { last4Posts, allWpPost, video } = data
  const { t } = useI18next()
  const postSections = React.useMemo(() => {
    const newsPostIndex = allWpPost.group.findIndex(
      x => x?.fieldValue?.toLowerCase() === "news"
    )
    const newsPostSection = allWpPost.group[newsPostIndex]

    {
      ;(allWpPost?.group as Mutable<
        Queries.BlogHomeQuery["allWpPost"]["group"]
      >)?.splice(newsPostIndex, 1)
    }
    {
      ;(allWpPost?.group as Mutable<
        Queries.BlogHomeQuery["allWpPost"]["group"]
      >)?.unshift(newsPostSection)
    }

    const temp = allWpPost.group.map((section, index) => (
      <PostSection
        trackingValue={
          section.nodes[0]?.categories?.nodes?.[0]?.tracking_value
            ?.trackingValue
        }
        posts={section.nodes as readonly PostType[]}
        key={"home-post-section-" + index}
      />
    ))
    if (video?.group?.[0]?.nodes)
      temp.splice(
        1,
        0,
        <VideoSection
          posts={video.group[0].nodes as readonly PostType[]}
          key={"home-video-section"}
        />
      )

    return temp
  }, [])

  const breadcrumb = React.useMemo(
    () => [
      {
        text: "Home",
        link: "/",
      },
      {
        text: "Blog",
        link: "/blog/",
      },
    ],
    []
  )

  const { gaTracker } = useSelector((state: RootState) => state.tracking)
  const handleFooterTracking = React.useCallback(() => {
    if (gaTracker) {
      gaTracker.trackEvent({
        eventName: blogHomeEvents.blog_home_footer,
      })
    }
  }, [gaTracker])

  return (
    <TranslationProvider>
      <BlogLayout
        disableFooterMargin
        isBlogHome
        dynamicLink="https://youngexchange.page.link/blog3"
      >
        <WebPageSchema breadcrumbs={breadcrumb} />
        <MetaDecorator disableSlogan />
        <AlternateHandler />
        <main>
          <Container size={["md", "lg"]}>
            <BreadCrumb breadcrumbs={breadcrumb} />
          </Container>
          {last4Posts.nodes[0] ? (
            <Box mt={["48px", "72px"]}>
              <Container size={["md", "lg"]}>
                <PostWrapper {...(last4Posts.nodes[0] as PostType)}>
                  <FocusPost />
                </PostWrapper>
              </Container>
            </Box>
          ) : null}

          <Box mt={["48px", "96px"]}>
            <PostSectionsWrapper>{postSections}</PostSectionsWrapper>
          </Box>

          <BottomBanner
            text={t("download-app.title")}
            background="#0084be"
            lightText
          >
            <FromStoreBtn
              className="responsive-download-banner"
              appleLink="https://youngexchange.page.link/blog1"
              playLink="https://youngexchange.page.link/blog2"
              event={handleFooterTracking}
            />
          </BottomBanner>
        </main>
      </BlogLayout>
    </TranslationProvider>
  )
}

export const query = graphql`
  fragment homeBlogFragment on WpPostGroupConnection {
    nodes {
      title
      id
      databaseId
      slug
      date
      modified
      seo {
        readingTime
      }
      tags {
        nodes {
          databaseId
          name
          slug
        }
      }
      categories {
        nodes {
          tracking_value {
            trackingValue
          }
          name
          slug
        }
      }
      author {
        node {
          name
        }
      }
      featuredImage {
        node {
          mediaItemUrl
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export const blogHomePageQuery = graphql`
  query BlogHome($language: String!, $pageLanguage: WpLanguageCodeEnum!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allWpPost(
      filter: {
        categories: {
          nodes: { elemMatch: { databaseId: { nin: [3237, 9, 30, 37, 5] } } }
        }
        language: { code: { eq: $pageLanguage } }
      }
      sort: { date: DESC }
    ) {
      group(field: { categories: { nodes: { name: SELECT } } }, limit: 3) {
        fieldValue
        ...homeBlogFragment
      }
    }
    video: allWpPost(
      filter: {
        tags: { nodes: { elemMatch: { databaseId: { in: [6101, 6105] } } } }
        language: { code: { eq: $pageLanguage } }
      }
      sort: { date: DESC }
    ) {
      group(field: { tags: { nodes: { name: SELECT } } }, limit: 2) {
        fieldValue
        ...homeBlogFragment
      }
    }
    last4Posts: allWpPost(
      sort: { date: DESC }
      filter: { language: { code: { eq: $pageLanguage } } }
      limit: 4
    ) {
      nodes {
        title
        id
        databaseId
        slug
        date
        modified
        seo {
          readingTime
        }
        tags {
          nodes {
            databaseId
            name
            slug
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
        author {
          node {
            name
          }
        }
        featuredImage {
          node {
            mediaItemUrl
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default Blog
