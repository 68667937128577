import { createSlice } from "@reduxjs/toolkit";
import { GATracker } from "../../service/tracking";

type StateProps = {
    gaTracker: GATracker | null
}

const initialState:StateProps = {
    gaTracker : null,
}

const slice = createSlice({
    name: "tracking",
    initialState,
    reducers: {
        initTracker(state) {
            state.gaTracker = GATracker.init()
        }
    },
})

export const { initTracker } = slice.actions
export default slice.reducer