import { Box } from "@youngagency/young-ui"
import styled from "styled-components"
import { css } from "@styled-system/css"

export const BorderBox = styled(Box)(
  css({
    border: "1px solid",
    borderColor: "grey.5",
  })
)
