import { Box } from "@youngagency/young-ui"
import React from "react"
import { BlockDescriptionProps } from "../../types/shared"
import { ResponsiveText } from "../layout"
import styled from "styled-components"

const StyledBox = styled(Box)`
  @media screen and (min-width: 767px) {
    transition: all 125ms linear;
    &:hover {
      background: var(--light-gray-8);
    }
  }
`

const ReletedCard = ({
  title,
  description,
}: Partial<BlockDescriptionProps>) => {
  return (
    <StyledBox
      maxWidth="756px"
      width="100%"
      borderRadius="16px"
      py="16px"
      px="24px"
      height="100%"
    >
      <Box>
        <ResponsiveText
          line={["32px", "22px"]}
          dimension={["24px", "18px"]}
          as="h2"
          my="0px"
          fontWeight={600}
        >
          {title}
        </ResponsiveText>
      </Box>
      <Box mt={["12px", "16px"]}>
        <ResponsiveText
          line={["18px", "22px"]}
          dimension={["14px", "16px"]}
          as="p"
          my="0px"
        >
          {description}
        </ResponsiveText>
      </Box>
    </StyledBox>
  )
}

export default ReletedCard
