import React from "react"
import { graphql, PageProps } from "gatsby"
import { Container, ResponsiveText } from "../../../components/layout"
import { Display, Box } from "@youngagency/young-ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import { capitalizeFirstLetter } from "../../../utils/helpers"
import styled from "styled-components"
import {
  AlternateHandler,
  WebPageSchema,
  MetaDecorator,
} from "../../../feature/seo"
import { PaginatedPageContextProps } from "../../../types/blog"
import { BreadCrumb, BlogLayout } from "../../../feature/navigation/components"
import { I18NextCustomContext } from "../../../types/shared"
import { Paginator } from "../../../components/shared/Paginator"
import { PostType } from "../../../feature/blog/types"
import { ArticleWall } from "../../../feature/blog/components"
import { TagTranslator } from "../../../feature/translations/components/Translator"
import { TranslationProvider } from "../../../feature/translations/context"

const StyledDisplay = styled(Display)`
  @media screen and (max-width: 767px) {
    font-size: 34px !important;
    line-height: 39px !important;
  }
`

type ContextProps = I18NextCustomContext &
  PaginatedPageContextProps & {
    slug: string
  }

const SingleTagTemplate: React.FC<
  PageProps<Queries.BlogTagQuery, ContextProps>
> = ({ data, pageContext: { canonicalPath, currentPage, slug, numPages } }) => {
  const { allWpPost } = data
  const slicedCanonicalPath = React.useMemo(() => canonicalPath.slice(1), [])
  const { language } = useI18next()
  const tagsNode = React.useMemo(() => {
    return allWpPost?.nodes?.[0]?.tags?.nodes?.find(
      el => el?.slug?.toLowerCase() === slug.toLowerCase()
    )
  }, [])

  const tagName = React.useMemo(() => tagsNode?.name, [])
  const tagSeoTitle = React.useMemo(() => tagsNode?.seo?.title, [])
  const tagsDescription = React.useMemo(() => tagsNode?.seo?.metaDesc, [])

  const breadcrumb = React.useMemo(
    () => [
      {
        text: "Home",
        link: "/",
      },
      {
        text: "Blog",
        link: "/blog/",
      },
      {
        text: capitalizeFirstLetter(tagName),
        link:
          currentPage === 1
            ? `${canonicalPath}/`
            : `${canonicalPath}/${currentPage}/`,
      },
    ],
    [tagName]
  )

  const ctx = React.useMemo(() => {
    const tagTranslator = new TagTranslator(tagsNode?.translations)
    const trx = tagTranslator.create(language, slug)

    return {
      translations: trx,
      disableTranslations: !tagTranslator.hasTranslation(
        tagsNode?.translations
      ),
    }
  }, [tagsNode?.translations, language, slug])

  return (
    <TranslationProvider value={ctx}>
      <BlogLayout
        slug={allWpPost?.nodes?.[0]?.categories?.nodes?.[0]?.slug}
        dynamicLink={"https://youngexchange.page.link/blog3"}
      >
        <MetaDecorator
          metaTitle={tagSeoTitle || tagName}
          metaDescription={tagsDescription ? tagsDescription : "Tag duplicato"}
        />
        <WebPageSchema
          title={tagSeoTitle || tagName}
          description={tagsDescription ? tagsDescription : "Tag duplicato"}
          breadcrumbs={breadcrumb}
        />
        <AlternateHandler />
        <Container size={["md", "lg"]}>
          <BreadCrumb
            breadcrumbs={breadcrumb}
            //  mt={["0px", "40px"]}
          />
        </Container>
        <main>
          <Container
            size={["md", "lg"]}
            mt={["34px", "57px"]}
            mb={["24px", "48px"]}
          >
            <StyledDisplay
              size="sm"
              mb="12px"
              as="h1"
              fontFamily="Matter"
              weight="heavy"
              style={{
                textTransform: "capitalize",
              }}
            >
              {tagName}
            </StyledDisplay>
            <Box mt={["0px", "8px"]}>
              <ResponsiveText
                dimension={["14px", "18px"]}
                maxWidth="55ch"
                color="grey.1"
                line={["18px", "24px"]}
              >
                {tagsDescription
                  ? tagsDescription
                      .replace(/<[^>]*>/g, " ")
                      .replace(/\s{2,}/g, " ")
                      .trim()
                  : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae aliquid laboriosam."}
              </ResponsiveText>
            </Box>
          </Container>
          <Box mt={["48px", "96px"]}>
            <ArticleWall posts={allWpPost.nodes as readonly PostType[]} />
            {numPages > 1 ? (
              <Box mt={["34px", "56px"]}>
                <Paginator
                  link={slicedCanonicalPath}
                  page={currentPage}
                  numOfPages={numPages}
                />
              </Box>
            ) : null}
          </Box>
        </main>
      </BlogLayout>
    </TranslationProvider>
  );
}

export const articleWallQuery = graphql`query BlogTag($slug: String!, $language: String!, $skip: Int!, $limit: Int!, $pageLanguage: WpLanguageCodeEnum!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  allWpPost(
    sort: {date: DESC}
    filter: {language: {code: {eq: $pageLanguage}}, status: {eq: "publish"}, tags: {nodes: {elemMatch: {slug: {eq: $slug}}}}}
    limit: $limit
    skip: $skip
  ) {
    nodes {
      title
      id
      databaseId
      slug
      date
      modified
      seo {
        readingTime
      }
      tags {
        nodes {
          description
          databaseId
          name
          slug
          seo {
            title
            metaDesc
          }
          translations {
            slug
            language {
              code
              locale
            }
            posts {
              nodes {
                id
                databaseId
              }
            }
          }
        }
      }
      categories {
        nodes {
          name
          slug
        }
      }
      author {
        node {
          name
        }
      }
      featuredImage {
        node {
          mediaItemUrl
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
}`

export default SingleTagTemplate
