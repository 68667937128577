import { Box, Icon } from "@youngagency/young-ui"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import {
  CardTexts,
  Card,
  CardButton,
  CardContent,
  CardImage,
} from "../../components/cards"
import { Text } from "../../components/texts/temporary"
import { SeoLink } from "../seo"
import { BoostImage } from "./LocalizedImaged"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { homePageEvents } from "../../service/tracking"


export const BoostSection = () => {
  const { t } = useTranslation()
  const {gaTracker} = useSelector((state:RootState)=> state.tracking)

  return (
    <>
      <SeoLink isExternal={false} link="/token-yng/clubs/">
        <Card
          hasShadow
          isAnimated
          mt="40px"
          background="#252525"
          invertBtn
          orientation="row"
          onClick={() => {
            if(gaTracker) gaTracker.trackEvent({
              eventName: homePageEvents.home_body_boost,
              payload: {
                card_clicked: 1,
              },
            })
          }}
        >
          <CardContent>
            <CardTexts>
              <Text.H6 as="h3" fontWeight={600} color="white">
                {t("homepage.section_4.card_1.title")}
              </Text.H6>
              <Text.Body1 fontWeight={600} as="p" color="grey.1">
                {t("homepage.section_4.card_1.description")}
              </Text.Body1>
            </CardTexts>

            <CardButton
              textColor="light"
              mt={["16px", "24px"]}
              rightIcon={<Icon icon="arrow_right" />}
            >
              {t("homepage.section_4.card_1.button")}
            </CardButton>
          </CardContent>
          <CardImage pb="0px !important">
            <Box maxWidth="100%" alignSelf="flex-end">
              <BoostImage.card1 />
            </Box>
          </CardImage>
        </Card>
      </SeoLink>

      {/* <CardStack mt="1rem">
        <Card backgroundColor="#F5F5F5" orientation="column">
          <CardContent>
            <CardTexts>
              <Text.H6 as="h3">{t("homepage.section_3.card_2.title")}</Text.H6>
            </CardTexts>

            <CardButton textColor="dark" mt={"16px"}>
              {t("homepage.section_3.card_2.button")}
            </CardButton>
          </CardContent>
          <CardImage>
            <Box maxWidth="100%">
              <StaticImage
                src="./images/boost-2.png"
                alt={t("homepage.section_3.card_2.img_alt_text")}
              />
            </Box>
          </CardImage>
        </Card>

        <Card background="#CCECFE" orientation="column">
          <CardContent>
            <CardTexts>
              <Text.H6 as="h3">{t("homepage.section_3.card_3.title")}</Text.H6>
            </CardTexts>

            <CardButton textColor="dark" mt={["16px", "24px"]}>
              {t("homepage.section_3.card_3.button")}
            </CardButton>
          </CardContent>
          <CardImage>
            <Box maxWidth="100%">
              <StaticImage
                src="./images/boost-3.png"
                alt={t("homepage.section_3.card_3.img_alt_text")}
              />
            </Box>
          </CardImage>
        </Card>
      </CardStack> */}
    </>
  )
}
