import { Box } from "@youngagency/young-ui"
import { StaticImage } from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import {
  HeroStack,
  ResponsiveButton,
  ResponsiveText,
} from "../../../../components/layout"
import { SeoLink } from "../../../seo"

export const Hero = () => {
  const { t } = useI18next()
  return (
    <HeroStack reverse="row">
      <Box
        maxWidth={["344px", "562px"]}
        borderRadius="50%"
        mx={["auto", "inherit"]}
        overflow="hidden"
        width="100%"
        style={{
          transform: "translateZ(0)",
        }}
      >
        <StaticImage
          src="../../images/token-rounded.png"
          alt={t("token_yng.ecosistema.title")}
          placeholder="dominantColor"
        />
      </Box>
      <Box>
        <ResponsiveText
          line={["30px", "39px"]}
          dimension={["24px", "34px"]}
          fontWeight={600}
          as="h3"
        >
          {t("token_yng.ecosistema.title")}
        </ResponsiveText>
        <Box mt="24px" maxWidth={["unset", "465px"]}>
          <ResponsiveText
            line={["18px", "20px"]}
            dimension={["14px", "16px"]}
            as="p"
          >
            {t("token_yng.ecosistema.description_1")}
          </ResponsiveText>
        </Box>
        <Box mt="24px" maxWidth={["unset", "465px"]}>
          <ResponsiveText
            line={["18px", "20px"]}
            dimension={["14px", "16px"]}
            as="p"
          >
            {t("token_yng.ecosistema.description_2")}
          </ResponsiveText>
        </Box>
        <Box mt="24px">
          <SeoLink isExternal={false} link={"/young-world/"}>
            <ResponsiveButton size="lg">
              {t("token_yng.ecosistema.cta")}
            </ResponsiveButton>
          </SeoLink>
        </Box>
      </Box>
    </HeroStack>
  )
}
