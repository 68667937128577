import { VStack } from "@youngagency/ui"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import usePopper from "../../hooks/usePopper"
import { createArrayFromJson } from "../../../../utils/general"
import { SeoLink } from "../../../seo"
import styled from "styled-components"
import {
  NavigationTooltipChipContent,
  NavigationTootltipChipContainer,
} from "./NavigationChip"
import { Body } from "@youngagency/ui"
import { useNavigationContext } from "../../context"
import { Box } from "@youngagency/young-ui"

const StyledBox = styled(Box)`
  .link-item {
    transition: all 75ms ease;
    cursor: pointer;
    &:hover {
      background: var(--r-grey-8);
    }
  }
`

export const CompanyTooltip = () => {
  const { t } = useI18next()
  const { offset } = useNavigationContext()

  usePopper({
    referenceID: t("menu.company.text"),
    tooltipID: "company-tooltip",
    placement: "bottom",
    modifiers: [
      {
        name: "offset",
        options: {
          offset,
        },
      },
    ],
    events: {
      open: ["mouseenter", "focus"],
      close: ["mouseleave", "blur"],
    },
  })

  const companyLinkArray = React.useMemo(() => {
    return createArrayFromJson(
      t,
      "menu.company.hover.sections",
      5,
      [],
      "title",
      "link"
    )
  }, [t])

  return (
    <StyledBox
      border="1px solid"
      borderColor="grey.5"
      className="tooltip"
      id="company-tooltip"
      borderRadius="16px"
      background="white"
      boxShadow="var(--shadow-1)"
    >
      <VStack
        align="flex-start"
        justify="flex-start"
        spacing={8}
        atoms={{
          p: 16,
        }}
      >
        {companyLinkArray.map(link => {
          return (
            <NavigationTootltipChipContainer
              key={`company-${link.title}`}
              id={`${link.title}`}
              justifyContent="flex-start"
            >
              <SeoLink
                isExternal={false}
                link={link.link as string}
                style={{
                  padding: "0px 16px",
                }}
              >
                <NavigationTooltipChipContent>
                  {typeof link.title === "string" ? (
                    <Body
                      variant="2"
                      fontWeight={600}
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {link.title}
                    </Body>
                  ) : null}
                </NavigationTooltipChipContent>
              </SeoLink>
            </NavigationTootltipChipContainer>
          )
        })}
      </VStack>
    </StyledBox>
  )
}
