import { Box } from "@youngagency/young-ui"
import React from "react"
import { ResponsiveText } from "../../../components/layout"
import { useFounderContext } from "../context/founder"
import { getCategoryValue } from "../utils"
import { FounderBox } from "./FounderBox"
import { Grid3To2Box } from "./layout"

export const AuditorsSection = () => {
  const ctx = useFounderContext()

  return (
    <Box>
      {ctx && getCategoryValue(ctx, "board-of-auditors") ? (
        <ResponsiveText
          line={["25px", "39px"]}
          dimension={["20px", "34px"]}
          as="h2"
          fontWeight={600}
        >
          {getCategoryValue(ctx, "board-of-auditors")}
        </ResponsiveText>
      ) : null}

      <Grid3To2Box mt="24px">
        {ctx?.item?.["board-of-auditors"]
          ? ctx?.item?.["board-of-auditors"].map(tizio => {
              return (
                <FounderBox
                  {...tizio}
                  disableFn
                  disableHover
                  key={tizio.title + "leader"}
                  maxWidth={["unset", "365px"]}
                  width="100%"
                />
              )
            })
          : null}
      </Grid3To2Box>
    </Box>
  )
}
