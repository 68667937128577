import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import uniqBy from "lodash/uniqBy"
import { TimeFrame } from "../../constants"

type GetAllTokenChartRequest = string | void

type GetTokenChartRequest = {
  currency: string
  timeframe: Omit<TimeFrame, "key" | "text">
  fiat?: never
}

export type GetTokenChartResponse = {
  points: {
    value: number
    time: string
    volume: number
    close: number
    high: number
    low: number
  }[]
  percentageChange: string
}

type ChartTokenType = "time" | "open" | "close" | "high" | "low" | "volume"

type AllChart24Token = {
  [x: string]: {
    [y in ChartTokenType]: number
  }[]
}

type AllChartTokenResponse = {
  [x: string]: {
    points: number[]
    percentage: number
  }
}

type CryptoCompareResponse = {
  coin_details: {
    current_price: string
    percent_change_24h: string
    current_supply: string
    market_cap: string
    volume_24h: string
    high_24h: string
  }
}

export const singleTokenApi = createApi({
  reducerPath: "token",
  baseQuery: fetchBaseQuery(),
  tagTypes: ["singleToken"],
  endpoints: builder => ({
    getTokenFromCryptoCompare: builder.query<CryptoCompareResponse, string>({
      query: symbol =>
        `https://min-api.cryptocompare.com/data/pricemultifull?fsyms=${symbol}&tsyms=EUR`,
      transformResponse: (data: any, _, arg) => {
        return {
          coin_details: {
            current_price: data["DISPLAY"][arg]["EUR"]["PRICE"],
            percent_change_24h: data["DISPLAY"][arg]["EUR"]["CHANGEPCT24HOUR"],
            current_supply: data["DISPLAY"][arg]["EUR"]["CIRCULATINGSUPPLY"],
            market_cap: data["DISPLAY"][arg]["EUR"]["MKTCAP"],
            volume_24h: data["DISPLAY"][arg]["EUR"]["TOTALVOLUME24H"],
            high_24h: data["DISPLAY"][arg]["EUR"]["HIGH24HOUR"],
          },
        }
      },
    }),
    getPillsChartLine: builder.query<
      Array<{ time: number; value: number }>,
      { coin: string; interval?: string }
    >({
      query: ({ coin, interval }) =>
        `https://prices.youngplatform.com/chart/line/${coin.toUpperCase()}?quote=EUR&interval=${
          interval || "1h"
        }`,
    }),
  }),
})

export const {
  useGetTokenFromCryptoCompareQuery,
  useLazyGetPillsChartLineQuery,
} = singleTokenApi
