import { BoxProps, Box, variant } from "@youngagency/young-ui"
import React from "react"
import { ComponentWithAs } from "../../types/system"
import styled from "styled-components"
import { css } from "@styled-system/css"

const variants = [
  "h2",
  "h3",
  "h5",
  "h6",
  "body1",
  "body2",
  "subtitle1",
  "subtitle2",
  "caption",
] as const

type Variant = typeof variants[number]

const StyledText = styled(Box)<{
  variant: Variant
}>(
  {
    fontFamily: "Matter",
    margin: 0,
    padding: 0,
    border: 0,
    verticalAlign: "baseline",
    "& strong": {
      fontWeight: 600,
    },
  },
  variant({
    prop: "variant",
    variants: {
      h2: css({
        fontSize: "32px",
        lineHeight: "40px",
        "@media (min-width: 744px)": {
          fontSize: "48px",
          lineHeight: "56px",
        },
        "@media (min-width: 1280px)": {
          fontSize: "64px",
          lineHeight: "72px",
        },
        "@media (min-width: 1680px)": {
          fontSize: "96px",
          lineHeight: "96px",
        },
      }),
      h3: css({
        fontSize: "32px",
        lineHeight: "40px",
        "@media (min-width: 744px)": {
          fontSize: "48px",
          lineHeight: "56px",
        },
        "@media (min-width: 1280px)": {
          fontSize: "56px",
          lineHeight: "64px",
        },
        "@media (min-width: 1680px)": {
          fontSize: "64px",
          lineHeight: "72px",
        },
      }),
      h5: css({
        fontSize: "32px",
        lineHeight: "40px",
        "@media (min-width: 744px)": {
          fontSize: "48px",
          lineHeight: "56px",
        },
      }),
      h6: css({
        fontSize: "24px",
        lineHeight: "32px",
        "@media (min-width: 744px)": {
          fontSize: "32px",
          lineHeight: "40px",
        },
      }),
      subtitle1: css({
        fontSize: "24px",
        lineHeight: "32px",
      }),
      subtitle2: css({
        fontSize: "18px",
        lineHeight: "24px",
      }),
      body1: css({
        fontSize: "16px",
        lineHeight: "24px",
      }),
      body2: css({
        fontSize: "14px",
        lineHeight: "20px",
      }),
      caption: css({
        fontSize: "12px",
        lineHeight: "16px",
      }),
    },
  })
)

const H2: ComponentWithAs<"div", BoxProps & { children?: React.ReactNode }> = ({
  children,
  ...rest
}) => {
  return (
    <StyledText variant="h2" {...rest}>
      {children}
    </StyledText>
  )
}

H2.defaultProps = {
  fontWeight: 600,
}

const H3: ComponentWithAs<"div", BoxProps & { children?: React.ReactNode }> = ({
  children,
  ...rest
}) => {
  return (
    <StyledText variant="h3" {...rest}>
      {children}
    </StyledText>
  )
}

H3.defaultProps = {
  fontWeight: 600,
}

const H5: ComponentWithAs<"div", BoxProps & { children?: React.ReactNode }> = ({
  children,
  ...rest
}) => {
  return (
    <StyledText variant="h5" {...rest}>
      {children}
    </StyledText>
  )
}

const H6: ComponentWithAs<"div", BoxProps & { children?: React.ReactNode }> = ({
  children,
  ...rest
}) => {
  return (
    <StyledText variant="h6" {...rest}>
      {children}
    </StyledText>
  )
}

const Subtitle1: ComponentWithAs<
  "div",
  BoxProps & { children?: React.ReactNode }
> = ({ children, ...rest }) => {
  return (
    <StyledText variant="subtitle1" {...rest}>
      {children}
    </StyledText>
  )
}

const Subtitle2: ComponentWithAs<
  "div",
  BoxProps & { children?: React.ReactNode }
> = ({ children, ...rest }) => {
  return (
    <StyledText variant="subtitle2" {...rest}>
      {children}
    </StyledText>
  )
}

const Body1: ComponentWithAs<
  "div",
  BoxProps & { children?: React.ReactNode }
> = ({ children, ...rest }) => {
  return (
    <StyledText variant="body1" {...rest}>
      {children}
    </StyledText>
  )
}
const Body2: ComponentWithAs<
  "div",
  BoxProps & { children?: React.ReactNode }
> = ({ children, ...rest }) => {
  return (
    <StyledText variant="body2" {...rest}>
      {children}
    </StyledText>
  )
}

const Caption: ComponentWithAs<
  "div",
  BoxProps & { children?: React.ReactNode }
> = ({ children, ...rest }) => {
  return (
    <StyledText variant="caption" {...rest}>
      {children}
    </StyledText>
  )
}

export const Text = {
  H2,
  H3,
  H5,
  H6,
  Subtitle1,
  Subtitle2,
  Body1,
  Body2,
  Caption,
}
