import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Box } from "@youngagency/young-ui"

const StyledBox = styled(Box)({
  display: "grid",
  gridTemplateRows: "repeat(6, 1fr)",
  gridTemplateColumns: "1fr 1fr",
  gap: "15px",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  "@media screen and (min-width: 768px)": {
    gridTemplateRows: "repeat(3, 1fr)",
    gridTemplateColumns: "repeat(4, 1fr)",
  },
})

const Wrapper = styled(Box)({
  overflow: "hidden",
  maxWidth: "154px",
  width: "100%",
  borderRadius: "16px",
  transform: "translateZ(0%)",
  "@media screen and (min-width: 768px)": {
    maxWidth: "272px",
  },
})

const LogosSection = () => {
  return (
    <StyledBox>
      <Wrapper>
        <StaticImage
          src="./images/Awin.png"
          alt="Awin Logo"
          placeholder="dominantColor"
        />
      </Wrapper>
      <Wrapper>
        <StaticImage
          src="./images/banco-azzoaglio.png"
          alt="Banco Azzoaglio"
          placeholder="dominantColor"
        />
      </Wrapper>
      <Wrapper>
        <StaticImage
          src="./images/Binance.png"
          alt="Binance Logo"
          placeholder="dominantColor"
        />
      </Wrapper>
      <Wrapper>
        <StaticImage
          src="./images/BITFINEX.png"
          alt="Bitfinex Logo"
          placeholder="dominantColor"
        />
      </Wrapper>
      <Wrapper>
        <StaticImage
          src="./images/Satispay.png"
          alt="BitGo Logo"
          placeholder="dominantColor"
        />
      </Wrapper>
      <Wrapper>
        <StaticImage
          src="./images/Chainalysis.png"
          alt="Chainalysis"
          placeholder="dominantColor"
        />
      </Wrapper>
      <Wrapper>
        <StaticImage
          src="./images/Etech.png"
          alt="Etech"
          placeholder="dominantColor"
        />
      </Wrapper>
      <Wrapper>
        <StaticImage
          src="./images/firebloks.png"
          alt="FireBlock Logo"
          placeholder="dominantColor"
        />
      </Wrapper>
      <Wrapper>
        <StaticImage
          src="./images/Kraken.png"
          alt="Kraken Logo"
          placeholder="dominantColor"
        />
      </Wrapper>
      <Wrapper>
        <StaticImage
          src="./images/onfido.png"
          alt="Onfido Logo"
          placeholder="dominantColor"
        />
      </Wrapper>
      <Wrapper>
        <StaticImage
          src="./images/Sella.png"
          alt="Banca Sella Logo"
          placeholder="dominantColor"
        />
      </Wrapper>
      <Wrapper>
        <StaticImage
          src="./images/ViaFintech.png"
          alt="Via Fintech Logo"
          placeholder="dominantColor"
        />
      </Wrapper>
    </StyledBox>
  )
}

export default LogosSection
