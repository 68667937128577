import React from "react"
import {
  styled,
  css,
  Box,
  VStack,
  HStack,
  BoxProps,
  Label,
} from "@youngagency/young-ui"
import { changeLanguageHandler } from "../../utils/general"
import { SupportedLanguageValue } from "../../types/footer"
import { useTranslationContext } from "../../feature/translations/context"
import { isEmpty } from "lodash"

const CustomLanguageSelector = styled(Box)(
  css({
    padding: "16px",
    color: "#000",
    background: "#fff",
    fontSize: "14px",
    boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.08)",
    borderRadius: "16px",
    width: "max-content",
  })
)

export const StyledLink = styled("a")(
  css({
    textDecoration: "none",
    width: "100%",
    transition: "all 75ms lienear",
    "& .lang-label": {
      padding: "8px 12px 8px 8px",
      borderRadius: "100px",
    },
    "&:not(:first-child)": {
      marginTop: "8px",
    },
    "&:hover": {
      "& .lang-label": {
        background: "var(--r-grey-5)",
      },
    },
  })
)

export const LanguageSwitcher = ({
  id,
  ...rest
}: {
  id: string
} & BoxProps) => {
  const { translations } = useTranslationContext()

  const getLanguageElements = React.useCallback((lng: string) => {
    switch (lng) {
      case "en":
        return {
          label: "EN",
          img: "/en_flags.svg",
        }
      case "fr":
        return {
          label: "FR",
          img: "/fr_flags.svg",
        }
      default:
        return {
          label: "IT",
          img: "/it_flags.svg",
        }
    }
  }, [])

  if (!isEmpty(translations))
    return (
      <CustomLanguageSelector id={id} {...rest}>
        <VStack width="100%">
          {translations?.map(({ slug, language }) => {
            return (
              <StyledLink
                style={{
                  textDecoration: "none",
                  color: "unset",
                }}
                title={`switch to ${language.code}`}
                href={`${process.env.CRAWL_URL}${slug}`}
                key={language.code}
                onClick={() =>
                  changeLanguageHandler(
                    language?.code?.toLowerCase() as SupportedLanguageValue
                  )
                }
              >
                {language.code ? (
                  <HStack
                    spacing="8px"
                    align="center"
                    justify="flex-start"
                    className="lang-label"
                  >
                    {
                      <img
                        src={
                          getLanguageElements(language.code.toLowerCase()).img
                        }
                        alt={`${language.code.toLowerCase()}-flags`}
                      />
                    }
                    <Label weight="heavy" size="sm" fontFamily="Matter">
                      {getLanguageElements(language.code.toLowerCase()).label}
                    </Label>
                  </HStack>
                ) : null}
              </StyledLink>
            )
          })}
        </VStack>
      </CustomLanguageSelector>
    )
  return null
}
