import styled from "styled-components"
import { Button } from "@youngagency/young-ui"

export const BlackButton = styled(Button)({
  backgroundColor: "var(--black)",
  color: "var(--white)",
  border: "1px solid var(--black)",
  "&:hover": {
    backgroundColor: "var(--grey-600-v2)",
    border: "1px solid var(--grey-600-v2)",
  },
  "&:disabled, &[disabled]": {
    backgroundColor: "var(--grey-600-v2)",
    border: "1px solid var(--grey-600-v2)",
    color: "var(--grey-300-v2)",
  },
})
