import { navigate } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import usePostHoverAnimation from "../../../components/blog/utils/usePostHoverAnimation"
import { PostType } from "../types"
import { getLocalePostFormattedDate } from "../utils"

const PostContext = React.createContext<useTranformDataContext | null>(null)

export const PostProvider = PostContext.Provider

export const usePostContext = () => React.useContext(PostContext)

export const useTransformPostData = ({
  title,
  slug,
  tags,
  featuredImage,
  seo,
  categories,
  date,
}: PostType) => {
  const { language } = useI18next()
  const formattedData = React.useMemo(() => {
    if (!date) return
    return getLocalePostFormattedDate(date, language)
  }, [language])

  const { name: categoryName, slug: categorySlug } =
    React.useMemo(() => {
      return categories?.nodes?.[0]
    }, []) || {}

  const { name: tagName, slug: tagSlug } =
    React.useMemo(() => {
      return tags?.nodes?.[0]
    }, []) || {}

  const { toggleBoxHoverAnimation, targetRef } = usePostHoverAnimation(
    "box_hover"
  )

  const image = React.useMemo(
    () =>
      featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
        ? getImage(
            featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
          )
        : null,
    []
  )

  const handleNavigation = React.useCallback(() => {
    let navigationLink: string = `/blog/${categorySlug}/${slug}/`
    if (language !== "it") navigationLink = `/${language}${navigationLink}`
    navigate(navigationLink)
  }, [categorySlug, language])

  return {
    formattedData,
    slug,
    categorySlug,
    categoryName,
    toggleBoxHoverAnimation,
    image,
    imageAltText: featuredImage?.node?.altText,
    handleNavigation,
    title,
    tags,
    tagName,
    tagSlug,
    seo,
    targetRef,
  }
}

export type useTranformDataContext = ReturnType<typeof useTransformPostData>
