import {
  Body,
  Box,
  Container,
  VStack,
  Heading,
  Button,
  Icon,
} from "@youngagency/ui"
import {
  Avatar,
  Container as CustomContainer,
} from "../../../components/layout"
import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { CardContainer } from "../../../components/cards/layout/CardContainer"
import { CardContent } from "../../../components/cards/layout/CardContent"
import { bannerStack } from "../../../components/banner/ecosistema/styles/banner-layout.css"
import { SquareCard } from "../../../components/cards/components/SquareCard"
import { gridBy3 } from "../../../components/cards/style/grid-layout.css"
import { centeredSquareCard } from "../../../components/cards/style/card-content.css"
import { CardsSection } from "../../../components/cards/components/CardsSection"
import { SecurityGameLinkKeys, securityGameLink } from "../contants"
import { navigate } from "@reach/router"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { antiPhishingEvents } from "../../../service/tracking"

export const ScamSection = () => {
  const { t, language } = useI18next()
  const { gaTracker } = useSelector((state: RootState) => state.tracking)

  const localizedGameLink = React.useMemo(() => {
    if (language.toLowerCase() in securityGameLink) {
      return securityGameLink[language.toLowerCase() as SecurityGameLinkKeys]
    }
    return securityGameLink["en"]
  }, [language])

  const handleClick = React.useCallback(() => {
    gaTracker?.trackEvent({
      eventName: antiPhishingEvents.security_body_quiz,
    })
    navigate(localizedGameLink)
  }, [localizedGameLink, gaTracker])
  return (
    <>
      <CustomContainer as="section">
        <Container>
          <VStack
            spacing={24}
            align="center"
            justify="center"
            atoms={{
              width: "full",
              mx: "auto",
            }}
          >
            <Heading
              variant="3"
              atoms={{
                textAlign: "center",
              }}
              fontWeight={400}
              dangerouslySetInnerHTML={{
                __html: t("security.scam_section.title"),
              }}
            />
            <Body
              variant="1"
              atoms={{
                textAlign: "center",
              }}
              color="grey200"
              style={{
                maxWidth: "700px",
              }}
              fontWeight={600}
            >
              {t("security.scam_section.description")}
            </Body>
          </VStack>
        </Container>
      </CustomContainer>

      <CardsSection
        marginTop={{
          mobile: 32,
          md: 40,
        }}
      >
        <VStack
          spacing={"unset"}
          align="center"
          justify="center"
          atoms={{
            __rowGap: "1rem",
            width: "full",
            mx: "auto",
          }}
        >
          <div className={gridBy3}>
            <SquareCard disableHover>
              <CardContainer width={"full"} background={"grey10"}>
                <CardContent
                  alignItems="center"
                  width={"full"}
                  className={centeredSquareCard}
                >
                  <VStack
                    align="center"
                    spacing={{
                      mobile: 16,
                      lg: 24,
                    }}
                  >
                    <Avatar
                      shape="circle"
                      width={["56px", "72px"]}
                      height={["56px", "72px"]}
                      background={"var(--white)"}
                    >
                      <Icon
                        icon="pinFilled"
                        atoms={{
                          width: {
                            mobile: 24,
                            md: 32,
                          },
                          height: {
                            mobile: 24,
                            md: 32,
                          },
                        }}
                      />
                    </Avatar>
                    <Heading
                      variant="6"
                      atoms={{
                        textAlign: "center",
                      }}
                      fontWeight={600}
                      dangerouslySetInnerHTML={{
                        __html: t("security.scam_section.cards.card_1.title"),
                      }}
                    />
                  </VStack>
                </CardContent>
              </CardContainer>
            </SquareCard>
            <SquareCard disableHover>
              <CardContainer width={"full"} background={"grey10"}>
                <CardContent
                  alignItems="center"
                  width={"full"}
                  className={centeredSquareCard}
                >
                  <VStack
                    align="center"
                    spacing={{
                      mobile: 16,
                      lg: 24,
                    }}
                  >
                    <Avatar
                      shape="circle"
                      width={["56px", "72px"]}
                      height={["56px", "72px"]}
                      background={"var(--white)"}
                    >
                      <Icon
                        icon="coinFilled"
                        atoms={{
                          width: {
                            mobile: 24,
                            md: 32,
                          },
                          height: {
                            mobile: 24,
                            md: 32,
                          },
                        }}
                      />
                    </Avatar>
                    <Heading
                      variant="6"
                      atoms={{
                        textAlign: "center",
                      }}
                      fontWeight={600}
                      dangerouslySetInnerHTML={{
                        __html: t("security.scam_section.cards.card_2.title"),
                      }}
                    />
                  </VStack>
                </CardContent>
              </CardContainer>
            </SquareCard>
            <SquareCard disableHover>
              <CardContainer width={"full"} background={"grey10"}>
                <CardContent
                  alignItems="center"
                  width={"full"}
                  className={centeredSquareCard}
                >
                  <VStack
                    align="center"
                    spacing={{
                      mobile: 16,
                      lg: 24,
                    }}
                  >
                    <Avatar
                      shape="circle"
                      width={["56px", "72px"]}
                      height={["56px", "72px"]}
                      background={"var(--white)"}
                    >
                      <Icon
                        icon="supportFilled"
                        atoms={{
                          width: {
                            mobile: 24,
                            md: 32,
                          },
                          height: {
                            mobile: 24,
                            md: 32,
                          },
                        }}
                      />
                    </Avatar>
                    <Heading
                      variant="6"
                      atoms={{
                        textAlign: "center",
                      }}
                      fontWeight={600}
                      dangerouslySetInnerHTML={{
                        __html: t("security.scam_section.cards.card_3.title"),
                      }}
                    />
                  </VStack>
                </CardContent>
              </CardContainer>
            </SquareCard>
          </div>
          <Box
            background="green10"
            width="full"
            borderRadius={16}
            p={{
              mobile: 24,
              lg: 48,
            }}
          >
            <Box className={bannerStack}>
              <VStack spacing={8}>
                <Heading variant="6" fontWeight={600}>
                  {t("security.scam_section.banner.title")}
                </Heading>
                <Body variant="1">
                  {t("security.scam_section.banner.description")}
                </Body>
              </VStack>
              <Button variant="solid" onClick={handleClick}>
                {t("security.scam_section.banner.button")}
              </Button>
            </Box>
          </Box>
        </VStack>
      </CardsSection>
    </>
  )
}
