import { useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import { Container as CustomContainer } from "../../../components/layout"
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@youngagency/young-ui"
import {
  Container,
  VStack,
  Heading,
  Subtitle,
  Body,
  Box,
} from "@youngagency/ui"

const DEFAULT_QUEERY = "security.footer.faqs.faq"

export const FaqSection = () => {
  const { t } = useTranslation()
  const faqs = React.useMemo(
    () => [
      {
        question: t(`${DEFAULT_QUEERY}_1.question`),
        answer: t(`${DEFAULT_QUEERY}_1.answer`),
      },
      {
        question: t(`${DEFAULT_QUEERY}_2.question`),
        answer: t(`${DEFAULT_QUEERY}_2.answer`),
      },
      {
        question: t(`${DEFAULT_QUEERY}_3.question`),
        answer: t(`${DEFAULT_QUEERY}_3.answer`),
      },
    ],
    [t]
  )
  return (
    <CustomContainer as="section">
      <Container>
        <VStack
          spacing={24}
          align="center"
          justify="center"
          atoms={{
            width: "full",
            mx: "auto",
          }}
        >
          <Heading
            variant="3"
            color="white"
            atoms={{
              textAlign: "center",
            }}
            fontWeight={600}
            dangerouslySetInnerHTML={{
              __html: t("security.footer.title"),
            }}
          />
        </VStack>
        <Box
          marginTop={{
            mobile: 24,
            md: 48,
          }}
          mx="auto"
          width="full"
        >
          <Accordion allowMultiple defaultIndex={0}>
            {faqs.map((faq, index) => {
              return (
                <AccordionItem
                  key={index}
                  background="var(--grey-700-v2)"
                  mt={index === 0 ? "0px" : "24px"}
                  borderRadius="16px"
                  overflow="hidden"
                  py={["36px", "44px"]}
                  px={["24px", "48px"]}
                >
                  <AccordionButton
                    justifyContent="space-between"
                    padding="unset !important"
                  >
                    <Subtitle variant="1" fontWeight={600} color="white">
                      {faq.question}
                    </Subtitle>
                    <AccordionIcon
                      icon="chevron_down"
                      fontSize={"24px"}
                      color="white"
                    />
                  </AccordionButton>
                  <AccordionPanel mt="24px">
                    <Body variant="2" color="white">
                      {faq.answer}
                    </Body>
                  </AccordionPanel>
                </AccordionItem>
              )
            })}
          </Accordion>
        </Box>
      </Container>
    </CustomContainer>
  )
}
