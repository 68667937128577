import React from "react"
import { ThemeProvider } from "styled-components"
import { YoungProvider as LegacyYoungProvider } from "@youngagency/young-ui"
import { YoungProvider, YoungProviderProps } from "@youngagency/ui"

import { Provider } from "react-redux"
import { store } from "./src/redux/store"
import MediaProvider from "./src/mediaTheme"
import { persistUtm } from "./src/redux/actions/handlePersistentUtm"
import { initTracker } from "./src/redux/reducers/trackingSlice"

const HARD_CODED_THEME: YoungProviderProps["options"] = {
  colorMode: {
    defaultMode: "light",
    mode: "light",
  },
  shouldUseResponsiveTypography: true,
}

store.dispatch(persistUtm())
store.dispatch(initTracker())

const GlobalProvider = ({ element }) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={{ layout: "light" }}>
        <LegacyYoungProvider>
          <YoungProvider options={HARD_CODED_THEME}>
            <MediaProvider>
             {element}
            </MediaProvider>
          </YoungProvider>
        </LegacyYoungProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default GlobalProvider
