import React from "react"
import { Box } from "@youngagency/ui"
import {
  clubsRowStyle,
  tableCell,
  tableCellContent,
  tableRow,
} from "../styles/tabs.css"
import { BaseBox } from "../../../types/system"

type TabsProps = Omit<BaseBox, "height" | "width"> & {
  id?: string
  className?: string
}

export const TableCell: React.FC<TabsProps> = ({ children, ...props }) => {
  return (
    <Box as="td" {...props} className={tableCell}>
      {children}
    </Box>
  )
}

export const TableRow: React.FC<TabsProps> = ({ children, ...props }) => {
  return (
    <Box {...props} as="tr" className={tableRow}>
      {children}
    </Box>
  )
}

export const TableCellContent: React.FC<TabsProps> = ({
  children,
  ...props
}) => {
  return (
    <Box {...props} className={tableCellContent}>
      {children}
    </Box>
  )
}

export const ClubsRow: React.FC<TabsProps> = ({ children, ...props }) => {
  return (
    <Box {...props} className={clubsRowStyle}>
      {children}
    </Box>
  )
}
