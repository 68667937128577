import { BoxProps, Box } from "@youngagency/young-ui"
import React from "react"
import { ResponsiveText } from "../layout"
const FaqTitle = ({ text, ...rest }: BoxProps & { text: string }) => {
  return (
    <Box {...rest}>
      <ResponsiveText
        line={["25px", "39px"]}
        dimension={["20px", "34px"]}
        fontWeight={600}
        as="h2"
      >
        {text}
      </ResponsiveText>
    </Box>
  )
}

export default FaqTitle
