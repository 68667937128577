import { Box, BoxProps } from "@youngagency/young-ui"
import React from "react"
import styled from "styled-components"

const CustomStack = styled.ul`
  width: 100%;
  padding-left: inherit;
  & > *:not(:first-of-type) {
    margin-top: 8px;
  }

  li {
    list-style: none;
  }

  @media screen and (min-width: 767px) {
    & > *:not(:first-of-type) {
      margin-top: 20px;
    }
  }
`

const FaqContent = ({ children, ...rest }: BoxProps) => {
  return (
    <Box {...rest}>
      <CustomStack>{children}</CustomStack>
    </Box>
  )
}

export default FaqContent
