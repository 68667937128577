import React from "react"
import { ComponentWithAs } from "../../../types/system"
import { Box, BoxProps } from "@youngagency/ui"
import { cardText, cardTextVariant } from "../style/card-text.css"
import clsx from "clsx"

type CardCustomTextProps = {
  variant: "h3"
} & React.HTMLAttributes<HTMLDivElement> &
  BoxProps

export const CardCustomText = ({
  variant,
  children,
  className,
  ...rest
}: CardCustomTextProps) => {
  const textVariant = cardTextVariant({ tag: variant })
  return (
    <Box
      as={variant}
      {...rest}
      className={clsx(cardText, textVariant, className)}
    >
      {children}
    </Box>
  )
}
