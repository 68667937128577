import React, { useEffect } from "react"
import styled from "styled-components"
import { useI18next } from "gatsby-plugin-react-i18next"
import "../landing.css"
import { Flex } from "../styledUi"
import { Container, ResponsiveText, ColumnStack, RowStack } from "../layout"
import { MENU_ANCHOR_LIST } from "./anchorlist"
import {
  handleActualLetter,
  formatGlossaryList,
  highlightsSearchedWord,
} from "./utils"
import {
  Box,
  SearchInput,
  useMediaQuery,
  Heading,
  Paragraph,
  Label,
  Button,
  Display,
  VStack,
  HStack,
} from "@youngagency/young-ui"
import { SeoLink } from "../../feature/seo"
import { GlossaryTermProps } from "../../types/glossary"

//Contiene le parole con la short description
const CardWordDiv = styled(Container)`
  cursor: pointer;
  &:hover {
    background-color: var(--light-gray-8);
  }
  @media screen and (max-width: 768px) {
    background-color: var(--light-gray-8);
  }
`

const StyledLink = styled.a`
  .anchor-text {
    transition: color 25ms ease;
    &:hover {
      color: black;
    }
  }
`

type Props = GlossaryTermProps & { search: string }
type GlossaryProps = Queries.GlossaryMainPageQuery["allWpGlossary"]["nodes"]

const CardWord = ({ title, seo, slug, search }: Props) => {
  return (
    <SeoLink
      link={`/glossary/` + slug + "/"}
      isExternal={false}
      style={{
        width: "100%",
      }}
    >
      <CardWordDiv
        size={["md", "md", "fullwidth", "fullwidth"]}
        display="flex"
        flexDirection="column"
        p="16px"
        borderRadius="16px"
        mb="16px"
        mt={["16px", "16px", "0px", "0px"]}
      >
        {title ? (
          <Heading
            as="h2"
            fontFamily="Matter"
            weight="heavy"
            my="0px"
            color="black"
            size="md"
            dangerouslySetInnerHTML={{
              __html: highlightsSearchedWord(title, search),
            }}
          />
        ) : null}

        <Paragraph
          size={"md"}
          fontFamily="Matter"
          color="grey.1"
          my="0px"
          dangerouslySetInnerHTML={{
            __html: highlightsSearchedWord(
              seo?.metaDesc || "No description provided",
              search
            ),
          }}
          style={{
            marginTop: "16px",
          }}
        ></Paragraph>
      </CardWordDiv>
    </SeoLink>
  )
}

const Glossary = ({ data }: { data: GlossaryProps }) => {
  const { isMobile } = useMediaQuery()

  const { t } = useI18next()

  const [search, setSearch] = React.useState("")

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (document) {
      handleActualLetter(MENU_ANCHOR_LIST)
    }
  }, [search])

  const emptyList = React.useCallback(() => {
    return (
      <Container display="flex" flexDirection="column" size="lg">
        <Label
          fontFamily="Matter"
          my="0px"
          size="md"
          color="black"
          style={{
            textAlign: "center",
          }}
        >
          {t("markets.txt_empty_list", {
            search: search,
          })}
        </Label>

        <Button
          variant="ghost"
          // color={"default"}
          style={{
            marginTop: "34px",
          }}
          onClick={() => setSearch("")}
        >
          {t("markets.btn_empty_list")}
        </Button>
      </Container>
    )
  }, [search])

  formatGlossaryList(data as any, MENU_ANCHOR_LIST, search)

  const printGlossaryList = () => {
    let list: JSX.Element[] = []

    MENU_ANCHOR_LIST.forEach(group_words => {
      let list_card: JSX.Element[] = []

      group_words.words_list.forEach(word => {
        list_card.push(<CardWord key={word.slug} {...word} search={search} />)
      })

      let item_to_print = (
        <Container
          display="flex"
          mx="inherit"
          alignItems="center"
          my="0px"
          key={"row_" + group_words.link}
          id={"row_" + group_words.link}
          style={{
            scrollMarginTop: isMobile ? "220px" : "168px",
          }}
        >
          <HStack
            width="100%"
            mb={["16px", "96px", "96px", "96px"]}
            align="start"
            position="relative"
            mt={["24px", "96px"]}
          >
            <Box
              display={["none", "none", "flex", "flex"]}
              minWidth={["0px", "0px", "172px", "172px"]}
              flexDirection="column"
              alignItems="center"
              height="100%"
              position="sticky"
              top="168px"
              width={["0px", "0px", "auto", "auto"]}
              style={{
                transition: "0.2s",
              }}
            >
              <Display
                size="lg"
                fontFamily="Matter"
                my="0px"
                color="gray.9"
                id={group_words.link}
                style={{
                  scrollMarginTop: "168px",
                  visibility: isMobile ? "hidden" : "visible",
                }}
              >
                {group_words.text}
              </Display>
            </Box>
            <ColumnStack ml={["0px", "24px"]}>{list_card}</ColumnStack>
          </HStack>
        </Container>
      )

      if (group_words.words_list.length > 0) {
        list.push(item_to_print)
      }
    })

    return list
  }

  const printMenuRow = () => {
    return (
      <Flex
        gap={["16px", "16px", "none", "none"]}
        width="100%"
        align={["start", "start", "start", "center", "center"]}
        direction={["column", "column", "row", "row"]}
      >
        <Box maxWidth={["unset", "362px"]} width="100%">
          <SearchInput
            size={"md"}
            isClearable={true}
            backgroundColor={"grey.8"}
            placeholder="Cerca in tutti gli asset"
            onChange={e => {
              if (e && e.target) {
                let element = e.target as HTMLInputElement
                setSearch(element.value)
              }
            }}
            style={{
              fontFamily: "Matter, Arial",
              fontWeight: 400,
              width: "100%",
            }}
          />
        </Box>

        <RowStack
          width="100%"
          rGap={["6px", "12px"]}
          pl={["0px", "24px"]}
          position={["relative", "static"]}
          pt={["24px", "0px"]}
          background={["#ffffff", "inherit"]}
          overflowX={["scroll", "scroll", "scroll", "visible"]}
        >
          {new Set(printGlossaryAnchorMenu())}
        </RowStack>
      </Flex>
    )
  }

  const printGlossaryAnchorMenu = () => {
    let list: JSX.Element[] = []

    MENU_ANCHOR_LIST.forEach((item, index) => {
      if (item.words_list.length > 0 || item.disabled) {
        list.push(
          <StyledLink
            key={index}
            href={"#row_" + item.link}
            id={"anchor_" + item.link}
            style={{
              textDecoration: "none",
              cursor: item.disabled ? "not-allowed" : "pointer",
            }}
          >
            <Paragraph
              size="md"
              fontFamily="Matter"
              my="0px"
              className="anchor-text"
              color={item.disabled ? "var(--light-gray-2)" : "grey.1"}
              style={{
                padding: "6px",
              }}
            >
              {item.text}
            </Paragraph>
          </StyledLink>
        )
      }
    })

    return list
  }

  return (
    <VStack align="center" position="relative">
      <Box
        px="24px"
        pt="0px"
        pb={["48px", "120px"]}
        width="100%"
        background="linear-gradient(180deg, rgba(248, 248, 250, 0) 0%, #f8f8fa 100%);"
      >
        <Container display="flex" flexDirection="column" size={["md", "lg"]}>
          <Box textAlign={["center", "left"]} marginTop={"48px"}>
            <ResponsiveText
              line={["39px", "96px"]}
              fontWeight={700}
              dimension={["34px", "96px"]}
              as="h1"
            >
              {t("glossary.title")}
            </ResponsiveText>
            <Box mt="24px">
              <ResponsiveText
                line={["21px", "25px"]}
                fontWeight={400}
                dimension={["20px", "24px"]}
              >
                {t("glossary.description")}
              </ResponsiveText>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container
        pt="24px"
        pb={["16px", "24px", "24px", "24px"]}
        mb={["16px", "96px", "96px", "96px"]}
        position="sticky"
        top={["48px", "56px"]}
        zIndex={1}
        bg="white"
        style={{
          transition: "0.2s",
        }}
      >
        <VStack align="center">{printMenuRow()}</VStack>
      </Container>

      {printGlossaryList().length > 0 ? printGlossaryList() : emptyList()}
    </VStack>
  )
}

export default Glossary
