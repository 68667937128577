import { I18nLink } from "./I18nLink"
import React from "react"
import { GatsbyLinkProps } from "gatsby"
import { triggerClickToExchangeEvents } from "../../../utils/general"

const linkStyle = { textDecoration: "none", color: "unset" }

export type NoToLink = Omit<GatsbyLinkProps<unknown>, "to" | "ref">

export const SeoLink = ({
  isExternal,
  link,
  rel = "none",
  children,
  target,
  className,
  style,
  isConversion,
  ...rest
}: {
  isExternal: boolean
  link: string
  children: React.ReactNode
  rel?: string
  target?: string
  className?: string
  style?: Record<string, string | number | undefined>
  isConversion?: true
} & NoToLink) => {
  if (isExternal) {
    return (
      <a
        style={{ ...linkStyle, ...style }}
        href={`${link}`}
        rel={isConversion ? "_self" : rel}
        className={className}
        target={target}
        onClick={isConversion ? triggerClickToExchangeEvents : undefined}
      >
        {children}
      </a>
    )
  }
  return (
    <I18nLink
      style={{ ...linkStyle, ...style }}
      className={className}
      to={link}
      rel={rel}
      {...rest}
    >
      {children}
    </I18nLink>
  )
}
