import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { CurrencyLookupTable } from "../../../redux/services/currency/types"
import { ParsedGetAllChartsV2ApiResponse } from "../../../service/charts/types"

export type TokenV2ExclusionConditionProps = {
  isPro: boolean
  isBase: boolean
  isSaving: boolean
  isLocked: boolean
  isProOnly: boolean
  symbol: string
} & Record<string, any>

type Props = {
  currencies?: CurrencyLookupTable
  chartData: ParsedGetAllChartsV2ApiResponse
}

export const useTokenExclusion = () => {
  const data: Queries.AllListedDinstictsQuery = useStaticQuery(query)
  const isTokenExluded = React.useCallback(
    <T extends TokenV2ExclusionConditionProps>(x: T) =>
      x.isBase &&
      !x.isLocked &&
      !["USD", "GBP", "EUR"].includes(x.symbol) &&
      data.allToken.distinct.includes(x.symbol.toUpperCase()),

    []
  )

  const currencyDataAndChartsParser = React.useCallback(
    ({ currencies, chartData }: Props) =>
      currencies
        ? Object.entries(currencies)
            .map(el => ({ ...el[1], ...chartData[el[1].symbol] }))
            .filter(x => isTokenExluded(x))
        : [],
    [isTokenExluded]
  )
  return { currencyDataAndChartsParser }
}

const query = graphql`query AllListedDinsticts {
  allToken(
    sort: {details: {marketCap: DESC}}
    filter: {id: {nin: ["EUR", "USD", "GBP"]}, isUnlisted: {eq: null}}
  ) {
    distinct(field: {symbol: SELECT})
  }
}`
