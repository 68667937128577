import React from "react"
import { navigate } from "@reach/router"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { useI18next } from "gatsby-plugin-react-i18next"

type Props =
  | {
      defaultLink: string
      callback?: never
    }
  | {
      defaultLink?: string | never
      callback: () => void | Promise<void>
    }

export const useHandlePersistUtm = () => {
  const { promo_utm } = useSelector((state: RootState) => state.promo)
  const { language } = useI18next()
  const handlePersistUtmCallback = React.useCallback(
    ({ defaultLink, callback }: Props) => {
      switch (promo_utm.type) {
        case "persistent":
          return navigate(
            `https://exchange.youngplatform.com/${language}/signup/?${promo_utm.value}`
          )
        case "none":
        case "session":
        default:
          if (callback) return callback()
          if (defaultLink) return navigate(defaultLink)
      }
    },
    [promo_utm.type, promo_utm.value, language]
  )

  /*
   * This function is used to persist the utm in the link
   */
  const handlePersistUtmLink = React.useCallback(
    (link: string) => {
      switch (promo_utm.type) {
        case "persistent":
          return `https://exchange.youngplatform.com/${language}/signup/?${promo_utm.value}`
        case "none":
        case "session":
        default:
          return link
      }
    },
    [promo_utm.type, promo_utm.value, language]
  )

  return { handlePersistUtmCallback, handlePersistUtmLink }
}


export const useHandleExternalPersistUtm = () => {
  const { promo_utm } = useSelector((state: RootState) => state.promo)
  const { language } = useI18next()
  const handleExternalPersistUtmCallback = React.useCallback(
    ({ defaultLink, callback }: Props) => {
      switch (promo_utm.type) {
        case "persistent":
          return navigate(
            `${defaultLink}/?${promo_utm.value}`
          )
        case "none":
        case "session":
        default:
          if (callback) return callback()
          if (defaultLink) return navigate(defaultLink)
      }
    },
    [promo_utm.type, promo_utm.value, language]
  )

  return { handleExternalPersistUtmCallback }
}
