import { Box, Button } from "@youngagency/young-ui"
import React from "react"
import { SubTitle } from "../../../../components"
import { RowStack } from "../../../../components/layout"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { navigate } from "@reach/router"
import { BannerContainer } from "./CustomBanner"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { isBannerVisible } from "../../utils"

export const FreenameBanner = ({ id }: { id: number }) => {
  const data = useStaticQuery(query) as Queries.FreeNameArticlesQuery
  const { t } = useTranslation()
  const handleNavigate = React.useCallback(() => {
    navigate("https://youngexchange.page.link/xFaU")
  }, [])

  const isVisible = React.useMemo(
    () => isBannerVisible(id, data?.allWpPost?.nodes),
    [data.allWpPost.nodes, id]
  )

  if (isVisible)
    return (
      <BannerContainer mt={["24px", "48px"]}>
        <RowStack
          rGap={["16px", "24px"]}
          p={["16px", "24px"]}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box maxWidth={["200px", "518px"]}>
            <SubTitle>{t("blog.freename.title")}</SubTitle>
            <Box mt={["8px", "16px"]}>
              <Button onClick={handleNavigate}>{t("blog.freename.cta")}</Button>
            </Box>
          </Box>
          <Box
            maxWidth={["96px", "166px"]}
            width="100%"
            borderRadius="16px"
            style={{
              transform: "translateZ(0)",
            }}
          >
            <StaticImage
              src="./images/freename.png"
              alt={t("blog.freename.title")}
            />
          </Box>
        </RowStack>
      </BannerContainer>
    )
  return null
}

const query = graphql`
  query FreeNameArticles {
    allWpPost(filter: { databaseId: { in: [32754, 32757] } }) {
      nodes {
        databaseId
        translations {
          databaseId
        }
      }
    }
  }
`
